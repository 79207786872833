import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Router } from '@angular/router';
import { CommonListService } from '../../services/common-list.service';
import { CustomerService } from '../../services/customer-service.service';
import { ToastrService } from 'ngx-toastr';
import { PrefectureModel } from '../../models/prefecture-model.model';
import { CountryModel } from '../../models/country-model.model';
import { CompanyModel } from '../../models/company-model.model';
import { CompanyIndustryClassificationModel } from '../../models/company-industry-classification-model.model';
import * as _ from 'underscore';
import { Title, Meta } from '@angular/platform-browser';
import { SelectOptionModel } from 'src/app/models/select-option-model';

@Component({
  selector: 'app-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.css']
})
export class CustomerProfileComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  commonListService: CommonListService;
  customerService: CustomerService;
  customerModel: CompanyModel = new CompanyModel();
  countryList: CountryModel[];
  prefectureList: PrefectureModel[];
  toastr: ToastrService;
  industryClassificationList: CompanyIndustryClassificationModel[];
  routerService: Router;
  offeringOrderParameterList: Array<SelectOptionModel>;
  offeringOrderParameterDefaultList: Array<SelectOptionModel>;
  isDefaultOrdering:boolean;

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private _commonListService: CommonListService,
    private _customerService: CustomerService,
    private _toastr: ToastrService,
    private titleService: Title,
    private meta: Meta) {

    this.router = _router.url;
    this.routerService = _router;
    this.sharedService = dataShareService;
    this.commonListService = _commonListService;
    this.customerService = _customerService;
    this.toastr = _toastr;

    this.sharedService = dataShareService;

    this.offeringOrderParameterList=new Array<SelectOptionModel>();
    this.offeringOrderParameterDefaultList=new Array<SelectOptionModel>();
    this.isDefaultOrdering=true;

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = "登録情報変更";
    this.sharedService.setSharedData(this.shareableData);






    if(this.sharedService.currentCulture=='jp'){

      this.titleService.setTitle("英文校正サイトの登録情報を確認");
      this.meta.updateTag({ name: 'keywords', content: '登録情報,英語校正,英語,情報変更,専門エディター校正,英語エディター,校正会社,登録論文校正,英語論文チェック,校正チェック' });
      this.meta.updateTag({ name: 'description', content: '英文校正サイトの登録情報を確認し,サイトをより的確に活用してみましょう。納品物の受領までウェブ上で完結。簡単,早い,確実！お問い合わせ,お見積りは無料。' });

    }
    else{


      this.titleService.setTitle("editing-Pro. Register information confirmation");
      this.meta.updateTag({ name: 'keywords', content: 'register information,Englishediting,English, edit info,professional editor editing,English editor,editing company,register academic writing editing,English academic writingcheck,editingcheck' });
      this.meta.updateTag({ name: 'description', content: 'Confirm registered information on editing-Pro. Check to utilize the site more efficiently. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });

    }
    
    this.fillOfferOrderParameterList();
  }

  fillOfferOrderParameterList()
  {
    this.offeringOrderParameterList=this.commonListService.getOfferingOrderParameterList();
    this.offeringOrderParameterDefaultList=this.commonListService.getOfferingOrderParameterDefaultList();
  }

  public updateCustomer() {

    if(this.isDefaultOrdering)
    {
      this.customerModel.FirstParameter = this.customerModel.SecondParameter = this.customerModel.ThirdParameter = this.customerModel.FourthParameter=null;
    }

    let industryClassList = this.getSlectedIndustryClassificationItems();
    this.customerService.updateCustomer(this.customerModel, industryClassList).subscribe((data) => {
      if (data) {
        this.toastr.success("アップデートできました。");//"Updated Successfully!"
      }
      else {
        this.toastr.success("アップデートに失敗しました。");//"Could not be Saved!"
      }
    });
  }

  private getCustomerModel() {
    this.customerService.getCompanyById(this.sharedService.currentUser.ID).subscribe((data) => {
      this.customerModel = data;
      if(this.customerModel.Name_Global=="Individual")
      {
        this.customerModel.Name_Global="個人";
      }
      this.onChangeCountry();
      if(data && (data.FirstParameter || data.SecondParameter || data.ThirdParameter || data.FourthParameter))
      {
        this.isDefaultOrdering=false;
      }

      this.industryClassificationList = this.customerModel.CompanyAssociatedIndustryClassifications;
    })

  }

  getSlectedIndustryClassificationItems(): CompanyIndustryClassificationModel[] {
    let industryClassificationSelectdItemList = new Array<CompanyIndustryClassificationModel>();
    _.each(this.industryClassificationList, function (parentItem, index) {
      _.each(parentItem.itemList, function (item, i) {
        if (item.IsSelected)
          industryClassificationSelectdItemList.push(item);
      });
    });

    let selectedItems = new Array<CompanyIndustryClassificationModel>();
    _.each(industryClassificationSelectdItemList, function (item, i) {
      let m = new CompanyIndustryClassificationModel();
      m.IndustryClassificationID = item.IndustryClassificationId;
      m.IndustryClassificationItemID = item.Id;
      m.IsSelected = item.IsSelected;
      selectedItems.push(m);
    });

    return selectedItems;
  }

  onChangeCountry() {
    //this.customerModel.CompanyLocationID=countryID
    this.commonListService.getPrefecturesByCountryID(this.customerModel.CompanyLocationID).subscribe((data) => {
      this.prefectureList = data;
    });
  }

  ngOnInit() {
    this.commonListService.getCountries(this.sharedService.currentCulture).subscribe((data) => {
      this.countryList = data;
    });

    this.getCustomerModel();
  }

}
