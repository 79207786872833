export class OrderEvaluationModel {
    EvaluationID:string
    TranslatorID:string
    TranslatorAs2ndID:string
    OrderID:string
    CustomerNo:string
    TranslatorNo:string
    OrderNo:string
    ClientID:string
    Q1Score:number
    Q2Score:number
    Q3Score:number
    Q4Score:number
    AvgScore:number
    Comment:string
    EvaluationDate:Date
    ServiceEvaluationScore:number
    FeedbackCompany:string
    TargetLanguage:string
    SourceLanguage:string
    TranslationFieldName:string
    TotalRecords:number
    AssignedTranslatorNo:number
    Assigned2ndEditorNo:number
}
