import { Component, OnInit } from '@angular/core';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { OrderEvaluationModel } from 'src/app/models/order-evaluation-model.model';
import { DataShareService } from 'src/app/services/data-share.service';
import { DataSharingModel } from 'src/app/models/data-sharing-model';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.css']
})
export class EvaluationComponent implements OnInit {
  affiliateService: AffiliateService;
  evulationList: { currentPage: number, totalItems: number, dataSource: OrderEvaluationModel[] };
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  pageSize: number;

  constructor(private _affiliateService: AffiliateService, private _router: Router,
    private dataShareService: DataShareService,
    private titleService: Title,
    private meta: Meta) {

    this.router = _router.url;
    this.sharedService = dataShareService;
    this.affiliateService = _affiliateService;
    this.evulationList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.pageSize = 10;

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = "お客様評価";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("お客様評価 | 翻訳プロ(editing-Pro.)管理者ページ");
    this.meta.addTag({ name: 'keywords', content: '英文校正, editing-pro, 管理者, お客様評価' });
    this.meta.addTag({ name: 'description', content: 'エディティング者プラスコーディネーターによるエディティングチェックで、簡単、確実な英語エディティングサービスをご提供中。高品質なエディティングをお届けします。お見積りは無料。' });
    this.getAffiliateEvaluationList();
  }

  getAffiliateEvaluationList(pageNo: number = 1) {
    let clientId = JSON.parse(localStorage.getItem("currentUser")).ID;
    this.affiliateService.getAffiliateEvaluationList(clientId, 3, this.sharedService.currentCulture, pageNo, this.pageSize).subscribe(data => {
      if (data) {
        this.evulationList.currentPage = pageNo;
        this.evulationList.dataSource = data;
        if (data.length > 0)
          this.evulationList.totalItems = data[0].TotalRecords;
      }
    });

  }

  onWebOrderPageIndexChange(event: PageChangedEvent) {
    if (localStorage.getItem("currentUser")) {
      let clientId = JSON.parse(localStorage.getItem("currentUser")).ID;
      this.getAffiliateEvaluationList(event.page);
    }
  }


  ngOnInit() {
  }

}
