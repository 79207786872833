import { Injectable } from '@angular/core';
import { PriceRowHeadModel } from '../models/price-row-head.model';
import { EditingPreferenceModel } from '../models/editing-pro-preference-model.model';
import { PriceMatricsModel } from '../models/price-matrics.model';
import { SpecializedFieldModel } from '../models/specialized-field.model';
import { EditingChargeSettingsModel } from '../models/editing-charge-settings.model';
import { EstimationService } from './estimation.service';
import { OrderDeliveryMode, DocumentVolume } from '../Enums.enum';

@Injectable({
   providedIn: 'root'
})
export class PriceMatrixService {
   //planType: 1:Light 2:Business 3:Expert
   editingChargeSettings: EditingChargeSettingsModel;
   priceMatrixRowHeads: PriceRowHeadModel;
   constructor(private _estimationService: EstimationService, ) {
      this.priceMatrixRowHeads = new PriceRowHeadModel();

   }

   private getEditingLanguageWeightByCode(code: string): number {
      let weight: number = 1;
      if (this.inParticularSpecializedFieldList(code)) {
         switch (code) {
            case "032":  //english
               weight = this.editingChargeSettings.LanguageEN;
               break;
            case "037":
               weight = this.editingChargeSettings.LanguageKR;
               break;
            case "088":  //chinese
            case "096":
            case "097":
               weight = this.editingChargeSettings.LanguageCN;
               break;
            case "113": //japanese
               weight = this.editingChargeSettings.LanguageJP;
               break;
            default:
               weight = 1;
               break;
         }

      }
      return weight;
   }

   private getEditingFieldAreaWeightByCode(code: string): number {
      let weight: number = 1;
      if (this.inParticularSpecializedFieldList(code)) {
         switch (code) {
            case "01":
               weight = this.editingChargeSettings.ContractsLegalArea;
               break;
            case "04":
               weight = this.editingChargeSettings.MedicalPharmaceuticalArea;
               break;
            case "10":
               weight = this.editingChargeSettings.PatentIntellectualArea;
               break;
            case "12":
               weight = this.editingChargeSettings.NormalDocumentsArea;
               break;
            case "13":
               weight = this.editingChargeSettings.FamilyRegisterCertificateArea;
               break;
         }
         
      }

      return weight;
   }

   getPriceMatrix(criteria: EditingPreferenceModel) {
      let subTypes: PriceRowHeadModel = new PriceRowHeadModel();
      //subTypes = criteria.IsDoubleCheck ? this.getDoubleCheckPriceRowHeads(criteria.WordCount) : this.getSingleCheckPriceRowHeads(criteria.WordCount);
      this.getPriceMatrixRowHeads();

      subTypes = this.priceMatrixRowHeads;
      let rowHeads: Array<number> = new Array<number>();
      rowHeads.push(subTypes.RowHead1.ID);
      rowHeads.push(subTypes.RowHead2.ID);
      rowHeads.push(subTypes.RowHead3.ID);
      rowHeads.push(subTypes.RowHead4.ID);

      let priceMatrix = new Array<PriceMatricsModel>();
      let r: PriceMatricsModel = new PriceMatricsModel();
      priceMatrix.push(r); //row 1
      let r1: PriceMatricsModel = new PriceMatricsModel();
      priceMatrix.push(r1); //row 2
      let r2: PriceMatricsModel = new PriceMatricsModel();
      priceMatrix.push(r2); //row 3
      let r3: PriceMatricsModel = new PriceMatricsModel();
      priceMatrix.push(r3); // row 4

      for (let p = 1; p <= 3; p++)//planType: 1:Light 2:Business 3:Expert
      {

         for (let s = 0; s < 4; s++) //4 sub type //except 30min,90min & 4h all are 100% ,30min-350%,90min-220%,4h-150%
         {
            let subTypeId: number = rowHeads[s];
            let mode: OrderDeliveryMode = s;
            let price: number = 0;
            price = this.calculatePrice(p, subTypeId, criteria, mode);
            if(price<150)  //minimum price 150 Yen issue-ChangeEP18
            {
               price=150;
            }
            if (p == 1)
               priceMatrix[s].LightPrice = parseInt(price.toString());
            else if (p == 2)
               priceMatrix[s].BusinessPrice = parseInt(price.toString());
            else if (p == 3)
               priceMatrix[s].ExpertPrice = parseInt(price.toString());

         }

      }

      return priceMatrix;
   }

   inParticularSpecializedFieldList(code: string) {
      //particular specialized Field List
      //contract legal 01,Medicalcare, Medicine and Pharmacy 04,Special permission & Intellectual property 10
      //Family register copy & all kinds of certificate 13
      let particularCodes = ["01", "04", "10","12", "13"];
      if (particularCodes.indexOf(code) > -1)
         return true;
      else
         return false;
   }

   getWorkVolume(wordCount: number): string {
      if (wordCount > 0 && wordCount <= 1500)
         return "Small";
      else if (wordCount > 1500 && wordCount <= 6000)
         return "Medium";
      else
         return "Large";
   }

   private getBasePerWordFee(planType: number, isDoubleCheck: boolean) {
      //Light	100%	3.0円	4.2円
      //Business	135%	4.1円	5.7円
      //Expert	200%	6.0円	8.4円
      let baseFeePerWord: number = this.editingChargeSettings.BaseCharge;
      if (isDoubleCheck) //2 editor
         baseFeePerWord = this.editingChargeSettings.BaseCharge * this.editingChargeSettings.DoubleEditor;
      else
         baseFeePerWord = this.editingChargeSettings.BaseCharge * this.editingChargeSettings.SingleEditor;

      if (planType == 1)
         baseFeePerWord = baseFeePerWord * this.editingChargeSettings.Light; //100%
      else if (planType == 2)
         baseFeePerWord = baseFeePerWord * this.editingChargeSettings.Business; //135%
      else
         baseFeePerWord = baseFeePerWord * this.editingChargeSettings.Expert; //200%

      return baseFeePerWord;
   }


   private calculateCriteriaWeight(criteria: EditingPreferenceModel): number {
      let weight: number = 1;
      
      //IsSpellCheck requirement changed now no effect
/*       if (criteria.IsSpellCheck)
         weight = weight * this.editingChargeSettings.SpellCheckYES;
      else
         weight = weight * this.editingChargeSettings.SpellCheckNO; */

      //IsGrammerCheck requirement changed now no effect
     /*  if (criteria.IsGrammerCheck)
         weight = weight * this.editingChargeSettings.GrammerCheckYES;
      else
         weight = weight * this.editingChargeSettings.GrammerCheckNO;
 */
      //Exression / wording
      if (criteria.IsWording)
         weight = weight * this.editingChargeSettings.WordingYES;
      else
         weight = weight * this.editingChargeSettings.WordingNO;

      //editing History /Tracing record  for 1,2-100% & 3-110%
      if (criteria.DeliveryTracingRecordID == 1)
         weight = weight * this.editingChargeSettings.EditingHistoryYES;
      else if (criteria.DeliveryTracingRecordID == 2)
         weight = weight * this.editingChargeSettings.EditingHistoryNO;
      else
         weight = weight * this.editingChargeSettings.EditingHistoryBoth;

      //machine translation/post editing  YES 150% No 100% 
      if (criteria.IsMachineTranslated)
         weight = weight * this.editingChargeSettings.MachineTranslatedYES;
      else
         weight = weight * this.editingChargeSettings.MachineTranslatedNO;

      //cross check for YES 150% for NO 100%  requirement changed now no effect 
     /*  if (criteria.IsCrossCheck)
         weight = weight * this.editingChargeSettings.CrossCheckYES;
      else
         weight = weight * this.editingChargeSettings.CrossCheckNO; */

      //is editing field in particular specialized fields
      if (criteria.IsEditingFieldInParticularArea) {
         weight = weight * this.getEditingFieldAreaWeightByCode(criteria.EditingFieldAreaCode);
      }
      else {
         weight = weight * 1;
      }

      //editing language now only english so no effect
      if (criteria.EditingLanguageCode) {
         weight = weight * this.getEditingLanguageWeightByCode(criteria.EditingLanguageCode);
      }

      return weight;
   }


   private getDeliveryModeWeight(mode: OrderDeliveryMode) {
      let weight: number = 1;

      if (mode == OrderDeliveryMode.VERY_FAST) {
         weight = weight * this.editingChargeSettings.ExpressDelivery;
      }
      else if (mode == OrderDeliveryMode.FAST) {
         weight = weight * this.editingChargeSettings.EarlyDelivery;
      }
      else if (mode == OrderDeliveryMode.NORMAL) {
         weight = weight * this.editingChargeSettings.NormalDelivery;
      }
      else {
         weight = weight * this.editingChargeSettings.LateDelivery;
      }

      return weight;
   }

   calculatePrice(planType: number, planSubtype: number, criteria: EditingPreferenceModel, deliveryMode: OrderDeliveryMode): number {
      let totalPrice: number = 0;
      let baseFeePerWord: number = this.getBasePerWordFee(planType, criteria.IsDoubleCheck);
      let criteriaWeight: number = this.calculateCriteriaWeight(criteria);
      //let subTypeWeight: number = this.getPlanSubTypeWeight(planSubtype);
      let subTypeWeight: number = this.getDeliveryModeWeight(deliveryMode);

      totalPrice = criteria.WordCount * baseFeePerWord * criteriaWeight * subTypeWeight;
      return totalPrice;
   }

   getSingleCheckPriceRowHeadsOld(wordCount: number): PriceRowHeadModel {
      let rh: PriceRowHeadModel = new PriceRowHeadModel();
      if (wordCount >= 0 && wordCount <= 300)
         rh = { RowHead1: { ID: 1, Name: "30min", Duration_en: "30min" }, RowHead2: { ID: 3, Name: "4h", Duration_en: "30min" }, RowHead3: { ID: 5, Name: "12h", Duration_en: "30min" }, RowHead4: { ID: 7, Name: "2day", Duration_en: "30min" } };
      else if (wordCount > 300 && wordCount <= 400)
         rh = { RowHead1: { ID: 2, Name: "90min", Duration_en: "30min" }, RowHead2: { ID: 4, Name: "8h", Duration_en: "30min" }, RowHead3: { ID: 6, Name: "24h", Duration_en: "30min" }, RowHead4: { ID: 8, Name: "3day", Duration_en: "30min" } };
      else if (wordCount > 400 && wordCount <= 1000)
         rh = { RowHead1: { ID: 3, Name: "4h", Duration_en: "30min" }, RowHead2: { ID: 5, Name: "12h", Duration_en: "30min" }, RowHead3: { ID: 7, Name: "2day", Duration_en: "30min" }, RowHead4: { ID: 9, Name: "4day", Duration_en: "30min" } };
      else if (wordCount > 1000 && wordCount <= 2000)
         rh = { RowHead1: { ID: 4, Name: "8h", Duration_en: "30min" }, RowHead2: { ID: 6, Name: "24h", Duration_en: "30min" }, RowHead3: { ID: 8, Name: "3day", Duration_en: "30min" }, RowHead4: { ID: 10, Name: "5day", Duration_en: "30min" } };
      else if (wordCount > 2000 && wordCount <= 3000)
         rh = { RowHead1: { ID: 5, Name: "12h", Duration_en: "30min" }, RowHead2: { ID: 7, Name: "2day", Duration_en: "30min" }, RowHead3: { ID: 9, Name: "4day", Duration_en: "30min" }, RowHead4: { ID: 11, Name: "6day", Duration_en: "30min" } };
      else if (wordCount > 3000 && wordCount <= 3500)
         rh = { RowHead1: { ID: 6, Name: "24h", Duration_en: "30min" }, RowHead2: { ID: 8, Name: "3day", Duration_en: "30min" }, RowHead3: { ID: 10, Name: "5day", Duration_en: "30min" }, RowHead4: { ID: 12, Name: "7day", Duration_en: "30min" } };
      else if (wordCount > 3500 && wordCount <= 7000)
         rh = { RowHead1: { ID: 7, Name: "2day", Duration_en: "30min" }, RowHead2: { ID: 9, Name: "4day", Duration_en: "30min" }, RowHead3: { ID: 11, Name: "6day", Duration_en: "30min" }, RowHead4: { ID: 13, Name: "8day", Duration_en: "30min" } };
      else if (wordCount > 7000 && wordCount <= 10000)
         rh = { RowHead1: { ID: 8, Name: "3day", Duration_en: "30min" }, RowHead2: { ID: 10, Name: "5day", Duration_en: "30min" }, RowHead3: { ID: 12, Name: "7day", Duration_en: "30min" }, RowHead4: { ID: 14, Name: "9day", Duration_en: "30min" } };
      else if (wordCount > 10000 && wordCount <= 14000)
         rh = { RowHead1: { ID: 9, Name: "4day", Duration_en: "30min" }, RowHead2: { ID: 11, Name: "6day", Duration_en: "30min" }, RowHead3: { ID: 13, Name: "8day", Duration_en: "30min" }, RowHead4: { ID: 15, Name: "10day", Duration_en: "30min" } };
      else if (wordCount > 14000 && wordCount <= 18000)
         rh = { RowHead1: { ID: 10, Name: "5day", Duration_en: "30min" }, RowHead2: { ID: 12, Name: "7day", Duration_en: "30min" }, RowHead3: { ID: 14, Name: "9day", Duration_en: "30min" }, RowHead4: { ID: 16, Name: "11day", Duration_en: "30min" } };
      else if (wordCount > 18000 && wordCount <= 20000)
         rh = { RowHead1: { ID: 11, Name: "6day", Duration_en: "30min" }, RowHead2: { ID: 13, Name: "8day", Duration_en: "30min" }, RowHead3: { ID: 15, Name: "10day", Duration_en: "30min" }, RowHead4: { ID: 17, Name: "12day", Duration_en: "30min" } };
      else if (wordCount > 20000 && wordCount <= 25000)
         rh = { RowHead1: { ID: 12, Name: "7day", Duration_en: "30min" }, RowHead2: { ID: 14, Name: "9day", Duration_en: "30min" }, RowHead3: { ID: 16, Name: "11day", Duration_en: "30min" }, RowHead4: { ID: 18, Name: "13day", Duration_en: "30min" } };
      else if (wordCount > 25000 && wordCount <= 30000)
         rh = { RowHead1: { ID: 14, Name: "9day", Duration_en: "30min" }, RowHead2: { ID: 16, Name: "11day", Duration_en: "30min" }, RowHead3: { ID: 18, Name: "13day", Duration_en: "30min" }, RowHead4: { ID: 19, Name: "15day", Duration_en: "30min" } };
      else if (wordCount > 30000 && wordCount <= 35000)
         rh = { RowHead1: { ID: 15, Name: "10day", Duration_en: "30min" }, RowHead2: { ID: 17, Name: "12day", Duration_en: "30min" }, RowHead3: { ID: 20, Name: "14day", Duration_en: "30min" }, RowHead4: { ID: 21, Name: "16day", Duration_en: "30min" } };
      else if (wordCount > 35000 && wordCount <= 40000)
         rh = { RowHead1: { ID: 17, Name: "12day", Duration_en: "30min" }, RowHead2: { ID: 20, Name: "14day", Duration_en: "30min" }, RowHead3: { ID: 21, Name: "16day", Duration_en: "30min" }, RowHead4: { ID: 22, Name: "18day", Duration_en: "30min" } };
      else if (wordCount > 40000 && wordCount <= 45000)
         rh = { RowHead1: { ID: 18, Name: "13day", Duration_en: "30min" }, RowHead2: { ID: 19, Name: "15day", Duration_en: "30min" }, RowHead3: { ID: 23, Name: "17day", Duration_en: "30min" }, RowHead4: { ID: 24, Name: "19day", Duration_en: "30min" } };
      else if (wordCount > 45000 && wordCount <= 50000)
         rh = { RowHead1: { ID: 20, Name: "14day", Duration_en: "30min" }, RowHead2: { ID: 21, Name: "16day", Duration_en: "30min" }, RowHead3: { ID: 22, Name: "18day", Duration_en: "30min" }, RowHead4: { ID: 25, Name: "20day", Duration_en: "30min" } };
      else if (wordCount > 50000 && wordCount <= 60000)
         rh = { RowHead1: { ID: 23, Name: "17day", Duration_en: "30min" }, RowHead2: { ID: 24, Name: "19day", Duration_en: "30min" }, RowHead3: { ID: 26, Name: "21day", Duration_en: "30min" }, RowHead4: { ID: 28, Name: "23day", Duration_en: "30min" } };
      else if (wordCount > 60000 && wordCount <= 70000)
         rh = { RowHead1: { ID: 25, Name: "20day", Duration_en: "30min" }, RowHead2: { ID: 27, Name: "22day", Duration_en: "30min" }, RowHead3: { ID: 29, Name: "24day", Duration_en: "30min" }, RowHead4: { ID: 31, Name: "26day", Duration_en: "30min" } };
      else if (wordCount > 70000 && wordCount <= 80000)
         rh = { RowHead1: { ID: 28, Name: "23day", Duration_en: "30min" }, RowHead2: { ID: 30, Name: "25day", Duration_en: "30min" }, RowHead3: { ID: 32, Name: "27day", Duration_en: "30min" }, RowHead4: { ID: 33, Name: "29day", Duration_en: "30min" } };

      return rh;
   }

   getDoubleCheckPriceRowHeadsOld(wordCount: number): PriceRowHeadModel {
      let rh: PriceRowHeadModel = new PriceRowHeadModel();
      if (wordCount >= 0 && wordCount <= 100)
         rh = { RowHead1: { ID: 2, Name: "90min", Duration_en: "30min" }, RowHead2: { ID: 4, Name: "8h", Duration_en: "30min" }, RowHead3: { ID: 6, Name: "24h", Duration_en: "30min" }, RowHead4: { ID: 8, Name: "3day", Duration_en: "30min" } };
      else if (wordCount > 100 && wordCount <= 400)
         rh = { RowHead1: { ID: 3, Name: "4h", Duration_en: "30min" }, RowHead2: { ID: 5, Name: "12h", Duration_en: "30min" }, RowHead3: { ID: 7, Name: "2day", Duration_en: "30min" }, RowHead4: { ID: 9, Name: "4day", Duration_en: "30min" } };
      else if (wordCount > 400 && wordCount <= 500)
         rh = { RowHead1: { ID: 4, Name: "8h", Duration_en: "30min" }, RowHead2: { ID: 6, Name: "24h", Duration_en: "30min" }, RowHead3: { ID: 8, Name: "3day", Duration_en: "30min" }, RowHead4: { ID: 10, Name: "5day", Duration_en: "30min" } };
      else if (wordCount > 500 && wordCount <= 1000)
         rh = { RowHead1: { ID: 5, Name: "12h", Duration_en: "30min" }, RowHead2: { ID: 7, Name: "2day", Duration_en: "30min" }, RowHead3: { ID: 9, Name: "4day", Duration_en: "30min" }, RowHead4: { ID: 11, Name: "6day", Duration_en: "30min" } };
      else if (wordCount > 1000 && wordCount <= 2000)
         rh = { RowHead1: { ID: 7, Name: "2day", Duration_en: "30min" }, RowHead2: { ID: 9, Name: "4day", Duration_en: "30min" }, RowHead3: { ID: 11, Name: "6day", Duration_en: "30min" }, RowHead4: { ID: 13, Name: "8day", Duration_en: "30min" } };
      else if (wordCount > 2000 && wordCount <= 3500)
         rh = { RowHead1: { ID: 8, Name: "3day", Duration_en: "30min" }, RowHead2: { ID: 10, Name: "5day", Duration_en: "30min" }, RowHead3: { ID: 12, Name: "7day", Duration_en: "30min" }, RowHead4: { ID: 14, Name: "9day", Duration_en: "30min" } };
      else if (wordCount > 3500 && wordCount <= 4500)
         rh = { RowHead1: { ID: 9, Name: "4day", Duration_en: "30min" }, RowHead2: { ID: 11, Name: "6day", Duration_en: "30min" }, RowHead3: { ID: 13, Name: "8day", Duration_en: "30min" }, RowHead4: { ID: 15, Name: "10day", Duration_en: "30min" } };
      else if (wordCount > 4500 && wordCount <= 6000)
         rh = { RowHead1: { ID: 10, Name: "5day", Duration_en: "30min" }, RowHead2: { ID: 12, Name: "7day", Duration_en: "30min" }, RowHead3: { ID: 14, Name: "9day", Duration_en: "30min" }, RowHead4: { ID: 16, Name: "11day", Duration_en: "30min" } };
      else if (wordCount > 6000 && wordCount <= 7000)
         rh = { RowHead1: { ID: 11, Name: "6day", Duration_en: "30min" }, RowHead2: { ID: 13, Name: "8day", Duration_en: "30min" }, RowHead3: { ID: 15, Name: "10day", Duration_en: "30min" }, RowHead4: { ID: 17, Name: "12day", Duration_en: "30min" } };
      else if (wordCount > 7000 && wordCount <= 8000)
         rh = { RowHead1: { ID: 12, Name: "7day", Duration_en: "30min" }, RowHead2: { ID: 14, Name: "9day", Duration_en: "30min" }, RowHead3: { ID: 16, Name: "11day", Duration_en: "30min" }, RowHead4: { ID: 18, Name: "13day", Duration_en: "30min" } };
      else if (wordCount > 8000 && wordCount <= 9000)
         rh = { RowHead1: { ID: 13, Name: "8day", Duration_en: "30min" }, RowHead2: { ID: 15, Name: "10day", Duration_en: "30min" }, RowHead3: { ID: 17, Name: "12day", Duration_en: "30min" }, RowHead4: { ID: 20, Name: "14day", Duration_en: "30min" } };
      else if (wordCount > 9000 && wordCount <= 10000)
         rh = { RowHead1: { ID: 14, Name: "9day", Duration_en: "30min" }, RowHead2: { ID: 16, Name: "11day", Duration_en: "30min" }, RowHead3: { ID: 18, Name: "13day", Duration_en: "30min" }, RowHead4: { ID: 19, Name: "15day", Duration_en: "30min" } };
      else if (wordCount > 10000 && wordCount <= 12000)
         rh = { RowHead1: { ID: 15, Name: "10day", Duration_en: "30min" }, RowHead2: { ID: 17, Name: "12day", Duration_en: "30min" }, RowHead3: { ID: 20, Name: "14day", Duration_en: "30min" }, RowHead4: { ID: 21, Name: "16day", Duration_en: "30min" } };
      else if (wordCount > 12000 && wordCount <= 14000)
         rh = { RowHead1: { ID: 17, Name: "12day", Duration_en: "30min" }, RowHead2: { ID: 20, Name: "14day", Duration_en: "30min" }, RowHead3: { ID: 21, Name: "16day", Duration_en: "30min" }, RowHead4: { ID: 22, Name: "18day", Duration_en: "30min" } };
      else if (wordCount > 14000 && wordCount <= 16000)
         rh = { RowHead1: { ID: 20, Name: "14day", Duration_en: "30min" }, RowHead2: { ID: 21, Name: "16day", Duration_en: "30min" }, RowHead3: { ID: 22, Name: "18day", Duration_en: "30min" }, RowHead4: { ID: 25, Name: "20day", Duration_en: "30min" } };
      else if (wordCount > 16000 && wordCount <= 18000)
         rh = { RowHead1: { ID: 19, Name: "15day", Duration_en: "30min" }, RowHead2: { ID: 23, Name: "17day", Duration_en: "30min" }, RowHead3: { ID: 24, Name: "19day", Duration_en: "30min" }, RowHead4: { ID: 26, Name: "21day", Duration_en: "30min" } };
      else if (wordCount > 18000 && wordCount <= 20000)
         rh = { RowHead1: { ID: 23, Name: "17day", Duration_en: "30min" }, RowHead2: { ID: 24, Name: "19day", Duration_en: "30min" }, RowHead3: { ID: 26, Name: "21day", Duration_en: "30min" }, RowHead4: { ID: 28, Name: "23day", Duration_en: "30min" } };
      else if (wordCount > 20000 && wordCount <= 25000)
         rh = { RowHead1: { ID: 26, Name: "21day", Duration_en: "30min" }, RowHead2: { ID: 28, Name: "23day", Duration_en: "30min" }, RowHead3: { ID: 30, Name: "25day", Duration_en: "30min" }, RowHead4: { ID: 32, Name: "27day", Duration_en: "30min" } };
      else if (wordCount > 25000 && wordCount <= 30000)
         rh = { RowHead1: { ID: 30, Name: "25day", Duration_en: "30min" }, RowHead2: { ID: 32, Name: "27day", Duration_en: "30min" }, RowHead3: { ID: 33, Name: "29day", Duration_en: "30min" }, RowHead4: { ID: 34, Name: "31day", Duration_en: "30min" } };
      return rh;
   }

   loadPriceMatrixRowHeads(wordCount: number, IsDoubleCheck: boolean = false) {
      this._estimationService.getPriceMatrixRowHeads(wordCount, IsDoubleCheck).subscribe((data) => {
         if (data) {
            this.priceMatrixRowHeads.RowHead1 = { ID: data[0].ID, Name: data[0].HeadName_jp, Duration_en: data[0].HeadName_en };
            this.priceMatrixRowHeads.RowHead2 = { ID: data[1].ID, Name: data[1].HeadName_jp, Duration_en: data[1].HeadName_en };
            this.priceMatrixRowHeads.RowHead3 = { ID: data[2].ID, Name: data[2].HeadName_jp, Duration_en: data[2].HeadName_en };
            this.priceMatrixRowHeads.RowHead4 = { ID: data[3].ID, Name: data[3].HeadName_jp, Duration_en: data[3].HeadName_en };
         }
      });
   }

   getPriceMatrixRowHeads(): PriceRowHeadModel {
      return this.priceMatrixRowHeads;
   }

   translateRowHeadTitle(title: string = "30min"): string {
      let sample_dictionary: Array<{ Text: string, Translation: string }>;
      sample_dictionary = new Array<{ Text: string, Translation: string }>();
      sample_dictionary.push({ Text: "30min", Translation: "30分以内" });
      sample_dictionary.push({ Text: "4h", Translation: "4時間以内" });
      sample_dictionary.push({ Text: "2day", Translation: "2日以内に" });
      let translationText: string;

      if (title.match("min")) {
         translationText = title.replace("min", "分以内");
      }
      else if (title.match("h")) {
         translationText = title.replace("h", "時間以内");
      }
      else {
         translationText = title.replace("day", "日以内に");
      }

      return translationText;
   }

   calculateBasePriceForPriceList(isDoubleCheck: boolean, editingFieldAreaCode: string, planSubType: OrderDeliveryMode, planType: number = 1,docSize:DocumentVolume=DocumentVolume.MEDIUM): number {
      let weight = 1;
      let IsEditingFieldInParticularArea = this.inParticularSpecializedFieldList(editingFieldAreaCode);
      if (IsEditingFieldInParticularArea) {
         weight = weight * this.getEditingFieldAreaWeightByCode(editingFieldAreaCode);
      }
      else {
         weight = weight * 1;
      }

      //let subTypeWeight = this.getPlanSubTypeWeight(planSubType);
      let subTypeWeight: number = this.getDeliveryModeWeight(planSubType);
      weight = weight * subTypeWeight;

      let baseFee = this.getBasePerWordFee(planType, isDoubleCheck) * weight;

      return baseFee;

   }
}
