import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-payment-confirm',
  templateUrl: './payment-confirm.component.html',
  styleUrls: ['./payment-confirm.component.css']
})
export class PaymentConfirmComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  authService: AuthenticationService;
  orderNo: string;
  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private _authService: AuthenticationService,
    private titleService: Title,
    private meta: Meta) {

    this.router = _router.url;
    this.sharedService = dataShareService;
    this.authService = _authService;
    this.orderNo = null;

    if (!localStorage.getItem('paymentForOrder')) {
      this._router.navigateByUrl('/404');
    }
    else {
      this.orderNo = localStorage.getItem('paymentForOrder');
      localStorage.removeItem('paymentForOrder');
    }

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = this.sharedService.currentCulture=='jp'?"決済完了":"payment complete";
    this.sharedService.setSharedData(this.shareableData);



    if(this.sharedService.currentCulture=='jp')
    {

      this.titleService.setTitle("英文校正サービスへのご発注ありがとうございます。");
      this.meta.addTag({ name: 'keywords', content: '高品質,インターネット対応,オンライン校正,英語校正,英語,専門校正,英語エディター,校正会社,論文校正,英語論文チェック' });
      this.meta.addTag({ name: 'description', content: '英文校正サービスへのご発注ありがとうございます。24時間365日対応。オンラインで利用できる校正サービス。納品物の受領までウェブ上で完結。簡単,早い,確実！30分からの短時間納品も可能です。お問い合わせ,お見積りは無料。' });

    }
    else{
      this.titleService.setTitle("Payment completion page");
      this.meta.addTag({ name: 'keywords', content: 'high quality,internet,onlineediting,Englishediting,English,professionalediting,Englisheditor,editingcompany,academic writingediting,Englishacademic writingcheck' });
      this.meta.addTag({ name: 'description', content: 'Editing-Pro.services Payment completion. 24hours service for 365 days. Online editing service that allows order to submission through online. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });
    }

    this.loadDynmicallyScript();
  }


  private loadDynmicallyScript() {
    var script = document.createElement('script');
    script.src = "../assets/js/ep_gtag.js";
    script.async = false;
    document.head.appendChild(script);
    script.onload = () => this.ep_gtagScriptLoaded();
  }

  private ep_gtagScriptLoaded() {
    console.info('EP gtag loaded Sucessfully!');
  }

  goToOrderDetails() {
    if (this.orderNo) {
      this._router.navigateByUrl('/customer/order-detail/' + this.orderNo);
    }
  }

  ngOnInit() {
  }

}
