import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { PaymentService } from '../../services/payment.service';
import { MiscellaneousModel } from '../../models/miscellaneous-model.model';
import { CustomerService } from '../../services/customer-service.service';
import { DataShareService } from '../../services/data-share.service';
import { CompanyCouponModel } from '../../company-coupon.model';
import { ToastrService } from 'ngx-toastr';
import { CredentialModel } from '../../models/credential.model';
import { Router } from '@angular/router';
import { ModalOptions, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MonthlyInvoiceComponent } from '../monthly-invoice/monthly-invoice.component';
import { DeliveryComponent } from '../../PDFTemplates/delivery/delivery.component';
import { ReceiptComponent } from '../../PDFTemplates/receipt/receipt.component';
import { InvoiceComponent } from '../../PDFTemplates/invoice/invoice.component';
import { EstimationComponent } from '../../PDFTemplates/estimation/estimation.component';

@Component({
  selector: 'app-admin-testing',
  templateUrl: './admin-testing.component.html',
  styleUrls: ['./admin-testing.component.css']
})
export class AdminTestingComponent implements OnInit {
  userService: UserService;
  AdminPIN: number;
  IsVerified: boolean;
  couponTypeList: Array<MiscellaneousModel>;
  couponModel: CompanyCouponModel;
  sharedService: DataShareService;
  discountModel: MiscellaneousModel;
  logOnBehalf: number;
  userRegistrationNo: number;
  router: Router;
  OrderNo: string;
  YearMonth: string;
  companyNo: number;
  IsSeal: boolean;
  bsModalRef: BsModalRef;
  customPatterns: string = "(1[0-2]|0[1-9])\/(1[5-9]|2\d)";
  selectedPDFType: number;

  constructor(private _userService: UserService, private _sharedService: DataShareService, private _router: Router,
    private _paymentService: PaymentService, private customerService: CustomerService,
    private modalService: BsModalService,
    private toastr: ToastrService) {
    this.userService = _userService;
    this.sharedService = _sharedService;
    this.IsVerified = false;
    this.couponTypeList = new Array<MiscellaneousModel>();
    this.couponModel = new CompanyCouponModel();
    this.couponModel.ApplicationID = 3;
    this.discountModel = null;
    this.logOnBehalf = 1;
    this.userRegistrationNo = null;
    this.YearMonth = null;
    this.OrderNo = null;
    this.companyNo = null;
    this.IsSeal = false;
    this.selectedPDFType = 1;


    this.router = _router;

    if (sessionStorage.getItem("AdminPIN")) {
      this.AdminPIN = parseInt(sessionStorage.getItem("AdminPIN"));
      this.verifyAdmin();
    }
  }

  verifyAdmin() {
    this.userService.verifyAdmin(this.AdminPIN).subscribe(data => {
      this.IsVerified = data;
      sessionStorage.setItem("AdminPIN", this.AdminPIN.toString());
      this.getAllCouponTypeList();
    });
  }

  getAllCouponTypeList() {
    this._paymentService.getDiscountTypeList().subscribe(data => {
      if (data) {
        this.couponTypeList = data;
      }
    });
  }

  issueCouponNow() {
    if (this.discountModel && this.couponModel.CompanyNo) {
      this.couponModel.CouponName = this.discountModel.DiscountType;
      this.couponModel.DiscountRate = this.discountModel.DiscountRate;
      this.customerService.issueCouponToCompany(this.couponModel).subscribe(result => {
        if (result) {
          this.toastr.success("Coupon issued successfully!");
        }
        else {
          this.toastr.error("Could not issue coupon maybe company registration no is not correct.");
        }
      });
    }

  }

  loginOnBehalfOfCustomerOrEditor() {
    if (this.userRegistrationNo) {
      let loginModel: CredentialModel = new CredentialModel();
      loginModel.AdminPIN = this.AdminPIN;
      loginModel.CultureId = this.sharedService.currentCulture;
      loginModel.EditingProUserType = this.logOnBehalf;
      loginModel.RegistrationNo = this.userRegistrationNo;

      this.userService.onBehalfLogin(loginModel).subscribe(data => {
        if (data) {
          localStorage.setItem('currentUser', JSON.stringify(data));
          if (data && data.UserType == 1)
            this.router.navigateByUrl('/customer/my-page');
          else if (data && data.UserType == 2)
            this.router.navigateByUrl('/editor/my-page');
        }
      });
    }

  }

  openInvoicePreviewWindow(month: number, year: number) {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-lg"
    };
    this.sharedService.setModalData({ OrderMonth: month, OrderYear: year, IsShowSeal: this.IsSeal, CustomerNo: this.companyNo });
    this.bsModalRef = this.modalService.show(MonthlyInvoiceComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;
  }

  invoicePreview() {
    let arr = this.YearMonth.split('/');
    let orderYear: number = parseInt(arr[0]);
    let orderMonth: number = parseInt(arr[1]);
    this.openInvoicePreviewWindow(orderMonth, orderYear);
  }

  openEstimationWindow() {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-lg"
    };
    this.sharedService.setModalData({ OrderNo: this.OrderNo ,CustomerNo:this.companyNo});
    this.bsModalRef = this.modalService.show(EstimationComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;
  }

  openInvoiceWindow() {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-lg"
    };
    this.sharedService.setModalData({ OrderNo: this.OrderNo,CustomerNo:this.companyNo });
    this.bsModalRef = this.modalService.show(InvoiceComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;
  }

  openDeliveryNoticeWindow() {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-lg"
    };
    this.sharedService.setModalData({ OrderNo: this.OrderNo,CustomerNo:this.companyNo });
    this.bsModalRef = this.modalService.show(DeliveryComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;
  }

  openReceiptWindow() {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-lg"
    };
    this.sharedService.setModalData({ OrderNo: this.OrderNo,CustomerNo:this.companyNo });
    this.bsModalRef = this.modalService.show(ReceiptComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;
  }

  PDFPreview() {
    if(this.OrderNo)
    {
      switch (this.selectedPDFType) {
        case 1:
          this.openEstimationWindow();
          break;
        case 2:
          this.openInvoiceWindow();
          break;
        case 3:
          this.openDeliveryNoticeWindow();
          break;
        case 4:
          this.openReceiptWindow();
          break;
      }
    }
    
  }


  ngOnInit() {
  }

}
