import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { SendEstimationWindowComponent } from './send-estimation-window/send-estimation-window.component';
import { EditingPreferenceModel } from '../models/editing-pro-preference-model.model';
import { OrderWeb } from '../models/order-web.model';
import { DocumentModel } from '../models/document.model';
import { OrderAppointedToStaffModel } from '../models/order-appointed-to-staff.model';
import { EstimationService } from '../services/estimation.service';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../services/utility.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MiscellaneousModel } from '../models/miscellaneous-model.model';
import { EditingChargeSettingsModel } from '../models/editing-charge-settings.model';
import { InvoiceComponent } from '../PDFTemplates/invoice/invoice.component';
import { EstimationComponent } from '../PDFTemplates/estimation/estimation.component';

@Component({
  selector: 'app-estimation-confirmation',
  templateUrl: './estimation-confirmation.component.html',
  styleUrls: ['./estimation-confirmation.component.css']
})
export class EstimationConfirmationComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  uploadedDocuments: Array<DocumentModel>;
  estimationByTextModel: { TranslationText: string, WordCount: number };
  isEstimationByDirectText: boolean;
  bsModalRef: BsModalRef;
  servicePreferenceModel: EditingPreferenceModel;
  orderModel: OrderWeb;
  estimationService: EstimationService;
  isOrderAlreadyPlaced: boolean;
  costSummary: { DeliveryAnticipatedDate: string, PaymentAmountWithoutTax: string, DiscountType: string, seasonalDiscount: number, DiscountRate: number, DiscountAmount: string, ConsumptionTax: string, PaymentWithTax: string };
  discountOptions: Array<MiscellaneousModel>;
  seasonalDiscountInfo: MiscellaneousModel;
  isDiscountCouponUsed: boolean;
  editingChargeSettings: EditingChargeSettingsModel;

  constructor(private _router: Router,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private dataShareService: DataShareService,
    private titleService: Title,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private utilityService: UtilityService,
    private meta: Meta,
    private _estimationService: EstimationService,
    private _toastr: ToastrService) {
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.estimationService = _estimationService;

    this.estimationByTextModel = { TranslationText: null, WordCount: 0 };
    this.servicePreferenceModel = new EditingPreferenceModel();
    this.orderModel = new OrderWeb();
    this.editingChargeSettings = null;
    this.discountOptions = new Array<MiscellaneousModel>();
    this.costSummary = { DeliveryAnticipatedDate: null, PaymentAmountWithoutTax: null, seasonalDiscount: null, DiscountRate: null, DiscountType: null, DiscountAmount: null, ConsumptionTax: null, PaymentWithTax: null };
    this.seasonalDiscountInfo = new MiscellaneousModel();
    this.isDiscountCouponUsed = false;

    if (localStorage.getItem("servicePreferenceModel")) {
      this.servicePreferenceModel = JSON.parse(localStorage.getItem("servicePreferenceModel"));
    }

    if (localStorage.getItem("SelectedEditorsForEstimation") && !this.sharedService.SelectedEditorsForEstimation) //in case of refresh
    {
      if (this.servicePreferenceModel.IsAppointingEditor) {
        let seletedEditors = JSON.parse(localStorage.getItem("SelectedEditorsForEstimation"));
        this.sharedService.SelectedEditorsForEstimation = seletedEditors;
      }
      else {
        this.sharedService.SelectedEditorsForEstimation = [];
      }

    }

    if (localStorage.getItem("estimationResult")) {
      let result = JSON.parse(localStorage.getItem("estimationResult"));
      if (Array.isArray(result)) //estimate by Documents
      {
        this.uploadedDocuments = result;
        this.isEstimationByDirectText = false;
      }
      else {  //by text estimation
        this.estimationByTextModel.TranslationText = result.TranslationText;
        this.estimationByTextModel.WordCount = result.WordCount;
        this.isEstimationByDirectText = true;
      }

    }

    //now remove 
    localStorage.removeItem("orderCopyModelForReorderSimilar");

    if (this.sharedService.currentUser && this.sharedService.currentUser.ID)
      this.getDiscountAllInfo(this.sharedService.currentUser.ID);

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = this.sharedService.currentCulture=='jp'?"ご利用ガイド":"User Guide";
    this.sharedService.setSharedData(this.shareableData);



    if(this.sharedService.currentCulture=='jp'){

      this.titleService.setTitle("英文校正サービスの見積り確認");
      this.meta.updateTag({ name: 'keywords', content: '見積り,確認,高品質,インターネット対応,オンライン校正,英語校正,英語,専門校正,英語エディター,校正会社,論文校正,英語論文チェック' });
      this.meta.updateTag({ name: 'description', content: '英文校正サービスの無料見積りをご確認ください。24時間365日対応。オンラインで利用できる校正サービス。納品物の受領までウェブ上で完結。簡単,早い,確実！30分からの短時間納品も可能です。お問い合わせ,お見積りは無料。' });
    }
    else{


      this.titleService.setTitle("editing-Pro.service estimate confirm");
      this.meta.updateTag({ name: 'keywords', content: 'estimate, confirm,high quality, available through internet,online editing,English editing,English,professional editing,English editor,editing company,academic writing editing,English academic writingcheck' });
      this.meta.updateTag({ name: 'description', content: 'editing-Pro.service free estimate confirmation. 24hours service for 365 days. Online editing service that allows order to submission through online. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });
    }
  }

  private getEditingChargeSettings() {
    this.estimationService.getEditingChargeSettings().subscribe((data) => {
      if (data) {
        this.editingChargeSettings = data;
        this.servicePreferenceModel.TaxRate = data.TaxRate;
      }
    });
  }

  getDiscountAllInfo(clientId: string) {
    this.estimationService.getAllDiscountInfoByClientId(clientId).subscribe(data => {
      if (data) {
        this.discountOptions = data.filter(item => item.DiscountType != 'SeasonalDC');
        this.seasonalDiscountInfo = data.find(item => item.DiscountType == 'SeasonalDC');
        let pleaseSelect: MiscellaneousModel = new MiscellaneousModel();
        pleaseSelect.DiscountType = 'None';
        pleaseSelect.CouponCount = null;
        pleaseSelect.DiscountStatus = true;
        pleaseSelect.DiscountTitle = '--Select--';
        this.discountOptions.unshift(pleaseSelect);

        let firstOrderDiscount = data.find(item => item.DiscountType == 'FirstOrderDC');
        if(firstOrderDiscount && firstOrderDiscount.CouponCount>0) //first order then
        {
          this.costSummary.DiscountType='FirstOrderDC';
          this.onDiscountChange();
        }

      }
    });
  }

  onDiscountChange() {

    if (this.costSummary.DiscountType != 'None') {
      let selectedDiscount = this.discountOptions.find(item => item.DiscountType == this.costSummary.DiscountType);
      if (selectedDiscount) {
        this.costSummary.DiscountRate = selectedDiscount.DiscountRate;
        this.isDiscountCouponUsed = true;
      }
      else {
        this.costSummary.DiscountRate = 0;
      }

    }
    else {
      this.costSummary.DiscountRate = 0;
      this.isDiscountCouponUsed = false;
    }

    this.orderModel.Discount = this.costSummary.DiscountRate * this.orderModel.EstimatedPrice;

    if (this.seasonalDiscountInfo && this.seasonalDiscountInfo.CouponCount > 0) {
      this.costSummary.seasonalDiscount = this.seasonalDiscountInfo.DiscountRate * this.orderModel.EstimatedPrice;
      this.orderModel.Discount += this.costSummary.seasonalDiscount;
    }
    else {
      this.costSummary.seasonalDiscount = 0;
    }

    this.orderModel.Discount = Math.floor(this.orderModel.Discount);

    this.orderModel.PriceAfterDiscount = this.orderModel.EstimatedPrice - this.orderModel.Discount;
    //calculate Tax
    this.servicePreferenceModel.TaxAmount = Math.floor(this.orderModel.PriceAfterDiscount * this.servicePreferenceModel.TaxRate);
    //end

    this.orderModel.PaymentAmount = Math.floor(this.orderModel.PriceAfterDiscount + this.servicePreferenceModel.TaxAmount); //later may include mailing cost so same as PriceAfterDiscount 
    this.orderModel.ConsumptionTax = this.servicePreferenceModel.TaxAmount;
    this.costSummary.ConsumptionTax = this.decimalPipe.transform(Math.floor(this.orderModel.ConsumptionTax), '1.0-0') + '円';
    this.costSummary.PaymentWithTax = this.decimalPipe.transform(this.orderModel.PaymentAmount, '1.0-0') + '円';
    this.costSummary.DiscountAmount = '-' + this.decimalPipe.transform(this.orderModel.Discount, '1.0-0') + '円';
  }

  openModalNow() {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-lg"
    };
    let sharedModalData = {
      OrderDate: this.orderModel.OrderDate,
      WordCount: this.servicePreferenceModel.WordCount,
      JobNumber: this.orderModel.OrderNo,
      PlanType: this.orderModel.DeliveryLevelName,
      Order: this.orderModel
    };

    this.sharedService.setModalData(sharedModalData);

    this.bsModalRef = this.modalService.show(SendEstimationWindowComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;
  }

  openSendEmailEstimationWindow() {

    this.beforeSendingEmailToStakeHolderAction().then((isOrderSaved: boolean) => {
      if (isOrderSaved) {
        this.openModalNow();
      }
      else {
        console.log('Order not saved error occured... so no need to send email');
      }

    });
  }

  private executeOperationCouponUsed() {
    if (this.isDiscountCouponUsed) {
      this.estimationService.updateCompanyCouponInfo(this.orderModel.OrderNo, this.orderModel.ClientID, this.costSummary.DiscountType).subscribe(data => {
        if (data) {
          console.log('coupon deducted');
        }
      });
    }
  }

  beforeSendingEmailToStakeHolderAction(): Promise<boolean> {
    let result = new Promise((resolve, reject) => {
      if (!this.orderModel.OrderNo) {
        this.prepareOrderModel();
        this.estimationService.saveWebOrder(this.orderModel).subscribe((data) => {
          if (data) {
            this.isOrderAlreadyPlaced = true;
            this.orderModel.OrderNo = data;
            this.executeOperationCouponUsed();
            localStorage.removeItem("estimationResult");
            localStorage.removeItem("SelectedEditorsForEstimation");
            localStorage.removeItem("servicePreferenceModel");
            resolve(true);
            return result;
          }
          else {
            resolve(false);
            return result;
          }
        });
      }
      else {  //already placed order
        resolve(true);
        return result;
      }
    });

    return result;

  }

  private getDeliveryPlanId(name: string): number {
    if (name == "Light")
      return 1;
    else if (name == "Business")
      return 2;
    else if (name == "Expert")
      return 3;
    else
      return 1;
  }

  private prepareOrderModel() {
    this.orderModel.ApplicationID = 3;
    this.orderModel.IsClaimed = false;
    this.orderModel.IsEmailSent = false;
    this.orderModel.IsPostPay = false;
    this.orderModel.MenuScript = this.isEstimationByDirectText ? this.estimationByTextModel.TranslationText : null;
    this.orderModel.OrderDate = new Date();
    this.orderModel.OrderStatus = 1; //waiting for confirmation

    this.orderModel.PaymentAmountWithoutTax = this.servicePreferenceModel.TotalPrice;
    this.costSummary.PaymentAmountWithoutTax = this.decimalPipe.transform(this.orderModel.PaymentAmountWithoutTax, '1.0-0') + '円';
    this.orderModel.EstimatedPrice = this.orderModel.PaymentAmountWithoutTax;
    //this.orderModel.PaymentAmount = Math.floor(parseFloat((this.servicePreferenceModel.TotalPrice + this.servicePreferenceModel.TaxAmount).toString()));

    this.costSummary.DeliveryAnticipatedDate = this.datePipe.transform(this.estimationService.getAnticipatedDateTime(this.servicePreferenceModel.PlanCourseDuration), "yyyy/MM/dd HH:mm:ss");
    this.orderModel.PaymentDate = null;
    this.orderModel.PaymentMethod = null;
    this.orderModel.PaymentStatus = 0;
    this.orderModel.SourceLanguageID = this.servicePreferenceModel.LanguageID;
    this.orderModel.TargetLanguageID = this.servicePreferenceModel.LanguageID;
    this.orderModel.TranslationFieldID = this.servicePreferenceModel.SpecializedFieldID;
    this.orderModel.DeliveryLevelName = this.servicePreferenceModel.PlanType; //light business or expert
    this.orderModel.DeliveryPlanID = this.servicePreferenceModel.PlanCourseID;
    this.orderModel.DeliveryPlan = this.servicePreferenceModel.PlanCourse;
    this.orderModel.WordCount = this.servicePreferenceModel.WordCount;
    this.orderModel.CommentToTranslator = this.servicePreferenceModel.CommentsToEditor;
    this.orderModel.CurrencyID = 3;//japanese yen
    this.orderModel.UnitPrice = 50;//dummy
    this.orderModel.CountType = 2;//1.page count 2.word count 3. charecter count
    this.orderModel.IsClaimed = false;
    this.orderModel.IsEmailSent = false;
    this.orderModel.TranslatorFee = null;
    this.orderModel.EndDate = null;
    if (localStorage.getItem("currentUser"))
    {
      let affiCode:string=JSON.parse(localStorage.getItem("currentUser")).AffiliateCode;
      if(affiCode)
      {
        this.orderModel.AffiliateCode=affiCode;
      }
    }

    this.orderModel.Discount = this.costSummary.DiscountRate ? this.costSummary.DiscountRate * this.orderModel.EstimatedPrice : 0;

    if (this.seasonalDiscountInfo && this.seasonalDiscountInfo.CouponCount > 0) {
      this.costSummary.seasonalDiscount = Math.floor(this.seasonalDiscountInfo.DiscountRate * this.orderModel.EstimatedPrice);
      this.orderModel.Discount += this.costSummary.seasonalDiscount;
    }
    else {
      this.costSummary.seasonalDiscount = 0;
    }

    this.orderModel.Discount = Math.floor(this.orderModel.Discount);

    this.orderModel.PriceAfterDiscount = this.orderModel.EstimatedPrice - this.orderModel.Discount;
    //calculate Tax
    this.servicePreferenceModel.TaxAmount = Math.floor(this.orderModel.PriceAfterDiscount * this.servicePreferenceModel.TaxRate);
    //end


    this.orderModel.PaymentAmount = Math.floor(this.orderModel.PriceAfterDiscount + this.servicePreferenceModel.TaxAmount); //later may include mailing cost so same as PriceAfterDiscount 
    this.orderModel.ConsumptionTax = this.servicePreferenceModel.TaxAmount;
    this.costSummary.ConsumptionTax = this.decimalPipe.transform(Math.floor(this.orderModel.ConsumptionTax), '1.0-0') + '円';
    this.costSummary.PaymentWithTax = this.decimalPipe.transform(this.orderModel.PaymentAmount, '1.0-0') + '円';


    this.costSummary.DiscountAmount = '-' + this.decimalPipe.transform(this.orderModel.Discount, '1.0-0') + '円';
    this.orderModel.PaymentDate = null;
    this.orderModel.RequestDate = null;
    this.orderModel.CompanyNotes = null;
    this.orderModel.CommentToBcause = null;
    this.orderModel.DeliveryComment = null;
    if (localStorage.getItem("currentUser"))
      this.orderModel.ClientID = JSON.parse(localStorage.getItem("currentUser")).ID;
    this.orderModel.WebDocumentList = this.uploadedDocuments;

    let clientId = this.orderModel.ClientID;
    let appointedList = new Array<OrderAppointedToStaffModel>();
    if (this.sharedService.SelectedEditorsForEstimation && this.servicePreferenceModel.IsAppointingEditor)
      this.sharedService.SelectedEditorsForEstimation.forEach(regId => {
        let appointed = new OrderAppointedToStaffModel();
        appointed.ClientID = clientId;
        appointed.TranslatorNo = regId.toString();
        appointedList.push(appointed);
      });
    this.orderModel.AppointedToStaffList = appointedList;
    this.orderModel.EditingPreference = this.servicePreferenceModel;

    this.onDiscountChange();

  }

  getFormattedTotalPrice(amount: number) {
    return this.utilityService.getThousandCommaSeparatedNumber(amount);
  }

  saveWebOrder() {
    if (!this.orderModel.OrderNo) {
      this.prepareOrderModel();
      this.estimationService.saveWebOrder(this.orderModel).subscribe((data) => {
        if (data) {
          this._toastr.success('注文完了できました。'); //"Order Placed Successfully!"
          this.isOrderAlreadyPlaced = true;
          this.orderModel.OrderNo = data;
          this.executeOperationCouponUsed();
          localStorage.removeItem("estimationResult");
          localStorage.removeItem("SelectedEditorsForEstimation");
          localStorage.removeItem("servicePreferenceModel");
          localStorage.removeItem('selectedPlanCourseButtonId');
          this._router.navigateByUrl("/customer/estimation-payment/" + this.orderModel.OrderNo);
        }
        else {
          this._toastr.error('注文できておりません。');//"cannot be placed order!"
        }
      });
    }
    else {
      this._router.navigateByUrl("/customer/estimation-payment/" + this.orderModel.OrderNo);
    }

  }


  private showEstimationModalNow() {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-lg"
    };
    this.sharedService.setModalData({ OrderNo: this.orderModel.OrderNo });
    this.bsModalRef = this.modalService.show(EstimationComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;
  }

  openInvoicePreviewWindow() {

    this.beforeSendingEmailToStakeHolderAction().then((isOrderSaved: boolean) => {
      if (isOrderSaved) {
        this.showEstimationModalNow();
      }
      else {
        console.log('Order not saved error occured... so no need to send email');
      }

    });


  }



  ngOnInit() {
    this.prepareOrderModel();
  }

}
