import { Injectable } from '@angular/core';
import { AppSetting } from '../app-setting';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StaffModel } from '../models/staff.model';
import { CredentialModel } from '../models/credential.model';
import { ErrorReportModel } from '../models/error-report-model.model';
import { isoStringToDate } from '@angular/common/src/i18n/format_date';
import { EditorSearchModel } from '../models/editor-search.model';
import { MessageModel } from '../models/message-model.model';
import { NoticeModel } from '../models/notice.model';
import { BankAccountModel } from '../models/bank-account.model';
import { MiscellaneousModel } from '../models/miscellaneous-model.model';
import { OrderWeb } from '../models/order-web.model';
import { StaffEnglishTypeModel } from '../models/staff-english-type.model';


@Injectable({
  providedIn: 'root'
})
export class EditorService {
  ROOT_URL: string = AppSetting.API_ENDPOINT;
  EDITINGPRO_ROOT_URL: string = AppSetting.EDITINGPRO_API_ENDPOINT;

  constructor(private http: HttpClient) {

  }

  checkExistingEditor(emailID: string, currentCulture: string) {
    const params = new HttpParams().set('emailID', emailID).set('culture', currentCulture);
    return this.http.get<boolean>(this.EDITINGPRO_ROOT_URL + 'editor/CheckExistingEditor', { params });
  }

  registerEditor(editor: StaffModel, imageFile: any) {
    let formData: FormData = new FormData();
    formData.append('file', imageFile ? imageFile[0] : null);
    formData.append('model', JSON.stringify(editor));
    return this.http.post<string>(this.EDITINGPRO_ROOT_URL + 'editor/RegisterEditor', formData);
  }
  changePassword(editorId: string, password: string) {
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL + 'editor/changepassword', { ID: editorId, Password: password });
  }

  getEditorDetailsById(id: string) {
    const params = new HttpParams().set('id', id);
    return this.http.get<StaffModel>(this.EDITINGPRO_ROOT_URL + 'editor/GetEditorDetailsById', { params });
  }
  
  unsubscribe(model: CredentialModel) {
    return this.http.post<string>(this.EDITINGPRO_ROOT_URL + 'editor/unsubscribe', model);
  }

  updateEditor(editor: StaffModel, imageFile: any) {
    let formData: FormData = new FormData();
    if (imageFile) {
      formData.append('file', imageFile[0]);
    }
    formData.append('model', JSON.stringify(editor));
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL + 'editor/UpdateEditor', formData);
  }

  resetPassword(regId: string, password: string, currentPassword: string) {
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL + 'editor/resetpassword', { RegistrationID: regId, Password: password, CurrentPassword: currentPassword });
  }

  saveSiteErrorReport(errorReport: ErrorReportModel, uploadDocument: any) {
    let formData: FormData = new FormData();
    formData.append('file', uploadDocument);
    formData.append('model', JSON.stringify(errorReport));
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL + "editor/SaveSiteErrorReport", formData);
  }

  searchEditor(specialityId: string, isOnlyCertified: boolean,clientId:string=null,editorNo:number=null) {
    const params = new HttpParams().set('SpecialityId', specialityId).set('IsOnlyCertified', isOnlyCertified ? "true" : "false").set('clientId', clientId).set("editorNo",editorNo?editorNo.toString():null);
    return this.http.get<Array<EditorSearchModel>>(this.EDITINGPRO_ROOT_URL + 'editor/search', { params });
  }

  getEditorDetailsByRegistrationId(regId: number,culture:string="jp") {
    const params = new HttpParams().set('regId', regId.toString()).set('culture', culture);
    return this.http.get<EditorSearchModel>(this.EDITINGPRO_ROOT_URL + 'editor/GetEditorDetailsByRegistrationId', { params });
  }

  searchFavoriteEditor(cusomerId: string, specialityId: string, isOnlyCertified: boolean, regId?: string) {
    if (!regId)
      regId = null;
    const params = new HttpParams().set('cusomerId', cusomerId).set('SpecialityId', specialityId).set('IsOnlyCertified', isOnlyCertified ? "true" : "false").set('regId', regId);
    return this.http.get<Array<EditorSearchModel>>(this.EDITINGPRO_ROOT_URL + 'editor/SearchFavoriteEditor', { params });
  }

  searchAppointedEditor(cusomerId: string, specialityId: string, isOnlyCertified: boolean, regId?: string) {
    if (!regId)
      regId = null;
    const params = new HttpParams().set('cusomerId', cusomerId).set('SpecialityId', specialityId).set('IsOnlyCertified', isOnlyCertified ? "true" : "false").set('regId', regId);
    return this.http.get<Array<EditorSearchModel>>(this.EDITINGPRO_ROOT_URL + 'editor/SearchAppointedEditor', { params });
  }

  searchPastRequestedEditor(customerId: string, specialityId: string, isOnlyCertified: boolean, regId?: string) {
    if (!regId)
      regId = null;
    const params = new HttpParams().set('cusomerId', customerId).set('SpecialityId', specialityId).set('IsOnlyCertified', isOnlyCertified ? "true" : "false").set('regId', regId);
    return this.http.get<Array<EditorSearchModel>>(this.EDITINGPRO_ROOT_URL + 'editor/SearchPastRequestedEditor', { params });
  }

  searchBlackListEditor(customerId: string, specialityId: string, isOnlyCertified: boolean, regId?: string) {
    if (!regId)
      regId = null;
    const params = new HttpParams().set('customerId', customerId).set('SpecialityId', specialityId).set('IsOnlyCertified', isOnlyCertified ? "true" : "false").set('regId', regId);
    return this.http.get<Array<EditorSearchModel>>(this.EDITINGPRO_ROOT_URL + 'editor/SearchBlackListEditor', { params });
  }

  sendMessage(message: MessageModel, uploadedDocument: any) {
    let formData: FormData = new FormData();
    formData.append('file', uploadedDocument);
    formData.append('model', JSON.stringify(message));
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL + 'editor/SendMessage', formData);
  }

  getEditorListForLandingPage(pageNo: number, pageSize: number) {
    const params = new HttpParams().set('pageNo', pageNo.toString()).set('pageSize', pageSize.toString());
    return this.http.get<Array<EditorSearchModel>>(this.EDITINGPRO_ROOT_URL + 'editor/GetEditorListForLandingPage', { params });
  }

  searchEditorForEstimation(customerId: string, specialityId: string, isOnlyNative: boolean, isFavoriteEditor: boolean, isPastNominationEditor: boolean, isPastRequestEditor: boolean, editorNo: number,isOnlyfirstEditor:boolean=true) {
    customerId = !customerId ? customerId = null : customerId;
    isOnlyfirstEditor=true; //new requirement only first editor
    const params = new HttpParams().set('clientId', customerId).set('SpecialityId', specialityId).set('IsOnlyNative', isOnlyNative ? "true" : "false").set('IsFavoriteEditor', isFavoriteEditor ? "true" : "false")
    .set('IsPastNominationEditor', isPastNominationEditor ? "true" : "false").set('IsPastRequestEditor', isPastRequestEditor ? "true" : "false").set('editorNo', editorNo ? editorNo.toString() : "null").set('IsOnlyfirstEditor', isOnlyfirstEditor ? "true" : "false");
    return this.http.get<Array<EditorSearchModel>>(this.EDITINGPRO_ROOT_URL + 'editor/SearchEditorForEstimation', { params });
  }

  getPastRequestedEditorById(editorId: string) {
    const params = new HttpParams().set('editorId', editorId)
    return this.http.get<EditorSearchModel>(this.EDITINGPRO_ROOT_URL + 'editor/GetPastRequestedEditorById', { params })
  }

  getUnreadMessagesByEditorId(editorId: string, pageNo: number) {
    const params = new HttpParams().set('id', editorId).set('pageNo', pageNo.toString());
    return this.http.get<Array<MessageModel>>(this.EDITINGPRO_ROOT_URL + 'editor/GetUnreadMessageByEditorID', { params });
  }

  getEditorNoticeList() {
    return this.http.get<Array<NoticeModel>>(this.EDITINGPRO_ROOT_URL + 'editor/GetEditorNoticeList');
  }

  getBankAccountDetailsByEditorId(editorId: string,culture:string="jp") {
    const params = new HttpParams().set('editorId', editorId).set('culture',culture);
    return this.http.get<BankAccountModel>(this.EDITINGPRO_ROOT_URL + 'editor/GetBankAccountDetailsByEditorId', { params });
  }

  getEditorPastWorkExperienceList(editorId: string,culture:string="jp") {
    const params = new HttpParams().set('editorId', editorId).set('culture',culture);
    return this.http.get<Array<MiscellaneousModel>>(this.EDITINGPRO_ROOT_URL + 'editor/GetEditorPastWorkExperienceList', { params });
  }

  getEditorInterviewList(pageNo: number, pageSize: number) {
    const params = new HttpParams().set('pageNo', pageNo.toString()).set('pageSize', pageSize.toString());
    return this.http.get<Array<EditorSearchModel>>(this.EDITINGPRO_ROOT_URL + 'editor/GetEditorInterviewList', { params })
  }

  getEditorInterviewDetails(editorNo: number) {
    const params = new HttpParams().set('editorNo', editorNo.toString());
    return this.http.get<EditorSearchModel>(this.EDITINGPRO_ROOT_URL + 'editor/GetEditorInterviewDetails', { params });
  }
  getEditorCurrentMonthOngoingJobHistory(editorId: string, pageNo: number, pageSize: number,month:number,year:number,culture:string="jp") {
    const params = new HttpParams().set('editorId', editorId).set('pageNo', pageNo.toString()).set('pageSize', pageSize.toString())
    .set('month', month.toString()).set('year', year.toString()).set('culture',culture);
    return this.http.get<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL + 'editor/GetEditorCurrentMonthOngoingJobHistory', { params });
  }

  getCertifiedEditorList(pageNo: number=1, pageSize: number=4) {
    const params = new HttpParams().set('pageNo', pageNo.toString()).set('pageSize', pageSize.toString());
    return this.http.get<Array<EditorSearchModel>>(this.EDITINGPRO_ROOT_URL + 'editor/GetCertifiedEditorListForLandingPage', { params })
  }
  getEditorMonthlyJobPaymentHistory(editorId:string, pageNo:number, pageSize:number,culture:string="jp")
  {
    const params = new HttpParams().set('editorId', editorId).set('pageNo', pageNo.toString()).set('pageSize', pageSize.toString()).set('culture',culture);
    return this.http.get<Array<MiscellaneousModel>>(this.EDITINGPRO_ROOT_URL+'editor/GetEditorMonthlyJobPaymentHistory', {params});
  }

  getEditorEnglishTypeByEditorId(editorId:string) {
    const params = new HttpParams().set('editorId',editorId);
    return this.http.get<Array<StaffEnglishTypeModel>>(this.EDITINGPRO_ROOT_URL + 'editor/GetEditorEnglishType',{params});
  }
  
  getCerficationSummary()
  {
	 return this.http.get<Array<MiscellaneousModel>>(this.EDITINGPRO_ROOT_URL + 'editor/GetCerficationSummary'); 
  }

  canEditorUseJapanese(editorId:string)
  {
   const params={editorId:editorId};
   return this.http.get<boolean>(this.EDITINGPRO_ROOT_URL+"editor/CanEditorUseJapanese",{params});
  }

}
