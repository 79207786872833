export class ContactUsModel {
    ID: string
    RegistrationID: number
    Name: string
    Name_en: string
    Name_jp: string
    Name_fr: string
    Name_kr: string
    Name_cn: string
    Name_tl: string
    DivisionName: string
    TelNumber: string
    Fax: string
    Email: string
    Comment: string
    Comment_en: string
    Comment_jp: string
    Comment_kr: string
    Comment_cn: string
    Comment_fr: string
    Comment_tl: string
    ReplyDate: Date
    RepliedBy: number
    IsReplied: boolean
    CompanyName: string
    PersonInCharge:string
    CompanyURLOne: string
    CompanyURLTwo: string
    PrefferedWebSystem: string
    IsApplication: boolean
    ApplicationID: number
    PostalCode:string
    Address:string 
}
