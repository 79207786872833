import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderWeb } from '../../models/order-web.model';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Title, Meta } from '@angular/platform-browser';
import { OrderOperationType } from '../../Enums.enum';
import { EstimationService } from '../../services/estimation.service';
import { ToastrService } from 'ngx-toastr';
import { AppSetting } from '../../app-setting';
import { StripePaymentModel } from '../../models/stripe-payment.model';
import { PaymentService } from '../../services/payment.service';
import { StripeConfigModel } from '../../models/stripe-config.model';
import { StripeHandleOpenConfigModel } from '../../models/stripe-handle-open-config.model';
import { IPSCardPaymentModel } from '../../models/ipscard-payment.model';
import { MiscellaneousModel } from 'src/app/models/miscellaneous-model.model';

@Component({
  selector: 'app-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.css']
})
export class PaymentInformationComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  expireYearList: Array<number>;
  orderModel: OrderWeb;
  paymentMethods: Array<string>;
  estimationService: EstimationService;
  paymentService: PaymentService;
  StripeHandle: any;
  IPSPaymentModel: IPSCardPaymentModel;
  getCouponName:MiscellaneousModel;


  constructor(private _router: Router,
    private titleService: Title,
    private _estimationService: EstimationService,
    private dataShareService: DataShareService,
    private _paymentService: PaymentService,
    private toastr: ToastrService,
    private meta: Meta) {

    this.router = _router.url;
    this.sharedService = dataShareService;
    this.estimationService = _estimationService;
    this.paymentService = _paymentService;

    this.paymentMethods = new Array<string>();
    this.IPSPaymentModel=new IPSCardPaymentModel();
    this.getCouponName=new MiscellaneousModel();
    this.paymentMethods = ["クレジットカード(IPS)", "クレジットカード(Stripe)", "月末〆請求(後払い)"];

    if (localStorage.getItem("paymentInfoModel")) {
      this.orderModel = JSON.parse(localStorage.getItem("paymentInfoModel"));
      this.getUserCouponName();
      //console.log('OrderModel:',this.orderModel);
      this.orderModel.PaymentMethodName = this.getPaymentMethodName(this.orderModel.PaymentMethod);
      this.orderModel.CurrentCulture = this.sharedService.currentCulture;
      this.fillYears();
    }
    else {
      this._router.navigateByUrl('/404');
    }

    this.StripeHandle = null;
    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = this.sharedService.currentCulture=='jp'?"決済情報画面":"Payment information screen";
    this.sharedService.setSharedData(this.shareableData);



    if(this.sharedService.currentCulture=='jp'){

      this.titleService.setTitle("英文校正サービスの決済情報");
      this.meta.addTag({ name: 'keywords', content: '決済情報,校正,高品質,24h対応,オンライン校正,英語校正,英語,専門校正,英語エディター,校正会社,論文校正,英語論文チェック' });
      this.meta.addTag({ name: 'description', content: '英文校正サービスの決済情報をご設定ください。24時間365日対応。オンラインで利用できる校正サービス。納品物の受領までウェブ上で完結。簡単,早い,確実！30分からの短時間納品も可能です。お問い合わせ,お見積りは無料。' });

    }
    else
    {
      this.titleService.setTitle("editing-Pro.service payment information");
      this.meta.addTag({ name: 'keywords', content: 'payment information,editing,high quality,24h support,online editing,English editing,English,professional editing,English editor,editing company,academic writing editing,English academic writingcheck' });
      this.meta.addTag({ name: 'description', content: 'Please set editing-Pro.service credit information. 24hours service for 365 days. Online editing service that allows order to submission through online. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });

    }

  }

  private getJPYToConvertedAmount(amount: number, toCurrencyCode: string) {
    //we can add currency converter API to convert amount in
    if (toCurrencyCode == 'JPY') {
      return amount;
    }
    else if (toCurrencyCode == 'USD') {
      //here we can use the api 
      return amount;
    }
    else {
      //other code
      return amount;
    }
  }

  private handleStripeTokenCallback(token: any) {
    if (token) {

      let stripePaymentModel: StripePaymentModel = new StripePaymentModel();
      stripePaymentModel.OrderID = this.orderModel.ID;
      stripePaymentModel.OrderNo=this.orderModel.OrderNo;
      stripePaymentModel.TokenID = token.id;
      stripePaymentModel.Email = token.email;
      let paymentAmount: number = this.orderModel.PaymentAmount + (this.orderModel.MailingCost ? this.orderModel.MailingCost : 0);
      stripePaymentModel.Amount = this.getJPYToConvertedAmount(paymentAmount, AppSetting.StripeCurrencyCode);
      stripePaymentModel.CurrencyCode = AppSetting.StripeCurrencyCode;
      stripePaymentModel.CultureCode=AppSetting.CurrentCulture;

      //call backend API
      this.paymentService.paymentWithStripe(stripePaymentModel).subscribe((data) => {
        if (data.ReturnCode == "0") {
          this.toastr.success(data.Message);
          localStorage.removeItem("paymentInfoModel");
          localStorage.removeItem("documentReceiveSettings");
          localStorage.setItem('paymentForOrder',this.orderModel.OrderNo);
          this._router.navigateByUrl('/customer/estimation-order');
        }
        else {
          this.toastr.error(data.Message);
        }
      });

    }

  }

  getPaymentMethodName(id: number) {
    if (id == 1)
      return this.paymentMethods[0];
    else if (id == 5)
      return this.paymentMethods[1];
    else
      return this.paymentMethods[2];
  }

  fillYears() {

    this.expireYearList = Array.from({ length: (new Date().getFullYear() + 16) - new Date().getFullYear() }, (_, index) => index + new Date().getFullYear());
  }

  payWithStripeCreditCard() {
    let openConfig: StripeHandleOpenConfigModel = new StripeHandleOpenConfigModel();
    let AmountInPayment: string;
    AmountInPayment = this.orderModel.CurrencySymbol.concat("");
    AmountInPayment = AmountInPayment.concat((this.orderModel.PaymentAmount + (this.orderModel.MailingCost?this.orderModel.MailingCost:0)).toFixed(2).toString());
    openConfig.name = "Pay for order ".concat(this.orderModel.OrderNo);
    openConfig.description = "Amount in payment: ".concat(AmountInPayment);
    openConfig.amount = null;
    openConfig.currency = AppSetting.CurrencyCode.toLowerCase();
    //openConfig.email = this.sharedService.currentUser.Email;
    openConfig.allowRememberMe = false;
    this.StripeHandle.open(openConfig);
  }

  payWithIPSCreditCard() {
    if (!this.IPSPaymentModel.CardNumber || !this.IPSPaymentModel.ExpirationMonth || !this.IPSPaymentModel.ExpirationYear
      || !this.IPSPaymentModel.CardHolderFirstName || !this.IPSPaymentModel.CardHolderLastName || !this.IPSPaymentModel.CardVerificationCode
      || !this.IPSPaymentModel.CardHolderEmail || !this.IPSPaymentModel.CardHolderPhone) {
      this.toastr.error('　入金情報を入力してください。'); //"Please fill all the payment information!"
      return;
    }

    this.IPSPaymentModel.OrderID = this.orderModel.ID;
    this.IPSPaymentModel.OrderNo=this.orderModel.OrderNo;
    this.IPSPaymentModel.ItemPrice = Math.round(this.orderModel.PaymentAmount + (this.orderModel.MailingCost?this.orderModel.MailingCost:0));
    this.IPSPaymentModel.ItemShippingPrice = 0;
    this.IPSPaymentModel.CultureCode=AppSetting.CurrentCulture;

    this.paymentService.paymentWithIPS(this.IPSPaymentModel).subscribe((data) => {
      if (data.ReturnCode == "0") {
        this.toastr.success(data.Message);
        localStorage.removeItem("paymentInfoModel");
        localStorage.removeItem("documentReceiveSettings");
        localStorage.setItem('paymentForOrder',this.orderModel.OrderNo);
        this._router.navigateByUrl('/customer/estimation-order');
      }
      else {
        this.toastr.error(data.Message);
      }
    });

  }

  payNow() {

    if (this.orderModel.PaymentMethod == 2) //monthly payment
    {
      let optype: OrderOperationType = OrderOperationType.POST_PAY;
      this.estimationService.executeOrderOperation(optype, this.orderModel).subscribe((data) => {
        if (data && data.ID) {
          localStorage.setItem('paymentForOrder',this.orderModel.OrderNo);
          localStorage.removeItem("paymentInfoModel");
          localStorage.removeItem("documentReceiveSettings");
          this._router.navigateByUrl('/customer/estimation-order');
        }
        else {
          this.toastr.error('エラーが発生しました。'); //"An error occured!"
        }
      });
    }
    else if (this.orderModel.PaymentMethod == 1)  //IPS payment
    {
      this.payWithIPSCreditCard();
    }
    else   //Stripe payment
    {
      this.payWithStripeCreditCard();
    }

  }


  private initStripe() {
    let stripeConfig: StripeConfigModel = new StripeConfigModel();
    stripeConfig.key = AppSetting.StripePublishableKey;
    stripeConfig.locale = 'auto';
    stripeConfig.token = this.handleStripeTokenCallback.bind(this);
    this.StripeHandle = (<any>window).StripeCheckout.configure(stripeConfig);
  }

  loadStripe() {

    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = AppSetting.StripeCheckoutScriptURL;
      window.document.body.appendChild(s);
    }
  }

  private closeCheckoutOnPageNavigation(_this: any) {
    window.addEventListener('popstate', function () {
      if (_this.StripeHandle == null)
        return;
      _this.StripeHandle.close();
      _this.StripeHandle = null;
    });

  }

  backToPreviousPage()
  {
     this._router.navigateByUrl('/customer/estimation-payment/'+this.orderModel.OrderNo);
  }

  getUserCouponName(){
    this.estimationService.getUsedDiscountCouponName(this.orderModel.OrderNo, this.orderModel.ClientID).subscribe((data)=>{
      if(data)
      this.getCouponName = data;
    });
  }

  ngOnInit() {
   // this.loadStripe();
    this.initStripe();
    this.closeCheckoutOnPageNavigation(this);
  }

}
