import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceSelectionGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve,reject)=>{
     
      let data =localStorage.getItem("estimationResult");
      let dataCopy =localStorage.getItem("orderCopyModelForReorderSimilar");
        if(data || dataCopy)
        return resolve(true);
        else
        {
          this.router.navigateByUrl('/');
          return resolve(false);
        }
     
    }); 
    }
}
