export class CredentialModel {
      EmailId : string
      Password :string
      CultureId :string
      ApplicationId :string
      UserType:UserType
      Description:string
      EditingProUserType:UserType
      RegistrationNo:number
      RegistrationID:string
      AdminPIN:number
}

export enum UserType{
      Customer = 1,
      Editor = 2,
      Partner = 3
}
