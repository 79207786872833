import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {DataShareService} from '../services/data-share.service';
import {DataSharingModel} from '../models/data-sharing-model';
import { Title,Meta  }     from '@angular/platform-browser';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css']
})
export class PaymentMethodComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;

  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private titleService: Title,
    private meta: Meta) {
      this.router=_router.url;
    this.sharedService=dataShareService;

    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName=this.sharedService.currentCulture=='jp'?"支払い方法について":"Payment method";
    this.sharedService.setSharedData(this.shareableData);




    if(this.sharedService.currentCulture=='jp'){


      this.titleService.setTitle("英文校正サービスのお支払い方法");
      this.meta.updateTag({ name: 'keywords', content: 'お支払い,決済,格安校正,オンライン校正,英語校正,英語,校正,専門エディター校正,英語エディター,校正会社,英文論文校正,英語論文チェック,校正チェックリスト,校正サービス' });
      this.meta.updateTag({ name: 'description', content: '英文校正のお支払い方法の確認はこちら。24時間365日,オンラインで利用できる校正サービス。納品物の受領までウェブ上で完結。簡単,早い,確実！お問い合わせ,お見積りは無料。' });

    }else{


      this.titleService.setTitle("editing-Pro.service payment how to");
      this.meta.updateTag({ name: 'keywords', content: 'payment,payment detail,resonable price editing,online editing,English editing,English,editing,professional editorediting,English editor,editing company,English writingacademic writing editing,English academic writing check,editing checklist,editing service' });
      this.meta.updateTag({ name: 'description', content: 'editing-Pro\'s payment details. 24hours service for 365 days. Online editing service that allows order to submission through online. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });



    }
     }

  ngOnInit() {
  }

}
