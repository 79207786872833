import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { ErrorReportModel } from 'src/app/models/error-report-model.model';
import { EditorService } from 'src/app/services/editor.service';
import { ClientEnvService } from 'src/app/services/client-env.service';
import { ToastrService } from 'ngx-toastr';
import { AppSetting } from 'src/app/app-setting';
import { Title,Meta  }     from '@angular/platform-browser';
import { NotificationType } from 'src/app/Enums.enum';


@Component({
  selector: 'app-error-report',
  templateUrl: './error-report.component.html',
  styleUrls: ['./error-report.component.css']
})
export class ErrorReportComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  siteErrorReport: ErrorReportModel;
  uploadedDocument: any;
  editorService: EditorService;
  clientEnvService: ClientEnvService;

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private _editorService: EditorService,
    private _clientEnvService: ClientEnvService,
    private titleService: Title,
    private meta: Meta,
    private _toastr: ToastrService) {

    this.router = _router.url;
    this.sharedService = dataShareService;
    this.editorService = _editorService;
    this.clientEnvService = _clientEnvService;
    this.initializeDefault();


    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName=='jp'? "サイトのエラー報告":"Error Report";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("英文校正サイトのエラー報告");
    this.meta.updateTag({ name: 'keywords', content: 'サイトエラー報告,格安校正,オンライン校正,英語校正,英語,校正会社,英語論文チェック' });
    this.meta.updateTag({ name: 'description', content: '英文校正サイト上に何かしらのエラーが発生した際には直ちに対応致しますのでこちらからエラー内容をお伝えください。' });

  }

  private initializeDefault()
  {
    this.siteErrorReport = new ErrorReportModel();
    if (this.sharedService.currentUser)
      {
        this.siteErrorReport.Email = this.sharedService.currentUser.Email;
        this.siteErrorReport.EditorNo = JSON.parse(localStorage.getItem("currentUser")).RegistrationID;
      }
    this.siteErrorReport.ErrorDescription="";
    this.siteErrorReport.Name = this.sharedService.currentUser.Name || this.sharedService.currentUser.CompanyPICName;
    this.siteErrorReport.BrowserVersion = this.clientEnvService.getFullBrowserName();
    this.siteErrorReport.OsName = this.clientEnvService.getFullOSName();
    this.siteErrorReport.ApplicationID = AppSetting.ApplicationID;
  }

  fileSelected(event:any){
    this.uploadedDocument = event.target.files[0];
  }

  sendErrorReport(){
    this.editorService.saveSiteErrorReport(this.siteErrorReport, this.uploadedDocument).subscribe((data)=>{
      if(data == true){
        this.initializeDefault();
        //this._toastr.success("あなたのエラーは送付されました。");//"Your Error Report Submitted Successfully."
        this.sharedService.showTranslationToastr('NOTIFICATION.YOUR_ERROR_REPORT_SUBMITTED_SUCCESSFULLY',NotificationType.SUCCESS);
      }

    });
  }

  ngOnInit() {
  }

}
