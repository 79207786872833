import { OrderAppointedToStaffModel } from "./order-appointed-to-staff.model"
import { DocumentModel } from "./document.model"
import { EditingPreferenceModel } from "./editing-pro-preference-model.model"

export class OrderWeb {
    ID:string
    ApplicationID:number
    RegistrationID:string
    OrderNo:string 
    InvoiceNo:string
    SourceLanguageID:string
    TargetLanguageID:string
    TranslationFieldID:string
    TranslationLevelType:number
    AssignedTranslatorID:string
    ClientID:string
    DeliveryPlanID:number
    DeliveryPlan:string
    DeliveryLevelName:string
    CurrencyID:number
    IntroducerID:string
    TranslationType:number
    OrderDate:Date
    DeliveryDate:Date 
    StartDate:Date 
    EndDate:Date 
    OrderStatus:number
    OrderStatusName:string 
    PaymentStatus:number
    PaymentMethod:number
    PaymentMethodName:string
    PaymentAmount:number
    PaymentAmountWithoutTax:number
    PaymentDate:Date
    WordCount:number
    CountType:number
    RemainingTimeInMinutes:number
    TranslatorFee:number
    EstimatedPrice:number
    UnitPrice:number
    Discount:number
    PriceAfterDiscount:number
    ConsumptionTax:number
    RequestDate:Date
    EstimationType:number
    CommentToTranslator:string
    MenuScript:string
    MenuScriptOriginal:string
    DeliveryComment:string
    CompanyNotes:string
    CommentToBcause:string
    IsClaimed:boolean
    DeductedPrice:number
    IsPostPay:boolean
    CharacterCount:number
    IsEmailSent:boolean
    SourceLanguage:string 
    TargetLanguage:string 
    TranslationFieldName:string 
    TotalPage:number 
    AppointedToStaffList:Array<OrderAppointedToStaffModel>
    WebDocumentList:Array<DocumentModel>
    EditingPreference:EditingPreferenceModel
    Assigned2ndEditorID:string
    TranslatorNo:number
    TotalCount:number
    AvgScore:number
    Assigned2ndEditorNo:number
    CustomerComment:string
    SubSpecialityFieldName:string
    Contents:string
    PaymentType:string
    TranslatorName:string
    WebOrderTitle:string
    Submission:Date
    CurrencySymbol:string 
    PostalFee:number 
    IsAssignAs2ndEditor:boolean
    DeliveryDocumentList:Array<DocumentModel>
    PostalCode:string 
    PrefectureName:string 
    CompanyAddress1:string 
    CompanyLocalName:string 
    CompanyDepartmentName:string 
    CompanyPicName:string 
    SubmissionDate1stEditor:Date
    SubmissionDate2ndEditor:Date
    OrderName:string 
    MailingCost:number 
    OrderMailingAmount:number 
    PostageAmount:number
    SecondEditorAssignedTime:Date
	OrderYear:number
	OrderMonth:number
    OrderRejectedBy:string
    CurrentCulture:string 
    CompanyTypeID: number
    FirstParameter: number
    SecondParameter: number
    ThirdParameter :number
    FourthParameter:number
    RemainingTime:string
    GrossProfitAmount:number
    EmailAddress:string
    StaffEmailAddress:string
    MotherLanguageName:string
    ContactNumber:string
    ContactAddress:string
    CustomerEvaluation:number
    StaffNoOfWorks:number
    RegisteredDate:Date
	ComplainDate:Date
    ClaimStatus:string
    ClientNo:string 
    StaffNumber:number
    ClaimDetails:string 
    DetailsForAction:string
    Editor2ndDueDate:Date
    Editor1stDueDate:Date
    AffiliateCode:string 
    NumberOfWorks:number
}
