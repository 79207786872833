import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable,of } from 'rxjs';
import {AppSetting} from '../app-setting';
import { CompanyModel } from '../models/company-model.model';
import { MessageModel } from '../models/message-model.model';
import { ErrorReportModel } from '../models/error-report-model.model';
import { CompanyIndustryClassificationModel } from '../models/company-industry-classification-model.model';
import { CredentialModel } from '../models/credential.model';
import { OrderWeb } from '../models/order-web.model';
import { MiscellaneousModel } from '../models/miscellaneous-model.model';
import { CompanyCouponModel } from '../company-coupon.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  ROOT_URL:string=AppSetting.API_ENDPOINT; 
  EDITINGPRO_ROOT_URL:string=AppSetting.EDITINGPRO_API_ENDPOINT;

  constructor(private http:HttpClient) { }

  saveSiteErrorReport(errorReport:ErrorReportModel,uploadedDocument:any)
  {
    let formData:FormData = new FormData();  
    formData.append('file',uploadedDocument);
    formData.append('model',JSON.stringify(errorReport));  
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL+'customer/SaveSiteErrorReport',formData);
  }


  resetPassword(regId:string,password:string,currentPassword:string)
  {
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL+'customer/resetpassword',{RegistrationID:regId,Password:password,CurrentPassword:currentPassword});
  }

  changePassword(customerId:string,password:string)
  {
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL+'customer/changepassword',{ID:customerId,Password:password});
  }

  getUnreadMessages(customerId:string,pageNo:number)
  {
    const  params = new  HttpParams().set('id', customerId).set('pageNo', pageNo.toString());
    return this.http.get<Array<MessageModel>>(this.EDITINGPRO_ROOT_URL+'customer/GetUnreadMessagesByCustomerId',{params});
  }

  getAllMessages(customerId:string,pageNo:number)
  {
    const  params = new  HttpParams().set('id', customerId).set('pageNo', pageNo.toString());
    return this.http.get<Array<MessageModel>>(this.EDITINGPRO_ROOT_URL+'customer/GetMessageListByCustomerId',{params});
  }

  getMessageDetailById(messageId:number,clientId:string)
  {
    const  params = new  HttpParams().set('id', messageId.toString()).set('clientId', clientId);
    return this.http.get<MessageModel>(this.EDITINGPRO_ROOT_URL+'customer/GetMessageDetailsById',{params});
  }

  checkExistingCustomer(emailID:string,currentCulture:string)
  {
    const  params = new  HttpParams().set('emailID', emailID).set('culture', currentCulture);
    return this.http.get<CompanyModel>(this.EDITINGPRO_ROOT_URL+'customer/checkExistingCustomer',{params});
  }

  registerCustomer(customer:CompanyModel)
  {
    return this.http.post<string>(this.EDITINGPRO_ROOT_URL+'customer/register',customer);
  }
  
  getCompanyById(comapnyId:string)
  {
    const  params = new  HttpParams().set('companyId', comapnyId);
    return this.http.get<CompanyModel>(this.EDITINGPRO_ROOT_URL+'customer/GetCompanyById',{params});
  }

  updateCustomer(customer:CompanyModel,indList:CompanyIndustryClassificationModel[])
  {
    customer.CompanyAssociatedIndustryClassifications=indList;
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL+'customer/UpdateCompany',customer);
  }

  sendMessage(message:MessageModel,uploadedDocument:any)
  {
    let formData:FormData = new FormData();  
    formData.append('file',uploadedDocument);
    formData.append('model',JSON.stringify(message));  
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL+'customer/SendMessage',formData);
  }

  unsubscribe(model:CredentialModel)
  {
    return this.http.post<string>(this.EDITINGPRO_ROOT_URL+'customer/unsubscribe',model);
  }

  registerAsFavorite(customerId:string, editorId:string )
  {
    console.log('editor,id',editorId);
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL+'customer/RegisterAsFavoriteEditor',{CustomerID:customerId,StaffID:editorId});
  }

  isAlreadyFavoriteEditor(customerId:string, editorId:string )
  {
    const  params = new  HttpParams().set('customerId', customerId).set('editorId',editorId);
    return this.http.get<boolean>(this.EDITINGPRO_ROOT_URL+'customer/IsAlreadyFavoriteEditor',{params});
  }

  registerAsBlackListed(customerId:string, editorId:string )
  {
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL+'customer/RegisterAsBlackListEditor',{CustomerID:customerId,StaffID:editorId});
  }

  removeFromBlackList(customerId:string, editorId:string)
  {
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL+'customer/RemoveFromBlackList',{CustomerID:customerId, StaffID:editorId});
  }

  removeFromFavoriteList(customerId:string, editorId:string)
  {
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL+'customer/RemoveFromFavoriteList',{CustomerID:customerId, StaffID:editorId});
  }

  updateMessageStatus(id:number, status:number)
  {
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL+'message/UpdateMessageStatus',{ID:id, Status:status});
  }
  getCustomerMonthWisePaymentList(customerId:string, pageNo:number, pageSize:number)
  {
    const params = new HttpParams().set('clientId', customerId).set('pageNo', pageNo.toString()).set('pageSize', pageSize.toString());
    return this.http.get<Array<MiscellaneousModel>>(this.EDITINGPRO_ROOT_URL+'customer/GetCustomerMonthWisePaymentList', {params});
  }

  getCustomerCurrentMonthPaymentList(customerId:string,year:number,month:number, pageNo:number, pageSize:number)
  {
    const params = new HttpParams().set('clientId', customerId).set('pageNo', pageNo.toString()).set('pageSize', pageSize.toString())
    .set('year', year.toString()).set('month', month.toString());
    return this.http.get<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL+'customer/GetCustomerCurrentMonthPaymentHistory', {params});
  }
  getCustomerUnpaidAmount(customerId:string){
    const params = new HttpParams().set('customerId', customerId)
    return this.http.get<number>(this.EDITINGPRO_ROOT_URL+'customer/GetCustomerUnpaidPaymentStatus', {params});
  }
  
  getIsAlreadyPostpayApproved(registrationNo:number)
  {
    const  params = new  HttpParams().set('registrationNo', registrationNo.toString());
    return this.http.get<boolean>(this.EDITINGPRO_ROOT_URL+'customer/GetIsAlreadyPostpayApproved',{params});
  }

  getCompanyAddressDetailsById(comapnyId:string,culture:string="jp")
  {
    const  params = new  HttpParams().set('companyId', comapnyId).set('culture', culture);
    return this.http.get<CompanyModel>(this.EDITINGPRO_ROOT_URL+'customer/GetClientAddressDetailsById',{params});
  }
  generatePdfTemplate(model:any){
    return this.http.post(this.EDITINGPRO_ROOT_URL +'GeneratePdf', model, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
      });
  }

  issueCouponToCompany(coupon:CompanyCouponModel)
  {
    return this.http.post<string>(this.EDITINGPRO_ROOT_URL+'customer/IssueCouponToCompany',coupon);
  }

  getCompanyIDByRegistrationNo(registrationNo:number)
  {
    const  params = new  HttpParams().set('registrationNo', registrationNo.toString());
    return this.http.get<string>(this.EDITINGPRO_ROOT_URL+'customer/GetCompanyIDByRegistrationNo',{params});
  }

}
