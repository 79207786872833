export class OrderReceiveDocumentSettingsModel {
    ID: string
    OrderID: string
    OrderNo: string
    ReceiptWayID: number
    IsReceiptNeeded: boolean
    IsPDFbyEmail: boolean
    IsAfterPaperOutput: boolean
    PostalCode: string
    Address: string
    NameOfAddress: string
    PersonInCharge: string
    PaymentMethodName: string
    PrefectureName:string 
    IsSendToRegisteredAddress: boolean
    IsParameterizedOrder: boolean
    PaymentMethod:number
    WebSiteURL:string 
    TelephoneNo:string 

    //new properties for EditingPro
    IsStatementOfDeliveryNeeded: boolean
    IsInvoiceNeeded: boolean
    StatementOfDeliveryId: number
    InvoiceDeliveryScheduleId: number
    ReceiptDeliveryScheduleId: number
    IsPDFbyEmailForStatement: boolean
    IsAfterPaperOutputForStatement: boolean
    IsPDFbyEmailForInvoice: boolean
    IsAfterPaperOutputForInvoice: boolean
    FirstParameter: number
    SecondParameter: number
    ThirdParameter :number
    FourthParameter:number
    //
    AddressLine1: string
    AddressLine2: string

    get GetFullAddress() {
        return this.AddressLine1 ? this.AddressLine1 : "" + " " + this.AddressLine2 ? this.AddressLine2 : "";
    }

}
