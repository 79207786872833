export class CompanyPartenerInfoModel {
    CompanyNo: string
    ParentCompanyNo: string
    ParentCompanyName: string
    ID: number
    CompanyID: string
    AffiliateCode: string
    ParentCompanyID: string
    ParentAffiliateCode: string
    ApplicationID: number
    CompanyPartnerTypeCode: string
    PostCode: string
    Email: string
    ContactNumber: string
    InvoiceEmail: string
    InvoiceContactNo: string
    Name: string
    CompanyName: string
    Address: string
    CEOName: string
    CurrentCulture:string 
}

