import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {DataShareService} from '../services/data-share.service';
import {DataSharingModel} from '../models/data-sharing-model';
import { Title,Meta  }     from '@angular/platform-browser';

@Component({
  selector: 'app-discount-campaign',
  templateUrl: './discount-campaign.component.html',
  styleUrls: ['./discount-campaign.component.css']
})
export class DiscountCampaignComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;

  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private titleService: Title,
    private meta: Meta) { 
      this.router=_router.url;
    this.sharedService=dataShareService;

    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName=this.sharedService.currentCulture =='jp'?"割引キャンペーン案内" : "campaign information";
    this.sharedService.setSharedData(this.shareableData);


    if(this.sharedService.currentCulture=='jp'){

      this.titleService.setTitle("英文校正,英文エディティングの割引キャンペーン");
      this.meta.updateTag({ name: 'keywords', content: '割引,キャンペーン,英語校正,英語,校正,専門エディター校正,英語エディター,校正会社,英文論文校正,英語論文チェック,校正チェックリスト,校正サービス,格安校正,激安校正,オンライン校正' });
      this.meta.updateTag({ name: 'description', content: '英文校正をよりお得に。割引をご利用頂けます。簡単,早い,確実！お問い合わせ,お見積りは無料。' });
      }else{

        this.titleService.setTitle("editing-Pro.,English editing discount campaign");
        this.meta.updateTag({ name: 'keywords', content: 'discount,campaign,English editing,English,editing,professional editorediting,Englisheditor,editing company,English writing academic writing editing,English academic writing check,editing checklist,editing service,resonable price editing, low price editing,online editing' });
        this.meta.updateTag({ name: 'description', content: 'experience editing-Pro. for cheaper price with special discount. Easy,fast,safe editing, inquiry, estimate for free.' });


      }

    }

  ngOnInit() {
  }

}
