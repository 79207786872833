import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router,ActivatedRoute } from '@angular/router';
import { ChangePasswordModel } from '../models/change-password.model';
import { CustomerService } from '../services/customer-service.service';
import { ToastrService } from 'ngx-toastr';
import { Title,Meta  }     from '@angular/platform-browser';
import { ConfirmDialogComponent,confirmDialogConfig } from '../confirm-dialog/confirm-dialog.component';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-reset-customer-password',
  templateUrl: './reset-customer-password.component.html',
  styleUrls: ['./reset-customer-password.component.css']
})
export class ResetCustomerPasswordComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  passwordChangeModel:ChangePasswordModel=new ChangePasswordModel();
  customerService:CustomerService;
  toastr:ToastrService;
  ngbModalOptions:ModalOptions;

  constructor(private _router: Router,
              private route:ActivatedRoute,
              private dataShareService:DataShareService,
              private _customerService:CustomerService,
              private _toastr:ToastrService,
              private _authService:AuthenticationService,
              private bsModalRef: BsModalRef,
    private modalService: BsModalService,
              private titleService: Title,
              private meta: Meta) {
    this.router=_router.url;
    this.sharedService=dataShareService;
    this.toastr=_toastr;
    this.customerService=_customerService;
    this.route.params.subscribe(params=>{
      if(params['passCode'])
      this.passwordChangeModel.CurrentPassword=params['passCode'];
      if(params['regId'])
      this.passwordChangeModel.RegistrationID=params['regId'];
       
    });
    
    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName="パスワード変更";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("英文校正サイトのパスワード再発行");
    this.meta.updateTag({ name: 'keywords', content: 'パスワード再発行,英語校正,英語,専門エディター校正,英語エディター,校正会社,論文校正,英語論文チェック,校正チェック' });
    this.meta.updateTag({ name: 'description', content: '英文校正サイトのアカウントパスワードを変更したい場合はこちら。納品物の受領までウェブ上で完結。簡単,早い,確実！お問い合わせ,お見積りは無料。' });

    if(localStorage.getItem("currentUser"))
     this.passwordChangeModel.EmailID=JSON.parse(localStorage.getItem("currentUser")).Email;

     let config:confirmDialogConfig={
      MessageText:"パスワード変更に成功しました 再度ログインしてください",
      IsOkOnly:true,
      OKBtnCallback:this.afterConfirm.bind(this)
      };
     this.ngbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      class: "modal-sm",
      initialState:config
    };

   }

   afterConfirm()
   {
       this._authService.loggedOut();
   }

   changePassword()
   {
     this.customerService.resetPassword(this.passwordChangeModel.RegistrationID,this.passwordChangeModel.NewPassword,this.passwordChangeModel.CurrentPassword).subscribe((data)=>{
       if(data==true)
       {
        this.bsModalRef= this.modalService.show(ConfirmDialogComponent,this.ngbModalOptions);
        // this.toastr.success('Password has been changed successfully!');
       }
       else
       {
        this.toastr.error('パスワードを変更できませんでした。'); //'Could not change the password!'
       }
     });
     
   }

  ngOnInit() {
  }

}
