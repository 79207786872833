export class DocumentModel {
    ID:string;
    OrderID:string;
    OrderNo:string
    FileName:string;
    FileDescription:string;
    OriginalFileName:string;
    Extension:string;
    WordCount:number;
    CharacterCount:number;
    ExcludeWordCount:number;
    TranslationText:string;
    DownloadURL:string;
    FileSize:number;
    UploadDate:Date;
    UploadType:string 
    SenderNo:string 
    ReceiverNo:string 
    Title:string
    Body:string
    UploadedByEditorID:string
    EditoryType:string 
    CreatedDate:Date
}
