import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { RatingModule } from 'ng-starrating';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditorRoutingModule } from './editor-routing.module';
import { EditorDashboardComponent } from './editor-dashboard/editor-dashboard.component';
import { EditorProfileComponent } from './editor-profile/editor-profile.component';
import { EditorMyPageComponent } from './editor-my-page/editor-my-page.component';
import { ErrorReportComponent } from './error-report/error-report.component';
import {MessageListComponent} from './message-list/message-list.component';
import { GuideComponent } from './guide/guide.component';
import { MessageDetailsComponent } from './message-details/message-details.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PenaltyComponent } from './penalty/penalty.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { ProfilePreviewComponent } from './profile-preview/profile-preview.component';
import { MessageComponent } from './message/message.component';
import { EditorOrderListComponent } from '../editor/editor-order-list/editor-order-list.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { OrderOfferComponent } from './order-offer/order-offer.component';
import { EditingDeliveryComponent } from './order-details/editing-delivery/editing-delivery.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModuleModule } from '../shared-module/shared-module.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { EditorMindsetComponent } from './editor-mindset/editor-mindset.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [EditorDashboardComponent, EditorProfileComponent, EditorMyPageComponent, ErrorReportComponent,MessageListComponent, GuideComponent, MessageDetailsComponent, ChangePasswordComponent, PenaltyComponent, UnsubscribeComponent, ProfilePreviewComponent, MessageComponent, EditorOrderListComponent, OrderDetailsComponent, PaymentInfoComponent, OrderOfferComponent, EditingDeliveryComponent, EditorMindsetComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModuleModule,
    PaginationModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TooltipModule.forRoot(),
    RatingModule,
    EditorRoutingModule,
    NgSelectModule
  ]
})
export class EditorModule { }
