import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Title,Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-penalty',
  templateUrl: './penalty.component.html',
  styleUrls: ['./penalty.component.css']
})
export class PenaltyComponent implements OnInit {
  router:string;
  sharedService:DataShareService;
  shareableData:DataSharingModel;

  constructor(private _router:Router, private _shareService:DataShareService,
    private titleService: Title,
    private meta: Meta ) {
    this.router=_router.url;
    this.sharedService=_shareService;

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute= this.router;
    this.shareableData.currentStateName=='jp'? "ペナルティについて":"About penalty";
    this.sharedService.setSharedData(this.shareableData);




    if(this.sharedService.currentCulture=='jp'){


      this.titleService.setTitle("英文エディター品質管理・ペナルティについての案内");
      this.meta.updateTag({ name: 'keywords', content: '品質管理,高品質,格安校正,オンライン校正,英語校正,英語,専門校正,英語エディター,校正会社,論文校正,英語論文チェック,ペナルティ' });
      this.meta.updateTag({ name: 'description', content: '英文エディター品質管理のためのペナルティについての案内を掲載しています。' });


    }else{

      this.titleService.setTitle("English writingeditorquality management・penalty");
      this.meta.updateTag({ name: 'keywords', content: 'quality management,high quality,resonable priceediting,online editing,English editing,English,professional editing,English editor,editing company,academic writing editing,English academic writing check,penalty' });
      this.meta.updateTag({ name: 'description', content: 'Penalty detail to ensure high quality English writing editing. ' });

    }

   }

  ngOnInit() {
  }

}
