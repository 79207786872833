import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AffiliateRoutingModule } from './affiliate-routing.module';
import { CompanyDashboardComponent } from './company-dashboard/company-dashboard.component';
import { CompanyMyPageComponent } from './company-my-page/company-my-page.component';
import { SalesCComponent } from './sales-c/sales-c.component';
import { MonthlyComponent } from './monthly/monthly.component';
import { SalesSComponent } from './sales-s/sales-s.component';
import { ListCComponent } from './list-c/list-c.component';
import { ListSComponent } from './list-s/list-s.component';
import { ListIComponent } from './list-i/list-i.component';
import { ClaimsComponent } from './claims/claims.component';
import { EvaluationComponent } from './evaluation/evaluation.component';
import {NoticeComponent} from './notice/notice.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpLoaderFactory } from '../customer/customer.module';
import { AffiliateInvoiceTemplateComponent } from './affiliate-invoice-template/affiliate-invoice-template.component';
import { RatingModule } from 'ng-starrating';

@NgModule({
  declarations: [CompanyDashboardComponent, CompanyMyPageComponent, SalesCComponent, MonthlyComponent, SalesSComponent, ListCComponent, ListSComponent, 
    ListIComponent, ClaimsComponent, EvaluationComponent,NoticeComponent, AffiliateInvoiceTemplateComponent],
  imports: [
    CommonModule,
    AffiliateRoutingModule,
    FormsModule,
    PaginationModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BsDatepickerModule.forRoot(),
    RatingModule
  ]
})
export class AffiliateModule { }
