import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSetting } from '../app-setting';
import { OrderEvaluationModel } from '../models/order-evaluation-model.model';
import { MiscellaneousModel } from '../models/miscellaneous-model.model';

@Injectable({
  providedIn: 'root'
})
export class EvaluationService {
  ROOT_URL: string = AppSetting.API_ENDPOINT;
  EDITINGPRO_ROOT_URL: string = AppSetting.EDITINGPRO_API_ENDPOINT;


  constructor(private http: HttpClient) { }

  getOrderEvaluationSummary() {
    return this.http.get<Array<OrderEvaluationModel>>(this.EDITINGPRO_ROOT_URL + 'evaluation/GetOrderEvaluationSummary');
  }

  getOrderEvaluationList(pageNo: number, editorId: string = null,pageSize:number=10,culture:string="jp") {
    const params = new HttpParams().set('editorId', editorId).set('pageNo',pageNo.toString())
	.set('pageSize',pageSize.toString()).set('culture', culture);
    return this.http.get<Array<OrderEvaluationModel>>(this.EDITINGPRO_ROOT_URL + 'evaluation/GetOrderEvaluationList',{params});
  }
  
  saveCustomerEvaluation(evaluation:OrderEvaluationModel) {
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL + 'evaluation/SaveCustomerEvaluation',evaluation);
  }

  getEditorProfileEvaluationSummary(editorId:string){
    const params = new HttpParams().set('editorId', editorId);
    return this.http.get<MiscellaneousModel>(this.EDITINGPRO_ROOT_URL + 'evaluation/GetEditorProfileEvaluationSummary', {params});
  }

}
