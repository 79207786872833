import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { MessageModel } from '../../models/message-model.model';
import { CustomerService } from '../../services/customer-service.service';
import { UtilityService } from '../../services/utility.service';
import { Title,Meta  }     from '@angular/platform-browser';
import { CommonListService } from 'src/app/services/common-list.service';

@Component({
  selector: 'app-message-details',
  templateUrl: './message-details.component.html',
  styleUrls: ['./message-details.component.css']
})
export class MessageDetailsComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  messageDetails: MessageModel;
  customerService: CustomerService;
  utility: UtilityService;
  commonService: CommonListService;
  messageInfo:{Title:string,OrderID:string,ReceiverID:string,ReceiverName:string, WebOrderNo:string,ParentId:number,ReplyDate:Date};
  enableReplyButton:boolean;

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private route: ActivatedRoute,
    private _customerService: CustomerService,
    private _commonService: CommonListService,
    private _utility: UtilityService,
    private titleService: Title,
    private meta: Meta) {

    this.router = _router.url;
    this.sharedService = dataShareService;
    this.customerService = _customerService;
    this.commonService = _commonService;
    this.utility = _utility;
    this.messageDetails = new MessageModel();
    this.messageInfo= {Title:null,OrderID:null,ReceiverID:null,ReceiverName:null, WebOrderNo:null,ParentId:0,ReplyDate:null};
    this.enableReplyButton=false;

    this.route.params.subscribe(params => {
      if (params['id']) {

        let messageId: number = params['id'];
        this.getMessageById(messageId);
      }
    });

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName= this.sharedService.currentCulture =='jp'? "メッセージ詳細":"Message details";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("英文校正サイトのメッセージ作成");
    this.meta.updateTag({ name: 'keywords', content: 'メッセージ,品質,ノーハウ,作成,格安校正,オンライン校正,英語校正,英語,専門校正,校正会社,論文校正,英語論文チェック' });
    this.meta.updateTag({ name: 'description', content: '英文の専門校正サイト・エディティングプロのメッセージの作成ページです。いつでもどこでもサクサク英文校正依頼や確認を。' });

  }

  formatFileSize(sizeInBytes: number) {
    return this.utility.formatBytes(sizeInBytes);
  }

  getMessageById(id: number) {
    this.customerService.getMessageDetailById(id,this.sharedService.currentUser.ID).subscribe((data) => {
      if (data) {
        this.messageDetails = data;
         //reply message config
         this.messageInfo.Title=this.messageDetails.Title;
         this.messageInfo.OrderID=this.messageDetails.OrderID;
         this.messageInfo.ReceiverID=this.messageDetails.SenderID;
         this.messageInfo.ReceiverName=this.messageDetails.SenderName;
         this.messageInfo.WebOrderNo=this.messageDetails.WebOrderNo;
         this.messageInfo.OrderID=this.messageDetails.OrderID;
         this.messageInfo.ParentId=this.messageDetails.ID;
         this.messageInfo.ReplyDate=new Date();
         this.enableReplyButton=true;
         //end
        if(data.Status==2) //unread
        {
          this.customerService.updateMessageStatus(this.messageDetails.ID,3).subscribe((data)=>{
            
          });
        }
      }

    });
  }

  downloadFile(filePath: string, isAbsolutePath = false) {
    if (isAbsolutePath)
      filePath = this.utility.getRelativePathFromFileURL(filePath);
    this.commonService.downloadFile(filePath, this.utility.getFileNameFromPath(filePath));
  }

  replyMessage()
  {
   localStorage.setItem("MessageOrderInfo",JSON.stringify(this.messageInfo));
   this._router.navigateByUrl("/editor/message");
  }

  ngOnInit() {
  }

}
