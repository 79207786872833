import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router } from '@angular/router';
import { CommonListService } from '../services/common-list.service';
import { Title,Meta  }     from '@angular/platform-browser';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.css']
})
export class CompanyProfileComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  totalRegisteredStaff:number;
  today:Date;
  
  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private _commonListService:CommonListService, 
    private titleService: Title,
    private meta: Meta) {
      this.router=_router.url;
      this.sharedService=dataShareService;
  
      this.shareableData=new DataSharingModel();
      this.shareableData.currentRoute=this.router;
      this.shareableData.currentStateName="会社概要";
      this.sharedService.setSharedData(this.shareableData);




      if(this.sharedService.currentCulture=='jp'){


        this.titleService.setTitle("英文校正の会社概要|editing-Pro.");
        this.meta.updateTag({ name: 'keywords', content: '翻訳会社,校正会社,英語校正,校正専門会社,論文校正専門,論文エディティング,エディティングプロ' });
        this.meta.updateTag({ name: 'description', content: 'エディティングプロの運営会社の情報となります。校正,翻訳,通訳の他,外国語や外国人に任せたいお仕事がありましたらお問合わせ下さい。' });

      }else{

        this.titleService.setTitle("Company profile about editing |editing-Pro.");
        this.meta.updateTag({ name: 'keywords', content: '"translationcompany,editing company,English editing,editing professionalcompany,academic writing editing professional,academic writing editing,editing-Pro."' });
        this.meta.updateTag({ name: 'description', content: 'Company information of editing-Pro that offers editing, translation, interpretation. If you have any requirements regarding foreign workers or foreign language, please contact us. ' });

      }
      
      this.today=new Date();
      this.totalRegisteredStaff=0;

      _commonListService.getTotalRegisteredStaff().subscribe((data)=>{
        this.totalRegisteredStaff=data;
      });

     }

  ngOnInit() {
  }

}
