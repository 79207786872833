import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {
  location:Location
  constructor(private _location: Location) {
    this.location=_location;
   }

   onClickBack()
   {
     this.location.back();
   }

  ngOnInit() {
  }

}
