import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router } from '@angular/router';
import { CommonListService } from '../services/common-list.service';
import { CompanyModel } from '../models/company-model.model';
import { CountryModel } from '../models/country-model.model';
import { PrefectureModel } from '../models/prefecture-model.model';
import { CustomerService } from '../services/customer-service.service';
import { ToastrService } from 'ngx-toastr';
import { CompanyIndustryClassificationModel } from '../models/company-industry-classification-model.model';
import * as _ from 'underscore';
import { CompanyIndustryClassificationItemModel } from '../models/company-industry-classification-item-model.model';
import { CredentialModel, UserType } from '../models/credential.model';
import { Title,Meta  }     from '@angular/platform-browser';
import { confirmDialogConfig, ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ModalOptions, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-customer-registration',
  templateUrl: './customer-registration.component.html',
  styleUrls: ['./customer-registration.component.css']
})
export class CustomerRegistrationComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  commonListService:CommonListService;
  customerService:CustomerService;
  customerModel:CompanyModel=new CompanyModel();
  countryList:CountryModel[];
  prefectureList:PrefectureModel[];
  isAcceptAggrement:boolean=false;
  toastr:ToastrService;
  industryClassificationList:CompanyIndustryClassificationModel[];
  routerService:Router ;
  bsModalRef: BsModalRef;

  constructor( private _router: Router,
              private dataShareService:DataShareService,
              private _commonListService:CommonListService,
              private _customerService:CustomerService,
              private modalService: BsModalService,
              private _toastr:ToastrService,
              private titleService: Title,
              private meta: Meta) {
    this.router=_router.url;
    this.routerService=_router;
    this.sharedService=dataShareService;
    this.commonListService=_commonListService;
    this.customerService=_customerService;
    this.toastr=_toastr;

    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName=this.sharedService.currentCulture=='jp'?"新規登録":"New registration";
    this.sharedService.setSharedData(this.shareableData);



    if(this.sharedService.currentCulture=='jp'){
      this.titleService.setTitle("英文校正サービスの新規登録");
      this.meta.updateTag({ name: 'keywords', content: '新規登録,品質,格安校正,オンライン校正,英語校正,英語,登録,専門校正,英語英文校正,校正会社,新規論文校正,英語論文チェック' });
      this.meta.updateTag({ name: 'description', content: '英文校正ならお任せを！簡単登録でいつでも英文エディティングの依頼ができる！納品物の受領までウェブ上で完結。簡単,早い,確実！お問い合わせ,お見積りは無料。' });

    }
    else{
      this.titleService.setTitle("editing-Pro.service new registration");
      this.meta.updateTag({ name: 'keywords', content: 'New registration,quality,resonable price editing,online editing,English editing,English, register, professional editing,English editing-Pro.,editing company,new academic writing editing,English academic writing check' });
      this.meta.updateTag({ name: 'description', content: 'Editing-Pro. Smooth and easy registration and order English editing service anywhere. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });


    }


    this.customerModel.IsPostTransactionAllowed=null;
    this.customerModel.CompanyTradingOfficeID='4556EFDA-E556-4D15-8D51-EDE9C4154190';  //default to japan branch office
    this.customerModel.CompanyLocationID=1; //Japan
    this.customerModel.IsCampaignEmail=true;
    this.onChangeCountry();
   }

   getSlectedIndustryClassificationItems():CompanyIndustryClassificationModel[]
   {
    let industryClassificationSelectdItemList=new Array<CompanyIndustryClassificationModel>();
     _.each(this.industryClassificationList,function(parentItem,index){
         _.each(parentItem.itemList,function(item,i){
            if(item.IsSelected)
            industryClassificationSelectdItemList.push(item);
         });
     });
     
     let selectedItems=new Array<CompanyIndustryClassificationModel>();
     _.each(industryClassificationSelectdItemList,function(item,i){
      let m=new CompanyIndustryClassificationModel();
       m.IndustryClassificationID=item.IndustryClassificationId;
       m.IndustryClassificationItemID=item.Id;
       m.IsSelected=item.IsSelected;
       selectedItems.push(m);
     });
   
    return selectedItems;
   }

   configAlert: confirmDialogConfig={
    MessageText: '<div>Sorry right now we have not enough certified resources<br/> to do this job as Expert in {SpecializedFieldName} field.<br/>Please choose plan other than Expert.</div>',
    IsOkOnly:true
  };

 alertModelOptions: ModalOptions={
    backdrop: 'static',
    keyboard: false,
    class: "modal-sm",
    initialState: this.configAlert
  };

   registerCustomer()
   {
    this.customerModel.CompanyAssociatedIndustryClassifications=this.getSlectedIndustryClassificationItems();
    this.customerModel.Name_Local=this.customerModel.Name_Global;  
    this.customerModel.Address1=this.customerModel.Address1_jp;
    
    if(this.sharedService.IsConsumerAffiliateSite && this.sharedService.AffiliateCode)
    {
      this.customerModel.AffiliateCode=this.sharedService.AffiliateCode;
      this.customerModel.IsPartner=false;
    }

    this.customerService.registerCustomer(this.customerModel).subscribe((data)=>{
      if(data==="Success")
      {
        let candidate=new CredentialModel();
        candidate.ApplicationId='3';
        candidate.CultureId='jp';
        candidate.EmailId=this.customerModel.ClientID;
        candidate.Password=this.customerModel.Password;
        candidate.UserType=UserType.Customer;
        
        
        localStorage.setItem("currentCandidate",JSON.stringify(candidate));
        this.routerService.navigate(['/customer/registration-confirm']);
      }
      else if(data && data!="Success"){
        if(this.sharedService.currentCulture=='en')
        {
          //this.toastr.error('Email ID already exist,Please use another one!');
          if(data!="3") //application ID
          {
            //this.toastr.error('If you\'ve used other b-cause services in the past, Please use password change menu.');
            this.configAlert.MessageText='If you\'ve used other b-cause services in the past, Please use password change menu.';
            this.bsModalRef = this.modalService.show(ConfirmDialogComponent, this.alertModelOptions);
          }
          else
          {
            this.toastr.error('Email ID already exist,Please use another one!');
          }
          
        }
        else
        {
          if(data!="3") //application ID
          {
            this.toastr.error('以前editing-Proを運営している株式会社ビーコスのサービス（翻訳・翻訳プロ・人材など）をご利用されたことがあるか、または名刺交換などを行っている場合は、弊社のDBにすでにご登録されている可能性がございます。この場合はパスワード変更ページからパスワードを登録後、ご利用ください');
          }
          else
          {
            this.toastr.error('ご登録いただいたＥメールＩＤはすでに登録されています。別のものをお使いください。');
          }
        }
       
      }

      else{
        this.toastr.error('Could not be Registered!');
      }
 
     }); 
     
   }

   isCustomerAlreadyExists()
   {
     if(!this.customerModel.ClientID || this.customerModel.ClientID=="")
     return;
    this.customerService.checkExistingCustomer(this.customerModel.ClientID,'jp').subscribe((data)=>{
      if(data)
      {
        //this.toastr.error('Email ID already exist,Please use another one!');
        if(this.sharedService.currentCulture=='en')
        {
          //this.toastr.error('Email ID already exist,Please use another one!');
          if(data.ApplicationId!=3) //application ID
          {
            //this.toastr.error('If you\'ve used other b-cause services in the past, Please use password change menu.');
            this.configAlert.MessageText='If you\'ve used other b-cause services in the past, Please use password change menu.';
            this.bsModalRef = this.modalService.show(ConfirmDialogComponent, this.alertModelOptions);
          }
          else
          {
            this.toastr.error('Email ID already exist,Please use another one!');
          }
          
        }
        else
        {
          if(data.ApplicationId!=3) //application ID
          {
            //this.toastr.error('以前editing-Proを運営している株式会社ビーコスのサービス（翻訳・翻訳プロ・人材など）をご利用されたことがあるか、または名刺交換などを行っている場合は、弊社のDBにすでにご登録されている可能性がございます。この場合はパスワード変更ページからパスワードを登録後、ご利用ください');
            this.configAlert.MessageText='以前editing-Proを運営している株式会社ビーコスのサービス（翻訳・翻訳プロ・人材など）をご利用されたことがあるか、または名刺交換などを行っている場合は、弊社のDBにすでにご登録されている可能性がございます。この場合はパスワード変更ページからパスワードを登録後、ご利用ください';
            this.bsModalRef = this.modalService.show(ConfirmDialogComponent, this.alertModelOptions);
          }
          else
          {
            this.toastr.error('ご登録いただいたＥメールＩＤはすでに登録されています。別のものをお使いください。!');
          }
        }
       
      }
      else
      {
        this.toastr.success('Email ID Available!');
      }
      
    });
   }

   onChangeCountry()
   {
      //this.customerModel.CompanyLocationID=countryID
      if(this.customerModel.CompanyLocationID)
       this.commonListService.getPrefecturesByCountryID(this.customerModel.CompanyLocationID).subscribe((data)=>{
       this.prefectureList=data;
      });
     else
     this.prefectureList=[];

   }

   onChangeCompanyType()
   {
     if(this.customerModel.CompanyTypeID==8)
     {
      this.customerModel.Name_Global="個人";
     }
     else
     {
      this.customerModel.Name_Global=null;
     }
   }

  ngOnInit() {

    this.commonListService.getIndustryClassifications('jp').subscribe((data)=>{
      let filteredData=_.filter(data,function(item){
        return item.itemList.length>0;
      });
      
      this.industryClassificationList=filteredData;

    });
    this.commonListService.getCountries(this.sharedService.currentCulture).subscribe((data)=>{
     this.countryList=data;
    });

  }

}
