import { Component, Input, OnInit } from '@angular/core';
import { CurrencyConverterService } from '../services/currency-converter.service';
import { DataShareService } from '../services/data-share.service';

@Component({
  selector: 'app-currency-field',
  templateUrl: './currency-field.component.html',
  styleUrls: ['./currency-field.component.css']
})
export class CurrencyFieldComponent implements OnInit {
  @Input('amount-in-jpy') amountJPY: number;

  convertedAmount: number;
  currencySymbol:string;
  currencyService:CurrencyConverterService;
  sharedService:DataShareService;
  currentCurrecyCode:string;

  constructor(private _sharedService:DataShareService,private _currencyService:CurrencyConverterService) {
    
    this.sharedService=_sharedService;
    this.currentCurrecyCode=this.sharedService.currentCurrency?this.sharedService.currentCurrency : "JPY";
    this.currencyService=_currencyService;

    if(this.currentCurrecyCode=='USD')
    {
      this.currencySymbol='$';
    }
    else if(this.currentCurrecyCode=='PHP')
    {
      this.currencySymbol='₱';
    }
    else
    {
      this.currencySymbol='¥';
    }
    this.currencyService.executeCurrencyConversion(this.convertAmount.bind(this));
   }

   private convertAmount()
   {
    let amount:number= this.currencyService.fx.convert(this.amountJPY, {from: "JPY", to: this.currentCurrecyCode});
    this.convertedAmount=amount;
   }

  ngOnInit() {
  }

}
