import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { RatingModule } from 'ng-starrating';
import { CustomerRoutingModule } from './customer-routing.module';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { CustomerMyPageComponent } from './customer-my-page/customer-my-page.component';
import { CustomerProfileComponent } from './customer-profile/customer-profile.component';
import { MessageListComponent } from './message-list/message-list.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ErrorReportComponent } from './error-report/error-report.component';
import { MessageDetailsComponent } from './message-details/message-details.component';
import { MessageComponent } from './message/message.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { FavoriteEditorListComponent } from './favorite-editor-list/favorite-editor-list.component';
import { EditorBlackListComponent } from './editor-black-list/editor-black-list.component';
import { PastAppointedComponent } from './past-appointed/past-appointed.component';
import { PastRequestedComponent } from './past-requested/past-requested.component';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { EvaluationComponent } from './evaluation/evaluation.component';
import { PaymentMethodSelectionComponent } from './payment-method-selection/payment-method-selection.component';
import { InvoiceBreakdownComponent } from './invoice-breakdown/invoice-breakdown.component';
import { PaymentInformationComponent } from './payment-information/payment-information.component';
import { PaymentConfirmComponent } from './payment-confirm/payment-confirm.component';
import { DownloadDeliveryComponent } from './order-details/download-delivery/download-delivery.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [CustomerDashboardComponent, CustomerMyPageComponent, CustomerProfileComponent, MessageListComponent, ChangePasswordComponent, ErrorReportComponent, MessageDetailsComponent, MessageComponent, UnsubscribeComponent, FavoriteEditorListComponent, EditorBlackListComponent, PastAppointedComponent, PastRequestedComponent, OrderListComponent, OrderDetailsComponent, EvaluationComponent, PaymentMethodSelectionComponent, InvoiceBreakdownComponent, PaymentInformationComponent, PaymentConfirmComponent, DownloadDeliveryComponent],
  imports: [
    CommonModule,
    FormsModule,
    PaginationModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RatingModule,
    NgSelectModule,
    CustomerRoutingModule
  ]
})
export class CustomerModule { }
