import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router } from '@angular/router';
import { Title,Meta  }     from '@angular/platform-browser';
import { AppSetting } from '../app-setting';
import { stringify } from 'querystring';

@Component({
  selector: 'app-inquery',
  templateUrl: './inquery.component.html',
  styleUrls: ['./inquery.component.css']
})
export class InqueryComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  IsEstimateInquery:boolean=true;
  commonContactUsURL:string=  AppSetting.API_ENDPOINT+'estimation/editing';   //"https://bcause-api.com/estimation/editing";

  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private titleService: Title,
    private meta: Meta) { 
    this.router=_router.url;
    this.sharedService=dataShareService;

    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName="見積り・お問合せ（コーディネーター依頼）";
    this.sharedService.setSharedData(this.shareableData);


    if(this.sharedService.currentCulture=='jp'){


      this.titleService.setTitle("英文校正の問い合わせ|editing-Pro.");
      this.meta.updateTag({ name: 'keywords', content: '問い合わせ,英語問い合わせ,校正問い合わせ,校正会社,英語校正,校正専門,エディティング' });
      this.meta.updateTag({ name: 'description', content: '見積は無料で,営業時間内には1時間以内に回答させて頂きます。お見積以外に校正や翻訳について確認されたい点がありましたら気軽に御連絡下さい。' });
    }
    else{
      this.titleService.setTitle("Inquiry about editing |editing-Pro.");
      this.meta.updateTag({ name: 'keywords', content: 'inquiry,English inquiry,editing inquiry,editing company,English editing,editing professional,editing' });
      this.meta.updateTag({ name: 'description', content: 'Estimation is for free and will be prepared within 1hour during office hours. If you have any inquries reagarding editing or translation work aside from estimation, please contact us.' });



    }
  }


  toggleIsEstimateOrContactUsInquery(isEstimate:boolean)
  {
    let radioDivEstimate=document.querySelector('#radioEstimate');
    let radioDivContactUs=document.querySelector('#radioContactUs');
     
    if(isEstimate)
      {
        this.IsEstimateInquery=true;
        radioDivEstimate.className="current";
        radioDivContactUs.className="";
      } 
     else
     {
        this.IsEstimateInquery=false; 
        radioDivEstimate.className="";
        radioDivContactUs.className="current";
     }
      
  }

  onFrameLoad()
  {

    let messageData:{Culture:string,WebsiteURL:string}={Culture:null,WebsiteURL:null};
    messageData.Culture=this.sharedService.currentCulture;
    messageData.WebsiteURL='http://localhost:4200/';
    let iframe:HTMLFrameElement=document.querySelector("#iFrame1");
    iframe.contentWindow.postMessage(messageData,"*");
  }

  ngOnInit() {
   // this.toggleIsEstimateOrContactUsInquery(this.IsEstimateInquery);
   
  }

  

}
