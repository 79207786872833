import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { DataShareService } from '../services/data-share.service';

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.css']
})
export class HomeBannerComponent implements OnInit {
  router:string ;
  sharedService: DataShareService;


  constructor(private _router: Router ,private dataShareService: DataShareService) { 
    this.router=_router.url;
    this.sharedService = dataShareService;
    this.sharedService.getBannerStyle();
  }



  ngOnInit() {
  }

}
