import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {DataShareService} from '../../services/data-share.service';
import {DataSharingModel} from '../../models/data-sharing-model';
import { Title,Meta  }     from '@angular/platform-browser';
import { EditorService } from '../../services/editor.service';
import { CommonListService } from '../../services/common-list.service';
import { SpecializedFieldModel } from '../../models/specialized-field.model';
import { EditorSearchModel } from '../../models/editor-search.model';
import * as _ from 'underscore';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import {AppSetting} from '../../app-setting';
import { LanguageModel } from '../../models/language.model';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from '../../services/customer-service.service';

@Component({
  selector: 'app-past-requested',
  templateUrl: './past-requested.component.html',
  styleUrls: ['./past-requested.component.css']
})
export class PastRequestedComponent implements OnInit {

  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  editorService: EditorService;
  toastrService: ToastrService;
  commonListService: CommonListService;
  specialitiesList:Array<SpecializedFieldModel>;
  languages: Array<LanguageModel>;
  searchModel:{isOnlyCeritfied:boolean,specialityId:string,sourceLanguageId:string,targetLanguageId:string,editorNo:string};
  editorList:Array<EditorSearchModel>;
  pageNumber:number=1;
  pageSize:number=12;
  totalItems:number;
  imageBaseUrl:string=AppSetting.IMAGE_BASE_URL;
  selectedEditors:Array<number>;
  customerService:CustomerService;

  constructor(private _editorService: EditorService,
    private _commonListService: CommonListService,
    private _router: Router,
    private _customerService:CustomerService,
    private dataShareService:DataShareService,
    private titleService: Title,
    private meta: Meta,
    private _toastrService: ToastrService) {

    this.router=_router.url;
    this.sharedService=dataShareService;
    this.commonListService = _commonListService;
    this.toastrService = _toastrService;
    this.editorService = _editorService;
    this.customerService=_customerService;
    this.specialitiesList=new Array<SpecializedFieldModel>();
    this.searchModel={isOnlyCeritfied:false,specialityId:null,sourceLanguageId:'1484e197-70f8-4f64-98cf-e0f06eff6f49',targetLanguageId:'1484e197-70f8-4f64-98cf-e0f06eff6f49',editorNo:null};
    this.editorList=new Array<EditorSearchModel>();
    this.totalItems=0;
    this.pageNumber=1;
    this.selectedEditors=new Array<number>();

    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName=this.sharedService.currentCulture=='jp'?"エディターリスト(過去依頼)":"Editor list (past order)";
    this.sharedService.setSharedData(this.shareableData);


    if(this.sharedService.currentCulture=='jp'){
      this.titleService.setTitle("英文校正サイトの専門エディターリストを確認");
      this.meta.addTag({ name: 'keywords', content: 'エディター,リスト,指名可能,英文校正,オンライン校正,英語校正,英語,校正,専門エディター校正,英語エディター,校正会社,英文論文校正,英語論文チェック,校正チェック' });
      this.meta.addTag({ name: 'description', content: 'プロのエディターのリストを見る' });
    }
    else{

      this.titleService.setTitle("Check the professional Requested editor list of proofreading site");
      this.meta.addTag({ name: 'keywords', content: 'Requested Editor, list, dispatchable, proofreading, online proofreading, English proofreading, English, proofreading, professional editor proofreading, English editor, calibration company, English paper calibration, English paper check, calibration check' });
      this.meta.addTag({ name: 'description', content: 'Check the professional Requested editor list of proofreading' });

    }
    this.getSpecilitites();
    this.searchEditorNow();
    this.getLanguageList();
   }


  clearFilter()
  {
    this.searchModel.specialityId=null;
    this.searchModel.isOnlyCeritfied=false;
    this.searchModel.editorNo=null;
    this.searchModel.sourceLanguageId='1484e197-70f8-4f64-98cf-e0f06eff6f49';
    this.searchModel.targetLanguageId='1484e197-70f8-4f64-98cf-e0f06eff6f49';
    this.pageNumber=1;
    this.searchEditorNow();
  }

  removeFromChooseList(item:number)
  {
      this.selectedEditors=_.without(this.selectedEditors,item);
  }

  onPageIndexChange(event: PageChangedEvent) {
    this.pageNumber=event.page;
 } 

  AddToChooseList(regId:number)
  {
    if(this.selectedEditors.length<5)
    {
      let item=_.find(this.selectedEditors,function(r:number) {
        return r==regId;
      });
      if(!item) //not already in the array
      {
        this.selectedEditors.push(regId);
      }
      else{
        this._toastrService.warning("エディターは既に選択されています。");//"The editor is already selected."
      }
    }
    else
    {
      this._toastrService.warning("一度に選択することはできるのは5人までです。");//"Sorry! You cannot select more than 5 at once."
    }
  }

  
  registerAsFavorite(editorId:string)
  {
    if (localStorage.getItem("currentUser"))
    {
      let customerId:string = JSON.parse(localStorage.getItem("currentUser")).ID;
      this.customerService.registerAsFavorite(customerId,editorId).subscribe((data)=>{
        if(data)
        {
           this._toastrService.success('お気に入り登録ができました。');//"Successfully Registered as Favorite!"
        }
        else{
            this._toastrService.error('お気に入り登録ができませんでした。');//"Could not be registered as Favorite!"
        }
      });
    }
  }

  searchEditorNow()
  {
    let customerId:string = JSON.parse(localStorage.getItem("currentUser")).ID;
    this.editorService.searchPastRequestedEditor(customerId,this.searchModel.specialityId,this.searchModel.isOnlyCeritfied,this.searchModel.editorNo).subscribe((data)=>{
      if(data)
      {
        this.editorList=data;
        //console.log('editor ID:',data)
        this.totalItems=data.length;
        _.each(this.editorList,function(item:EditorSearchModel){
         let spList=_.pluck(item.Speciality.slice(0,5), 'Name');
         item.SpecializedFiled=spList.join(',');
        });
      }
      else{
        this.editorList=new Array<EditorSearchModel>();
        this.totalItems=0;
      }
      //this.getEditorFavoriteStatus(this.editorList.ID);
    });
  }

  getSpecilitites()
  {
    this.commonListService.getSpecializedFieldList().subscribe((data)=>{
      this.specialitiesList=data;
    });
  }

  getLanguageList() {
    this.commonListService.getAllLanguages().subscribe((data) => {
      if (data)
        this.languages = data;
    });
  }

  goToEstimate()
  {
    if(this.selectedEditors && this.selectedEditors.length>0)
    {
      localStorage.setItem("SelectedEditorsForEstimation",JSON.stringify(this.selectedEditors));
      this._router.navigateByUrl("/appointed");
    }
    else
    {
      this._toastrService.info('少なくともエディターを1名選んでください。'); //"You've to choose at least an editor!"
    }
  }

  ngOnInit() {
  }

}
