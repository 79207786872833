import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { Router } from '@angular/router';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Title, Meta } from '@angular/platform-browser';
import { EvaluationService } from '../../services/evaluation.service';
import { OrderEvaluationModel } from '../../models/order-evaluation-model.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { EstimationService } from '../../services/estimation.service';
import { OrderWeb } from '../../models/order-web.model';
import { MessageModel } from '../../models/message-model.model';
import { EditorService } from '../../services/editor.service';
import { NoticeModel } from '../../models/notice.model';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-editor-my-page',
  templateUrl: './editor-my-page.component.html',
  styleUrls: ['./editor-my-page.component.css']
})
export class EditorMyPageComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  evaluationService: EvaluationService;
  utilityService: UtilityService;
  evulationList: { currentPage: number, totalItems: number, dataSource: OrderEvaluationModel[] };
  avaiableOrderList: { currentPage: number, totalItems: number, dataSource: OrderWeb[] };
  editorOrderList: { currentPage: number, totalItems: number, dataSource: OrderWeb[] };
  noticeList: { currentPage: number, totalItems: number, dataSource: NoticeModel[] };
  appointedCriteriaOrderList: { currentPage: number, totalItems: number, dataSource: OrderWeb[] };
  runningOrderList: { currentPage: number, totalItems: number, dataSource: OrderWeb[] };
  estimationService: EstimationService;
  unreadMessageList: { currentPage: number, totalItems: number, dataSource: MessageModel[] };
  editorService: EditorService;
  noticeDataList: NoticeModel[];
  editorNo:number;

  constructor(private _router: Router,
    private _dataShareService: DataShareService,
    private _evaluationService: EvaluationService,
    private _estimationService: EstimationService,
    private _editorService: EditorService,
    private _utilityService: UtilityService,
    private titleService: Title,
    private meta: Meta) {
    this.router = _router.url;
    this.sharedService = _dataShareService;
    this.evaluationService = _evaluationService;
    this.estimationService = _estimationService;
    this.editorService = _editorService;
    this.utilityService=_utilityService;
    this.evulationList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.avaiableOrderList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.unreadMessageList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.noticeList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.editorOrderList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.appointedCriteriaOrderList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.runningOrderList = { currentPage: 1, totalItems: 0, dataSource: null };

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = "My Page";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("英文校正サイトのMypage（エディター）");
    this.meta.updateTag({ name: 'keywords', content: '英文,英文校正,英文エディター,専門校正,すぐ対応,簡単' });
    this.meta.updateTag({ name: 'description', content: '英文校正サイトのMypageの情報がご確認いただけます。24時間365日対応。オンラインで利用できる校正サービス。納品物の受領までウェブ上で完結。簡単,早い,確実！30分からの短時間納品も可能です。お問い合わせ,お見積りは無料。' });
    if (localStorage.getItem("stateBeforeLogin")) {
      let stateToGo: string = localStorage.getItem("stateBeforeLogin");
      this._router.navigate([stateToGo]).then(function (status) {
        if (status) {
          console.log("Removing SavedState Info...");
          localStorage.removeItem("stateBeforeLogin");
        }
      });
    }

    let editorNo = JSON.parse(localStorage.getItem("currentUser")).RegistrationID;
    this.editorNo = editorNo;

    this.getEditorEvaluationList();
    this.getAvaiableOrderList();
    this.getAllNotice();
    this.getEditorOrderList();
    this.getAppointedCriteriaOrderList();
    this.getRunningOrderList();
  }

  private getAllNotice() {
    this.editorService.getEditorNoticeList().subscribe((data) => {
      if (data) {
        this.noticeList.dataSource = data;
        this.noticeDataList = data.slice(0, 5);
        if (data.length > 0)
          this.noticeList.totalItems = data.length;
      }
    });
  }

  onNoticeListPageIndexChange(event: PageChangedEvent) {
    let pageSize: number = 5;
    let start: number = (event.page - 1) * pageSize;
    let end: number = start + 5;
    this.noticeDataList = this.noticeList.dataSource.slice(start, end);
    this.noticeList.currentPage = event.page;
  }

  getEditorEvaluationList() {
    const editor = JSON.parse(localStorage.getItem("currentUser"));
    this.evaluationService.getOrderEvaluationList(1, editor.ID).subscribe((data) => {
      if (data)
        this.evulationList.dataSource = data;
      if (data.length > 0)
        this.evulationList.totalItems = data[0].TotalRecords;
    })
  }

  onEvaluationListPageIndexChange(event: PageChangedEvent) {
    this.evaluationService.getOrderEvaluationList(event.page).subscribe((data) => {
      this.evulationList.currentPage = event.page;
      this.evulationList.dataSource = data;
      if (data.length > 0)
        this.evulationList.totalItems = data[0].TotalRecords;
    });
  }

  getAvaiableOrderList() {
    this.estimationService.getUnassignedOrderList(this.avaiableOrderList.currentPage, 12, this.sharedService.currentUser.ID, this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.avaiableOrderList.dataSource = data;
      //console.log('Available OrderList:',data);
      if (data.length > 0)
        this.avaiableOrderList.totalItems = data[0].TotalCount;
    })
  }

  onAvaiableOrderListPageIndexChange(event: PageChangedEvent) {
    this.estimationService.getUnassignedOrderList(event.page, 12, this.sharedService.currentUser.ID, this.sharedService.currentCulture).subscribe((data) => {
      this.avaiableOrderList.currentPage = event.page;
      this.avaiableOrderList.dataSource = data;
      if (data.length > 0)
        this.avaiableOrderList.totalItems = data[0].TotalCount;
    });
  }

  getRunningOrderList() {
    this.estimationService.getEditorRunningList(this.sharedService.currentUser.ID, this.runningOrderList.currentPage, 12,this.sharedService.currentCulture).subscribe((data) => {
      if (data.length > 0)
      {
        console.log(data);
        this.runningOrderList.totalItems = data[0].TotalCount;
        data.forEach(o=>{
         o.RemainingTime=this.utilityService.getTimeString(o.RemainingTimeInMinutes,this.sharedService.currentCulture);
        });
      }

      this.runningOrderList.dataSource = data;

    })
  }

  onRunningOrderListPageIndexChange(event: PageChangedEvent) {
    this.runningOrderList.currentPage = event.page;
    this.estimationService.getEditorRunningList(this.sharedService.currentUser.ID, this.runningOrderList.currentPage, 12,this.sharedService.currentCulture).subscribe((data) => {
      
      if (data.length > 0)
      {
        
        this.runningOrderList.totalItems = data[0].TotalCount;
        data.forEach(o=>{
         o.RemainingTime=this.utilityService.getTimeString(o.RemainingTimeInMinutes,this.sharedService.currentCulture);
        });
      }

      this.runningOrderList.dataSource = data;
        
    });
  }

  getEditorOrderList(pageNo: number = 1) {
    this.estimationService.getEditorOrderListByEditorId(this.sharedService.currentUser.ID, pageNo, 12, this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.editorOrderList.dataSource = data;
      if (data.length > 0)
        this.editorOrderList.totalItems = data[0].TotalCount;
    })
  }

  onEditorOrderListPageIndexChange(event: PageChangedEvent) {
    this.editorOrderList.currentPage = event.page;
    this.getEditorOrderList(event.page);
  }

  getUnreadMessages(pageNo: number = 1) {
    this.editorService.getUnreadMessagesByEditorId(this.sharedService.currentUser.ID, pageNo).subscribe((data) => {
      if (data) {
        this.unreadMessageList.currentPage = pageNo;
        this.unreadMessageList.dataSource = data;
        if (data.length)
          this.unreadMessageList.totalItems = data[0].TotalRows;
      }

    });
  }

  onMessageListPageIndexChange(event: PageChangedEvent) {
    this.unreadMessageList.currentPage = event.page;
    this.getUnreadMessages(event.page);
  }


  getAppointedCriteriaOrderList(pageNo: number = 1) {
    this.estimationService.getEditorOpportunityList(this.sharedService.currentUser.ID, pageNo, 12, true, this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.appointedCriteriaOrderList.dataSource = data;
      if (data.length > 0)
        this.appointedCriteriaOrderList.totalItems = data[0].TotalCount;
    })
  }

  onAppointedCriteriaOrderListPageIndexChange(event: PageChangedEvent) {
    this.appointedCriteriaOrderList.currentPage = event.page;
    this.getAppointedCriteriaOrderList(event.page);
  }

  getWorkedAsEditorType(order:OrderWeb)
  {
    if(order.TranslatorNo==this.editorNo)
    return '1st';
    else
    return '2nd';
  }

  ngOnInit() {
    this.getUnreadMessages();
    if (this.sharedService.bsModalRef) {
      this.sharedService.bsModalRef.hide();
    }
  }

}
