import { Component, OnInit } from '@angular/core';
import { OrderWeb } from '../../models/order-web.model';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Meta, Title } from '@angular/platform-browser';
import { DataShareService } from '../../services/data-share.service';
import { AffiliateService } from '../../services/affiliate.service';
import { Router } from '@angular/router';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { NoticeModel } from 'src/app/models/notice.model';

@Component({
  selector: 'app-company-my-page',
  templateUrl: './company-my-page.component.html',
  styleUrls: ['./company-my-page.component.css']
})
export class CompanyMyPageComponent implements OnInit {

affiliateService: AffiliateService;
paymentAfterDeliveryOrderList: { currentPage: number, totalItems: number, dataSource: OrderWeb[] };
salesBreakdownClientOrderList:{currentPage: number, totalItems: number, dataSource: OrderWeb[]};
salesBreakdownEditorOrderList:{currentPage: number, totalItems: number, dataSource: OrderWeb[]};
claimOrderList:{currentPage: number, totalItems: number, dataSource: OrderWeb[]};
noticeList: { currentPage: number, totalItems: number, dataSource: NoticeModel[] };
router: string;
sharedService: DataShareService;
shareableData: DataSharingModel;

constructor(private _affiliateService: AffiliateService, private _router: Router,
  private dataShareService: DataShareService,
  private titleService: Title,
  private meta: Meta) {
  this.router = _router.url;
  this.sharedService = dataShareService;
  this.affiliateService = _affiliateService;
  
  this.paymentAfterDeliveryOrderList = { currentPage: 1, totalItems: 0, dataSource: null };
  this.salesBreakdownClientOrderList = { currentPage: 1, totalItems: 0, dataSource: null };
  this.salesBreakdownEditorOrderList = { currentPage: 1, totalItems: 0, dataSource: null };
  this.noticeList = { currentPage: 1, totalItems: 0, dataSource: null };
  this.claimOrderList = { currentPage: 1, totalItems: 0, dataSource: null };
  this.shareableData = new DataSharingModel();
  this.shareableData.currentRoute = this.router;
  this.shareableData.currentStateName = "提携Mypageトップ";
  this.sharedService.setSharedData(this.shareableData);
  this.titleService.setTitle("提携Mypageトップ | 翻訳プロ(editing-Pro.)管理者ページ");
  this.meta.addTag({ name: 'keywords', content: '英文校正, editing-pro, 管理者' });
  this.meta.addTag({ name: 'description', content: 'エディティング者プラスコーディネーターによるエディティングチェックで、簡単、確実な英語エディティングサービスをご提供中。高品質なエディティングをお届けします。お見積りは無料。' });
  this.getPostpayOrderList();
  this.getClientWeeklySalesBreakdownList();
  this.getEditorWeeklySalesBreakdownList();
  this.getNoticeList();
}

getPostpayOrderList(pageNo:number=1) {
  if (localStorage.getItem("currentUser")) {
    let clientId =JSON.parse(localStorage.getItem("currentUser")).ID;
    this.affiliateService.getPostpayOrderList(clientId,10,pageNo, this.sharedService.currentCulture, 3).subscribe(data => {
      if (data) {
        this.paymentAfterDeliveryOrderList.currentPage = pageNo;
        this.paymentAfterDeliveryOrderList.dataSource = data;
        if (data.length > 0)
          this.paymentAfterDeliveryOrderList.totalItems = data[0].TotalCount;
      }
    });
  }
}

onPostpayOrderPageIndexChange(event: PageChangedEvent) {
  if (localStorage.getItem("currentUser")) {
    this.getPostpayOrderList(event.page);
  }
}

getClientWeeklySalesBreakdownList(pageNo:number=1) {
  if (localStorage.getItem("currentUser")) {
    let clientId =JSON.parse(localStorage.getItem("currentUser")).ID;
    this.affiliateService.getCustomerSalesBreakdown(clientId, 0,0,2,"",null,null,"",10,pageNo, this.sharedService.currentCulture, 3).subscribe(data => {
      if (data) {
        this.salesBreakdownClientOrderList.currentPage = pageNo;
        this.salesBreakdownClientOrderList.dataSource = data;
        if (data.length > 0)
          this.salesBreakdownClientOrderList.totalItems = data[0].TotalCount;
      }
    });
  }
}

onClientWeeklySalesPageIndexChange(event: PageChangedEvent) {
  if (localStorage.getItem("currentUser")) {
    this.getClientWeeklySalesBreakdownList(event.page);
  }
}

getEditorWeeklySalesBreakdownList(pageNo:number=1) {
  if (localStorage.getItem("currentUser")) {
    let clientId =JSON.parse(localStorage.getItem("currentUser")).ID;
    this.affiliateService.getEditorSalesBreakdown(clientId, 0,0,2,"",null,null,null,10,pageNo, 'jp', 3).subscribe(data => {
      if (data) {
        this.salesBreakdownEditorOrderList.currentPage = pageNo;
        this.salesBreakdownEditorOrderList.dataSource = data;
        if (data.length > 0)
          this.salesBreakdownEditorOrderList.totalItems = data[0].TotalCount;
      }
    });
  }
}

onEditorWeeklySalesPageIndexChange(event: PageChangedEvent) {
  if (localStorage.getItem("currentUser")) {
    this.getEditorWeeklySalesBreakdownList(event.page);
  }
}

getNoticeList(pageNo:number=1) {
  if (localStorage.getItem("currentUser")) {
    this.affiliateService.getAllNoticeList().subscribe(data => {
      if (data) {
        this.noticeList.currentPage = pageNo;
        this.noticeList.dataSource = data;
        if (data.length > 0)
          this.noticeList.totalItems = data[0].TotalCount ? data[0].TotalCount : data.length;
      }
    });
  }
}

onNoticeListPageIndexChange(event: PageChangedEvent) {
  if (localStorage.getItem("currentUser")) {
    this.getNoticeList(event.page);
  }
}

getClaimList(pageNo:number=1) {
  if (localStorage.getItem("currentUser")) {
    let clientId =JSON.parse(localStorage.getItem("currentUser")).ID;
    this.affiliateService.getClaimList(clientId,2,"",null,null,"",10,pageNo, 'jp', 3).subscribe(data => {
      if (data) {
        this.claimOrderList.currentPage = pageNo;
        this.claimOrderList.dataSource = data;
        if (data.length > 0)
          this.claimOrderList.totalItems = data[0].TotalCount;
      }
    });
  }
}

onClaimListPageIndexChange(event: PageChangedEvent) {
  if (localStorage.getItem("currentUser")) {
    this.getClaimList(event.page);
  }
}

  ngOnInit() {
  }

}
