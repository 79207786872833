import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {DataShareService} from '../../services/data-share.service';
import {DataSharingModel} from '../../models/data-sharing-model';
import { Title,Meta  }     from '@angular/platform-browser';
import { EditorService } from '../../services/editor.service';
import { CommonListService } from '../../services/common-list.service';
import { SpecializedFieldModel } from '../../models/specialized-field.model';
import { EditorSearchModel } from '../../models/editor-search.model';
import * as _ from 'underscore';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import {AppSetting} from '../../app-setting';
import { LanguageModel } from '../../models/language.model';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/services/customer-service.service';

@Component({
  selector: 'app-favorite-editor-list',
  templateUrl: './favorite-editor-list.component.html',
  styleUrls: ['./favorite-editor-list.component.css']
})
export class FavoriteEditorListComponent implements OnInit {

  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  editorService: EditorService;
  toastrService: ToastrService;
  commonListService: CommonListService;
  specialitiesList:Array<SpecializedFieldModel>;
  languages: Array<LanguageModel>;
  searchModel:{isOnlyCeritfied:boolean,specialityId:string,sourceLanguageId:string,targetLanguageId:string,editorNo:string};
  editorList:Array<EditorSearchModel>;
  pageNumber:number=1;
  pageSize:number=12;
  totalItems:number;
  imageBaseUrl:string=AppSetting.IMAGE_BASE_URL;
  selectedEditors:Array<number>;
  customerService:CustomerService;
  
  constructor(private _editorService: EditorService,
    private _commonListService: CommonListService,
    private _router: Router,
    private _customerService:CustomerService,
    private dataShareService:DataShareService,
    private titleService: Title,
    private meta: Meta,
    private _toastrService: ToastrService) {
    this.router=_router.url;
    this.sharedService=dataShareService;
    this.commonListService = _commonListService;
    this.toastrService = _toastrService;
    this.editorService = _editorService;
    this.customerService=_customerService;
    this.specialitiesList=new Array<SpecializedFieldModel>();
    this.searchModel={isOnlyCeritfied:false,specialityId:null,sourceLanguageId:'1484e197-70f8-4f64-98cf-e0f06eff6f49',targetLanguageId:'1484e197-70f8-4f64-98cf-e0f06eff6f49',editorNo:null};
    this.editorList=new Array<EditorSearchModel>();
    this.totalItems=0;
    this.pageNumber=1;
    this.selectedEditors=new Array<number>();

    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName=this.sharedService.currentCulture=='jp'?"エディターリスト(お気に入り)":"Editor list (favorite)";
    this.sharedService.setSharedData(this.shareableData);


    if(this.sharedService.currentCulture=='jp')
    {
      this.titleService.setTitle("英文校正サイトの専門エディターリストを確認");
      this.meta.addTag({ name: 'keywords', content: 'エディター,リスト,指名可能,英文校正,オンライン校正,英語校正,英語,校正,専門エディター校正,英語エディター,校正会社,英文論文校正,英語論文チェック,校正チェック' });
      this.meta.addTag({ name: 'description', content: '英文校正サイトの専門エディターリストがご確認頂けます。納品物の受領までウェブ上で完結。簡単,早い,確実！お問い合わせ,お見積りは無料。' });
    }
    else{
      this.titleService.setTitle("editing-Pro. Professional editor list confirmation");
      this.meta.addTag({ name: 'keywords', content: 'editor,list,appoint,editing-Pro.,online editing,English editing,English,editing,professional editorediting,English editor,editing company,English writing academic writing editing, English academic writing check,editing check' });
      this.meta.addTag({ name: 'description', content: 'Check editing-Pro\'s professional editor list. One stop service that is easy, fast and safe. Inquiry and estimate are free. ' });

    }
    this.getSpecilitites();
    this.searchEditorNow();
    this.getLanguageList();

   }

   clearFilter()
  {
    this.searchModel.specialityId=null;
    this.searchModel.isOnlyCeritfied=false;
    this.searchModel.editorNo=null;
    this.searchModel.sourceLanguageId='1484e197-70f8-4f64-98cf-e0f06eff6f49';
    this.searchModel.targetLanguageId='1484e197-70f8-4f64-98cf-e0f06eff6f49';
    this.pageNumber=1;
    this.searchEditorNow();
  }

  onPageIndexChange(event: PageChangedEvent) {
     this.pageNumber=event.page;
  } 

  AddToFavorite(regId:number)
  {
    if(this.selectedEditors.length<5)
    {
      let item=_.find(this.selectedEditors,function(r:number) {
        return r==regId;
      });
      if(!item) //not already in the array
      {
        this.selectedEditors.push(regId);
      }
      else{
        this._toastrService.warning("エディターは既に選択されています。");//"The editor is already selected."
      }
    }
    else
    {
      this._toastrService.warning("一度に選択することはできるのは5人までです。"); //"Sorry! You cannot select more than 5 at once."
    }
  }

  removeFromFavorite(item:number)
  {
      this.selectedEditors=_.without(this.selectedEditors,item);
  }

  favoriteCancellation(editorId:string)
  {
      if (localStorage.getItem("currentUser"))
      {
  
       let customerId:string = JSON.parse(localStorage.getItem("currentUser")).ID;
       this.customerService.removeFromFavoriteList(customerId,editorId).subscribe((data)=>{
         if(data)
         {
           this._toastrService.success('アップデートできました。');//"Editor removed from your favorite list successfully!"
           this.searchEditorNow();
         }
         else
         this._toastrService.error('アップデートに失敗しました。'); //"Editor Could Not be Removed From favorite list!"
       });
       }
  }

  searchEditorNow()
  {
    let customerId:string = JSON.parse(localStorage.getItem("currentUser")).ID;
    this.editorService.searchFavoriteEditor(customerId,this.searchModel.specialityId,this.searchModel.isOnlyCeritfied,this.searchModel.editorNo).subscribe((data)=>{
      if(data)
      {
        this.editorList=data;
        this.totalItems=data.length;
        _.each(this.editorList,function(item:EditorSearchModel){
         let spList=_.pluck(item.Speciality.slice(0,5), 'Name');
         item.SpecializedFiled=spList.join(',');
        });
      }
      else{
        this.editorList=new Array<EditorSearchModel>();
        this.totalItems=0;
      }
    });
  }

  getSpecilitites()
  {
    this.commonListService.getSpecializedFieldList().subscribe((data)=>{
      this.specialitiesList=data;
    });
  }

  getLanguageList() {
    this.commonListService.getAllLanguages().subscribe((data) => {
      if (data)
        this.languages = data;
        //console.log('languages',this.languages);
    });
  }

  goToEstimate()
  {
    if(this.selectedEditors && this.selectedEditors.length>0)
    {
      localStorage.setItem("SelectedEditorsForEstimation",JSON.stringify(this.selectedEditors));
      this._router.navigateByUrl("/appointed");
    }
    else
    {
      this._toastrService.info('少なくともエディターを1名選んでください。');//"You've to choose at least an editor!"
    }
 
  }

  ngOnInit() {
  }

}
