export class ErrorReportModel {
    ID :number
    ApplicationID :number
    Email :string
    Name :string
    ErrorPageUrl :string
    ErrorMessage :string
    OsName :string
    BrowserVersion :string
    ErrorDescription :string
    Reproducibility :string
    CustomerNo:string
    EditorNo:string
}
