import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Title, Meta } from '@angular/platform-browser';
import { EditorService } from '../services/editor.service';
import { EditorSearchModel } from '../models/editor-search.model';
import { AppSetting } from '../app-setting';
import * as _ from 'underscore';
import { SpecializedFieldModel } from '../models/specialized-field.model';
import { SoftwareSkillModel } from '../models/software-skill.model';
import { CustomerUser } from '../models/customer-user.model';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from '../services/customer-service.service';
import { EvaluationService } from '../services/evaluation.service';
import { MiscellaneousModel } from '../models/miscellaneous-model.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { UtilityService } from '../services/utility.service';
import { OrderEvaluationModel } from '../models/order-evaluation-model.model';
import { EditorCertificationInfoModel } from '../models/editor-certification-info';

@Component({
  selector: 'app-editor-profile-common',
  templateUrl: './editor-profile-common.component.html',
  styleUrls: ['./editor-profile-common.component.css']
})
export class EditorProfileCommonComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  editorService: EditorService;
  editorModel: EditorSearchModel;
  imageBaseUrl: string = AppSetting.IMAGE_BASE_URL;
  customerService: CustomerService;
  IsAlreadyFavoriteEditor: boolean;
  evaluationService:EvaluationService;
  evulationList: { currentPage: number, totalItems: number, dataSource: OrderEvaluationModel[] };
  miscellaneousModel:MiscellaneousModel;
  editorPastWorkingList:Array<MiscellaneousModel>;
  certificationFields: Array<string> = ["法律", "医療", "特許", "証明書"];

  constructor(private _router: Router,
    private _sharedService: DataShareService,
    private _customerService: CustomerService,
    private _evaluationService:EvaluationService,
    private titleService: Title,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private _editorService: EditorService,
    private utilityService:UtilityService,
    private meta: Meta) {
    this.router = _router.url;
    this.sharedService = _sharedService;
    this.editorService = _editorService;
    this.customerService = _customerService;
    this.evaluationService=_evaluationService;
    this.evulationList = {currentPage: 1, totalItems: 0, dataSource: null};
    this.editorPastWorkingList=new Array<MiscellaneousModel>();
    this.miscellaneousModel = new MiscellaneousModel();
    this.editorModel = new EditorSearchModel();
    this.editorModel.CertificationInfo=new EditorCertificationInfoModel();
    this.editorModel.Speciality = new Array<SpecializedFieldModel>();
    this.editorModel.SofwareSkills = new Array<SoftwareSkillModel>();
    this.IsAlreadyFavoriteEditor = false;

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName =='jp'? "エディタープロフィール":"Editor Profile";
    this.sharedService.setSharedData(this.shareableData);


    if(this.sharedService.currentCulture){

      let title: string = "【国籍】【専門】【認定分野】エディター";
      this.titleService.setTitle(title);
      this.meta.updateTag({ name: 'keyword', content: "【国籍】【専門】【認定分野】エディター" });
      this.meta.updateTag({ name: 'description', content: "【国籍】【専門】【認定分野】エディタープロフィール。エディティングプロの品質はお客様の正直なエディター紹介より守られております。ご使用後には是非エディターの評価をお願いします。" });

    }
    else{

      let title: string = "【Nationality】【Professional】【Certified category】editor";
      this.titleService.setTitle(title);
      this.meta.updateTag({ name: 'keyword', content: "【Nationality】【Professional】【Certified category】editor" });
      this.meta.updateTag({ name: 'description', content: "【Nationality】【Professional】【Certified category】editor profile. editing-Pro's quality is guaranteed by costomer's review. Please review editors after using the service. " });

    }


    this.route.params.subscribe(params => {
      if (params['id']) {
        let regId: number = params['id'];
        this.getEditorDetails(regId);
      }
    });
  }

  getEditorFavoriteStatus() {
    if (localStorage.getItem("currentUser")) {
      let customerId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
      this.customerService.isAlreadyFavoriteEditor(customerId, this.editorModel.ID).subscribe((data) => {
        this.IsAlreadyFavoriteEditor = data;
      });
    }
  }

  registerAsFavorite(editorId: string) {
    if (localStorage.getItem("currentUser")) {
      let customerId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
      this.customerService.registerAsFavorite(customerId, editorId).subscribe((data) => {
        if (data) {
          this.IsAlreadyFavoriteEditor = true;
          this.toastr.success(this.shareableData.currentStateName =='jp'? "お気に入り登録ができました。":"Successfully Registered as Favorite!");
        }
        else {
          this.toastr.error(this.shareableData.currentStateName =='jp'? "お気に入り登録ができませんでした。":"Could not be registered as Favorite!");
        }
      });
    }
  }

  private updateMetaData(nationality:string,professional:string,certifiedCategory:string){
    this.titleService.setTitle((nationality? nationality:'')+(professional? professional : '')+(certifiedCategory? certifiedCategory : '')+'英文専門エディターのインタビュー詳細');
    this.meta.updateTag({ name: 'description', content: (nationality? nationality :'')+(professional? professional : '')+(certifiedCategory? certifiedCategory : '')+'英文校正の専門エディターのインタビューの詳細が読みたいならすぐクリック！あなたの知りたい英文校正のプロの世界について確認頂けます。' });
    this.meta.updateTag({name:'keyword',content: (nationality? nationality :'')+(professional? professional : '')+(certifiedCategory? certifiedCategory : '')+'エディター'});
   }

  getEditorDetails(regId: number) {
    this.editorService.getEditorDetailsByRegistrationId(regId,this.sharedService.currentCulture).subscribe((data) => {
      if (data) {
        this.editorModel = data;
        if(data.Speciality && data.Speciality.length>5)
        {
          data.Speciality= _.first(data.Speciality,5);
        }
        let specilityList:Array<string>=new Array<string>();
         specilityList=_.pluck(data.Speciality,"Name");
         let cert:string=this.getCertificationCSV();
         this.updateMetaData(data.Nationality,specilityList.join('、'), cert);

        this.getEditorFavoriteStatus();
        this.editorProfileEvaluationSummary();
        this.editorPastWorkList();
        this.getEditorEvaluationList();
        if (data.SofwareSkills)
          this.editorModel.SofwareSkillCSV = _.pluck(data.SofwareSkills, 'Name').join(',');
      }
    });
  }

  getCertificationCSV(): string {
    let csv: string = "";
    let certifications: Array<string> = new Array<string>();
    if (this.editorModel.CertificationInfo) {
      if (this.editorModel.CertificationInfo.IsLegalExpert)
           certifications.push(this.certificationFields[0]);
      if (this.editorModel.CertificationInfo.IsMedicalExpert)
           certifications.push(this.certificationFields[1]);
      if (this.editorModel.CertificationInfo.IsPatentExpert)
           certifications.push(this.certificationFields[2]);
      if (this.editorModel.CertificationInfo.IsCertificateExpert)
           certifications.push(this.certificationFields[3]);

      csv = certifications.join("、");
    }
    return csv
  }

  goToEstimate(editorId:number) {
      let selectedEditors=new Array<number>();
      selectedEditors.push(editorId);
      localStorage.setItem("SelectedEditorsForEstimation", JSON.stringify(selectedEditors));
      this._router.navigateByUrl("/appointed");
  }

  editorProfileEvaluationSummary(){
 
    this.evaluationService.getEditorProfileEvaluationSummary(this.editorModel.ID).subscribe((data)=>{
      if(data)
      this.miscellaneousModel = data;
    })
  }

  editorPastWorkList(){
    this.editorService.getEditorPastWorkExperienceList(this.editorModel.ID,this.sharedService.currentCulture).subscribe((data)=>{
      if(data)
        this.editorPastWorkingList = data;
    });
  }

  getSum(data:Array<any>,fieldname:string)
  {
    return this.utilityService.getSum(data,fieldname);
  }

  getEditorEvaluationList(){
    this.evaluationService.getOrderEvaluationList(1,this.editorModel.ID,10,this.sharedService.currentCulture).subscribe((data)=>{
      if(data)
      this.evulationList.dataSource = data;
      if(data.length>0)
      this.evulationList.totalItems = data[0].TotalRecords;
    })
  }

  onEvaluationListPageIndexChange(event: PageChangedEvent) {
   this.evaluationService.getOrderEvaluationList(event.page,this.editorModel.ID,10,this.sharedService.currentCulture).subscribe((data) => {
     this.evulationList.currentPage = event.page;
     this.evulationList.dataSource = data;
     if (data.length > 0)
       this.evulationList.totalItems = data[0].TotalRecords;
   });
 }

  ngOnInit() {
  }

}
