import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Title, Meta } from '@angular/platform-browser';
import { EstimationService } from '../../services/estimation.service';
import { OrderWeb } from '../../models/order-web.model';
import { EditingPreferenceModel } from '../../models/editing-pro-preference-model.model';
import { OrderReceiveDocumentSettingsModel } from 'src/app/models/order-receive-document-settings';
import { CustomerService } from '../../services/customer-service.service';
import { DocumentDeliveryPeriodModel } from 'src/app/models/document-delivery-period.model';
import { SelectOptionModel } from '../../models/select-option-model';
import { CommonListService } from '../../services/common-list.service';
import { confirmDialogConfig, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-payment-method-selection',
  templateUrl: './payment-method-selection.component.html',
  styleUrls: ['./payment-method-selection.component.css']
})
export class PaymentMethodSelectionComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  estimationService: EstimationService;
  commonListService: CommonListService;
  orderDetails: OrderWeb;
  customerService: CustomerService;
  documentReceiveSettings: OrderReceiveDocumentSettingsModel;
  documentDeliveryPeriods: Array<DocumentDeliveryPeriodModel>;
  offeringOrderParameterList: Array<SelectOptionModel>;
  offeringOrderParameterDefaultList: Array<SelectOptionModel>;
  isDefaultOrdering: boolean;
  isAlreadyPostPayApproved:boolean;

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private route: ActivatedRoute,
    private _commonListService: CommonListService,
    private _estimationService: EstimationService,
    private _customerService: CustomerService,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private titleService: Title,
    private meta: Meta) {
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.estimationService = _estimationService;
    this.commonListService = _commonListService;
    this.orderDetails = new OrderWeb();
    this.orderDetails.EditingPreference = new EditingPreferenceModel();
    this.customerService = _customerService;
    this.documentReceiveSettings = new OrderReceiveDocumentSettingsModel();
    this.documentReceiveSettings.IsSendToRegisteredAddress = true;
    this.documentReceiveSettings.IsStatementOfDeliveryNeeded = false;
    this.documentReceiveSettings.IsInvoiceNeeded = false;
    this.documentReceiveSettings.IsReceiptNeeded = false;
    this.documentReceiveSettings.IsPDFbyEmail = false;
    this.documentReceiveSettings.IsPDFbyEmailForInvoice = false;
    this.documentReceiveSettings.IsPDFbyEmailForStatement = false;
    this.documentReceiveSettings.IsAfterPaperOutput = false;
    this.documentReceiveSettings.IsAfterPaperOutputForInvoice = false;
    this.documentReceiveSettings.IsAfterPaperOutputForStatement = false;
	this.isAlreadyPostPayApproved=false;

    this.documentDeliveryPeriods = new Array<DocumentDeliveryPeriodModel>();
    this.offeringOrderParameterList = new Array<SelectOptionModel>();
    this.offeringOrderParameterDefaultList = new Array<SelectOptionModel>();
    this.isDefaultOrdering = true;
    this.orderDetails.PaymentMethod=5;


    this.route.params.subscribe(params => {
      if (params['orderNo']) {
        let orderNo: string = params['orderNo'];
        if (localStorage.getItem("currentUser")) {
          let clientId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
		  let regNo:number=JSON.parse(localStorage.getItem("currentUser")).RegistrationNo;
          this.getOrderDetails(orderNo, clientId);
          this.setDefaultAddressDetails(clientId);
		  this.setPostPayApprovedStatus(regNo);
        }
      }
    });

    this.getDocumentDeliveryPeriods();

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = this.sharedService.currentCulture=='jp'?"決済方法選択":'Select payment method';
    this.sharedService.setSharedData(this.shareableData);


    if(this.sharedService.currentCulture=='jp'){

      this.titleService.setTitle("英文校正サービスの決済情報");
      this.meta.addTag({ name: 'keywords', content: '決済情報,校正,高品質,24h対応,オンライン校正,英語校正,英語,専門校正,英語エディター,校正会社,論文校正,英語論文チェック' });
      this.meta.addTag({ name: 'description', content: '英文校正サービスの決済情報をご設定ください。24時間365日対応。オンラインで利用できる校正サービス。納品物の受領までウェブ上で完結。簡単,早い,確実！30分からの短時間納品も可能です。お問い合わせ,お見積りは無料。' });

    }
    else{
      this.titleService.setTitle("editing-Pro.service payment information");
      this.meta.addTag({ name: 'keywords', content: 'payment information,editing,high quality,24h support,online editing,English editing,English,professional editing,English editor,editing company,academic writing editing,English academic writingcheck' });
      this.meta.addTag({ name: 'description', content: 'Please set editing-Pro.service credit information. 24hours service for 365 days. Online editing service that allows order to submission through online. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });

    }
    this.fillOfferOrderParameterList();
  }
  
  private setPostPayApprovedStatus(regNo:number)
  {
	  this.customerService.getIsAlreadyPostpayApproved(regNo).subscribe(result=>{
		 this.isAlreadyPostPayApproved=result; 
	  });
  }

  fillOfferOrderParameterList() {
    this.offeringOrderParameterList = this.commonListService.getOfferingOrderParameterList();
    this.offeringOrderParameterDefaultList = this.commonListService.getOfferingOrderParameterDefaultList();
  }

  setDefaultAddressDetails(clientId: string) {
    this.customerService.getCompanyAddressDetailsById(clientId).subscribe(data => {
      this.documentReceiveSettings.PersonInCharge = data.CompanyPicName;
      this.documentReceiveSettings.PostalCode = data.PostalCode;
      this.documentReceiveSettings.PrefectureName=data.PrefectureName
      this.documentReceiveSettings.NameOfAddress = data.Name;
      this.documentReceiveSettings.AddressLine1 = data.Address1;
      this.documentReceiveSettings.AddressLine2 = data.Address2;
      this.documentReceiveSettings.Address = data.Address1?data.Address1 : "" + data.Address2?data.Address2:"";
      this.documentReceiveSettings.WebSiteURL=data.WebSiteURL;
      this.documentReceiveSettings.TelephoneNo=data.TelephoneNo;
    });
  }

  getOrderDetails(orderNo: string, clientId: string) {
    this.estimationService.GetOrderDetailsForCustomerByOrderNo(orderNo, clientId).subscribe((data) => {
      if (data) {
        this.orderDetails = data;
        if(data && (data.FirstParameter || data.SecondParameter || data.ThirdParameter || data.FourthParameter))
      {
        this.isDefaultOrdering=false;
        this.documentReceiveSettings.FirstParameter = data.FirstParameter;
        this.documentReceiveSettings.SecondParameter = data.SecondParameter;
        this.documentReceiveSettings.ThirdParameter = data.ThirdParameter;
        this.documentReceiveSettings.FourthParameter = data.FourthParameter;
      }
        //console.log('Company Type ID:',data.CompanyTypeID);
        //setting default payment method to IPS Credit card 1
        this.orderDetails.PaymentMethod = 5; //Stripe Credit Card 5 default EP200829-LIM02
        
        if (localStorage.getItem("paymentInfoModel"))//it comes from next screen
        {
          let orderDetails = JSON.parse(localStorage.getItem("paymentInfoModel"));
          this.orderDetails.PaymentMethod = orderDetails.PaymentMethod;
        }
        if (!data.EditingPreference)
          this.orderDetails.EditingPreference = new EditingPreferenceModel();
      }
    })
  }

  getDocumentDeliveryPeriods() {
    this.estimationService.getDocumentDeliveryPeriodList().subscribe(data => {
      if (data) {
        this.documentDeliveryPeriods = data;
      }
    });
  }

  goToFinalizePage() {
    if (this.documentReceiveSettings.IsReceiptNeeded ||
      this.documentReceiveSettings.IsInvoiceNeeded ||
      this.documentReceiveSettings.IsStatementOfDeliveryNeeded) {
      this.orderDetails.MailingCost = 231; // 210 fixed + 5%tax
    }
    else{
    this.orderDetails.MailingCost = 0; //fixed 
    }

    
    localStorage.setItem("paymentInfoModel", JSON.stringify(this.orderDetails));
    localStorage.setItem("documentReceiveSettings", JSON.stringify(this.documentReceiveSettings));
    this._router.navigateByUrl("/customer/payment-finalize");

  }


  configAmount: confirmDialogConfig={
    MessageText: '<div style="text-align:center">後払い申し込みは<br/>3000円以上の案件のみ<br/>可能です。</div>',
    IsOkOnly:true
  };

  amountModelOptions: ModalOptions={
    backdrop: 'static',
    keyboard: false,
    class: "modal-sm",
    initialState: this.configAmount
  };


  configRecognation: confirmDialogConfig={
    MessageText: '後払い申し込みのため<br/>Mypageから以下の情報を追加してください<br/>(会社名、住所、電話番号、サイトURL)',
    IsOkOnly:true
  };

  RecognationModelOptions: ModalOptions={
    backdrop: 'static',
    keyboard: false,
    class: "modal-sm",
    initialState: this.configRecognation
  };

  saveOrderReceiveDocumentSettings() {
    this.documentReceiveSettings.PaymentMethod = this.orderDetails.PaymentMethod;
    this.documentReceiveSettings.OrderNo = this.orderDetails.OrderNo;
    this.documentReceiveSettings.OrderID = this.orderDetails.ID;
    if(this.isDefaultOrdering){
      this.documentReceiveSettings.FirstParameter = null;
      this.documentReceiveSettings.SecondParameter = null;
      this.documentReceiveSettings.ThirdParameter = null;
      this.documentReceiveSettings.FourthParameter = null;
      this.documentReceiveSettings.IsParameterizedOrder=false;
    }
    else{
      this.documentReceiveSettings.IsParameterizedOrder=true;
    }

    if(this.documentReceiveSettings.PaymentMethod==2)
    {
		if(!this.isAlreadyPostPayApproved)
		{
			 if(this.orderDetails.PaymentAmount<3000)
			  {
				this.bsModalRef = this.modalService.show(ConfirmDialogComponent, this.amountModelOptions);
				return;
			  }
		}     

      if(!this.documentReceiveSettings.Address || !this.documentReceiveSettings.WebSiteURL
         || !this.documentReceiveSettings.TelephoneNo)
      {
        this.bsModalRef = this.modalService.show(ConfirmDialogComponent, this.RecognationModelOptions);
        return;
      }
    }


    if (!this.documentReceiveSettings.IsStatementOfDeliveryNeeded) {
      this.documentReceiveSettings.StatementOfDeliveryId = null;
    }
    if (!this.documentReceiveSettings.IsInvoiceNeeded) {
      this.documentReceiveSettings.InvoiceDeliveryScheduleId = null;
    }
    if (!this.documentReceiveSettings.IsReceiptNeeded) {
      this.documentReceiveSettings.ReceiptDeliveryScheduleId = null;
    }
    this.estimationService.saveOrderReceiveDocumentSettings(this.documentReceiveSettings).subscribe(data => {
      if (data) {
        this.goToFinalizePage();
      }
    });
  }

  ngOnInit() {

    if (localStorage.getItem("paymentInfoModel"))//it comes from next screen
    {
      this.orderDetails = JSON.parse(localStorage.getItem("paymentInfoModel"));
    }

    if (localStorage.getItem("documentReceiveSettings")) {
      this.documentReceiveSettings = JSON.parse(localStorage.getItem("documentReceiveSettings"));
    }
  }

}
