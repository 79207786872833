import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Title, Meta } from '@angular/platform-browser';
import { EditorService } from '../../services/editor.service';
import { EditorSearchModel } from '../../models/editor-search.model';
import { AppSetting } from '../../app-setting';
import * as _ from 'underscore';
import { SpecializedFieldModel } from '../../models/specialized-field.model';
import { SoftwareSkillModel } from '../../models/software-skill.model';
import { EvaluationService } from '../../services/evaluation.service';
import { MiscellaneousModel } from '../../models/miscellaneous-model.model';
import { UtilityService } from '../../services/utility.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { OrderEvaluationModel } from '../../models/order-evaluation-model.model';

@Component({
  selector: 'app-profile-preview',
  templateUrl: './profile-preview.component.html',
  styleUrls: ['./profile-preview.component.css']
})
export class ProfilePreviewComponent implements OnInit {
  router:string;
  sharedService:DataShareService;
  shareableData: DataSharingModel;
  editorService: EditorService;
  evaluationService:EvaluationService;
  miscellaneousModel:MiscellaneousModel;
  editorModel:EditorSearchModel;
  evulationList: { currentPage: number, totalItems: number, dataSource: OrderEvaluationModel[] };
  editorPastWorkingList:Array<MiscellaneousModel>;
  imageBaseUrl:string=AppSetting.IMAGE_BASE_URL;

  constructor(private _router:Router,
    private _sharedService:DataShareService,
    private _evaluationService: EvaluationService,
    private utilityService:UtilityService,
    private titleService:Title,
    private route: ActivatedRoute,
    private _editorService: EditorService,
    private meta:Meta) { 

      this.router=_router.url;
      this.sharedService=_sharedService;
      this.editorService=_editorService;
      this.evaluationService=_evaluationService;
      this.evulationList = {currentPage: 1, totalItems: 0, dataSource: null};
      this.miscellaneousModel=new MiscellaneousModel();
      this.editorModel=new EditorSearchModel();
      this.editorPastWorkingList=new Array<MiscellaneousModel>();
      this.editorModel.Speciality=new Array<SpecializedFieldModel>();
      this.editorModel.SofwareSkills=new Array<SoftwareSkillModel>();

      this.shareableData = new DataSharingModel();
      this.shareableData.currentRoute=this.router;
      this.shareableData.currentStateName = this.sharedService.currentCulture =='jp'? "プロフィール確認":"Profile Preview";
      this.sharedService.setSharedData(this.shareableData);
      let title:string="プロフィールを確認（英文エディター用）";
      this.titleService.setTitle("プロフィールを確認（英文エディター用）");
      this.meta.updateTag({name:'keyword', content:"プロフィール,確認,校正サイト,オンライン校正,英語校正,英語,英文エディター,校正会社,論文校正,英語論文チェック"});
      this.meta.updateTag({name:'description', content:"英語校正サイトのプロフィール確認ページです。プロフィールの完成度を高めて指名の校正依頼を増やしましょう！"});
      
      if (this.sharedService.currentUser)
      {
         let regId: number = this.sharedService.currentUser.RegistrationID;
         this.getEditorDetails(regId);
      }

      this.editorProfileEvaluationSummary();
      this.getEditorEvaluationList();
    }

    getEditorDetails(regId:number)
    {
      this.editorService.getEditorDetailsByRegistrationId(regId,this.sharedService.currentCulture).subscribe((data)=>{
        if(data)
        {
          this.editorModel=data;
          this.editorPastWorkList();
          console.log(data);
          if(data.SofwareSkills)
          this.editorModel.SofwareSkillCSV=_.pluck(data.SofwareSkills,'Name').join(',');
        }
      });
    }
    editorProfileEvaluationSummary(){
      const editor = JSON.parse(localStorage.getItem("currentUser"));
      this.evaluationService.getEditorProfileEvaluationSummary(editor.ID).subscribe((data)=>{
        if(data)
        this.miscellaneousModel = data;
      })
    }

    editorPastWorkList(){
      this.editorService.getEditorPastWorkExperienceList(this.editorModel.ID,this.sharedService.currentCulture).subscribe((data)=>{
        if(data)
          this.editorPastWorkingList = data;
      });
    }

    getSum(data:Array<any>,fieldname:string)
    {
      return this.utilityService.getSum(data,fieldname);
    }
    
    getEditorEvaluationList(){
      const editor = JSON.parse(localStorage.getItem("currentUser"));
      this.evaluationService.getOrderEvaluationList(1,editor.ID,10,this.sharedService.currentCulture).subscribe((data)=>{
        if(data)
        this.evulationList.dataSource = data;
        if(data.length>0)
        this.evulationList.totalItems = data[0].TotalRecords;
      })
    }
 
    onEvaluationListPageIndexChange(event: PageChangedEvent) {
	const editor = JSON.parse(localStorage.getItem("currentUser"));
     this.evaluationService.getOrderEvaluationList(event.page,editor.ID,10,this.sharedService.currentCulture).subscribe((data) => {
       this.evulationList.currentPage = event.page;
       this.evulationList.dataSource = data;
       if (data.length > 0)
         this.evulationList.totalItems = data[0].TotalRecords;
     });
   }

  ngOnInit() {
  }
}