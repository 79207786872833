import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;

  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private titleService: Title,
    private meta: Meta) {
      this.router=_router.url;
    this.sharedService=dataShareService;

    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName=this.sharedService.currentCulture=='jp'?"プライバシーポリシー":"Privacy policy";
    this.sharedService.setSharedData(this.shareableData);



    if(this.sharedService.currentCulture=='jp'){

      this.titleService.setTitle("エディティングプロのプライバシーポリシー");
      this.meta.updateTag({ name: 'keywords', content: 'プライバシーポリシー,英文の校正,エディティングプロ,品質の秘密,品質保証,校正依頼,校正依頼方法,校正方法,校正のコツ,論文校正' });
      this.meta.updateTag({ name: 'description', content: 'エディティングプロのプライバシーポリシーとなります。校正,翻訳,通訳の他,外国語や外国人に任せたいお仕事がありましたらお問合わせ下さい。' });

    }{

      this.titleService.setTitle("editing-Pro. Private policy");
      this.meta.updateTag({ name: 'keywords', content: 'Privacy policy,English writing editing,editing-Pro.,quality secret,quality guarantee,editing order,editing how to order,editing method,editing tips,academic writingediting' });
      this.meta.updateTag({ name: 'description', content: 'This is the private policy of editing-Pro. Editing, translation, interpretation, other job that requires foreign workers or foreign language, please contact us.' });



    }
     }

  ngOnInit() {
  }

}
