import { Component, OnInit } from '@angular/core';
import {DataShareService} from '../services/data-share.service';
import {DataSharingModel} from '../models/data-sharing-model';
import { Router } from '@angular/router';
import { Title,Meta  }     from '@angular/platform-browser';
@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.css']
})
export class UserGuideComponent implements OnInit {
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  router: string;
  constructor(private titleService: Title ,
    private _router: Router,
    private dataShareService:DataShareService,
    private meta: Meta) {
    this.router=_router.url;
    titleService.setTitle("英文校正発注のコツ,ご利用ガイド");
    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName="ご利用ガイド";
    this.dataShareService.setSharedData(this.shareableData);
    this.sharedService=dataShareService;

    if(this.dataShareService.currentCulture=='jp'){


      this.titleService.setTitle("英文校正発注のコツ,ご利用ガイド");
      this.meta.updateTag({ name: 'keywords', content: '英文,校正,エディティング,発注,コツ,ご利用,ガイド' });
      this.meta.updateTag({ name: 'description', content: '英文校正時の発注のコツ,うまくいく方法をご提示いたします。英文校正,指名英文校正,クロスチェック,英語翻訳＋コーディネータの発注の仕方ご利用ガイドの説明' });

    }
    else{


      this.titleService.setTitle("editing-Pro. Order tips, user guide");
      this.meta.updateTag({ name: 'keywords', content: 'English writing,editing,editing,order,tips,users,guide' });
      this.meta.updateTag({ name: 'description', content: 'Tips for using editing-Pro., for successful order. editing-Pro., appointing editing-Pro.,cross check,English translation＋how to place a order by coordinator, user guide description' });



    }

   }

  ngOnInit() {
  }

}
