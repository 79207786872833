import { Injectable } from '@angular/core';
import { DataSharingModel } from '../models/data-sharing-model'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EditingProUser } from '../models/editing-pro.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NotificationType } from '../Enums.enum';
import { Router } from '@angular/router';

@Injectable({
  providedIn: "root"
})
export class DataShareService {
  private SharedData: DataSharingModel;
  public bsModalRef: BsModalRef;
  public loginStatus: boolean;
  public currentUser: any;
  public currentCulture: string;
  public OpenLoginPopup: any;
  public SelectedEditorsForEstimation: Array<number>;
  private sharedModalData: any;
  public currentCurrency: string;
  public currentURLPrefix:string;

  //Affiliate Site related
  public AffiliateCode:string
  public AffiliateSiteURL:string
  public AffiliateSiteHeaderURI:string
  public IsConsumerAffiliateSite:boolean;
  public backImageStyle:any;
  public sitelogoUrl:any;
  public AffiliateSiteLogoURL:string  


  constructor(private translate: TranslateService, private _toastr: ToastrService,private router:Router) {
    if (localStorage.getItem("currentCulture")) {
      this.currentCulture = localStorage.getItem("currentCulture");
    }
    else {
      this.currentCulture = "jp";
    }

    if(this.currentCulture=='jp')
    {
      this.currentURLPrefix=null;
    }
    else
    {
      this.currentURLPrefix=`/${this.currentCulture}`;
    }

    this.translate.setDefaultLang(this.currentCulture);
    this.translate.use(this.currentCulture);
    this.currentCurrency = 'JPY';
    this.setCurrentCurrency();

    if(sessionStorage.getItem('AffiliateSiteInfo'))
    {
      let info=JSON.parse(sessionStorage.getItem('AffiliateSiteInfo'));
      this.setAffiliateSiteInfo(info.AffiliateCode,info.AffiliateSiteURL);
    }
    
  }

  setCurrentCurrency() {
    if (localStorage.getItem('currentUser')) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser.NationalityID && currentUser.NationalityID == 15)//phhilipine
      {
        this.currentCurrency = 'PHP';
      }
      else {
        if (this.currentCulture == 'en') {
          this.currentCurrency = 'USD';
        }
        else {
          this.currentCurrency = 'JPY';
        }
      }
    }
    else {
      if (this.currentCulture == 'en') {
        this.currentCurrency = 'USD';
      }
      else {
        this.currentCurrency = 'JPY';
      }
    }

    //this.currentCurrency='PHP'; for testing 
  }

  setCurrentCulture(lang: string,IsReload:boolean=true) {
    this.currentCulture = lang;
    this.translate.setDefaultLang(this.currentCulture);
    this.translate.use(this.currentCulture);
    localStorage.setItem("currentCulture", lang);

    if(this.currentCulture=='jp')
    {
      this.currentURLPrefix=null;
    }
    else
    {
      this.currentURLPrefix=`/${this.currentCulture}`;
    }


    let currentUrl=window.location.href;
    let newUrl:string='';
    if(currentUrl.includes('/en/') || currentUrl.includes('/jp/'))
    {
      newUrl=currentUrl.replace('/en/',"/"+lang+"/").replace('/jp/',"/"+lang+"/");
    }
    else if(currentUrl.endsWith('/en') || currentUrl.endsWith('/jp'))
    {
      newUrl=currentUrl.replace('/en',"/"+lang).replace('/jp',"/"+lang);
    }
    else if(!currentUrl.includes('/en/') && !currentUrl.includes('/jp/'))
    {
       newUrl ='/'+lang+currentUrl;
    }
    else
    {
      newUrl=currentUrl;
    }

    if(IsReload)
    {
      if(newUrl.endsWith('/en/en') || newUrl.endsWith('/jp/jp'))
      {
        newUrl=newUrl.replace("/en/en","/en").replace("/jp/jp","/jp");
      }
      if(this.router.url=='/')
      {
        newUrl=newUrl.concat(lang);
      }
      if(newUrl.includes('jphttp'))
      {
        newUrl='/jp';
      }
      if(newUrl.includes('enhttp'))
      {
        newUrl='/en';
      }
      
      if(!this.currentURLPrefix)
      {
        newUrl=newUrl.replace('/jp/','').replace('/jp','');
      }

      window.location.assign(newUrl);
    } 
  }

  setSharedData(val: DataSharingModel) {
    this.SharedData = val;
  }

  getSharedData() {

    return this.SharedData;
  }

  setModalData(data) {
    this.sharedModalData = data;
  }

  getModalData() {
    return this.sharedModalData;
  }

  homeClicked() {
    let sharedData = new DataSharingModel();
    sharedData.currentRoute = '/';
    sharedData.currentStateName = "Home";
    this.setSharedData(sharedData);
  }


  showTranslationToastr(messagekey: string, type: NotificationType) {
    this.translate.get(messagekey).subscribe((translatedText: string) => {
      let message: string = translatedText != null ? translatedText : messagekey;
      if (type == NotificationType.SUCCESS) {
        this._toastr.success(message);
      }
      else if (type == NotificationType.WARNING) {
        this._toastr.warning(message);
      }
      else {
        this._toastr.error(message);
      }
    });

  }


  //affliate related
  setAffiliateSiteInfo(code:string,url:string)
  {
    if(code && url)
    {
      this.AffiliateCode=code;
      this.AffiliateSiteURL=url;
      this.IsConsumerAffiliateSite=true;
      this.AffiliateSiteHeaderURI=url+'header.jpg';
      this.AffiliateSiteLogoURL=url+'logo.png';
      let affInfo={AffiliateCode:this.AffiliateCode,AffiliateSiteURL:this.AffiliateSiteURL,
        IsConsumerAffiliateSite:this.IsConsumerAffiliateSite,AffiliateSiteHeaderURI:this.AffiliateSiteHeaderURI,
        AffiliateSiteLogoURL:this.AffiliateSiteLogoURL
      };

      sessionStorage.setItem('AffiliateSiteInfo',JSON.stringify(affInfo));

      this.getBannerStyle();
      console.info('Affiliate site info set:',this.AffiliateCode,this.AffiliateSiteURL,this.IsConsumerAffiliateSite);
    }

  }

  getBannerStyle()
  {
    let myStyles = {
      'background-image': this.IsConsumerAffiliateSite ? 'url(\''+this.AffiliateSiteHeaderURI+'\')' : 'url(\'/assets/img/hero-banner.jpg\')'
      };
    this.sitelogoUrl=this.IsConsumerAffiliateSite ? this.AffiliateSiteLogoURL : '../../assets/img/logo.png';
    this.backImageStyle=myStyles;
  }

}