import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSetting } from '../app-setting';
import { UserType, CredentialModel } from '../models/credential.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  ROOT_URL:string=AppSetting.API_ENDPOINT; 
  EDITINGPRO_ROOT_URL:string=AppSetting.EDITINGPRO_API_ENDPOINT;

  constructor(private http:HttpClient) { 

  }

  reissuePassword(emailID:string,type:UserType,culture:string = "jp")
  {
    return this.http.post<string>(this.EDITINGPRO_ROOT_URL+'user/reissuepassword',{EmailId:emailID,EditingProUserType:type,CultureId:culture});
  }

  verifyAdmin(PIN:number)
  {
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL+'user/verifyAdminPIN?PIN='+PIN,null);
  }

  onBehalfLogin(model:CredentialModel)
  {
    return this.http.post<any>(this.EDITINGPRO_ROOT_URL+'admin/OnBehalfLogin',model);
  }

}
