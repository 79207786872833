import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { ToastrService } from 'ngx-toastr';
import  * as _  from 'underscore';

@Component({
  selector: 'app-editor-mindset',
  templateUrl: './editor-mindset.component.html',
  styleUrls: ['./editor-mindset.component.css']
})
export class EditorMindsetComponent implements OnInit {
  sharedService: DataShareService;
  closeModalCallBack:any;
  
  constructor( private dataShareService: DataShareService,
    private _toastr: ToastrService) {
      this.sharedService = dataShareService;
      this.closeModalCallBack = null;

    let sharedModalData = this.sharedService.getModalData();
    if(sharedModalData)
    {
      this.closeModalCallBack = sharedModalData.CloseCallBack;
    }
   }
   onCloseClicked()
   {
    this.sharedService.bsModalRef.hide();
    
    if(_.isFunction(this.closeModalCallBack))
    {
      this.closeModalCallBack();
    }
   }

  ngOnInit() {
  }

}
