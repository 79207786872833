import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeCurrencyDirective } from './change-currency.directive';

@NgModule({
  declarations: [ChangeCurrencyDirective],
  imports: [
    CommonModule
  ],
  exports:[ChangeCurrencyDirective]
})
export class SharedModuleModule { }
