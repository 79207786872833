export class IPSCardPaymentModel {
    OrderID: string
    CardNumber: string
    ExpirationMonth: number
    ExpirationYear: number
    CardHolderLastName: string
    CardHolderFirstName: string
    CardHolderEmail: string
    CardHolderPhone: string
    ItemName: string
    ItemPrice: number
    ItemShippingPrice: number
    CardVerificationCode: string
    CultureCode:string
    OrderNo:string
}
