import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Title, Meta } from '@angular/platform-browser';
import * as _ from 'underscore';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { EditorSearchWindowComponent } from './editor-search-window/editor-search-window.component';
import { CommonListService } from '../services/common-list.service';
import { LanguageModel } from '../models/language.model';
import { SpecializedFieldModel } from '../models/specialized-field.model';
import { EditingPreferenceModel } from '../models/editing-pro-preference-model.model';
import { EstimationService } from '../services/estimation.service';
import { EditingProDeliveryTracingModel } from '../models/editing-pro-delivery-tracing.model';
import { ToastrService } from 'ngx-toastr';
import { PriceMatrixService } from '../services/price-matrix.service';
import { PriceRowHeadModel } from '../models/price-row-head.model';
import { PriceMatricsModel } from '../models/price-matrics.model';
import { OrderWeb } from '../models/order-web.model';
import {CurrencyPipe} from '@angular/common';
import { EditorService } from '../services/editor.service';
import { MiscellaneousModel } from '../models/miscellaneous-model.model';
import { confirmDialogConfig, ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DocumentModel } from '../models/document.model';


export enum PlanType {
  Light = 1,
  Business = 2,
  Expert = 3
}

@Component({
  selector: 'app-estimate-service',
  templateUrl: './estimate-service.component.html',
  styleUrls: ['./estimate-service.component.css']
})

export class EstimateServiceComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  bsModalRef: BsModalRef;
  commonListService: CommonListService;
  languages: Array<LanguageModel>;
  SpecializedFieldList: Array<SpecializedFieldModel>;
  selectedPlanType: PlanType;
  servicePreferenceModel: EditingPreferenceModel;
  estimationService: EstimationService;
  editorService:EditorService;
  deliveryTracingList: Array<EditingProDeliveryTracingModel>;
  uploadedReferenceFile: any;
  isUploadSuccessfull: boolean;
  isPiggyBacked: boolean;
  priceMatrixService: PriceMatrixService;
  priceRowHeads: PriceRowHeadModel;
  priceMatrix: Array<PriceMatricsModel>;
  isDisabledLightPlan: boolean;
  isDisabledExpertPlan: boolean;
  pastWebOrders: Array<OrderWeb>;
  selectedOrderSettingsOrderNo: string;
  selectedPlanCourseButtonId: string;
  DeliveryLevelName:string;
  planDetailsToolTipContent:string;
  certificationSummary:Array<MiscellaneousModel>;
  certificationalFieldList:Array<SpecializedFieldModel>;
  certificateAreaDictionary:Array<any>;

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private _estimationService: EstimationService,
    private _editorService:EditorService,
    private modalService: BsModalService,
    private titleService: Title,
    private _commonListService: CommonListService,
    private meta: Meta,
    private cp:CurrencyPipe,
    private _priceMatrixService: PriceMatrixService,
    private _toastr: ToastrService) {
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.commonListService = _commonListService;
    this.estimationService = _estimationService;
    this.priceMatrixService = _priceMatrixService;
    this.editorService=_editorService;
    this.deliveryTracingList = new Array<EditingProDeliveryTracingModel>();
    this.SpecializedFieldList = new Array<SpecializedFieldModel>();
    this.certificationSummary= new Array<MiscellaneousModel>(); 
    this.servicePreferenceModel = new EditingPreferenceModel();
    this.certificationalFieldList=new Array<SpecializedFieldModel>();
    this.certificateAreaDictionary=new Array<any>();
    
    this.priceRowHeads = new PriceRowHeadModel();
    this.isDisabledLightPlan = false;
    this.isDisabledExpertPlan=false;
    this.DeliveryLevelName=null;
    this.pastWebOrders = new Array<OrderWeb>();
    this.selectedPlanCourseButtonId = null;


    if (localStorage.getItem("SelectedEditorsForEstimation")) {
      let seletedEditors = JSON.parse(localStorage.getItem("SelectedEditorsForEstimation"));
      this.sharedService.SelectedEditorsForEstimation = seletedEditors;
      this.servicePreferenceModel.IsAppointingEditor=true;
    }
    else {
      this.sharedService.SelectedEditorsForEstimation = new Array<number>();
    }

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = this.sharedService.currentCulture== 'jp'? "見積(サービス選択)" :"estimation (service choice)";
    this.sharedService.setSharedData(this.shareableData);

    if(localStorage.getItem("newEstimationBasicPreference"))
    {
      let preference = JSON.parse(localStorage.getItem("newEstimationBasicPreference"));
      this.servicePreferenceModel.SpecializedFieldID=preference.SpecializedFieldID;
    }

    if (localStorage.getItem("estimationResult")) {
      let result = JSON.parse(localStorage.getItem("estimationResult"));
      this.servicePreferenceModel.WordCount = 0;
      this.servicePreferenceModel.TranslationText='';
      if (Array.isArray(result)) //estimate by Documents
      {
        result.forEach((file, i) => {
          this.servicePreferenceModel.WordCount += file.WordCount;
          this.servicePreferenceModel.TranslationText +=file.TranslationText;
        });

        this.servicePreferenceModel.IsEstimationByDocument=true;
      }
      else {  //by text estimation
        this.servicePreferenceModel.WordCount += result.WordCount;
        this.servicePreferenceModel.TranslationText=result.TranslationText;
        this.servicePreferenceModel.IsEstimationByDocument=false;
      }

    }

    this.planDetailsToolTipContent=this.getPlanDetailsTooltipHTML();
    this.setServicePreferenceDefaultSettings();



     if (this.sharedService.currentCulture=='jp'){

       this.titleService.setTitle("英文校正サービスの無料見積り");
       this.meta.updateTag({ name: 'keywords', content: '見積り,高品質,インターネット対応,オンライン校正,英語校正,英語,専門校正,英語エディター,校正会社,論文校正,英語論文チェック' });
       this.meta.updateTag({ name: 'description', content: '英文校正サービスの無料見積りは24時間365日対応。オンラインで利用できる校正サービス。納品物の受領までウェブ上で完結。簡単,早い,確実！30分からの短時間納品も可能です。お問い合わせ,お見積りは無料。' });
       
    }
    else{
      
      this.titleService.setTitle("editing-Pro.service free estimate");
      this.meta.updateTag({ name: 'keywords', content: 'estimate,high quality,available through internet,online editing, English editing, English, professional editing,English editor,editing company,academic writing editing, English academic writing check' });
      this.meta.updateTag({ name: 'description', content: 'Free estimation for editing-Pro. that operates 24hours service for 365 days. Online editing service that allows order to submission through online. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });


     }

    this.getLanguageList();
    this.getCertificationSummary();
    this.getSpecializedFieldList();
    this.getDeliveryTracings();
    this.getEditingChargeSettings();
    this.initPriceMatrix();
    // this.priceMatrixService.loadPriceMatrixRowHeads(this.servicePreferenceModel.WordCount,this.servicePreferenceModel.IsDoubleCheck);
    this.loadPriceMatrixRowHeads();
    this.getRecentWebOrdersOfThisClient();
  }

  onManuscriptChanged()
  {
    let doc:DocumentModel=new DocumentModel();
    doc.TranslationText=this.servicePreferenceModel.TranslationText.trim();
    doc.WordCount = doc.TranslationText.trim().split(' ').length;
    this.servicePreferenceModel.WordCount=doc.WordCount;
    localStorage.setItem("estimationResult", JSON.stringify(doc));
  }

  onManuscriptChangedCompleted()
  {
    this.onParameterChange();
  }

  private createDictionary()
  {
    if(this.certificationSummary)
    {
      this.certificateAreaDictionary.push({Code:'01',Count:this.certificationSummary[0].LegalExpertCount});
      this.certificateAreaDictionary.push({Code:'04',Count:this.certificationSummary[0].MedicalExpertCount});
      this.certificateAreaDictionary.push({Code:'10',Count:this.certificationSummary[0].PatentExpertCount});
      this.certificateAreaDictionary.push({Code:'12',Count:this.certificationSummary[0].GeneralExpertCount});
      this.certificateAreaDictionary.push({Code:'13',Count:this.certificationSummary[0].CertificateExpertCount});
    }
  }

  private getCertificationSummary()
  {
     this.editorService.getCerficationSummary().subscribe(data=>{
      if(data)
      {
        this.certificationSummary=data;
        this.createDictionary();
      }
     });
  }

  private getPlanDetailsTooltipHTML():string{
    let html:string=`<div class="tprice3-box topiclist">
    <div class="row mLR0">
    <div class="col-sm-4 paddingLR0">
      <div [ngStyle]="{'background-color':selectedPlanType==1?'#c6d2eb':'#fff'}" class="sgl-3pice p3light">
        <div class="p3head">
          <h4>【Light プラン】</h4>
        </div>
        <div class="p3body">
          <h5>Lightプランの特徴</h5>
          <ul>
          <li>校正経験のあるエディター</li>
          <li>非ネイティブ</li>
          <li>指名依頼不可</li>
          <li>2週間の無料保証付き</li>
          </ul>
          <h5>このような方にお勧めです！</h5>
          <ul>
          <li>簡単な校正が必要な方に！</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-sm-4 paddingLR0">
      <div [ngStyle]="{'background-color':selectedPlanType==2?'#c6d2eb':'#fff'}" class="sgl-3pice p3business">
        <div class="p3head">
          <h4>【Business プラン】</h4>
        </div>
        <div class="p3body">
          <h5>Businessプランの特徴</h5>
          <ul>
          <li>実績を積んできたエディター</li>
          <li>非ネイティブ</li>
          <li>指名依頼可能</li>
          <li>2週間の無料保証付き</li>
          </ul>
          <h5>このような方にお勧めです！</h5>
          <ul>
          <li>より専門的な校正が必要な方に！</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-sm-4 paddingLR0">
      <div [ngStyle]="{'background-color':selectedPlanType==3?'#c6d2eb':'#fff'}" class="sgl-3pice p3expert">
        <div class="p3head">
          <h4>【 Expert プラン】</h4>
        </div>
        <div class="p3body">
          <h5>Expertプランの特徴</h5>
          <ul>
          <li>高い実績を積んできたエディター</li>
          <li>非ネイティブ</li>
          <li>指名依頼可能</li>
          <li>4週間の無料保証付き</li>
          </ul>
          <h5>このような方にお勧めです！</h5>
          <ul>
          <li>極力専門的な校正が方に！</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  </div>`;

  return html;
  }

  onOrderSettingChange() {
    this.estimationService.getOrderDetailsByOrderNo(this.selectedOrderSettingsOrderNo).subscribe((data) => {
      if (data && data.OrderNo) {
        this.setPreferenceSettingsByPreviousOrder(data);
      }
    });
  }

  setPreferenceSettingsByPreviousOrder(settings: OrderWeb) {
    if (settings.EditingPreference) {
      let preference: EditingPreferenceModel = new EditingPreferenceModel();
      preference = settings.EditingPreference;
      this.servicePreferenceModel.IsSpellCheck = preference.IsSpellCheck;
      this.servicePreferenceModel.IsGrammerCheck = preference.IsGrammerCheck;
      this.servicePreferenceModel.IsWording = preference.IsWording;
      this.servicePreferenceModel.DeliveryTracingRecordID = preference.DeliveryTracingRecordID;
      this.servicePreferenceModel.IsDoubleCheck = preference.IsDoubleCheck;
      this.servicePreferenceModel.IsAppointingEditor = preference.IsAppointingEditor;
      this.servicePreferenceModel.IsMachineTranslated = preference.IsMachineTranslated;
      this.servicePreferenceModel.IsEditingComment = preference.IsEditingComment;
      this.servicePreferenceModel.IsCrossCheck = false;
      this.servicePreferenceModel.IsTermCheck = false;
      this.servicePreferenceModel.IsWordElimination = preference.IsWordElimination;
      this.servicePreferenceModel.IsLayoutOrganization = false;

      this.servicePreferenceModel.LanguageID = preference.LanguageID ? preference.LanguageID : '1484e197-70f8-4f64-98cf-e0f06eff6f49';
      this.servicePreferenceModel.SpecializedFieldID = preference.SpecializedFieldID;
    }

  }

  getRecentWebOrdersOfThisClient() {
    if (this.sharedService.currentUser && this.sharedService.currentUser.ID)
      this.estimationService.getRecentPastOrderListByClientId(this.sharedService.currentUser.ID).subscribe(data => {
        if (data && data.length > 0) {
          this.pastWebOrders = data;
        }
        else {
          let orderDefault: OrderWeb = new OrderWeb();
          orderDefault.OrderNo = null;
          orderDefault.OrderName = '過去の発注案件がありません';
          this.pastWebOrders.push(orderDefault);
        }
      });
  }

  loadPriceMatrixRowHeads() {
    this.estimationService.getPriceMatrixRowHeads(this.servicePreferenceModel.WordCount, this.servicePreferenceModel.IsDoubleCheck).subscribe((data) => {
      if (data) {
        this.priceMatrixService.priceMatrixRowHeads.RowHead1 = { ID: data[0].ID, Name: data[0].HeadName_jp, Duration_en: data[0].HeadName_en };
        this.priceMatrixService.priceMatrixRowHeads.RowHead2 = { ID: data[1].ID, Name: data[1].HeadName_jp, Duration_en: data[1].HeadName_en };
        this.priceMatrixService.priceMatrixRowHeads.RowHead3 = { ID: data[2].ID, Name: data[2].HeadName_jp, Duration_en: data[2].HeadName_en };
        this.priceMatrixService.priceMatrixRowHeads.RowHead4 = { ID: data[3].ID, Name: data[3].HeadName_jp, Duration_en: data[3].HeadName_en };
      }
    });
  }

  initPriceMatrix() {
    this.priceMatrix = new Array<PriceMatricsModel>();
    let r: PriceMatricsModel = new PriceMatricsModel();
    this.priceMatrix.push(r); //row 1
    this.priceMatrix.push(r); //row 2
    this.priceMatrix.push(r); //row 3
    this.priceMatrix.push(r); // row 4
  }

  getPriceMatrixRowHead() {
    this.estimationService.getPriceMatrixRowHeads(this.servicePreferenceModel.WordCount, this.servicePreferenceModel.IsDoubleCheck).subscribe((data) => {
      if (data) {
        this.priceMatrixService.priceMatrixRowHeads.RowHead1 = { ID: data[0].ID, Name: data[0].HeadName_jp, Duration_en: data[0].HeadName_en };
        this.priceMatrixService.priceMatrixRowHeads.RowHead2 = { ID: data[1].ID, Name: data[1].HeadName_jp, Duration_en: data[1].HeadName_en };
        this.priceMatrixService.priceMatrixRowHeads.RowHead3 = { ID: data[2].ID, Name: data[2].HeadName_jp, Duration_en: data[2].HeadName_en };
        this.priceMatrixService.priceMatrixRowHeads.RowHead4 = { ID: data[3].ID, Name: data[3].HeadName_jp, Duration_en: data[3].HeadName_en };

        this.priceRowHeads = this.priceMatrixService.getPriceMatrixRowHeads();
      }
    });

  }

  onParameterChange() {
    this.estimationService.getPriceMatrixRowHeads(this.servicePreferenceModel.WordCount, this.servicePreferenceModel.IsDoubleCheck).subscribe((data) => {
      if (data) {
        this.priceMatrixService.priceMatrixRowHeads.RowHead1 = { ID: data[0].ID, Name: data[0].HeadName_jp, Duration_en: data[0].HeadName_en };
        this.priceMatrixService.priceMatrixRowHeads.RowHead2 = { ID: data[1].ID, Name: data[1].HeadName_jp, Duration_en: data[1].HeadName_en };
        this.priceMatrixService.priceMatrixRowHeads.RowHead3 = { ID: data[2].ID, Name: data[2].HeadName_jp, Duration_en: data[2].HeadName_en };
        this.priceMatrixService.priceMatrixRowHeads.RowHead4 = { ID: data[3].ID, Name: data[3].HeadName_jp, Duration_en: data[3].HeadName_en };

        this.priceRowHeads = this.priceMatrixService.getPriceMatrixRowHeads();
        this.applyConditionForSpecializedField();
        this.getPriceMatrixRowHead();
        this.priceMatrix = this.priceMatrixService.getPriceMatrix(this.servicePreferenceModel);
        //console.log('priceMatrix',this.priceMatrix);
        if (this.priceMatrix && this.priceMatrix.length > 0) {
          this.priceMatrix.forEach(item => {
            item.BusinessPriceText =  this.cp.transform(item.BusinessPrice,'JPY','‎¥','1.0-0') ;
            item.ExpertPriceText =this.cp.transform(item.ExpertPrice,'JPY','‎¥','1.0-0') ;
            item.LightPriceText = this.cp.transform(item.LightPrice,'JPY','‎¥','1.0-0') ;
          });
        }



        if (this.selectedPlanType == PlanType.Light && this.isDisabledLightPlan == true) //for light plan disable
        {

        }
        else {
          this.clearSlectedPlan();

          if (this.selectedPlanCourseButtonId) {
            let btnSelecter: string = '#' + this.selectedPlanCourseButtonId;
            let btnPlanCourse: HTMLInputElement = document.querySelector<HTMLInputElement>(btnSelecter);
            if (btnPlanCourse)
             {
              btnPlanCourse.click();
              localStorage.setItem('selectedPlanCourseButtonId', this.selectedPlanCourseButtonId);
             } 
          }
          else{
            
            let btnSelecter: string = '#btnB2';  //default
            let btnPlanCourse: HTMLInputElement = document.querySelector<HTMLInputElement>(btnSelecter);
            if (btnPlanCourse)
             {
              btnPlanCourse.click();
              this.selectedPlanCourseButtonId='btnB2';
              localStorage.setItem('selectedPlanCourseButtonId', this.selectedPlanCourseButtonId);

             } 
          }

        }

        if (this.selectedPlanType == PlanType.Expert && this.isDisabledExpertPlan == true) //for light plan disable
        {

        }
        else {
          this.clearSlectedPlan();

          if (this.selectedPlanCourseButtonId) {
            let btnSelecter: string = '#' + this.selectedPlanCourseButtonId;
            let btnPlanCourse: HTMLInputElement = document.querySelector<HTMLInputElement>(btnSelecter);
            if (btnPlanCourse)
             {
              btnPlanCourse.click();
              localStorage.setItem('selectedPlanCourseButtonId', this.selectedPlanCourseButtonId);
             } 
          }
          else{
            
            let btnSelecter: string = '#btnB2';  //default
            let btnPlanCourse: HTMLInputElement = document.querySelector<HTMLInputElement>(btnSelecter);
            if (btnPlanCourse)
             {
              btnPlanCourse.click();
              this.selectedPlanCourseButtonId='btnB2';
              localStorage.setItem('selectedPlanCourseButtonId', this.selectedPlanCourseButtonId);

             } 
          }

        }

      }
    });

  }

  clearSlectedPlan() {
    //reset plan selected
    this.selectedPlanType = null;
    this.servicePreferenceModel.PlanType = null;
    this.servicePreferenceModel.PlanCourse = null;
    this.servicePreferenceModel.PlanCourseID = null;
    const rowheaders = document.querySelectorAll('.subType');
    _.each(rowheaders, function (item) {
      item.classList.remove('t_green');
      item.classList.remove('t_blue');
      item.classList.remove('t_purple');
    });

    const listColHeads = document.querySelectorAll('.plan_title');
    _.each(listColHeads, function (item, index) {
      item.style = "width:100%;";
      item.classList.remove('t_green');
      item.classList.remove('t_blue');
      item.classList.remove('t_purple');
    });

    const listItems = document.querySelectorAll('.list-item-b');
    _.each(listItems, function (item, index) {
      item.classList.remove('b_green');
      item.classList.remove('b_blue');
      item.classList.remove('b_purple');
    });

  }


  applyConditionForSpecializedField() {
    let selectedId = this.servicePreferenceModel.SpecializedFieldID;
    let sp: SpecializedFieldModel = this.SpecializedFieldList.find(function (item) {
      return item.ID == selectedId;
    });

    if (sp) {
      this.servicePreferenceModel.EditingFieldAreaCode = sp.Code;
      this.servicePreferenceModel.IsEditingFieldInParticularArea = this.priceMatrixService.inParticularSpecializedFieldList(sp.Code);
    }
    else {
      this.servicePreferenceModel.IsEditingFieldInParticularArea = false;
    }

    if (sp && this.servicePreferenceModel.IsEditingFieldInParticularArea) {
      this.isDisabledLightPlan = true;
    }
    else {
      this.isDisabledLightPlan = false;
    }
  //only business and expert order can be appointed order
    if(this.servicePreferenceModel.IsAppointingEditor)
    {
      this.isDisabledLightPlan=this.isDisabledLightPlan || true;
    }
    else{
      this.isDisabledLightPlan=this.isDisabledLightPlan && false;
    }

    this.validateExpertPlanSelectionCapability();

    //language
    if (this.languages && this.servicePreferenceModel.LanguageID) {
      let langId = this.servicePreferenceModel.LanguageID;
      let l: LanguageModel = this.languages.find(function (item) {
        return item.ID == langId;
      });

      if (l) {
        this.servicePreferenceModel.EditingLanguageCode = l.Code;
      }
    }

  }


  openEditorSearchWindow() {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-lg"
    };
     let preference:EditingPreferenceModel=new EditingPreferenceModel();
     preference=this.servicePreferenceModel;
    this.sharedService.setModalData(preference);
    this.bsModalRef = this.modalService.show(EditorSearchWindowComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;

  }

  removeFromList(item: number) {
    this.sharedService.SelectedEditorsForEstimation = _.without(this.sharedService.SelectedEditorsForEstimation, item);
    localStorage.setItem("SelectedEditorsForEstimation", JSON.stringify(this.sharedService.SelectedEditorsForEstimation));
  }

  getDeliveryTracings() {
    this.estimationService.getEditingProDeliveryTracings().subscribe((data) => {
      if (data)
        this.deliveryTracingList = data;
        // if(this.sharedService.currentCulture== 'jp')
        // {
        //   this.deliveryTracingList.Name_jp
        // }
        // console.log('data of deliveryTracingLIst',this.deliveryTracingList);
    });
  }
  getLanguageList() {
    this.commonListService.getAllLanguages().subscribe((data) => {
      if (data)
        this.languages = data;
    });
  }
  getSpecializedFieldList() {
    this.commonListService.getSpecializedFieldList().subscribe((data) => {
      if (data)
       {
        this.SpecializedFieldList = data;
        data.forEach(item=>{
         if(item.Code=='01' || item.Code=='04' || item.Code=='10' || item.Code=='12' || item.Code=='13')
         {
           this.certificationalFieldList.push(item);
         }
        });
       } 
    });
  }

  resetSpecialPreferenceSettings(planType: PlanType) {
    if (planType == PlanType.Light) {
      this.servicePreferenceModel.IsEditingComment = false;
      this.servicePreferenceModel.IsCrossCheck = false;
      this.servicePreferenceModel.IsTermCheck = false;
      this.servicePreferenceModel.IsWordElimination = false;
      this.servicePreferenceModel.IsLayoutOrganization = false;
    }
    else if (planType == PlanType.Business) {
      this.servicePreferenceModel.IsWordElimination = false;
      this.servicePreferenceModel.IsLayoutOrganization = false;
    }
    else {

    }

  }

  onPlanSelect(event, planType: PlanType, subtype: number, amount: number) {

    if (event && event.target) {
      let btn: HTMLInputElement = <HTMLInputElement>event.target;
      this.selectedPlanCourseButtonId = btn.id;
      localStorage.setItem('selectedPlanCourseButtonId', this.selectedPlanCourseButtonId);
    }

    this.clearSlectedPlan();

    this.selectedPlanType = planType;
    this.servicePreferenceModel.PlanType = PlanType[planType];
    this.servicePreferenceModel.TotalPrice = amount;
    const listItems = document.querySelectorAll('.b_button');
    _.each(listItems, function (item, index) {
      item.style = "width:100%;";
      item.classList.remove('b_green');
      item.classList.remove('b_blue');
      item.classList.remove('b_purple');
    });

    const listColHeads = document.querySelectorAll('.plan_title');
    _.each(listColHeads, function (item, index) {
      item.style = "width:100%;";
      item.classList.remove('t_green');
      item.classList.remove('t_blue');
      item.classList.remove('t_purple');
    });


    const rowheaders = document.querySelectorAll('.subType');
    _.each(rowheaders, function (item) {
      item.classList.remove('t_green');
      item.classList.remove('t_blue');
      item.classList.remove('t_purple');
    });

    const rowheader = document.querySelector("#subType_" + subtype) as HTMLDivElement;


    if (planType == PlanType.Light) {
      const colheader = document.querySelector(".title_light") as HTMLDivElement;
      colheader.classList.add('t_green');
      rowheader.classList.add('t_green');
      event.target.classList.add('b_green');
      this.resetSpecialPreferenceSettings(planType);
      this.enableRowsRange();
      this.disableRowsRange(8, 12);
    }
    else if (planType == PlanType.Business) {
      const colheader = document.querySelector(".title_business") as HTMLDivElement;
      colheader.classList.add('t_blue');
      rowheader.classList.add('t_blue');
      event.target.classList.add('b_blue');
      this.resetSpecialPreferenceSettings(planType);
      this.enableRowsRange();
      this.disableRowsRange(11, 12);
    }
    else {
      const colheader = document.querySelector(".title_expert") as HTMLDivElement;
      colheader.classList.add('t_purple');
      rowheader.classList.add('t_purple');
      event.target.classList.add('b_purple');
      this.resetSpecialPreferenceSettings(planType);
      this.enableRowsRange();
    }


    let selectedCourse: string = rowheader.innerText;
    this.servicePreferenceModel.PlanCourse = selectedCourse;

    if (subtype == 1) {
      this.servicePreferenceModel.PlanCourseID = this.priceRowHeads.RowHead1.ID;
      this.servicePreferenceModel.PlanCourseDuration = this.priceRowHeads.RowHead1.Duration_en;
    }
    else if (subtype == 2) {
      this.servicePreferenceModel.PlanCourseID = this.priceRowHeads.RowHead2.ID;
      this.servicePreferenceModel.PlanCourseDuration = this.priceRowHeads.RowHead2.Duration_en;
    }
    else if (subtype == 3) {
      this.servicePreferenceModel.PlanCourseID = this.priceRowHeads.RowHead3.ID;
      this.servicePreferenceModel.PlanCourseDuration = this.priceRowHeads.RowHead3.Duration_en;
    }
    else {
      this.servicePreferenceModel.PlanCourseID = this.priceRowHeads.RowHead4.ID;
      this.servicePreferenceModel.PlanCourseDuration = this.priceRowHeads.RowHead4.Duration_en;
    }

  }

  private disableRowsRange(start: number, end: number) {
    //disable
    for (let i = start; i <= end; i++) {
      const row = document.querySelector("#prefer_row_" + i);
      //if(row)
     // row.classList.add('tr_bg_disable');

    }
  }

  private enableRowsRange() {
    //disable
    for (let i = 8; i <= 12; i++) {
      const row = document.querySelector("#prefer_row_" + i);
      if(row)
      row.classList.remove('tr_bg_disable');
    }
  }

  fileSelected(event: any) {
    this.uploadedReferenceFile = event.target.files[0];
    this.isUploadSuccessfull = false;
  }

  getJapaneseText(text: string) {
    return this.priceMatrixService.translateRowHeadTitle(text);
  }

  private getEditingChargeSettings() {
    this.estimationService.getEditingChargeSettings().subscribe((data) => {
      if (data) {
        this.priceMatrixService.editingChargeSettings = data;
        this.servicePreferenceModel.TaxRate=data.TaxRate;
        this.onParameterChange();
      }
    });
  }


  uploadReferenceFile() {
    let uploadedFile = this.uploadedReferenceFile;
    if (this.uploadedReferenceFile)
      this.estimationService.savePreferenceRefFile(this.uploadedReferenceFile).subscribe((data) => {
        if (data) {
          this.servicePreferenceModel.ReferenceFilePath = data.DownloadURL;
          this.isUploadSuccessfull = true;
          
          this.servicePreferenceModel.ReferenceFile = data; //for order 
          this.servicePreferenceModel.UploadedFileOrginalName = uploadedFile.name;
        }
      });
  }

  goToestimationResult() {
    let id = this.servicePreferenceModel.DeliveryTracingRecordID;
    let d: EditingProDeliveryTracingModel = _.find(this.deliveryTracingList, function (item) {
      return item.ID == id;
    });
    this.servicePreferenceModel.DeliveryTracingRecordName = d.Name_jp;
    if (!this.servicePreferenceModel.SpecializedFieldID) {
      this._toastr.warning("専門分野を選択してください");
      return;
    }
    else
    {
      let editingField:SpecializedFieldModel=this.SpecializedFieldList.find(s=>s.ID==this.servicePreferenceModel.SpecializedFieldID);
      if(editingField)
      {
        this.servicePreferenceModel.SpecializedFieldName=editingField.Name;
      }
    }

    if (this.isDisabledLightPlan && this.selectedPlanType == 1) //light is disabled but selected previously
    {
      this._toastr.warning("Lightプランはご利用になれません。他のプランを選択してください。"); //"Light Plan is not available.Please Select a Plan other than Light!"
      return;
    }

    if (this.isDisabledExpertPlan && this.selectedPlanType == 3) //light is disabled but selected previously
    {
      this._toastr.warning("Expertプランはご利用になれません。他のプランを選択してください。"); //"Expert Plan is not available.Please Select a Plan other than Light!"
      return;
    }

    if (!this.selectedPlanType) {
      this._toastr.warning("プランを選択してください。");//"Please Select a Plan"
      return;
    }

    this.servicePreferenceModel.EditingServicePlan=this.getServicePlanFullName();
    if(!this.validateExpertPlanSelectionCapability())
    {
      return;
    }
    else
    {
      if(this.servicePreferenceModel.TotalPrice<150)
      {
        this.configMinimumAmount.MessageText=this.configMinimumAmount.MessageText.replace("{paymentAmount}",this.servicePreferenceModel.TotalPrice.toFixed(0).toString());
        this.bsModalRef = this.modalService.show(ConfirmDialogComponent, this.MinimumAmountModelOptions);
      }
      else
      {
        localStorage.setItem("servicePreferenceModel", JSON.stringify(this.servicePreferenceModel));
        this._router.navigateByUrl("/customer/estimation-result");
      }
      
    }

  }

  private selectButtonByPlanCourseId()
  {
    if(this.servicePreferenceModel.PlanCourseID==this.priceMatrixService.priceMatrixRowHeads.RowHead1.ID)
    {
         if(this.DeliveryLevelName=='Light')
         {
          this.selectedPlanCourseButtonId='btnL1';
         }
         else if(this.DeliveryLevelName=='Business')
         {
          this.selectedPlanCourseButtonId='btnB1';
         }
         else
         {
          this.selectedPlanCourseButtonId='btnE1';
         }
    }
    if(this.servicePreferenceModel.PlanCourseID==this.priceMatrixService.priceMatrixRowHeads.RowHead2.ID)
    {
      if(this.DeliveryLevelName=='Light')
      {
       this.selectedPlanCourseButtonId='btnL2';
      }
      else if(this.DeliveryLevelName=='Business')
      {
       this.selectedPlanCourseButtonId='btnB2';
      }
      else
      {
       this.selectedPlanCourseButtonId='btnE2';
      }
    }
    if(this.servicePreferenceModel.PlanCourseID==this.priceMatrixService.priceMatrixRowHeads.RowHead3.ID)
    {
      if(this.DeliveryLevelName=='Light')
      {
       this.selectedPlanCourseButtonId='btnL3';
      }
      else if(this.DeliveryLevelName=='Business')
      {
       this.selectedPlanCourseButtonId='btnB3';
      }
      else
      {
       this.selectedPlanCourseButtonId='btnE3';
      }
    }
    if(this.servicePreferenceModel.PlanCourseID==this.priceMatrixService.priceMatrixRowHeads.RowHead4.ID)
    {
      if(this.DeliveryLevelName=='Light')
      {
       this.selectedPlanCourseButtonId='btnL4';
      }
      else if(this.DeliveryLevelName=='Business')
      {
       this.selectedPlanCourseButtonId='btnB4';
      }
      else
      {
       this.selectedPlanCourseButtonId='btnE4';
      }
    }


    let btnSelecter: string = '#' + this.selectedPlanCourseButtonId;
    let btnPlanCourse: HTMLInputElement = document.querySelector<HTMLInputElement>(btnSelecter);
    if (btnPlanCourse)
     {
      btnPlanCourse.click();
      localStorage.setItem('selectedPlanCourseButtonId', this.selectedPlanCourseButtonId);
     } 

  }

  setServicePreferenceDefaultSettings() {
    if (localStorage.getItem("orderCopyModelForReorderSimilar")) {
      let copyOrder: OrderWeb = new OrderWeb();
      copyOrder = JSON.parse(localStorage.getItem("orderCopyModelForReorderSimilar"));
      this.DeliveryLevelName=copyOrder.DeliveryLevelName;
      //let currentWordCount:number=this.servicePreferenceModel.WordCount;
      this.servicePreferenceModel = copyOrder.EditingPreference;
      this.servicePreferenceModel.TranslationText=copyOrder.MenuScript;

      //this.servicePreferenceModel.WordCount=currentWordCount;

      this.initialize();
      this.selectButtonByPlanCourseId();
 
      let seletedEditors: Array<number> = new Array<number>();
      if(copyOrder.AppointedToStaffList && copyOrder.AppointedToStaffList.length>0)
      {
        copyOrder.AppointedToStaffList.forEach(s => {
          seletedEditors.push(s.RegistrationID);
        });
      }
   
      this.sharedService.SelectedEditorsForEstimation = seletedEditors;
      localStorage.setItem("SelectedEditorsForEstimation", JSON.stringify(this.sharedService.SelectedEditorsForEstimation));
     
    }
    else {
      this.servicePreferenceModel.IsSpellCheck = true;
      this.servicePreferenceModel.IsGrammerCheck = true;
      this.servicePreferenceModel.IsWording = true;
      this.servicePreferenceModel.DeliveryTracingRecordID = 1;
      this.servicePreferenceModel.IsDoubleCheck = true;
      this.servicePreferenceModel.IsAppointingEditor = (this.servicePreferenceModel.IsAppointingEditor && this.sharedService.SelectedEditorsForEstimation && this.sharedService.SelectedEditorsForEstimation.length > 0) ? true : false;
      this.servicePreferenceModel.IsMachineTranslated = false;
      this.servicePreferenceModel.IsEditingComment = false;
      this.servicePreferenceModel.IsCrossCheck = false;
      this.servicePreferenceModel.IsTermCheck = false;
      this.servicePreferenceModel.IsWordElimination = false;
      this.servicePreferenceModel.IsLayoutOrganization = false;
      this.servicePreferenceModel.LanguageID = '1484e197-70f8-4f64-98cf-e0f06eff6f49';
      this.servicePreferenceModel.SpecializedFieldID = null;
      this.servicePreferenceModel.PurposeOfEditing="Business";
      this.servicePreferenceModel.DesiredEnglishType="AmericanEnglish";
      this.servicePreferenceModel.DirectionOfEditing="EditorDecide";
      this.servicePreferenceModel.ReaderTargetType="AcademicScholars";

      if(localStorage.getItem("newEstimationBasicPreference"))
      {
        let preference = JSON.parse(localStorage.getItem("newEstimationBasicPreference"));
        this.servicePreferenceModel.SpecializedFieldID=preference.SpecializedFieldID;
      }

    }


    if (localStorage.getItem("servicePreferenceModel"))  //if comes from go back of confirmation page
    {
      this.servicePreferenceModel = JSON.parse(localStorage.getItem("servicePreferenceModel"));
      this.isPiggyBacked = true;
      if (localStorage.getItem('selectedPlanCourseButtonId')) {
        this.selectedPlanCourseButtonId = localStorage.getItem('selectedPlanCourseButtonId');
        this.onParameterChange();
      }

    }
  }

  private initialize()
  {
    this.estimationService.getPriceMatrixRowHeads(this.servicePreferenceModel.WordCount, this.servicePreferenceModel.IsDoubleCheck).subscribe((data) => {
      if (data) {
        this.priceMatrixService.priceMatrixRowHeads.RowHead1 = { ID: data[0].ID, Name: data[0].HeadName_jp, Duration_en: data[0].HeadName_en };
        this.priceMatrixService.priceMatrixRowHeads.RowHead2 = { ID: data[1].ID, Name: data[1].HeadName_jp, Duration_en: data[1].HeadName_en };
        this.priceMatrixService.priceMatrixRowHeads.RowHead3 = { ID: data[2].ID, Name: data[2].HeadName_jp, Duration_en: data[2].HeadName_en };
        this.priceMatrixService.priceMatrixRowHeads.RowHead4 = { ID: data[3].ID, Name: data[3].HeadName_jp, Duration_en: data[3].HeadName_en };

        this.priceRowHeads = this.priceMatrixService.getPriceMatrixRowHeads();
        this.getPriceMatrixRowHead();
        this.initPriceMatrix();

        if (localStorage.getItem('selectedPlanCourseButtonId')) {
          this.selectedPlanCourseButtonId = localStorage.getItem('selectedPlanCourseButtonId');
          this.onParameterChange();
        }

      }
    });
  }


  private getServicePlanFullName()
  {
    let servicePlan:string =this.servicePreferenceModel.SpecializedFieldName+' '+this.servicePreferenceModel.PlanType+' ';
    let suffix:string =this.servicePreferenceModel.IsAppointingEditor? '指名英文校正' :'英文校正';
    return servicePlan+suffix;
  }

  private validateExpertPlanSelectionCapability():boolean
  {
    let categoryId:string=this.servicePreferenceModel.SpecializedFieldID;
    let item=this.certificationalFieldList.find(o=>o.ID==categoryId);
    if(item)
    {

      let xObj=this.certificateAreaDictionary.find(x=>x.Code==item.Code);
      if(xObj)
      {
        let expertCount:number=xObj.Count;
        this.configExpertSituation.MessageText=this.configExpertSituation.MessageText.replace('{SpecializedFieldName}',this.servicePreferenceModel.SpecializedFieldName);
        if(expertCount>5)
        {
          this.isDisabledExpertPlan=false;
         return true;
        }
        else{
          //this.bsModalRef = this.modalService.show(ConfirmDialogComponent, this.expertSituationModelOptions);
          this.isDisabledExpertPlan=true;
          return this.selectedPlanType!=3;
        }  
      }
      else
      {
        this.isDisabledExpertPlan=false;
        return true;
      }
      
    } 
    else{
      this.isDisabledExpertPlan=false;
     return true;
    }
    
  }

  configExpertSituation: confirmDialogConfig={
    MessageText: '<div>Sorry right now we have not enough certified resources<br/> to do this job as Expert in {SpecializedFieldName} field.<br/>Please choose plan other than Expert.</div>',
    IsOkOnly:true
  };

  expertSituationModelOptions: ModalOptions={
    backdrop: 'static',
    keyboard: false,
    class: "modal-sm",
    initialState: this.configExpertSituation
  };
  
  
  configMinimumAmount: confirmDialogConfig={
    MessageText: '<div>Minimum Order amount is <strong>¥150</strong> .<br/>But your payment amount is <strong class="text-red">¥{paymentAmount}</strong>.<br/>Sorry! you cannot proceed.</div>',
    IsOkOnly:true
  };

  MinimumAmountModelOptions: ModalOptions={
    backdrop: 'static',
    keyboard: false,
    class: "modal-sm",
    initialState: this.configMinimumAmount
  };


  ngOnInit() {

    this.initialize();

  }
}
