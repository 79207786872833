import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import {DataShareService} from '../services/data-share.service';
import {DataSharingModel} from '../models/data-sharing-model';
import { Title,Meta  }     from '@angular/platform-browser';

@Component({
  selector: 'app-calibration-sample',
  templateUrl: './calibration-sample.component.html',
  styleUrls: ['./calibration-sample.component.css'],

})
export class CalibrationSampleComponent implements OnInit {

  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  
  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private titleService: Title,
    private meta: Meta) {
    this.router=_router.url;
    this.sharedService=dataShareService;

    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName="校正サンプル";
    this.sharedService.setSharedData(this.shareableData);


    if(this.sharedService.currentCulture=='jp'){


      this.titleService.setTitle("英文校正サービスの校正サンプル|editing-Pro.");
      this.meta.updateTag({ name: 'keywords', content: '校正サンプル,英文校正サンプル, 分野別サンプル,editing sample,校正,校正比較,論文サンプル' });
      this.meta.updateTag({ name: 'description', content: '英文校正サービスのサンプルがご確認出来ます。医学,科学,社会学などの専門分野のサンプルよりご確認下さい。。' });

    }
    else{


      this.titleService.setTitle("editing-Pro.serviceのeditingsample|editing-Pro.");
      this.meta.updateTag({ name: 'keywords', content: 'editingsample,editing-Pro.sample, samples by category,editing sample,editing,editing comparison,academic writingsample' });
      this.meta.updateTag({ name: 'description', content: 'Check editing-Pro.service\'s sample. Samples for medicine, science, social studies, and other professional fields.' });


    }
   

      
   }

  ngOnInit() {
    
  }

}
