import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { ChangePasswordModel } from '../models/change-password.model';
import { EditorService } from '../services/editor.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import {ConfirmDialogComponent, confirmDialogConfig} from '../confirm-dialog/confirm-dialog.component';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-reset-editor-password',
  templateUrl: './reset-editor-password.component.html',
  styleUrls: ['./reset-editor-password.component.css']
})
export class ResetEditorPasswordComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  passwordChangeModel:ChangePasswordModel=new ChangePasswordModel();
  editorService:EditorService;
  toastr:ToastrService;
  ngbModalOptions:ModalOptions;
  constructor(private _router: Router,
    private route:ActivatedRoute,
    private dataShareService:DataShareService,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private _editorService:EditorService,
    private _authService:AuthenticationService,
    private _toastr:ToastrService) {
      this.router=_router.url;
      this.sharedService=dataShareService;
      this.toastr=_toastr;
      this.editorService=_editorService;
      this.route.params.subscribe(params=>{
        if(params['passCode'])
        this.passwordChangeModel.CurrentPassword=params['passCode'];
        if(params['regId'])
        this.passwordChangeModel.RegistrationID=params['regId'];
     });

     this.shareableData=new DataSharingModel();
     this.shareableData.currentRoute=this.router;
     this.shareableData.currentStateName=='jp'? "パスワード変更":"Change Password";
     this.sharedService.setSharedData(this.shareableData);

     if(localStorage.getItem("currentUser"))
     this.passwordChangeModel.EmailID=JSON.parse(localStorage.getItem("currentUser")).Email;
 
     let config:confirmDialogConfig={
       MessageText:this.shareableData.currentStateName =='jp'? "パスワード変更に成功しました 再度ログインしてください":"Password changed successfully Please log in again",
       IsOkOnly:true,
       OKBtnCallback:this.afterConfirm.bind(this)
       };
      this.ngbModalOptions = {
       backdrop : 'static',
       keyboard : false,
       class: "modal-sm",
       initialState:config
     };

    }

  ngOnInit() {
  }

  afterConfirm()
   {
       this._authService.loggedOut();
   }

  changePassword()
  {
    this.editorService.resetPassword(this.passwordChangeModel.RegistrationID,this.passwordChangeModel.NewPassword,this.passwordChangeModel.CurrentPassword).subscribe((data)=>{
      if(data==true)
      {
        this.bsModalRef= this.modalService.show(ConfirmDialogComponent,this.ngbModalOptions);
      //  this.toastr.success('Password has been changed successfully!');
      }
      else
      {
       this.toastr.error(this.shareableData.currentStateName =='jp'? "パスワードが一致しません。":"Current password did not match!"); //'Current password did not match!'
      }
    });
    
  }

}
