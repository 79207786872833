import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../services/customer-service.service';
import { DataShareService } from '../../services/data-share.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { MessageModel } from '../../models/message-model.model';
import * as _ from 'underscore';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Router } from '@angular/router';
import { Title,Meta  }     from '@angular/platform-browser';


@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.css']
})
export class MessageListComponent implements OnInit {
  customerService: CustomerService;
  sharedService: DataShareService;
  router: string;
  shareableData: DataSharingModel;

  messageList: { currentPage: number, totalItems: number, dataSource: MessageModel[] };


  constructor(private _customerService: CustomerService,
    private _sharedService: DataShareService,
    private _router: Router,
    private titleService: Title,
    private meta: Meta) {
    this.customerService = _customerService;
    this.sharedService = _sharedService;
    this.router = _router.url;
    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName =this.sharedService.currentCulture=='jp'?"メッセージ一覧":"Message list";
    this.sharedService.setSharedData(this.shareableData);




    if(this.sharedService.currentCulture=='jp'){

      this.titleService.setTitle("英文校正サイトのメッセージ作成");
      this.meta.addTag({ name: 'keywords', content: 'メッセージ,品質,ノーハウ,作成,格安校正,オンライン校正,英語校正,英語,専門校正,校正会社,論文校正,英語論文チェック' });
      this.meta.addTag({ name: 'description', content: '英文の専門校正サイト・エディティングプロのメッセージの作成ページです。いつでもどこでもサクサク英文校正依頼や確認を。' });

    }
    else{

      this.titleService.setTitle("editing-Pro.Message");
      this.meta.addTag({ name: 'keywords', content: 'message,quality,knowhow,create,resonable priceediting,onlineediting,Englishediting,English,professionalediting,editingcompany,academic writingediting,Englishacademic writingcheck' });
      this.meta.addTag({ name: 'description', content: 'Message creation page for English writing professional editing sit editing-Pro. Smooth and easy English editing order at editing-Pro.' });

    }

    this.messageList = { currentPage: 1, totalItems: 0, dataSource: null };
  }

  private manipulateMessageList(list: MessageModel[]) {

     let newList=[];
     
     list.forEach(i=>{
      i.ParentId=this.getFinalParentId(list,i);
      newList.push(i);
     });
        

     let listOfParentIds=_.uniq(_.pluck(list,"ParentId"));
  
    let subParentList = _.filter(list, function (item) {
      return _.indexOf(listOfParentIds,item.ParentId)>-1 && item.ParentId>0;
    }); 


    let actualParentList = _.filter(list, function (item) {
      return item.ParentId==0;
    });



    let allChildren = Array<MessageModel>();

    _.each(actualParentList, function (item) {
      //keep an orginal copy before modify
      //  allParent.push(Object.assign({},item)); 
      item.IsHideChildren = true;
      item.IsParent = true;
      item.HasUnreadChild = false;
      item.Children = _.filter(list, function (subItem) {
        let predicate = (subItem.WebOrderNo == item.WebOrderNo && subItem.ParentId > 0 && subItem.ParentId==item.ID) ? true : false;
        if (predicate == true)
          {
            if(subItem.Status==2)
            {
              item.HasUnreadChild = true;
            }
            allChildren.push(subItem);
          }
        return predicate;
      });
      item.Children=item.Children.reverse();
    });

    //finding out orphan  not in parent nor in children
    let allParentId = _.pluck(actualParentList, 'ID');
    let allChildsId = _.pluck(allChildren, 'ID');
    let orphans = Array<MessageModel>();
    orphans = _.filter(list, function (item) {
      return ((!_.contains(allParentId, item.ID) && !_.contains(allChildsId, item.ID)));
    });

    _.each(orphans.reverse(), function (item) {
      item.IsOrphan = true;
      actualParentList.unshift(item);
    });
    return actualParentList;
  }

  private getFinalParentId(list,item)
  {
    let p=_.find(list,function(i){
      return i.ID==item.ParentId;
    });

    if(p && p.ParentId==0)
    return p.ID;
    else if(!p)
    return 0;
    else 
    return this.getFinalParentId(list,p);
    
  }

  getMessageList(pageno: number = 1) {
    this.customerService.getAllMessages(this.sharedService.currentUser.ID, pageno).subscribe((data) => {
      if (data) {
        this.messageList.currentPage = 1;
        this.messageList.dataSource = this.manipulateMessageList(data);
        if (data.length > 0)
          this.messageList.totalItems = data[0].TotalRows;
        
      }

    });
  }

  onPageIndexChange(event: PageChangedEvent) {
    this.customerService.getAllMessages(this.sharedService.currentUser.ID, event.page).subscribe((data) => {
      
      if (data) {
        this.messageList.currentPage = event.page;
        this.messageList.dataSource = this.manipulateMessageList(data);
        if (data.length > 0)
          this.messageList.totalItems = data[0].TotalRows;
      }
    });
  }

  ngOnInit() {
    this.getMessageList();
  }

}
