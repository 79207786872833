import { Injectable } from '@angular/core';
import { AppSetting } from '../app-setting';
import { HttpClient } from '@angular/common/http';
import { StripePaymentModel } from '../models/stripe-payment.model';
import { IPSCardPaymentModel } from '../models/ipscard-payment.model';
import { IPSResponse } from '../models/ipsresponse.model';
import { MiscellaneousModel } from '../models/miscellaneous-model.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  ROOT_URL: string = AppSetting.API_ENDPOINT;
  EDITINGPRO_ROOT_URL: string = AppSetting.EDITINGPRO_API_ENDPOINT;
  constructor(private http: HttpClient) {

  }

  paymentWithStripe(model: StripePaymentModel) {
    return this.http.post<IPSResponse>(this.EDITINGPRO_ROOT_URL + 'payment/PaymentWithStripe', model);
  }

  paymentWithIPS(model: IPSCardPaymentModel) {
    return this.http.post<IPSResponse>(this.EDITINGPRO_ROOT_URL + 'payment/PaymentWithIPS', model);
  }

  getDiscountTypeList()
  {
    return this.http.get<Array<MiscellaneousModel>>(this.EDITINGPRO_ROOT_URL + 'payment/GetAllDiscountTypeInfo');
  }

}
