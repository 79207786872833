import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {DataShareService} from '../services/data-share.service';
import {DataSharingModel} from '../models/data-sharing-model';
import { Title,Meta  }     from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;

  constructor(
    private _router: Router,
    private dataShareService:DataShareService,
    private titleService: Title,
    private meta: Meta
  ) {
    this.router=_router.url;
    this.sharedService=dataShareService;

    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName="";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("");
    this.meta.updateTag({ name: 'keywords', content: 'about-keywords' });
    this.meta.updateTag({ name: 'description', content: 'about-description' });
   }

  ngOnInit() {
  }

}
