import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { CustomerService } from '../../services/customer-service.service';
import { CompanyModel } from '../../models/company-model.model';
import { EstimationService } from '../../services/estimation.service';
import { OrderWeb } from '../../models/order-web.model';
import { MiscellaneousModel } from '../../models/miscellaneous-model.model';
import { saveAs } from "file-saver";

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {

  sharedService: DataShareService;
  customerService: CustomerService;
  model: { EstimationModel: any, Html: string, Css: string };
  itemList: Array<any>;
  orderNo: string;
  fixedDataModel: any;
  obj: any;
  customerModel: CompanyModel = new CompanyModel();
  estimationService: EstimationService;
  orderDetail: OrderWeb;
  customerNo: number;

  constructor(private _sharedService: DataShareService,private _customerService: CustomerService,
    private _estimationService: EstimationService) { 
    this.sharedService = _sharedService;
    this.customerService = _customerService;
    this.estimationService = _estimationService;

    this.orderDetail = new OrderWeb();
    this.itemList = new Array<any>();
    this.fixedDataModel = {};
    this.obj={};
    this.model = { EstimationModel: null, Html: "", Css: "" };
    let modalData: MiscellaneousModel = this.sharedService.getModalData();
    if (modalData) {
      this.orderNo=modalData.OrderNo;
      this.customerNo=modalData.CustomerNo;
    }
    this.getOrderDetails();
  }

  downloadPdfTemplate() {
    this.model.Html = document.querySelector(".readonly-template-dom").innerHTML;
    this.model.Css = document.querySelector(".container-css").innerHTML;
    this.customerService.generatePdfTemplate(this.model).subscribe((data) => {
      let PDFName: string = this.orderNo  + '_請求書' + '.pdf';
      saveAs(data, PDFName);
      this.sharedService.bsModalRef.hide();
    });
  }


  getOrderDetails() {
    
    this.estimationService.getOrderDetailsByOrderNo(this.orderNo).subscribe((data) => {
      if (data) {
        let orderList:Array<OrderWeb>=new Array<OrderWeb>();
        orderList.push(data);
        this.fixedDataModel.EstimationNo = data.OrderNo;
        this.fixedDataModel.PostageAmount=data.PostageAmount;
        this.fixedDataModel.Discount=data.Discount;
        this.getCustomerModel(data.ClientID);
        //init some data
        if(!orderList[0].EndDate)
        orderList[0].EndDate=new Date();
        let options = { year: 'numeric', month: 'long', day: 'numeric' };
        if (data) {
          this.fixedDataModel.DeliveryDate = new Date(orderList[0].EndDate).toLocaleDateString('ja-JP', options);
          this.obj.IsSingleOrder = true;
        }

        let CurrentDate: Date = new Date();
        this.obj.CurrentDate = CurrentDate == null ? null : new Date(CurrentDate).toLocaleDateString('ja-JP', options);
        this.obj.DeliveryDateJp = new Date(orderList[0].DeliveryDate).toLocaleDateString('ja-JP', options);

        orderList.forEach(order => {
          let detailObj = {
            OrderNo: order.OrderNo,
            PaymentAmount: order.PaymentAmount,
            Remarks: order.OrderNo + ' ' + order.OrderName, AmountOfMoney: order.PaymentAmount, ConsumptionTax: order.ConsumptionTax
          }
          this.itemList.push(detailObj);
        });

        this.obj.TotalEstimatedPrice = 0;
        this.obj.TotalConsumptionTax = 0;
        this.obj.PaymentAmountWithoutTax = 0;
        for (let i = 0; i < this.itemList.length; i++) {
          this.obj.TotalEstimatedPrice = this.obj.TotalEstimatedPrice + this.itemList[i].AmountOfMoney;
          this.obj.TotalConsumptionTax = this.obj.TotalConsumptionTax + this.itemList[i].ConsumptionTax;
        }

        this.fixedDataModel.ConsumptionTax = this.obj.TotalConsumptionTax;
        this.fixedDataModel.Total = this.orderDetail.EstimatedPrice;
        this.fixedDataModel.FeesHead = data.OrderName;
        this.fixedDataModel.Date1MonthLater = new Date(new Date(orderList[0].DeliveryDate).setMonth(new Date(orderList[0].DeliveryDate).getMonth()+1)).toLocaleDateString('ja-JP', options);
        this.obj.EstimationDate = new Date(data.OrderDate).toLocaleDateString('ja-JP', options);;
        this.obj.PaymentAmountWithoutTax = this.obj.TotalEstimatedPrice - this.obj.TotalConsumptionTax;

      }

    });
  }

  getCustomerModel(cid:string) {
    let clientId: string = cid;
    this.customerService.getCompanyById(clientId).subscribe((data) => {
      this.customerModel = data;
	  this.customerModel.Address="";
	  this.customerModel.Address=data.Address1?this.customerModel.Address+data.Address1 : this.customerModel.Address;
	  this.customerModel.Address=data.Address2?this.customerModel.Address+data.Address2 : this.customerModel.Address;
    })
  }


  ngOnInit() {
  }

}
