import { DocumentModel } from "./document.model"
import { OrderDeliveryMode } from "../Enums.enum"

export class EditingPreferenceModel {
        ID :number
        WordCount :number
        LanguageID? :string
        SpecializedFieldID? :string
        SpecializedFieldName :string
        IsSpellCheck :boolean
        IsGrammerCheck :boolean
        IsWording :boolean
        DeliveryTracingRecordID? :number
        DeliveryTracingRecordName:string 
        IsDoubleCheck :boolean
        IsAppointingEditor :boolean
        AppointedEditor1? :number
        AppointedEditor2? :number
        AppointedEditor3? :number
        IsMachineTranslated :boolean
        IsEditingComment :boolean
        IsCrossCheck :boolean
        IsTermCheck :boolean
        IsWordElimination :boolean
        IsLayoutOrganization :boolean
        OtherPreferredEditingMethod? :string
        CommentsToEditor? :string
        ReferenceFilePath? :string
        UploadedFileOrginalName:string
        IsEditingFieldInParticularArea:boolean
        EditingFieldAreaCode:string
        EditingLanguageCode:string
        EditingServicePlan:string
        PlanType:string
        PlanCourse:string 
        TotalPrice:number 
        TaxRate:number 
        TaxAmount:number 
        PlanCourseID:number
        PlanCourseDuration:string
        ReferenceFile:DocumentModel
        DeliveryMode:OrderDeliveryMode
        PurposeOfEditing:string
        DesiredEnglishType:string
        DirectionOfEditing:string
        ReaderTargetDetails:string
        PurposeOthersName:string 
        EnglishTypeOthersName:string 
        ReaderTargetType:string
        TranslationText:string
        IsEstimationByDocument:boolean    
}