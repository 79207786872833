import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {DataShareService} from '../services/data-share.service';
import {DataSharingModel} from '../models/data-sharing-model';
import { Title,Meta  }     from '@angular/platform-browser';
import { AppComponent } from '../app.component';
import { EstimationService } from '../services/estimation.service';

@Component({
  selector: 'app-editing-service',
  templateUrl: './editing-service.component.html',
  styleUrls: ['./editing-service.component.css']
})
export class EditingServiceComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  pricesPerWord:{Light:number,Business:number,Expert:number};
  esimationService:EstimationService;

  constructor(private _router: Router,
    private _esimationService: EstimationService,
    private dataShareService:DataShareService,
    private titleService: Title,
    private meta: Meta) {
      this.router=_router.url;
    this.sharedService=dataShareService;
    this.esimationService = _esimationService;
    this.pricesPerWord={ Light:null,Business:null,Expert:null};

    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName="サービス概要及びプラン別の内容";
    this.sharedService.setSharedData(this.shareableData);



    if(this.sharedService.currentCulture=='jp'){


      this.titleService.setTitle("英文校正サービス別の概要及びプラン別の内容");
      this.meta.updateTag({ name: 'keywords', content: '英語校正, 英語,校正, 専門エディター校正,英語エディター,校正会社,英文論文校正,英語論文チェック,校正チェックリスト,校正サービス,格安校正,激安校正, オンライン校正.' });
      this.meta.updateTag({ name: 'description', content: '英語の校正は校正プロ(trans-Pro.)にお任せ下さい。24時間365日,オンラインで利用できる校正サービス。納品物の受領までウェブ上で完結。簡単,早い,確実！30分からの短時間納品も可能です。お問い合わせ,お見積りは無料。' });


    }
    else{

      
      this.titleService.setTitle("editing-Pro.service plan overview by categories ");
      this.meta.updateTag({ name: 'keywords', content: '"Englishediting, English,editing, professional editor editing,Englisheditor,editing company,English writing academic writing editing,Englishacademic writingcheck,editing check list,editing service,resonable priceediting ,low priced editing, online editing. ".' });
      this.meta.updateTag({ name: 'description', content: '"Ask editing-Pro. for professional English editing. 24hours service for 365 days. Online editing service that allows order to submission through online. One stop service that is easy, fast and safe. Inquiry and estimate are free. "' });


    }





    this.getChargeSettings(); 
  }

    getChargeSettings()
    {
      this.esimationService.getEditingChargeSettings().subscribe((data)=>{
            if(data)
            {
              this.pricesPerWord.Business=data.BaseCharge*data.Business;
              this.pricesPerWord.Expert=data.BaseCharge*data.Expert;
              this.pricesPerWord.Light=data.BaseCharge*data.Light;
            }
      });
    } 


  ngOnInit() {
  }

}
