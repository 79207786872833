import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router } from '@angular/router';
import { ErrorReportModel } from '../models/error-report-model.model';
import { ClientEnvService } from '../services/client-env.service';
import { AppSetting } from '../app-setting';
import { CustomerService } from '../services/customer-service.service';
import { ToastrService } from 'ngx-toastr';
import { Title, Meta } from '@angular/platform-browser';
import { AuthenticationService } from '../services/authentication.service';
import { NotificationType } from '../Enums.enum';

@Component({
  selector: 'app-error-report',
  templateUrl: './error-report.component.html',
  styleUrls: ['./error-report.component.css']
})
export class ErrorReportComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  siteErrorReport: ErrorReportModel;
  uploadedDocument: any;
  customerService: CustomerService;
  clientEnvService: ClientEnvService;

  constructor(private _router: Router,
    private _dataShareService: DataShareService,
    private _clientEnvService: ClientEnvService,
    private _customerService: CustomerService,
    private _authService:AuthenticationService,
    private _toastr: ToastrService,
    private titleService: Title,
    private meta: Meta) {

    this.router = _router.url;
    this.sharedService = _dataShareService;
    this.clientEnvService = _clientEnvService;
    this.customerService = _customerService;
    this.initializeDefault();
    

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName =='jp'? "サイトのエラー報告":"Error report";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("英文校正サイトのエラー報告");
    this.meta.updateTag({ name: 'keywords', content: 'サイトエラー報告,格安校正,オンライン校正,英語校正,英語,校正会社,英語論文チェック' });
    this.meta.updateTag({ name: 'description', content: '英文校正サイト上に何かしらのエラーが発生した際には直ちに対応致しますのでこちらからエラー内容をお伝えください。' });
  }

  private initializeDefault()
  {
    //initialize default values
    this.siteErrorReport = new ErrorReportModel();
    this.siteErrorReport.BrowserVersion = this.clientEnvService.getFullBrowserName();
    this.siteErrorReport.OsName = this.clientEnvService.getFullOSName();
    this.siteErrorReport.ApplicationID = AppSetting.ApplicationID;
    this.siteErrorReport.ErrorDescription = "";
    if (localStorage.getItem("currentUser")) {
      this.siteErrorReport.Email = JSON.parse(localStorage.getItem("currentUser")).Email;
      this.siteErrorReport.Name = this.sharedService.currentUser.Name || this.sharedService.currentUser.CompanyPICName;

      if(this._authService.sharedService.currentUser && this._authService.sharedService.currentUser.UserType==1)
      this.siteErrorReport.CustomerNo = JSON.parse(localStorage.getItem("currentUser")).RegistrationID;
      else if(this._authService.sharedService.currentUser && this._authService.sharedService.currentUser.UserType==2)
      this.siteErrorReport.EditorNo = JSON.parse(localStorage.getItem("currentUser")).RegistrationID;
    }
  }

  fileSelected(event: any) {  //this time developed by regarding that only one file will be uploaded
    this.uploadedDocument = event.target.files[0];
  }

  sendErrorReport() {
    this.customerService.saveSiteErrorReport(this.siteErrorReport, this.uploadedDocument).subscribe((data) => {
      if (data == true) {
        this.initializeDefault();
        this.sharedService.showTranslationToastr('NOTIFICATION.YOUR_ERROR_REPORT_SUBMITTED_SUCCESSFULLY',NotificationType.SUCCESS);
      }
    });
  }

  ngOnInit() {
  }

}
