import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Router } from '@angular/router';
import { ContactUsModel } from '../../models/contact-us.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  contactUsModel:ContactUsModel;
  toastr:ToastrService;


  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private _toastr:ToastrService) {
    this.router=_router.url;
    this.sharedService=dataShareService;
    this.toastr=_toastr;
    this.contactUsModel=new ContactUsModel();
    this.contactUsModel.ApplicationID=3;

     
    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName="見積り・お問合せ（コーディネーター依頼）";
    this.sharedService.setSharedData(this.shareableData);
   }

   onSendButtonClicked()
   {
     console.log('contact us send button clicked!');
   }

  ngOnInit() {
  }

}
