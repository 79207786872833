import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthenticationService} from '../services/authentication.service';
import { EditingProUser } from '../models/editing-pro.model';
import { DataShareService } from '../services/data-share.service';
@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {
  _authService :AuthenticationService;
  sharedService:DataShareService;

  constructor(private _auth:AuthenticationService,private _sharedService:DataShareService,private router: Router) {
    this._authService=_auth;
    this.sharedService=_sharedService;
   }

   canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const userType = route.data.UserType;
    if(!localStorage.getItem('currentUser'))
    {
      localStorage.setItem("OpenLoginPopup","1");
      this.router.navigateByUrl("/");
       return false;
    }

    const currentUser:EditingProUser =JSON.parse(localStorage.getItem('currentUser'));
    
    if (currentUser.UserType != userType) {
      this.router.navigateByUrl("/404");
      return false;
    }
    return true;
  }
}
