import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-developer-testing',
  templateUrl: './developer-testing.component.html',
  styleUrls: ['./developer-testing.component.css']
})
export class DeveloperTestingComponent implements OnInit {
theAmountInJPY:number;
  constructor() { 
    this.theAmountInJPY=9983000;
  }

  ngOnInit() {
  }

}
