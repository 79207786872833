import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Title,Meta  }     from '@angular/platform-browser';
import { DataSharingModel } from '../models/data-sharing-model';
import * as _ from 'underscore';
import { SoftwareSkillModel } from '../models/software-skill.model';
import { CommonListService } from '../services/common-list.service';
import { LanguageModel } from '../models/language.model';
import { LanguageLevelModel } from '../models/language-level.model';
import { EducationModel } from '../models/education.model';
import { CountryModel } from '../models/country-model.model';
import { SpecializedFieldModel } from '../models/specialized-field.model';
import { PrefectureModel } from '../models/prefecture-model.model';
import { StaffModel } from '../models/staff.model';
import { StaffWorkingStatusModel } from '../models/staff-working-status.model';
import { StaffEducationModel } from '../models/staff-education.model';
import { UtilityService } from '../services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { EditorService } from '../services/editor.service';
import { StaffSoftwareSkillModel } from '../models/staff-software-skill.model';
import { StaffProfesionalSpecialityModel } from '../models/staff-profesional-speciality.model';
import { StaffPresentStatusModel } from '../models/staff-present-status.model';
import { MajorSubjectModel } from '../models/major-subject.model';
import { MajorSubjectDetailsModel } from '../models/major-subject-details.model';
import { BankModel } from '../models/bank.model';
import { BankAccountTypeModel } from '../models/bank-account-type.model';
import { BankBranchModel } from '../models/bank-branch.model';
import { BankAccountModel } from '../models/bank-account.model';
import { CredentialModel, UserType } from '../models/credential.model';
import { empty } from 'rxjs';
import { isNgTemplate } from '@angular/compiler';
import { EnglishLanguageTypeModel } from '../models/english-language-type.model';
import { StaffEnglishTypeModel } from '../models/staff-english-type.model';
import { MasterCurrencyModel } from '../models/master-currency.model';


@Component({
  selector: 'app-editor-registration',
  templateUrl: './editor-registration.component.html',
  styleUrls: ['./editor-registration.component.css']
})
export class EditorRegistrationComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  commonListService: CommonListService;
  shareableData: DataSharingModel;
  yearOptions: number[];
  dayOptions: number[];
  birthDate: { Day: number, Month: number, Year: number };
  softwareSkills: Array<SoftwareSkillModel>;
  languages: Array<LanguageModel>;
  languageLevels: Array<LanguageLevelModel>;
  educationalDegrees: Array<EducationModel>;
  countries: Array<CountryModel>;
  specializedFields: Array<SpecializedFieldModel>;
  prefectures: Array<PrefectureModel>;
  workingStatusList: Array<StaffWorkingStatusModel>;
  bankList: Array<BankModel>;
  bankAccountTypeList:Array<BankAccountTypeModel>;
  bankBranchList:Array<BankBranchModel>;
  majorSubjectList: Array<MajorSubjectModel>;
  majorSubjectDetailsList: Array<MajorSubjectDetailsModel>;
  editorModel: StaffModel;
  isAcceptAggrement: boolean = false;
  utility: UtilityService;
  imagesFiles: any = null;
  toastrService: ToastrService;
  editorService: EditorService;
  routerService:Router;
  englishTypeList: Array<EnglishLanguageTypeModel>;
  payPalCurrencyList:Array<MasterCurrencyModel>;


  constructor(private _router: Router,
    private route:ActivatedRoute,
    private dataShareService: DataShareService,
    private _editorService: EditorService,
    private titleService: Title,
    private meta: Meta,
    private _utility: UtilityService,
    private _commonListService: CommonListService,
    private _toastrService: ToastrService) {
    this.router = _router.url;
    this.routerService=_router;
    this.sharedService = dataShareService;
    this.toastrService = _toastrService;
    this.editorService = _editorService;
    this.commonListService = _commonListService;
    this.utility = _utility;

    this.shareableData = new DataSharingModel();
    this.softwareSkills = new Array<SoftwareSkillModel>();
    this.languages = new Array<LanguageModel>();
    this.languageLevels = new Array<LanguageLevelModel>();
    this.educationalDegrees = new Array<EducationModel>();
    this.countries = new Array<CountryModel>();
    this.specializedFields = new Array<SpecializedFieldModel>();
    this.prefectures = new Array<PrefectureModel>();
    this.workingStatusList = new Array<StaffWorkingStatusModel>();
    this.majorSubjectList=new Array<MajorSubjectModel>();
    this.majorSubjectDetailsList=new Array<MajorSubjectDetailsModel>();
    this.bankList= Array<BankModel>();
    this.bankAccountTypeList=Array<BankAccountTypeModel>();
    this.bankBranchList=Array<BankBranchModel>();
    this.editorModel = new StaffModel();
    this.englishTypeList = Array<EnglishLanguageTypeModel>();
    this.payPalCurrencyList=Array<MasterCurrencyModel>();

    this.route.params.subscribe(params=>{
      if(params['refId'])
        {
          let referalId:string =params['refId'];
          this.editorModel.IntroducedBy=referalId;
        }
     });

    this.editorModel.IsEditor=true;
    this.editorModel.Education1 = new StaffEducationModel();
    this.editorModel.Education2 = new StaffEducationModel();
    this.editorModel.Education3 = new StaffEducationModel();
    this.editorModel.Staffbank=new BankAccountModel();

    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName= this.dataShareService.currentCulture =='jp'? "新規登録（エディター）":"New registration (editor)";
    this.sharedService.setSharedData(this.shareableData);


    if(this.sharedService.currentCulture=='jp')
    {
      this.titleService.setTitle("エディティングプロへの新規エディター登録");
      this.meta.updateTag({ name: 'keywords', content: '英文校正,エディター,登録,品質,格安校正,オンライン校正,英語校正,英語,登録,専門校正,英語英文校正,校正会社,新規論文校正,英語論文チェック' });
      this.meta.updateTag({ name: 'description', content: 'エディティングプロへのエディター登録で,英文校正の専門家として活躍してみませんか？ 短時間で高単価の仕事内容を掲載しております。' });
    }
    else{
      this.titleService.setTitle("editing-Pro. New editor registration");
      this.meta.updateTag({ name: 'keywords', content: 'editing-Pro.,editor, register,quality,resonable priceediting,online editing,Englishediting,English,register,professional editing,English editing-Pro.,editing company,new academic writing editing,English academic writing check' });
      this.meta.updateTag({ name: 'description', content: 'Work as a professional editor by  registering editing-Pro. Fast and high priced jobs are available for 24 hours/7days.' });
    }


    this.fillEnglishTypeList();
    this.initDateOfBirthFields();
    this.getSoftwareSkills();
    this.getLanguageList();
    this.getLanguageLevelList();
    this.getEducationalDegreeList();
    this.getCountryList();
    this.getSpecializedFieldList();
    this.getPrefectureList();
    this.getWorkingStatusList();
    this.getMajorSubjectList();
    //this.getBankList();
    this.getBankAccountTypeList();
    this.getPaypalCurrencyList();
  }

  private fillEnglishTypeList() {
    let british: EnglishLanguageTypeModel = {
      ID: 1, Name_en: 'British English', Name_jp: 'イギリス英語', IsSelected: false
    };
    let american: EnglishLanguageTypeModel = {
      ID: 2, Name_en: 'American english', Name_jp: 'アメリカ英語', IsSelected: false
    };
    let australian: EnglishLanguageTypeModel = { ID: 3, Name_en: 'Australian English', Name_jp: 'オーストラリア英語', IsSelected: false };
    let other: EnglishLanguageTypeModel = {
      ID: 4, Name_en: 'Other English', Name_jp: 'その他地域の英語', IsSelected: false
    };
    this.englishTypeList.push(british, american, australian, other);
    //console.log('englishTypeList',this.englishTypeList);
  }

  private getSoftwareSkills() {
    this.commonListService.getAllStaffSoftwareSkills(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.softwareSkills = data;
    });
  }

  private getLanguageList() {
    this.commonListService.getAllLanguages(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.languages = data;
    });
  }

  private getLanguageLevelList() {
    this.commonListService.getLanguageLevelList(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.languageLevels = data;
    });
  }

  private getEducationalDegreeList() {
    this.commonListService.getEducationalDegreeList(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.educationalDegrees = data;
    });
  }

  private getCountryList() {
    this.commonListService.getCountries(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.countries = data;
    });
  }

  private getPrefectureList() {
    //country ID 1 for Japan
    this.commonListService.getPrefecturesByCountryID(1,this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.prefectures = data;
    });
  }

  private getSpecializedFieldList() {
    this.commonListService.getSpecializedFieldList(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.specializedFields = data;
    });
  }

  private initDateOfBirthFields() {
    this.yearOptions = (_.range(1951, new Date().getFullYear() + 1)).reverse();
    this.dayOptions = _.range(1, 31 + 1);
  }
  private getBirthDate(): Date {
    return new Date(this.birthDate.Year, this.birthDate.Month, this.birthDate.Day);
  }

  private getWorkingStatusList() {
    this.commonListService.getWorkingStatusList(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.workingStatusList = data;
    });
  }

  private getMajorSubjectList() {
    this.commonListService.getMajorSubjectList(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.majorSubjectList = data;
    });
  }

  getMajorSubjectDetailsList(majorsubjectId:string) {
    this.commonListService.getMajorSubjectDetailList(majorsubjectId,this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.majorSubjectDetailsList = data;
    });
  }

  public getBankList() {
    let countryId:number=0;
    if(this.editorModel.LivingCountryID)
    {
      countryId=this.editorModel.LivingCountryID;
    }
    if(this.editorModel.Staffbank.CountryID){
      countryId = this.editorModel.Staffbank.CountryID;
    }

    this.commonListService.getBankList(countryId,this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.bankList = data;
    });
  }

  private getBankAccountTypeList() {
    this.commonListService.getBankAccountTypeList(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.bankAccountTypeList = data;
    });
  }

   getBankBranchListByBankId(bankId:string) {
    this.commonListService.getBankBranchListByBankId(bankId,this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.bankBranchList = data;
        //console.log('BranchList:',this.bankBranchList);
    });
  }

  getPaypalCurrencyList(){
    this.commonListService.getPaypalCurrencyList(this.sharedService.currentCulture).subscribe((data)=>{
      if(data)
      this.payPalCurrencyList = data;
    });
  }

  setFiles(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.toastrService.warning(this.shareableData.currentStateName =='jp'? "イメージのみサポートされています。":"Only images are supported."); //"Only images are supported."
      return;
    }
    this.imagesFiles = files;
  };

  isEditorAlreadyExists() {
    if (!this.editorModel.StaffEmailID || this.editorModel.StaffEmailID == "")
      return;
    this.editorService.checkExistingEditor(this.editorModel.StaffEmailID, 'jp').subscribe((data) => {
      if (data === true)
        this.toastrService.error(this.shareableData.currentStateName =='jp'? "ご登録いただいたＥメールＩＤはすでに登録されています。別のものをお使いください。":"Email ID already exist,Please use another one!"); //'Email ID already exist,Please use another one!'
      else
        this.toastrService.success(this.shareableData.currentStateName =='jp'? "このＥメールＩＤは利用可能です。":"Email ID Available!");//'Email ID Available!'
    });
  }

  private mapEditorModelData()
  {
    //education
    let educations:Array<StaffEducationModel>=new Array<StaffEducationModel>();
    if(this.editorModel.Education1.StaffEducationDegreeID)
    {
      let eItem:StaffEducationModel=new StaffEducationModel();
      eItem.StaffEducationDegreeID=this.editorModel.Education1.StaffEducationDegreeID;
      this.editorModel.EducationalDegree1=this.editorModel.Education1.StaffEducationDegreeID;
      eItem.InstituteName_jp=this.editorModel.Education1.InstituteName_jp;
      eItem.Department_jp=this.editorModel.Education1.Department_jp;
      eItem.MajorSubjectID=this.editorModel.Education1.MajorSubjectID;
      eItem.MajorSubjectDetailsID=this.editorModel.Education1.MajorSubjectDetailsID;
      educations.push(eItem);
    }

    if(this.editorModel.Education2.StaffEducationDegreeID)
    {
      let eItem:StaffEducationModel=new StaffEducationModel();
      eItem.StaffEducationDegreeID=this.editorModel.Education2.StaffEducationDegreeID;
      this.editorModel.EducationalDegree2=this.editorModel.Education2.StaffEducationDegreeID;
      eItem.InstituteName_jp=this.editorModel.Education2.InstituteName_jp;
      eItem.Department_jp=this.editorModel.Education2.Department_jp;
      eItem.MajorSubjectID=this.editorModel.Education2.MajorSubjectID;
      eItem.MajorSubjectDetailsID=this.editorModel.Education2.MajorSubjectDetailsID;
      educations.push(eItem);
    }
    
    if(this.editorModel.Education3.StaffEducationDegreeID)
    {
      let eItem:StaffEducationModel=new StaffEducationModel();
      eItem.StaffEducationDegreeID=this.editorModel.Education3.StaffEducationDegreeID;
      this.editorModel.EducationalDegree3=this.editorModel.Education3.StaffEducationDegreeID;
      eItem.InstituteName_jp=this.editorModel.Education3.InstituteName_jp;
      eItem.Department_jp=this.editorModel.Education3.Department_jp;
      eItem.MajorSubjectID=this.editorModel.Education3.MajorSubjectID;
      eItem.MajorSubjectDetailsID=this.editorModel.Education3.MajorSubjectDetailsID;
      educations.push(eItem);
    }

    this.editorModel.Educations=educations;

    //working status
    let wsList= _.filter(this.workingStatusList,function(item:StaffWorkingStatusModel){
        return item.IsSelected==true;
    });
      let presetnStatusList=new Array<StaffPresentStatusModel>();
    _.each(wsList,function(item:StaffWorkingStatusModel){
       let ps=new StaffPresentStatusModel();
       ps.StaffEmploymentTypeID=item.ID;
       presetnStatusList.push(ps);
    });

    this.editorModel.StaffPresentStatus=presetnStatusList;
     //software Skill
     let sSkills=_.filter(this.softwareSkills,function(item:SoftwareSkillModel){
        return item.IsSelected==true;
     });

     let staffSSkills=new Array<StaffSoftwareSkillModel>();
     _.each(sSkills,function(item:SoftwareSkillModel){
        let m:StaffSoftwareSkillModel=new StaffSoftwareSkillModel();
        m.StaffSoftwareSkillID=item.ID;
        staffSSkills.push(m);
     });

     this.editorModel.staffsoft=staffSSkills;

     //Specialized Fields
    let specialFields= _.filter(this.specializedFields,function(item:SpecializedFieldModel){
      return item.IsSelected==true;
     });

     let staffSpecializedFields=Array<StaffProfesionalSpecialityModel>();
     _.each(specialFields,function(item:SpecializedFieldModel){
      let s=new StaffProfesionalSpecialityModel();
      s.ProfessionalID=item.ID;
      staffSpecializedFields.push(s);
     });

     this.editorModel.StaffProfessional=staffSpecializedFields;

     //Birthdate
     this.editorModel.BirthDate= this.getBirthDate();
     this.editorModel.ApplicationID=3;
     if(this.editorModel.IsJapanese)
     this.editorModel.LivingCountryID=1;

     if(this.sharedService.IsConsumerAffiliateSite && this.sharedService.AffiliateCode)
     {
       this.editorModel.AffiliateCode=this.sharedService.AffiliateCode;
     }

     //Staff English TypeList
    this.editorModel.StaffEnglishTypeList = [];
    this.englishTypeList.forEach(item => {
      if (item.IsSelected) {
        let x: StaffEnglishTypeModel = new StaffEnglishTypeModel();
        x.EnglishType = item.ID;
        x.StaffID = this.editorModel.ID;
        this.editorModel.StaffEnglishTypeList.push(x);
      }
    });
  }

  previewImage() {
    if(this.imagesFiles)
    this.utility.getImageDataURL(this.imagesFiles);
  }

  validationCheck(){
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

   if(this.editorModel.StaffEmailID == null || this.editorModel.StaffEmailID == ''|| this.editorModel.StaffEmailID == undefined){
    this.toastrService.error(this.shareableData.currentStateName =='jp'? "メール(ID) は必須":"Email (ID) is required");
    return false;
  }
  else{ 
    if(!regexp.test(this.editorModel.StaffEmailID)){
        this.toastrService.error(this.shareableData.currentStateName =='jp'? "メールの形が有効ではありません":"Email address is not valid!");
        return false;
      }
    };
    if(this.editorModel.RetypedStaffEmailID == undefined || this.editorModel.RetypedStaffEmailID == null || this.editorModel.RetypedStaffEmailID == ''){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "メール もう一度 は必須":"Email again is required!");
      return false;
    }
  if(this.editorModel.RetypedStaffEmailID !== this.editorModel.StaffEmailID){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "メールアドレスが一致しません":"Email address does not match")
      return false;
    }
  if(this.editorModel.Password == undefined || this.editorModel.Password == '' || this.editorModel.Password == null){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "パスワード は必須":"Password required");
      return false;
    };

    if(this.editorModel.Password.length<8){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "パスワード 8文字以上":"Password 8 characters or more");
      return false;
    };
    if(this.editorModel.RetypedPassword == undefined || this.editorModel.RetypedPassword == null || this.editorModel.RetypedPassword ==''){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "パスワード確認 は必須":"Password confirmation required");
      return false;
    }
    if(this.editorModel.RetypedPassword !==this.editorModel.Password){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "パスワードが一致しません":"Passwords do not match");
      return false;
    };
    if(this.editorModel.FirstName_jp == undefined || this.editorModel.FirstName_jp == '' || this.editorModel.FirstName_jp == null){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "氏(苗字) は必須":"Mr (surname) is required");
      return false;
    };
    if(this.editorModel.LastName_jp == undefined || this.editorModel.LastName_jp == null ||this.editorModel.LastName_jp == ''){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "名(名前) は必須":"First name is required")
      return false;
    };
    if(this.editorModel.Sex == null){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "性別 は必須":"Gender is required");
      return false;
    };
    if(this.editorModel.NationalityID == undefined){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "国籍 は必須":"Nationality required");
      return false;
    };
    if(this.editorModel.NativeLanguageID == undefined){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "母国語 は必須":"Native language required");
      return false;
    };
    if(this.editorModel.ForiegnLanguage1ID == undefined){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "外国語1 は必須":"Foreign language 1 required");
      return false;
    };
    if(this.editorModel.ForeignLang1Level == undefined){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "レベル は必須":"Level required");
      return false;
    };
    let wsList= _.filter(this.workingStatusList,function(item:StaffWorkingStatusModel){
      return item.IsSelected==true;
  });
    if(wsList.length == 0){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "現在の状態 は必須":"Current state is mandatory");
      return false;
    };
    if(this.editorModel.IsJapanese ==true && (this.editorModel.CountryPrefectureID == undefined || this.editorModel.CountryPrefectureID == null)){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "在住地 は必須":"Residence is required");
      return false;
    };
    if(this.editorModel.IsJapanese == false &&(this.editorModel.LivingCountryID == undefined || this.editorModel.LivingCountryID ==null)){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "在住地 は必須":"Residence is required");
      return false;
    }

    if(this.editorModel.Address_jp == undefined || this.editorModel.Address_jp == '' || this.editorModel.Address_jp == null){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "住所 は必須":"Address required");
      return false;
    };

    let specialFields= _.filter(this.specializedFields,function(item:SpecializedFieldModel){
      return item.IsSelected==true;
     });
    if(specialFields.length == 0){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "専門・得意分野 は必須":"Specialization / speciality fields are required");
      return false;
    };

let sSkills=_.filter(this.softwareSkills,function(item:SoftwareSkillModel){
        return item.IsSelected==true;
     });
    if(sSkills.length == 0){
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "対応ソフトウェア は必須":"Compatible software is required");
      return false;
    };
    if(this.editorModel.Education1.StaffEducationDegreeID == undefined || this.editorModel.Education1.MajorSubjectID == undefined ||
      this.editorModel.Education1.MajorSubjectDetailsID == undefined || this.editorModel.Education1.InstituteName_jp == '' ||
      this.editorModel.Education1.InstituteName_jp == null || this.editorModel.Education1.Department_jp == '' || this.editorModel.Education1.Department_jp == null){
        this.toastrService.error(this.shareableData.currentStateName =='jp'? "学歴1 (最終学歴) は必須":"Education 1 (final education) required");
        return false;
      }
      if(this.editorModel.MainCareer_jp == '' || this.editorModel.MainCareer_jp == null || this.editorModel.MainCareer_jp == undefined){
        this.toastrService.error(this.shareableData.currentStateName =='jp'? "主な履歴 は必須":"Main history required");
        return false;
      }
      if(this.editorModel.SelfPR_jp == '' || this.editorModel.SelfPR_jp == null || this.editorModel.SelfPR_jp == undefined){
        this.toastrService.error(this.shareableData.currentStateName =='jp'? "自己PR は必須":"Self PR is mandatory");
        return false;
      }
      if(this.editorModel.TelephoneNo == undefined || this.editorModel.TelephoneNo == '' || this.editorModel.TelephoneNo == null){
        this.toastrService.error(this.shareableData.currentStateName =='jp'? "電話 は必須":"Phone required");
        return false;
      }

      if(this.editorModel)
      {
        this.editorModel.Surname_Global=this.editorModel.LastName_jp;
      }

      return true;
  }

  checkLimit(event,item){
    let specialFields= _.filter(this.specializedFields,function(item:SpecializedFieldModel){
      return item.IsSelected==true;
     });
     if(specialFields.length>4)
     {
       if(item.IsSelected!=true)
        {event.preventDefault();
        event.stopPropagation();
       this.toastrService.error(this.shareableData.currentStateName =='jp'? "一度に選択することはできるのは5人までです。":"Sorry! You cannot select more than 5 at once.");//Sorry! You cannot select more than 5 at once.
        }
       
     }
  }

  registerEditor()
  {
    if(!this.validationCheck())
    return;

    this.mapEditorModelData();
    this.editorModel.CultureID = this.sharedService.currentCulture;
    this.editorService.registerEditor(this.editorModel,this.imagesFiles).subscribe((data)=>{
      if(data=="Success")
      { 
        this.toastrService.success(this.shareableData.currentStateName =='jp'? "登録が完了しました。":"Successfully Registered!"); //"Successfully Registered!"
        let candidate=new CredentialModel();
        candidate.ApplicationId='3';
        candidate.CultureId='jp';
        candidate.EmailId=this.editorModel.StaffEmailID;
        candidate.Password=this.editorModel.Password;
        candidate.UserType=UserType.Editor;
        
        
        localStorage.setItem("currentCandidate",JSON.stringify(candidate));
        this.routerService.navigateByUrl('/editor/registration-confirm');
      }
      else if(data=="Email Already Exists!")
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "このEメールは既に存在しています。":"Email Already Exists!"); //"Email Already Exists!"
      else
      this.toastrService.error(this.shareableData.currentStateName =='jp'? "登録できませんでした。":"Could not be Registered!"); //"Could not be Registered!"
    });
  }

  ngOnInit() {
    this.birthDate = { Day: 1, Month: 1, Year: 2019 };
    this.editorModel.Sex = true;
    this.editorModel.IsJapanese = true;
    this.editorModel.StaffTypeID=6; //editor
    this.editorModel.LivingCountryID=1;
    this.editorModel.RegistrationDate=new Date();

  }

}
