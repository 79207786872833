import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { ChangePasswordModel } from '../../models/change-password.model';
import { EditorService } from '../../services/editor.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmDialogComponent, confirmDialogConfig } from '../../confirm-dialog/confirm-dialog.component';
import { AuthenticationService } from '../../services/authentication.service';
import { Title, Meta } from '@angular/platform-browser';
import { NotificationType } from 'src/app/Enums.enum';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  passwordChangeModel: ChangePasswordModel= new ChangePasswordModel();
  editorService: EditorService;
  toastr:ToastrService;
  ngbModelOptions: ModalOptions;


  constructor(private _router:Router, 
              private _sharedService:DataShareService,
              private _editorService: EditorService,
              private titleService: Title,
              private meta:Meta,
              private bsModalRef: BsModalRef,
              private bsModelService: BsModalService,
              private _toastr:ToastrService,
              private _authService:AuthenticationService) {
     this.router= _router.url;
     this.sharedService = _sharedService;
     this.editorService= _editorService;
     this.toastr= _toastr;
     if(localStorage.getItem("currentUser"))
     this.passwordChangeModel.EmailID = JSON.parse(localStorage.getItem("currentUser")).Email;

     this.shareableData = new DataSharingModel();
     this.shareableData.currentRoute = this.router;
     if(this.sharedService.currentCulture=='jp')
     {
      this.shareableData.currentStateName = "パスワード変更";
      this.sharedService.setSharedData(this.shareableData);
      this.titleService.setTitle("英文校正サイトのパスワード変更");
      this.meta.updateTag({name:'keywords', content: 'パスワード変更,英語校正,英語,専門エディター校正,英語エディター,校正会社,論文校正,英語論文チェック,校正チェック'});
      this.meta.updateTag({name:'description', content:'英文校正サイトのアカウントパスワードを変更したい場合はこちら。納品物の受領までウェブ上で完結。簡単,早い,確実！お問い合わせ,お見積りは無料。'});
     }
     else
     {
      this.shareableData.currentStateName = "Password Change";
      this.sharedService.setSharedData(this.shareableData);
      this.titleService.setTitle("editing-Pro. Password change");
      this.meta.updateTag({name:'keywords', content: 'password change,English editing,English,professional editorediting,English editor,editing company,academic writing editing,English academic writing check,editing check'});
      this.meta.updateTag({name:'description', content:'Change account password of editing-Pro. One stop service that is easy, fast and safe. Inquiry and estimate are free.'});
     }
     

     let config:confirmDialogConfig ={
      MessageText:this.sharedService.currentCulture=='jp'?"パスワード変更に成功しました, 再度ログインしてください" : "Password changed successfully, Please log in again",
      IsOkOnly: true,
      OKBtnCallback:this.afterConfirm.bind(this)
    };

    this.ngbModelOptions = {
      backdrop:'static',
      keyboard:false,
      class:"modal-sm",
      initialState:config
    };
  }

  
  afterConfirm(){
    this._authService.loggedOut();

  }


  changePassword(){
    this.editorService.changePassword(this.sharedService.currentUser.ID, this.passwordChangeModel.NewPassword).subscribe((data)=>{
      if(data==true)
      {
        this.bsModalRef = this.bsModelService.show(ConfirmDialogComponent, this.ngbModelOptions);
        //this.toastr.success("Password has been changed successfully!");
      }
      else
      {
        this.toastr.error(this.sharedService.currentCulture=='jp'? "パスワードを変更できませんでした。":"Could not change the password!"); //"Could not change the password!"
      }
    });
  }

  ngOnInit() {
  }

}
