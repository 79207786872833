import { Component, PipeTransform, Pipe, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { DataShareService } from './services/data-share.service';
import { DataSharingModel } from './models/data-sharing-model';
import { Title } from '@angular/platform-browser';
import { setTheme } from 'ngx-bootstrap/utils';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { AuthenticationService } from './services/authentication.service';
import { DomSanitizer } from "@angular/platform-browser";
import { CurrencyConverterService } from './services/currency-converter.service';
import { AppSetting } from './app-setting';
import { LoadingBarModule, LoadingBarService } from '@ngx-loading-bar/core';


@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent implements OnInit {
  title = 'EditingPro';
  sharedService: DataShareService;
  parentShareableData: DataSharingModel;
  router: string;
  config: any
  bsModalRef: BsModalRef;
  authService: AuthenticationService;
  currenctConverter: CurrencyConverterService;
  affiliateCode: string = AppSetting.AffiliateCode;
  AffiliateURL: string = AppSetting.AffiliateURL;
  IsBuildForAffiliate: boolean = AppSetting.IsBuidForAffiliate;

  constructor(private modalService: BsModalService,
    private titleService: Title, private _router: Router,
    private dataShareService: DataShareService,
    private loadingBarService: LoadingBarService,
    private _authService: AuthenticationService,
    private _currenctConverter: CurrencyConverterService) {
    setTheme('bs3');
    this.router = _router.url;
    titleService.setTitle("英文校正のエディティングプロ。専門エディターによる本格英文校正|editing-Pro.");
    this.sharedService = dataShareService;
    this.authService = _authService;
    this.currenctConverter = _currenctConverter;
    /*     this.sharedService.setCurrentCulture('jp'); */
    this.parentShareableData = new DataSharingModel();
    this.parentShareableData.currentRoute = this.router;
    this.parentShareableData.currentStateName = "Home";
    this.sharedService.setSharedData(this.parentShareableData);
    this.sharedService.OpenLoginPopup = this.openLoginWindow.bind(this)

    if (localStorage.getItem('currentUser')) {
      this.sharedService.loginStatus = true;
      this.sharedService.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    this.currenctConverter.executeCurrencyConversion(this.testCurrencyConversion.bind(this));
    //for affiliate site
    if (this.IsBuildForAffiliate) {
      this.sharedService.setAffiliateSiteInfo(this.affiliateCode, this.AffiliateURL);
    }


  };

  private testCurrencyConversion() {
    let amo: number = this.currenctConverter.fx.convert(12000.99, { from: "JPY", to: "USD" });
  }

  openLoginWindow(tabIndex: number, saveState?: boolean) {

    if (saveState) {
      localStorage.setItem("stateBeforeLogin", this._router.url)
    }

    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-sm"
    };

    this.bsModalRef = this.modalService.show(LoginModalComponent, ngbModalOptions);
    this.bsModalRef.content.closeBtnName = 'Close';
    this.dataShareService.bsModalRef = this.bsModalRef;
    if (tabIndex && tabIndex == 2) {
      setTimeout(() => {
        let ele = document.querySelector('#editorLoginTab') as HTMLAnchorElement;
        ele.click();
      }, 200);

    }

  }

  logOut() {
    this.authService.loggedOut();
  }

  ngOnInit() {
    this._router.events.subscribe((evt) => {

      if (evt instanceof NavigationStart) {
        console.log('before:', evt.url);
        if (evt.url.includes('enhttp') || evt.url.includes('jphttp')) {
          const prefix: string = localStorage.getItem("currentCulture") ? localStorage.getItem("currentCulture") : 'jp';
          if (this.sharedService.currentCulture == 'jp') {
            this.sharedService.currentURLPrefix = null;
          }
          else {
            this.sharedService.currentURLPrefix = `/${this.sharedService.currentCulture}`;
            this._router.navigateByUrl("/" + prefix).then(data => {
              this.loadingBarService.complete();
            });
          }

        }
        else if (evt.url != "/" && !evt.url.includes('/en/') && !evt.url.includes('/jp/') && !evt.url.endsWith('/jp') && !evt.url.endsWith('/en')) {
          const prefix: string = localStorage.getItem("currentCulture") ? localStorage.getItem("currentCulture") : 'jp';
          let newUrl: string = '/' + prefix + evt.url;
          if (this.sharedService.currentCulture == 'jp') {
            this.sharedService.currentURLPrefix = null;
          }
          else {
            this.sharedService.currentURLPrefix = `/${this.sharedService.currentCulture}`;
            this._router.navigateByUrl(newUrl).then(data => {
              this.loadingBarService.complete();
            });
          }

        }

      }

      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.bindPostMessageEventListener();
  }

  handleMessage(event: Event) {
    let msgEvent: MessageEvent = event as MessageEvent;
    if (msgEvent.data && msgEvent.data.code) {
      let data = msgEvent.data;
      this.sharedService.setAffiliateSiteInfo(data.code, data.url);
    }

  }


  bindPostMessageEventListener() {
    if (window.addEventListener) {
      window.addEventListener("message", this.handleMessage.bind(this), false);
    }
    else {
      (<any>window).attachEvent("onmessage", this.handleMessage.bind(this));
    }
  }



}
