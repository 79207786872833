import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { NoticeModel } from '../models/notice.model';
import { CommonListService } from '../services/common-list.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css']
})
export class NoticeComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  allNoticeList: NoticeModel[];
  commonListService: CommonListService;
  noticeList: { currentPage: number, totalItems: number, dataSource: NoticeModel[] };
  pageSize:number;

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private _commonListService: CommonListService,
    private titleService: Title,
    private meta: Meta) {
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.commonListService = _commonListService;

    this.pageSize=10;
    this.noticeList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName =this.sharedService.currentCulture== 'jp'?"お知らせ":"Notice";
    this.sharedService.setSharedData(this.shareableData);



    if(this.sharedService.currentCulture=='jp'){


      this.titleService.setTitle("サイトからのお知らせ");
      this.meta.updateTag({ name: 'keywords', content: 'お知らせ,決済,格安校正,オンライン校正,英語校正,英語,校正,専門エディター校正,英語エディター,校正会社,英文論文校正,英語論文チェック,校正チェックリスト' });
      this.meta.updateTag({ name: 'description', content: '英語校正に関するサイトのお知らせを確認してください。納品物の受領までウェブ上で完結。簡単,早い,確実！お問い合わせ,お見積りは無料。' });


    }

    else{

      this.titleService.setTitle("Notification from editing-Pro.site");
      this.meta.updateTag({ name: 'keywords', content: 'unsubscrive,editing-Pro.,editor, registration,quality,resonable price editing,editing,English editing,English, register,professional editing,Englishediting-Pro.,editingcompany,new academic writing editing, Englishacademic writingcheck' });
      this.meta.updateTag({ name: 'description', content: 'Check notification for English editing site. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });

    }
  }

  onPageIndexChange(event: PageChangedEvent) {
    this.noticeList.currentPage = event.page;
    this.setPaginatedData(this.pageSize);
  }

  private setPaginatedData(pageSize: number = 5) {
    let data: Array<NoticeModel> = new Array<NoticeModel>();
    data = this.allNoticeList;
    if (data && data.length > 0) {
      this.noticeList.dataSource = data.slice((this.noticeList.currentPage - 1) * pageSize, this.noticeList.currentPage * pageSize);
    }
  }


  private getAllNotice() {
    this.commonListService.getNoticeList().subscribe((data) => {
      if (data) {
        
        this.noticeList.currentPage = 1;
        this.allNoticeList = data;
        this.setPaginatedData(this.pageSize);
        this.noticeList.totalItems = this.allNoticeList.length;
      }
    });
  }

  ngOnInit() {
    this.getAllNotice();
  }

}
