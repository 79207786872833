import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalibrationSampleComponent } from './calibration-sample/calibration-sample.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { UserGuideComponent } from './user-guide/user-guide.component';
import { ServiceTermsComponent } from './service-terms/service-terms.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CustomerRegistrationComponent } from './customer-registration/customer-registration.component';
import { EditorRegistrationComponent } from './editor-registration/editor-registration.component';
import { EditorEvaluationListComponent } from './editor-evaluation-list/editor-evaluation-list.component';
import { CustomerRegistrationConfirmComponent } from './customer-registration-confirm/customer-registration-confirm.component';
import { InqueryComponent } from './inquery/inquery.component';
import { EditorRegistrationConfirmComponent } from './editor-registration-confirm/editor-registration-confirm.component';
import { FreeEditingEstimationComponent } from './free-editing-estimation/free-editing-estimation.component';
import { PasswordReissueComponent } from './password-reissue/password-reissue.component';
import { ResetCustomerPasswordComponent } from './reset-customer-password/reset-customer-password.component';
import { NoticeComponent } from './notice/notice.component';
import { QuestionAnswerComponent } from './question-answer/question-answer.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { QualityComponent } from './quality/quality.component';
import { TipsForOrderComponent } from './tips-for-order/tips-for-order.component';
import { DiscountCampaignComponent } from './discount-campaign/discount-campaign.component';
import { OrderDetailsCommonComponent } from './order-details-common/order-details-common.component';
import { ResetEditorPasswordComponent } from './reset-editor-password/reset-editor-password.component';
import { CoordinateIntroductionComponent } from './coordinate-introduction/coordinate-introduction.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { EditingServiceComponent } from './editing-service/editing-service.component';
import {EditorSearchComponent} from './editor-search/editor-search.component';
import { EditorProfileCommonComponent } from './editor-profile-common/editor-profile-common.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CommercialTransactionsLawComponent } from './commercial-transactions-law/commercial-transactions-law.component';
import { SiteMapComponent } from './site-map/site-map.component';
import {ErrorReportComponent} from './error-report/error-report.component';
import { EstimateServiceComponent } from './estimate-service/estimate-service.component';
import { ServiceSelectionGuard } from './guards/service-selection-guard.service';
import {AppointedEstimationComponent} from './appointed-estimation/appointed-estimation.component';
import {EstimationConfirmationComponent} from './estimation-confirmation/estimation-confirmation.component';
import { CustomerEvaluationComponent } from './customer-evaluation/customer-evaluation.component';
import {PriceComponent} from './price/price.component';
import {EditorInterviewListComponent} from './editor-interview-list/editor-interview-list.component';
import {EditorInterviewDetailsComponent} from './editor-interview-details/editor-interview-details.component';
import { InvoiceTemplateComponent } from './invoice-template/invoice-template.component';
import { InvoiceComponent } from './PDFTemplates/invoice/invoice.component';
import { DeveloperTestingComponent } from './developer-testing/developer-testing.component';
import { ProfilePhotoGuideComponent } from './profile-photo-guide/profile-photo-guide.component';

const prefix:string =localStorage.getItem("currentCulture") ? localStorage.getItem("currentCulture") : 'jp';

const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'en', component: LandingPageComponent },
  { path: 'jp', component: LandingPageComponent },
  { path: 'jp/', component: LandingPageComponent },
  { path: 'en/',  component: LandingPageComponent  },
  { path: 'sample', component: CalibrationSampleComponent },
  { path: 'en/sample', component: CalibrationSampleComponent },
  { path: 'jp/sample', component: CalibrationSampleComponent },
  { path: 'user-guide', component: UserGuideComponent },
  { path: 'en/user-guide', component: UserGuideComponent },
  { path: 'jp/user-guide', component: UserGuideComponent },
  { path: 'terms-service', component: ServiceTermsComponent },
  { path: 'en/terms-service', component: ServiceTermsComponent },
  { path: 'jp/terms-service', component: ServiceTermsComponent },
  { path: 'customer/registration', component: CustomerRegistrationComponent }, 
  { path: 'en/customer/registration', component: CustomerRegistrationComponent },
  { path: 'jp/customer/registration', component: CustomerRegistrationComponent },
  { path: 'editor/registration', component: EditorRegistrationComponent },
  { path: 'en/editor/registration', component: EditorRegistrationComponent },
  { path: 'jp/editor/registration', component: EditorRegistrationComponent },
  { path: 'editor/registration/:refId', component: EditorRegistrationComponent },
  { path: 'en/editor/registration/:refId', component: EditorRegistrationComponent },
  { path: 'jp/editor/registration/:refId', component: EditorRegistrationComponent },
/*   { path: 'customer-evaluation', component: EditorEvaluationListComponent }, */
  { path: 'customer/registration-confirm', component: CustomerRegistrationConfirmComponent },
  { path: 'en/customer/registration-confirm', component: CustomerRegistrationConfirmComponent },
  { path: 'jp/customer/registration-confirm', component: CustomerRegistrationConfirmComponent },
  { path: 'editor/registration-confirm', component: EditorRegistrationConfirmComponent },
  { path: 'en/editor/registration-confirm', component: EditorRegistrationConfirmComponent },
  { path: 'jp/editor/registration-confirm', component: EditorRegistrationConfirmComponent },
  { path: 'inquery', component: InqueryComponent },
  { path: 'en/inquery', component: InqueryComponent },
  { path: 'jp/inquery', component: InqueryComponent },
  { path: 'online', component: FreeEditingEstimationComponent },
  { path: 'jp/online', component: FreeEditingEstimationComponent },
  { path: 'en/online', component: FreeEditingEstimationComponent },
  { path: 'password-reissue', component: PasswordReissueComponent },
  { path: 'en/password-reissue', component: PasswordReissueComponent },
  { path: 'jp/password-reissue', component: PasswordReissueComponent },
  { path: 'notice', component: NoticeComponent },
  { path: 'en/notice', component: NoticeComponent },
  { path: 'jp/notice', component: NoticeComponent },
  { path: 'qa', component: QuestionAnswerComponent },
  { path: 'en/qa', component: QuestionAnswerComponent },
  { path: 'jp/qa', component: QuestionAnswerComponent },
  { path: 'company', component: CompanyProfileComponent },
  { path: 'en/company', component: CompanyProfileComponent },
  { path: 'jp/company', component: CompanyProfileComponent },
  { path: 'quality', component: QualityComponent },
  { path: 'en/quality', component: QualityComponent },
  { path: 'jp/quality', component: QualityComponent },
  { path: 'project/:orderNumber', component: OrderDetailsCommonComponent },
  { path: 'en/project/:orderNumber', component: OrderDetailsCommonComponent },
  { path: 'jp/project/:orderNumber', component: OrderDetailsCommonComponent },
  { path: 'tips-for-order', component: TipsForOrderComponent },
  { path: 'en/tips-for-order', component: TipsForOrderComponent },
  { path: 'jp/tips-for-order', component: TipsForOrderComponent },
  { path: 'campaign', component: DiscountCampaignComponent },
  { path: 'en/campaign', component: DiscountCampaignComponent },
  { path: 'jp/campaign', component: DiscountCampaignComponent },
  { path: 'coordinator', component: CoordinateIntroductionComponent },
  { path: 'en/coordinator', component: CoordinateIntroductionComponent },
  { path: 'jp/coordinator', component: CoordinateIntroductionComponent },
  { path: 'payment', component: PaymentMethodComponent },
  { path: 'en/payment', component: PaymentMethodComponent },
  { path: 'jp/payment', component: PaymentMethodComponent },
  { path: 'editing-service', component: EditingServiceComponent },
  { path: 'en/editing-service', component: EditingServiceComponent },
  { path: 'jp/editing-service', component: EditingServiceComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'en/privacy-policy', component: PrivacyPolicyComponent },
  { path: 'jp/privacy-policy', component: PrivacyPolicyComponent },
  { path: 'commercial-transaction-act', component: CommercialTransactionsLawComponent },
  { path: 'en/commercial-transaction-act', component: CommercialTransactionsLawComponent },
  { path: 'jp/commercial-transaction-act', component: CommercialTransactionsLawComponent },
  { path: 'editors', component: EditorSearchComponent },
  { path: 'en/editors', component: EditorSearchComponent },
  { path: 'jp/editors', component: EditorSearchComponent },
  { path: 'editor/:id', component: EditorProfileCommonComponent },
  { path: 'en/editor/:id', component: EditorProfileCommonComponent },
  { path: 'jp/editor/:id', component: EditorProfileCommonComponent },
  { path: 'customer-password-reset/:passCode/:regId', component: ResetCustomerPasswordComponent },
  { path: 'en/customer-password-reset/:passCode/:regId', component: ResetCustomerPasswordComponent },
  { path: 'jp/customer-password-reset/:passCode/:regId', component: ResetCustomerPasswordComponent },
  { path: 'editor-password-reset/:passCode/:regId', component: ResetEditorPasswordComponent },
  { path: 'en/editor-password-reset/:passCode/:regId', component: ResetEditorPasswordComponent },
  { path: 'jp/editor-password-reset/:passCode/:regId', component: ResetEditorPasswordComponent },
  { path: 'sitemap', component: SiteMapComponent },
  { path: 'en/sitemap', component: SiteMapComponent },
  { path: 'jp/sitemap', component: SiteMapComponent },
  { path: 'picture',component:ProfilePhotoGuideComponent},
  { path: 'en/picture',component:ProfilePhotoGuideComponent},
  { path: 'jp/picture',component:ProfilePhotoGuideComponent},
  { path: 'customer-evaluation', component: CustomerEvaluationComponent },
  { path: 'en/customer-evaluation', component: CustomerEvaluationComponent },
  { path: 'jp/customer-evaluation', component: CustomerEvaluationComponent },
  { path: 'error-report', component: ErrorReportComponent },
  { path: 'en/error-report', component: ErrorReportComponent },
  { path: 'jp/error-report', component: ErrorReportComponent },
  { path: 'price', component:PriceComponent},
  { path: 'en/price', component:PriceComponent},
  { path: 'jp/price', component:PriceComponent},
  { path: 'estimation', component: EstimateServiceComponent,canActivate:[ServiceSelectionGuard] },
  { path: 'en/estimation', component: EstimateServiceComponent,canActivate:[ServiceSelectionGuard] },
  { path: 'jp/estimation', component: EstimateServiceComponent,canActivate:[ServiceSelectionGuard] },
 /*  { path: 'estimation-result', component: EstimationConfirmationComponent,canActivate:[ServiceSelectionGuard] }, */
  { path: 'appointed',component:AppointedEstimationComponent},
  { path: 'en/appointed',component:AppointedEstimationComponent},
  { path: 'jp/appointed',component:AppointedEstimationComponent},
  // { path: 'editor-interview',component:EditorInterviewListComponent},
  { path: 'test',component:DeveloperTestingComponent},
  { path: 'invoice-temp',component:InvoiceComponent},
  { path: 'en/invoice-temp',component:InvoiceComponent},
  { path: 'jp/invoice-temp',component:InvoiceComponent},
  // { path: 'editor-interview/:editorNo',component:EditorInterviewDetailsComponent},
  { path: '404', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
