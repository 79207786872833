export class NoticeModel {
    ID: string
    NoticeNo: number
    RegisteredDate: Date
    Priority: number
    PriorityName: string
    NoticeURL: string
    ClientDisplayStatus: number
    ClientDisplayStatusName: string
    StaffDisplayStatus: number
    StaffDisplayStatusName: string
    PartnerDisplayStatus: number
    PartnerDisplayStatusName: string
    Title: string
    Title_en: string
    Title_jp: string
    Title_kr: string
    Title_cn: string
    Title_fr: string
    Title_tl: string
    Description: string
    Description_jp:string 
    RegisteredDateJP: string 
    TotalCount:number 
}
