export class StaffEducationModel {
    StaffEducationID: string
    StaffID: string
    StaffEducationDegreeID: string
    MajorSubjectID: string
    MajorSubjectDetailsID: string
    IssuedCountryID: string
    StartDate: Date
    EndDate: Date
    IsContinuing: boolean
    InstituteName: string
    InstituteName_en: string
    InstituteName_jp: string
    InstituteName_kr: string
    InstituteName_cn: string
    InstituteName_fr: string
    InstituteName_tl: string
    Comment: string
    Comment_en: string
    Comment_jp: string
    Comment_kr: string
    Comment_cn: string
    Comment_fr: string
    Comment_tl: string
    IsMarkedForDelete: boolean
    Department: string
    Department_en: string
    Department_jp: string
}
