import { Injectable } from '@angular/core';
import { AppSetting } from '../app-setting';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OrderWeb } from '../models/order-web.model';
import { CompanySearchModel } from '../models/company-search.model';
import { NoticeModel } from '../models/notice.model';
import { AffiliateInvoiceModel } from '../models/affiliate-invoice.model';
import { MonthlySalesModel } from '../models/monthly-sales-model';
import { OrderEvaluationModel } from '../models/order-evaluation-model.model';
import { CompanyPartenerInfoModel } from '../models/company-partener-info.model';
import { AffiliateCompanyBankInfoModel } from '../models/affiliate-company-bank-info.model';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService {
  ROOT_URL:string=AppSetting.API_ENDPOINT; 
  EDITINGPRO_ROOT_URL:string=AppSetting.EDITINGPRO_API_ENDPOINT;

  constructor(private http:HttpClient) { }

  getCustomerSalesBreakdown(PartnerId:string,Month:number,Year:number,OpType:number,OrderNo:string="",StartDate:Date=null,EndDate:Date=null,ClientName:string="",PageSize:number=10,PageNo:number=1,culture:string='jp',applicationId:number=3)
  {
    const  params = new  HttpParams().set('PartnerId', PartnerId).set('Month',Month.toString()).set('Year',Year.toString()).set('OpType', OpType.toString())
    .set('OrderNo', OrderNo).set('StartDate',StartDate? StartDate.toDateString() : null).set('EndDate',EndDate? EndDate.toDateString() : null).set('ClientName',ClientName)
    .set('PageSize', PageSize.toString()).set('PageNo', PageNo.toString())
    .set('culture', culture).set('applicationId', applicationId.toString());
    return this.http.get<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL+'affiliate/GetCustomerSalesBreakdown',{params});
  }

  getEditorSalesBreakdown(PartnerId:string,Month:number,Year:number,OpType:number,OrderNo:string="",StartDate:Date,EndDate:Date,StaffNo:number=null,PageSize:number=10,PageNo:number=1,culture:string='jp',applicationId:number=3)
  {
    const  params = new  HttpParams().set('PartnerId', PartnerId).set('Month', Month.toString()).set('Year',Year.toString()).set('OpType', OpType.toString()).set('OrderNo',OrderNo).set('StartDate',StartDate?StartDate.toDateString() : null).set('EndDate',EndDate?EndDate.toDateString() : null).set('StaffNo',StaffNo?StaffNo.toString() : null).set('PageSize', PageSize.toString()).set('PageNo', PageNo.toString()).set('culture', culture).set('applicationId', applicationId.toString());
    return this.http.get<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL+'affiliate/GetEditorSalesBreakdown',{params});
  }

  getClientList(model: CompanySearchModel) {
    return this.http.post<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL + 'affiliate/GetPartnerClientList', model);
  }
  getEditorList(model: CompanySearchModel) {
    return this.http.post<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL + 'affiliate/GetPartnerEditorList', model);
  }

  getPostpayOrderList(PartnerId:string,PageSize:number=10,PageNo:number=1,culture:string='jp',applicationId:number=3)
  {
    const  params = new  HttpParams().set('PartnerId', PartnerId)
    .set('PageSize', PageSize.toString()).set('PageNo', PageNo.toString())
    .set('culture', culture).set('applicationId', applicationId.toString());
    return this.http.get<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL+'affiliate/GetPostpayOrderList',{params});
  }

  getAllNoticeList()
  {
    return this.http.get<Array<NoticeModel>>(this.EDITINGPRO_ROOT_URL+'affiliate/GetAllPublishedNoticeList');
  }

  getClaimList(PartnerId:string,OpType:number,OrderNo:string="",StartDate:Date=null,EndDate:Date=null,ClaimDetails:string=null,PageSize:number=10,PageNo:number=1,culture:string='jp',applicationId:number=3)
  {
    const  params = new  HttpParams().set('PartnerId', PartnerId)
    .set('OpType', OpType.toString())
    .set('OrderNo', OrderNo).set('StartDate',StartDate? StartDate.toDateString() : null).set('EndDate',EndDate? EndDate.toDateString() : null).set('ClaimDetails',ClaimDetails)
    .set('PageSize', PageSize.toString()).set('PageNo', PageNo.toString())
    .set('culture', culture).set('applicationId', applicationId.toString());
    return this.http.get<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL+'affiliate/GetClaimList',{params});
  }

  getInvoiceList(PartnerId:string,Month:number,Year:number,OpType:number,applicationId:number=3,culture:string='jp',PageSize:number=10,PageNo:number=1,CompanyNo:string="")
  {
    const  params = new  HttpParams().set('PartnerId', PartnerId).set('Month',Month.toString()).set('Year',Year.toString()).set('OpType', OpType.toString())
    .set('applicationId', applicationId.toString()).set('culture', culture)
    .set('PageSize', PageSize.toString()).set('PageNo', PageNo.toString()).set('CompanyNo', CompanyNo?CompanyNo:"");

    return this.http.get<Array<AffiliateInvoiceModel>>(this.EDITINGPRO_ROOT_URL+'affiliate/GetInvoiceList',{params});
  }

  getAllMonthlySalesBreakdown(PartnerId:string,applicationId:number=3,OpType:number=1,PageNo:number=1,PageSize:number=10)
  {
    const  params = new  HttpParams().set('partnerId', PartnerId).set('applicationId', applicationId.toString()).set('opType', OpType.toString()).set('pageSize', PageSize.toString()).set('pageNo', PageNo.toString());
    return this.http.get<Array<MonthlySalesModel>>(this.EDITINGPRO_ROOT_URL+'affiliate/GetAllMonthlySales',{params});
  }

  getAffiliateEvaluationList(PartnerId:string,applicationId:number=3,culture:string="jp",PageNo:number=1,PageSize:number=10)
  {
    const  params = new  HttpParams().set('partnerId', PartnerId).set('applicationId', applicationId.toString()).set('culture', culture).set('pageSize', PageSize.toString()).set('pageNo', PageNo.toString());
    return this.http.get<Array<OrderEvaluationModel>>(this.EDITINGPRO_ROOT_URL+'affiliate/GetAffiliateEvaluationList',{params});
  }

  getPartnerCompanyInfo(model: CompanyPartenerInfoModel) {
    return this.http.post<CompanyPartenerInfoModel>(this.ROOT_URL + 'partnercompany/getdetails', model);
  }

  getAffiliateCompanyBankInfo(partnerId:string) {
    const  params = new  HttpParams().set('partnerId', partnerId)
    return this.http.get<AffiliateCompanyBankInfoModel>(this.EDITINGPRO_ROOT_URL + 'affiliate/GetAffiliateCompanyBankInfo', {params});
  }

}
