export class EditorCertificationInfoModel {
    ID:number
    IsActive?:boolean
    IsContractedEditor?:boolean
    EditorType:string
    IsAllowAutoCertification?:boolean
    InternalScore?:number
    IsGeneralExpert?:boolean
    IsCertificateExpert?:boolean
    IsPatentExpert?:boolean
    IsLegalExpert?:boolean
    IsMedicalExpert?:boolean
    CanNotAcceptStartDate?:Date
    CanNotAcceptEndDate?:Date 
    CreatedDate:Date 
}
