import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataSharingModel } from '../models/data-sharing-model';
import { DataShareService } from '../services/data-share.service';

@Component({
  selector: 'app-profile-photo-guide',
  templateUrl: './profile-photo-guide.component.html',
  styleUrls: ['./profile-photo-guide.component.css']
})
export class ProfilePhotoGuideComponent implements OnInit {

  sharedService:DataShareService;
  shareableData:DataSharingModel;
  router: string;
  
  constructor(private titleService: Title ,
    private dataShareService:DataShareService,
    private _router: Router,
    private meta: Meta) { 

    this.router=_router.url;
    titleService.setTitle("Picture");
    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName="";
    this.dataShareService.setSharedData(this.shareableData);

    }

  ngOnInit() {
  }

}
