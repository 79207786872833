import { SoftwareSkillModel } from "./software-skill.model"
import { StaffEducationModel } from "./staff-education.model"
import { StaffWorkingStatusModel } from "./staff-working-status.model"
import { StaffProfesionalSpecialityModel } from "./staff-profesional-speciality.model"
import { StaffSoftwareSkillModel } from "./staff-software-skill.model"
import { EducationModel } from "./education.model"
import { StaffPresentStatusModel } from "./staff-present-status.model"
import { BankAccountModel } from "./bank-account.model"
import { StaffCurrentStateModel } from "./staff-current-state.model"
import { StaffEnglishTypeModel } from "./staff-english-type.model"

export class StaffModel {
        ID: string
        RegistrationID: number
        StaffEmailID: string
        RetypedStaffEmailID: string
        RetypedPassword: string
        ApplicationID: number
        StaffTypeID: number
        RegistrationDate: Date
        Password: string
        Name: string
        EditingExperienceYear: number
        NickName: string
        NickName_en: string
        NickName_jp: string
        NickName_kr: string
        NickName_cn: string
        NickName_fr: string
        NickName_tl: string
        Surname: string
        FirstName: string
        FirstName_en: string
        FirstName_jp: string
        FirstName_kr: string
        FirstName_cn: string
        FirstName_fr: string
        FirstName_tl: string
        MiddleName: string
        MiddleName_en: string
        MiddleName_jp: string
        MiddleName_kr: string
        MiddleName_cn: string
        MiddleName_fr: string
        MiddleName_tl: string
        LastName: string
        LastName_en: string
        LastName_jp: string
        LastName_kr: string
        LastName_cn: string
        LastName_fr: string
        LastName_tl: string
        StaffNo: string
        Sex: boolean
        BirthDate: Date
        PostalCode: string
        IsJapanese: boolean
        LivingCountryID: number
        CountryPrefectureID: string
        NativeLanguageID: string
        NativeLanguageName: string
        ForiegnLanguage1ID: string
        SecondLanguageName: string
        SecondLanguageLvlName: string
        ThirdLanguageLvlName: string
        ThirdLanguageName: string
        ForiegnLanguage2ID: string
        ForiegnLanguage3ID: string
        ForiegnLanguage4ID: string
        ForeignLang1Level: number
        ForeignLang2Level: number
        ForeignLang3Level: number
        ForeignLang4Level: number
        Address: string
        Address_en: string
        Address_jp: string
        Address_kr: string
        Address_cn: string
        Address_fr: string
        Address_tl: string
        MainCareer_jp: string
        TelephoneNo: string
        MobileNo: string
        SelfPR: string
        SelfPR_en: string
        SelfPR_jp: string
        SelfPR_kr: string
        SelfPR_cn: string
        SelfPR_fr: string
        SelfPR_tl: string
        Education1: StaffEducationModel
        Education2: StaffEducationModel
        Education3: StaffEducationModel
        EducationalDegree1: string
        EducationalDegree2: string
        EducationalDegree3: string
        CurrentEmailAddress1: string
        ImageURL: string
        NationalityID: number
        Staffbank: BankAccountModel
        staffsoft: Array<StaffSoftwareSkillModel>
        Educations: Array<StaffEducationModel>
        StaffPresentStatus: Array<StaffPresentStatusModel>
        StaffProfessional: Array<StaffProfesionalSpecialityModel>
        StaffCurrentState: Array<StaffCurrentStateModel>
        TotalPage: number
        IntroducedBy:string 
        IsNarrator: boolean
        IsEditor: boolean
        IsContractedEditor: boolean
        Surname_Global:string
        StaffEnglishTypeList: Array<StaffEnglishTypeModel>
        CanStaffUseJapanese:boolean
        CultureID:string
        AffiliateCode:string 
}
