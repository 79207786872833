import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompanyMyPageComponent } from './company-my-page/company-my-page.component';
import { CompanyDashboardComponent } from './company-dashboard/company-dashboard.component';
import { SalesCComponent } from './sales-c/sales-c.component';
import { MonthlyComponent } from './monthly/monthly.component';
import { SalesSComponent } from './sales-s/sales-s.component';
import { ListCComponent } from './list-c/list-c.component';
import { ListSComponent } from './list-s/list-s.component';
import { ListIComponent } from './list-i/list-i.component';
import { ClaimsComponent } from './claims/claims.component';
import { EvaluationComponent } from './evaluation/evaluation.component';
import { NoticeComponent } from './notice/notice.component';
import { AuthGuard } from '../guards/auth-guard.service';
import { RoleGuardService as RoleGuard } from '../guards/role-guard.service';

const prefix:string =localStorage.getItem("currentCulture") ? localStorage.getItem("currentCulture") : 'jp';

const routes: Routes = [
  {
    path: "partner", component: CompanyDashboardComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      UserType: '3'
    },
    children: [
      { path: 'my-page', component: CompanyMyPageComponent, pathMatch: "full" },
      { path: 'sales-c', component: SalesCComponent },
      { path: 'monthly', component: MonthlyComponent },
      { path: 'sales-s', component: SalesSComponent },
      { path: 'list-c', component: ListCComponent },
      { path: 'list-s', component: ListSComponent },
      { path: 'list-i', component: ListIComponent },
      { path: 'claims', component: ClaimsComponent },
      { path: 'evaluation', component: EvaluationComponent },
      { path: 'notice', component: NoticeComponent }
    ]
  },
  {
    path: "en/partner", component: CompanyDashboardComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      UserType: '3'
    },
    children: [
      { path: 'my-page', component: CompanyMyPageComponent, pathMatch: "full" },
      { path: 'sales-c', component: SalesCComponent },
      { path: 'monthly', component: MonthlyComponent },
      { path: 'sales-s', component: SalesSComponent },
      { path: 'list-c', component: ListCComponent },
      { path: 'list-s', component: ListSComponent },
      { path: 'list-i', component: ListIComponent },
      { path: 'claims', component: ClaimsComponent },
      { path: 'evaluation', component: EvaluationComponent },
      { path: 'notice', component: NoticeComponent }
    ]
  },
  {
    path: "jp/partner", component: CompanyDashboardComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      UserType: '3'
    },
    children: [
      { path: 'my-page', component: CompanyMyPageComponent, pathMatch: "full" },
      { path: 'sales-c', component: SalesCComponent },
      { path: 'monthly', component: MonthlyComponent },
      { path: 'sales-s', component: SalesSComponent },
      { path: 'list-c', component: ListCComponent },
      { path: 'list-s', component: ListSComponent },
      { path: 'list-i', component: ListIComponent },
      { path: 'claims', component: ClaimsComponent },
      { path: 'evaluation', component: EvaluationComponent },
      { path: 'notice', component: NoticeComponent }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AffiliateRoutingModule { }
