import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Title, Meta } from '@angular/platform-browser';
import { MessageModel } from '../../models/message-model.model';
import { CustomerService } from '../../services/customer-service.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import * as _ from 'underscore';
import { OrderWeb } from '../../models/order-web.model';
import { EstimationService } from '../../services/estimation.service';
import { EditingPreferenceModel } from '../../models/editing-pro-preference-model.model';
import { CommonListService } from '../../services/common-list.service';
import { UtilityService } from '../../services/utility.service';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { EditingDeliveryComponent } from './editing-delivery/editing-delivery.component';
import { ToastrService } from 'ngx-toastr';
import { OrderOperationType, NotificationType, EnglishLanguageType } from '../../Enums.enum';
import { DocumentModel } from '../../models/document.model';
import { confirmDialogConfig, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { StaffEnglishTypeModel } from '../../models/staff-english-type.model';
import { MiscellaneousModel } from '../../models/miscellaneous-model.model';
import { EditorService } from '../../services/editor.service';
import { EditorMindsetComponent } from '../editor-mindset/editor-mindset.component';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  customerService: CustomerService;
  editorService: EditorService;
  shareableData: DataSharingModel;
  orderInfo: { OrderID: string, ReceiverID: string, ReceiverName: string, WebOrderNo: string, ParentId: number, ReplyDate: Date };
  messageList: { currentPage: number, totalItems: number, dataSource: MessageModel[] };
  orderDetails: OrderWeb;
  estimationService: EstimationService;
  orderNo: string;
  commonService: CommonListService;
  isEditorCanAcceptEnglishEditingOrderPenaltyOver: boolean;
  canAcceptOrderAs2ndEditor: boolean;
  isDownloadButtonEnable: boolean;
  isDeliveryButtonEnable: boolean;
  submittedDeliveryfiles: Array<DocumentModel>;
  canAcceptExpertOrder: boolean;
  disableAcceptButton: boolean;
  isAppointedOrder: boolean;
  disableRejectButton: boolean;
  isAlreadyRejected: boolean;
  alertMessageText: string;
  orderCount:number;


  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private route: ActivatedRoute,
    private _customerService: CustomerService,
    private _estimationService: EstimationService,
    private _commonService: CommonListService,
    private _editorService: EditorService,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private utilityService: UtilityService,
    private toastr: ToastrService,
    private titleService: Title,
    private meta: Meta) {

    this.router = _router.url;
    this.sharedService = dataShareService;
    this.estimationService = _estimationService;
    this.commonService = _commonService;
    this.editorService = _editorService;
    this.orderInfo = { OrderID: null, ReceiverID: null, ReceiverName: null, WebOrderNo: null, ParentId: 0, ReplyDate: null };
    this.messageList = { currentPage: 1, totalItems: null, dataSource: null };
    this.orderDetails = new OrderWeb();
    this.isDownloadButtonEnable = false;
    this.isAppointedOrder = false;
    this.disableAcceptButton = false;
    this.disableRejectButton = false;
    this.isEditorCanAcceptEnglishEditingOrderPenaltyOver = false;
    this.canAcceptOrderAs2ndEditor = false;
    this.isDeliveryButtonEnable = false;
    this.isAlreadyRejected = false;
    this.orderDetails.EditingPreference = new EditingPreferenceModel();
    this.submittedDeliveryfiles = new Array<DocumentModel>();
    this.canAcceptExpertOrder = false;
    this.alertMessageText = this.sharedService.currentCulture == 'jp' ? "お客様のご希望は{{EnglishType}}です。対応できる案件なのかをもう一度ご確認の上受注してください。" : "This Client prefers the {{EnglishType}} Language. Please accept this job if you have a better understanding of the said language. ";

    this.route.params.subscribe(params => {
      if (params['orderNo']) {
        let orderNo: string = params['orderNo'];
        this.orderNo = orderNo;
      }
    });

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = this.sharedService.currentCulture == 'jp' ? "案件詳細" : "Order details";
    this.sharedService.setSharedData(this.shareableData);


    if(this.sharedService.currentCulture=='jp'){
      this.titleService.setTitle(`${this.orderNo} 案件詳細を確認（英文エディター用）`);
      this.meta.updateTag({ name: 'keywords', content: '案件リスト確認,英文校正,英語エディティング,注文,内訳,リスト,英語,チェック' });
      this.meta.updateTag({ name: 'description', content: '受注している案件の詳細をご確認ください。24時間365日,オンライン校正サービス。すべての過程がウェブ上で完結。簡単,早い,確実！' });

    }
    else{
      this.titleService.setTitle(`${this.orderNo} Order detail confirmation（For English editors））`);
      this.meta.updateTag({ name: 'keywords', content: 'project list confirm,editing-Pro.,English editing,order,detail,list,English,check' });
      this.meta.updateTag({ name: 'description', content: 'please check the job detail. 24hours service for 365 days. Online editing service that allows order to submission through online. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });

    }
  }

  config: confirmDialogConfig = {
    MessageText: "これは指定案件です、別の案件を受注してみてください。",
    IsYesNo: true,
    YesBtnCallback: this.YesClicked.bind(this)
  };
  withdrawalModalOptions: ModalOptions = {
    backdrop: 'static',
    keyboard: false,
    class: "modal-sm",
    initialState: this.config
  };

  YesClicked() {
    this._router.navigateByUrl("/project/" + this.orderNo);
  }

  public enableDisableAcceptButton() {
    if (this.orderDetails.AssignedTranslatorID == this.sharedService.currentUser.ID || this.orderDetails.Assigned2ndEditorID == this.sharedService.currentUser.ID) {
      this.disableAcceptButton = true;
      return;
    }
    if (!this.orderDetails.EditingPreference.IsDoubleCheck) {
      if (this.orderDetails.OrderStatus != 4) {
        this.disableAcceptButton = true;
        return;
      }
    }
    else {
      if (this.sharedService.currentUser.EditorType == '1st') {
        if (this.orderDetails.OrderStatus != 14 || this.orderDetails.DeliveryLevelName == 'Light') {
          this.disableAcceptButton = true;
          return;
        }
      }
      else {
        if (this.orderDetails.OrderStatus != 17 && (this.orderDetails.DeliveryLevelName != 'Light' && (this.orderDetails.OrderStatus == 14 || this.orderDetails.OrderStatus == 4))) {
          this.disableAcceptButton = true;
          return;
        }
      }
    }
    this.disableAcceptButton = false;
    return;
  }

  private isDownloadEnable() {
    if (this.orderDetails && (this.orderDetails.AssignedTranslatorID || this.orderDetails.Assigned2ndEditorID)) {
      if (this.sharedService.currentUser && (this.orderDetails.AssignedTranslatorID == this.sharedService.currentUser.ID ||
        this.orderDetails.Assigned2ndEditorID == this.sharedService.currentUser.ID)) {
        this.isDownloadButtonEnable = true;
      }
      else {
        this.isDownloadButtonEnable = false;
      }
    }
    //2nd editor also can download EP200703-LIM11
  }

  calculateHalfDateTime(start: Date, end: Date): Date {
    return this.utilityService.getHalfDateTimeOfOrder(start, end);
  }

  manipulateFileSize(fileSizeInBytes: number) {
    return this.utilityService.formatBytes(fileSizeInBytes);
  }


  private manipulateMessageList(list: MessageModel[]) {
    let parentList = _.filter(list, function (item) {
      return item.ParentId == 0;
    });

    let allChildren = Array<MessageModel>();

    _.each(parentList, function (item) {
      //keep an orginal copy before modify
      //  allParent.push(Object.assign({},item)); 
      item.IsHideChildren = true;
      item.IsParent = true;
      item.Children = _.filter(list, function (subItem) {
        let predicate = (subItem.WebOrderNo == item.WebOrderNo && subItem.ParentId > 0) ? true : false;
        if (predicate == true)
          allChildren.push(subItem);
        return predicate;
      });
    });

    //finding out orphan  not in parent nor in children
    let allParentId = _.pluck(parentList, 'ID');
    let allChildsId = _.pluck(allChildren, 'ID');
    let orphans = Array<MessageModel>();
    orphans = _.filter(list, function (item) {
      return ((!_.contains(allParentId, item.ID) && !_.contains(allChildsId, item.ID)));
    });

    _.each(orphans.reverse(), function (item) {
      item.IsOrphan = true;
      parentList.unshift(item);
    });
    return parentList;
  }

  getMessageList(pageno: number = 1) {
    this.estimationService.getAllMessagesByOrderId(this.orderNo).subscribe((data) => {
      if (data) {
        this.messageList.currentPage = 1;
        this.messageList.dataSource = this.manipulateMessageList(data);
        if (data.length > 0)
          this.messageList.totalItems = data[0].TotalRows;
      }
    });
  }

  onPageIndexChange(event: PageChangedEvent) {
    this.customerService.getAllMessages(this.sharedService.currentUser.ID, event.page).subscribe((data) => {
      if (data) {
        this.messageList.currentPage = event.page;
        this.messageList.dataSource = this.manipulateMessageList(data);
        if (data.length > 0)
          this.messageList.totalItems = data[0].TotalRows;
      }
    });
  }

  getOrderDetails(orderNo: string, editorId: string) {
    this.estimationService.GetOrderDetailsForEditorByOrderNo(orderNo, editorId, this.sharedService.currentCulture).subscribe((data) => {
      if (data) {
        this.orderDetails = data;
        if (data.OrderNo) {
          this.getMessageList();
        }
        this.orderDetails.CurrentCulture = this.sharedService.currentCulture;
        //for appointed orderDetails
        if (this.orderDetails.AppointedToStaffList && this.orderDetails.AppointedToStaffList.length > 0) {
          this.isAppointedOrder = true;
          let appointedEditor = this.orderDetails.AppointedToStaffList.find(e => e.TranslatorID == editorId);
          if (appointedEditor && appointedEditor.IsRejected == true) //appointed editor rejected it previously
          {
            this.disableRejectButton = true;
            this.isAlreadyRejected = true;
          }

          if (!appointedEditor || !data.OrderNo) {
            if (this.sharedService.currentUser.EditorType == '1st') {
              this._router.navigateByUrl("/project/" + orderNo);
              return;
            }
          }
        }

        this.estimationService.canAcceptExpertEditingOrder(this.orderDetails.TranslationFieldID, editorId).subscribe((data) => {
          if (this.sharedService.currentUser.EditorType == '2nd') {
            this.canAcceptExpertOrder = true; //ChangeEP26
          }
          else {
            this.canAcceptExpertOrder = data;
          }

        });

        this.getSubmittedDeliveryDocuments();

        if (this.sharedService.currentUser.EditorType == '2nd') {
          if (this.orderDetails.SubmissionDate1stEditor && this.orderDetails.Assigned2ndEditorID == this.sharedService.currentUser.ID)
            this.isDeliveryButtonEnable = true;
          else
            this.isDeliveryButtonEnable = false;

          if (this.orderDetails.AssignedTranslatorID == this.sharedService.currentUser.ID) //for light 1st editor
          {
            this.isDeliveryButtonEnable = true;
          }
        }
        else {
          if (this.orderDetails.OrderStatus == 5 || this.orderDetails.OrderStatus > 14) {
            if (this.orderDetails.AssignedTranslatorID == this.sharedService.currentUser.ID)
              this.isDeliveryButtonEnable = true;
            else
              this.isDeliveryButtonEnable = false;
          }
        }

        if (!data.OrderNo) {
          this._router.navigateByUrl("/project/" + orderNo);
        }

        this.orderInfo.ReceiverID = this.orderDetails.ClientID;
        this.canAcceptOrderAs2ndEditor = data.AssignedTranslatorID ? true : false; // after the 1st editor is Assigned

        if (this.canAcceptOrderAs2ndEditor && data.OrderStatus == 17) //17 means 2nd editor recruiting
        {
          this.canAcceptOrderAs2ndEditor = true;
        }
        else {
          this.canAcceptOrderAs2ndEditor = false;
        }

        this.getMessageReceiverName();
        this.isDownloadEnable();
        this.enableDisableAcceptButton();

        if (this.orderDetails.EditingPreference && this.orderDetails.EditingPreference.ReferenceFilePath) {
          this.orderDetails.EditingPreference.UploadedFileOrginalName = this.utilityService.getFileNameFromPath(this.orderDetails.EditingPreference.ReferenceFilePath);
          this.orderDetails.EditingPreference.ReferenceFile = data.WebDocumentList.find(item => item.UploadType == "REFERENCES");
          this.orderDetails.WebDocumentList = data.WebDocumentList.filter(item => item.UploadType != "REFERENCES");
        }

        if (this.orderDetails.WebDocumentList && this.orderDetails.WebDocumentList.length > 0 && this.orderDetails.WebDocumentList[0].TranslationText) {

          this.orderDetails.WebDocumentList[0].TranslationText = this.orderDetails.WebDocumentList[0].TranslationText.split(' ').slice(0, 20).join(' ');
        }

        if (this.orderDetails.MenuScript && this.orderDetails.MenuScript.length > 0) {
          this.orderDetails.MenuScript = this.orderDetails.MenuScript.split(" ").splice(0, 50).join(" ");
          if (this.orderDetails.MenuScript.length > 50)
            this.orderDetails.MenuScript = this.orderDetails.MenuScript + ' ...';
        }

        if (!this.orderDetails.EditingPreference) {
          this.orderDetails.EditingPreference = new EditingPreferenceModel();
        }
      }
    })
  }

  checkIsEditorCanAcceptEnglishEditingOrder() {
    this.estimationService.IsEditorCanAcceptEnglishEditingOrder(this.sharedService.currentUser.ID).subscribe(data => {
      this.isEditorCanAcceptEnglishEditingOrderPenaltyOver = data;
    });
  }

  executeReject() {
    let editorId: string = this.sharedService.currentUser.ID;
    this.orderDetails.OrderRejectedBy = editorId;
    this.estimationService.executeOrderOperation(OrderOperationType.REJECT, this.orderDetails).subscribe(data => {
      if (data && data.ID) {
        this.toastr.success("Rejected successfully!");
        this.getOrderDetails(this.orderNo, editorId);
      }
    })
  }

  alert2ndEditorConfig: confirmDialogConfig = {
    MessageText: `このファイルはクライアント様からの原本ファイルです。
    校正は1stエディターからもらったファイルで作業してください。
    1stエディターの作業物は「納品原稿」からダウンロードできます。`,
    IsOkOnly: true
  };
  alert2nsEditorModalOptions: ModalOptions = {
    backdrop: 'static',
    keyboard: false,
    class: "modal-sm",
    initialState: this.alert2ndEditorConfig
  };

  private showAlertMessageTo2ndEditor() {
    let message: string = this.sharedService.currentCulture == 'jp' ? `このファイルはクライアント様からの原本ファイルです。
    校正は1stエディターからもらったファイルで作業してください。
    1stエディターの作業物は「納品原稿」からダウンロードできます。` : `This file is a original file. not 1st editor's file.
    Please note this is not an editing target.
    You can download 1st editor's submit file(editing target) from "Manuscript".`;

    this.alert2ndEditorConfig.MessageText = message;
    this.bsModalRef = this.modalService.show(ConfirmDialogComponent, this.alert2nsEditorModalOptions);
  }

  downloadFile(filePath: string, isAbsolutePath = false, isSubmittedFile = false) {
    if (this.sharedService.currentUser && this.sharedService.currentUser.ID == this.orderDetails.Assigned2ndEditorID) {
      this.showAlertMessageTo2ndEditor();
    }

    if (isAbsolutePath) {
      filePath = this.utilityService.getRelativePathFromFileURL(filePath);
    }

    if (!isSubmittedFile) {
      this.commonService.downloadFile(filePath, this.utilityService.getFileNameFromPath(filePath));
    }

  }

  downloadMenuScript(orderId: string) {
    if (this.sharedService.currentUser && this.sharedService.currentUser.ID == this.orderDetails.Assigned2ndEditorID) {
      this.showAlertMessageTo2ndEditor();
    }
    let fileName = this.orderDetails.MenuScript.split(" ").splice(0, 5).join(" ");
    fileName = fileName.replace(/[/\\?%*:|"<>]/g, '-'); //remove illegal charecters
    this.estimationService.getMenuScriptByOrderId(orderId, fileName);
  }
  private getMessageReceiverName() {
    this.commonService.getRecepientRegistrationNo(this.orderInfo.ReceiverID, true).subscribe((data) => {
      if (data)
        this.orderInfo.ReceiverName = data;
    })

  }

  private getSubmittedDeliveryDocuments() {
    this.estimationService.getSubmittedDeliveryDocuments(this.orderDetails.ID).subscribe(data => {
      if (data) {
        this.submittedDeliveryfiles = data;
      }
    });
  }

  goToSendMessage() {
    this.orderInfo.OrderID = this.orderDetails.ID;
    this.orderInfo.WebOrderNo = this.orderDetails.OrderNo;
    localStorage.setItem("MessageOrderInfo", JSON.stringify(this.orderInfo));
    setTimeout(() => {
      this._router.navigateByUrl("/editor/message");
    }, 1000);
  }

  private getEditorType(): string {
    if (this.sharedService.currentUser && this.sharedService.currentUser.ID) {
      if (this.sharedService.currentUser.ID == this.orderDetails.AssignedTranslatorID) {
        return '1st';
      }
      else {
        return '2nd';
      }

    }
    else {
      return null;
    }
  }

  openEditingDeliveryWindow() {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-md"
    };

    let sharedModalData = {
      JobNumber: this.orderDetails.OrderNo,
      SenderNo: this.sharedService.currentUser.RegistrationID,
      ReceiverNo: this.orderInfo.ReceiverName,
      OrderID: this.orderDetails.ID,
      UploadedByEditorID: this.sharedService.currentUser.ID,
      EditoryType: this.getEditorType(),
      CloseCallBack: this.reloadOrderDetails.bind(this)
    };

    this.sharedService.setModalData(sharedModalData);
    this.bsModalRef = this.modalService.show(EditingDeliveryComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;
  }

  private acceptOrderNow() {
    if (this.sharedService.currentUser.EditorType) {
      if (this.sharedService.currentUser.EditorType == '2nd') {
        if (this.orderDetails.DeliveryLevelName == 'Light' && this.orderDetails.AssignedTranslatorID == null) {
          this.orderDetails.AssignedTranslatorID = this.sharedService.currentUser.ID;
          this.orderDetails.IsAssignAs2ndEditor = false;
        }
        else {
          this.orderDetails.Assigned2ndEditorID = this.sharedService.currentUser.ID;
          this.orderDetails.IsAssignAs2ndEditor = true;
        }
      }
      else {
        this.orderDetails.AssignedTranslatorID = this.sharedService.currentUser.ID;
        this.orderDetails.IsAssignAs2ndEditor = false;
      }

      this.estimationService.executeOrderOperation(OrderOperationType.ACCEPT, this.orderDetails).subscribe((data) => {
        if (data && typeof (data) === "string") {
          if (data == "Not Eligible to accept!Ongoing Project 2") {
            //this.toastr.warning("同時に受注可能な案件数は２件までです");
            this.sharedService.showTranslationToastr('NOTIFICATION.UP_TO_2_PROJECTS_CAN_BE_ACCEPTED_AT_THE_SAME_TIME', NotificationType.WARNING);
          }
          else {
            this.toastr.warning(data);
          }

        }
        else if (data && typeof (data) === "object" && data.ID) {
          //this.toastr.success("ご注文を承りました。");//"The order Accepted Successfully!"
          this.sharedService.showTranslationToastr('NOTIFICATION.THE_ORDER_ACCEPTED_SUCCESSFULLY', NotificationType.SUCCESS);
          this.reloadOrderDetails();
          this.notifyAdminAboutJapaneseCommentsToEditor();
        }
        else {
          //this.toastr.error("エラーが発生しました。");//"There is an error occured!"
          this.sharedService.showTranslationToastr('NOTIFICATION.THERE_IS_AN_ERROR_OCCURED', NotificationType.ERROR);
        }
      });
    }
    else {
      //this.toastr.warning("エディター登録が完了するまで注文を受諾することはできません。"); //"You cannot accept the order untill being an Editor!"
      this.sharedService.showTranslationToastr('NOTIFICATION.YOU_CANNOT_ACCEPT_THE_ORDER_UNTILL_BEING_AN_EDITOR', NotificationType.WARNING);
    }
  }

  alertConfig: confirmDialogConfig = {
    MessageText: this.alertMessageText,
    IsYesNo: true,
    YesBtnCallback: this.acceptOrderNow.bind(this)
  };
  alertModalOptions: ModalOptions = {
    backdrop: 'static',
    keyboard: false,
    class: "modal-sm",
    initialState: this.alertConfig
  };

  private IsMatchDesiredEnglishType(): boolean {
    let editorEnglishTypeList: Array<StaffEnglishTypeModel> = new Array<StaffEnglishTypeModel>();
    if (!this.orderDetails.EditingPreference.DesiredEnglishType) {
      return true;
    }
    if (this.sharedService.currentUser.StaffEnglishTypeList) {
      editorEnglishTypeList = this.sharedService.currentUser.StaffEnglishTypeList;
      if (editorEnglishTypeList.length > 0) {
        let isPresent: boolean = false;
        editorEnglishTypeList.forEach(item => {
          if (item.EnglishType == EnglishLanguageType[this.orderDetails.EditingPreference.DesiredEnglishType]) {
            isPresent = true;
          }
        });

        return isPresent;
      }
    }
    else {
      return false;
    }

  }

  private notifyAdminAboutJapaneseCommentsToEditor() {
    if (this.orderDetails.EditingPreference && this.orderDetails.EditingPreference.CommentsToEditor) {
      let text: string = this.orderDetails.EditingPreference.CommentsToEditor;
      if (this.utilityService.isJapaneseLanguage(text)) {
        this.editorService.canEditorUseJapanese(this.orderDetails.AssignedTranslatorID).subscribe(result => {
          if (!result) //cannot use
          {
            let data: MiscellaneousModel = new MiscellaneousModel();
            data.OrderNo = this.orderDetails.OrderNo;
            this.estimationService.notifyAdminAboutjapaneseCommentsToEditor(data).subscribe(res => {
              console.log(res);
            });
          }
        });

      }
    }

  }

  executeAcceptOrderTask()
  {
    if (this.sharedService.currentUser && this.sharedService.currentUser.ID) {

      if (!this.canAcceptExpertOrder && this.orderDetails.DeliveryLevelName == 'Expert') {
        //this.toastr.warning("現在このExpert案件は受注できません。該当分野のExpert受注可能条件を満たしておく必要があります。");//"Sorry you can't accept [" + this.orderDetails.TranslationFieldName + "] Expert Order!"
        this.sharedService.showTranslationToastr('NOTIFICATION.IT_IS_NECESSARY_TO_MEET_THE_EXPERT_ORDER_AVAILABILITY_CONDITIONS_IN_THE_RELEVANT_FIELD', NotificationType.WARNING);
        return;
      }

      if (!this.sharedService.currentUser.EditorType) {
        //this.toastr.warning("現在、エディターがご注文を受け付けられません。");//"Temporary Editor Can't Accept Order!"
        this.sharedService.showTranslationToastr('NOTIFICATION.TEMPORARY_EDITOR_CANNOT_ACCEPT_ORDER', NotificationType.WARNING);
        return;
      }

      if (!this.isEditorCanAcceptEnglishEditingOrderPenaltyOver) {
        ////this.toastr.warning("ご注文を受諾することができません。米国、英国、カナダ、オーストラリア、ニュージーランド、アイルランドの国籍をもっている方のみ受諾できます。");//"Not Eligible to accept! Must have the nationality of following countries:USA,UK,Canada,Australia,New Zealand,Ireland!"
        //this.toastr.warning("ペナルティ期間がまだ終了しておりません。終了後より案件を受注できます。");//"Your penalty period is not finished yet.So you cannot receive an order."
        this.sharedService.showTranslationToastr('NOTIFICATION.YOUR_PENALTY_PERIOD_IS_NOT_FINISHED_YET_SO_YOU_CANNOT_RECEIVE_AN_ORDER', NotificationType.WARNING);
        return;
      }

      else if (this.sharedService.currentUser.EditorType == '2nd' && !this.canAcceptOrderAs2ndEditor && this.orderDetails.DeliveryLevelName != 'Light') {
        //this.toastr.warning("第一エディターがアサインされていません。第一エディターがアサインされてから受諾できます。"); //"1st Editor is not assigned yet! You can accept it after the 1st editor has been Assigned"
        this.sharedService.showTranslationToastr('NOTIFICATION._1ST_EDITOR_IS_NOT_ASSIGNED_YET_YOU_CAN_ACCEPT_IT_AFTER_THE_1ST_EDITOR_HAS_BEEN_ASSIGNED', NotificationType.WARNING);
        return;
      }
      else if (!this.IsMatchDesiredEnglishType()) {
        if (this.orderDetails.EditingPreference.DesiredEnglishType) {
          let dic = [];
          dic["AmericanEnglish"] = this.sharedService.currentCulture == "jp" ? "アメリカ英語" : "American English";
          dic["BritishEnglish"] = this.sharedService.currentCulture == "jp" ? "イギリス英語" : "British English";
          dic["AustralianEnglish"] = this.sharedService.currentCulture == "jp" ? "オーストラリア英語" : "Australian English";
          this.alertMessageText = this.alertMessageText.replace("{{EnglishType}}", dic[this.orderDetails.EditingPreference.DesiredEnglishType]);
          this.alertConfig.MessageText = this.alertMessageText;
          this.bsModalRef = this.modalService.show(ConfirmDialogComponent, this.alertModalOptions);
        }

      }
      else {
        if (this.sharedService.currentUser.EditorType) {
          if (this.sharedService.currentUser.EditorType == '2nd') {
            if (this.orderDetails.DeliveryLevelName == 'Light' && this.orderDetails.AssignedTranslatorID == null) {
              this.orderDetails.AssignedTranslatorID = this.sharedService.currentUser.ID;
              this.orderDetails.IsAssignAs2ndEditor = false;
            }
            else {
              this.orderDetails.Assigned2ndEditorID = this.sharedService.currentUser.ID;
              this.orderDetails.IsAssignAs2ndEditor = true;
            }
          }
          else {
            this.orderDetails.AssignedTranslatorID = this.sharedService.currentUser.ID;
            this.orderDetails.IsAssignAs2ndEditor = false;
          }

          this.estimationService.executeOrderOperation(OrderOperationType.ACCEPT, this.orderDetails).subscribe((data) => {
            if (data && typeof (data) === "string") {
              if (data == "Not Eligible to accept!Ongoing Project 2") {
                //this.toastr.warning("同時に受注可能な案件数は２件までです");
                this.sharedService.showTranslationToastr('NOTIFICATION.UP_TO_2_PROJECTS_CAN_BE_ACCEPTED_AT_THE_SAME_TIME', NotificationType.WARNING);
              }
              else {
                this.toastr.warning(data);
              }

            }
            else if (data && typeof (data) === "object" && data.ID) {
              //this.toastr.success("ご注文を承りました。");//"The order Accepted Successfully!"
              this.sharedService.showTranslationToastr('NOTIFICATION.THE_ORDER_ACCEPTED_SUCCESSFULLY', NotificationType.SUCCESS);
              this.reloadOrderDetails();
              this.notifyAdminAboutJapaneseCommentsToEditor();
            }
            else {
              //this.toastr.error("エラーが発生しました。");//"There is an error occured!"
              this.sharedService.showTranslationToastr('NOTIFICATION.THERE_IS_AN_ERROR_OCCURED', NotificationType.ERROR);
            }
          });
        }
        else {
          //this.toastr.warning("エディター登録が完了するまで注文を受諾することはできません。"); //"You cannot accept the order untill being an Editor!"
          this.sharedService.showTranslationToastr('NOTIFICATION.YOU_CANNOT_ACCEPT_THE_ORDER_UNTILL_BEING_AN_EDITOR', NotificationType.WARNING);
        }
      }

    }
  }

  acceptOrder() {
    if(this.orderCount==0)
    {
       this.openEditingGuideWindow();
    }
    else
    {
      this.executeAcceptOrderTask();
    }
   
  }

  reloadOrderDetails() {
    let editorId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
    this.getOrderDetails(this.orderDetails.OrderNo, editorId);
    this.getOrderCountByEditorId(editorId);
  }

  openEditingGuideWindow() {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-lg"
    };
    let sharedModalData = {
      CloseCallBack: this.executeAcceptOrderTask.bind(this)
    };

    this.sharedService.setModalData(sharedModalData);
    this.bsModalRef = this.modalService.show(EditorMindsetComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;
  }

  getOrderCountByEditorId(editorId:string)
  {
    if(localStorage.getItem("currentUser"))
    {
      let editorId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
      this.estimationService.getOrderCountByEditorId(editorId).subscribe(data=>{
         this.orderCount=data;
      });
    }
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['orderNo']) {
        let orderNo: string = params['orderNo'];
        this.orderNo = orderNo;
        if (localStorage.getItem("currentUser")) {
          if (this.sharedService.currentUser.EditorType) {
            let editorId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
            this.getOrderDetails(orderNo, editorId);
            this.getOrderCountByEditorId(editorId);
            this.checkIsEditorCanAcceptEnglishEditingOrder();
          }
          else { //not a first or 2nd editor yet
            //this.toastr.warning('現在このExpert案件は受注できません。該当分野のExpert受注可能条件を満たしておく必要があります。'); //"これは指定案件です、別の案件を受注してみてください。"
            ////this.bsModalRef = this.modalService.show(ConfirmDialogComponent, this.withdrawalModalOptions);
            this.sharedService.showTranslationToastr('NOTIFICATION.IT_IS_NECESSARY_TO_MEET_THE_EXPERT_ORDER_AVAILABILITY_CONDITIONS_IN_THE_RELEVANT_FIELD', NotificationType.WARNING);
          }
        }
      }
    });
  }

}
