import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Router } from '@angular/router';
import { ErrorReportModel } from '../../models/error-report-model.model';
import { ClientEnvService } from '../../services/client-env.service';
import { AppSetting } from '../../app-setting';
import { CustomerService } from '../../services/customer-service.service';
import { ToastrService } from 'ngx-toastr';
import { Title,Meta  }     from '@angular/platform-browser';

@Component({
  selector: 'app-error-report',
  templateUrl: './error-report.component.html',
  styleUrls: ['./error-report.component.css']
})
export class ErrorReportComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  siteErrorReport: ErrorReportModel;
  uploadedDocument: any;
  customerService: CustomerService;
  clientEnvService: ClientEnvService;


  constructor(private _router: Router,
    private _dataShareService: DataShareService,
    private _clientEnvService: ClientEnvService,
    private _customerService: CustomerService,
    private _toastr: ToastrService,
    private titleService: Title,
    private meta: Meta) {

    this.router = _router.url;
    this.sharedService = _dataShareService;
    this.clientEnvService = _clientEnvService;
    this.customerService = _customerService;
    this.initializeDefault();
    

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName =this.sharedService.currentCulture=='jp'?"サイトのエラー報告":"Site error report";
    this.sharedService.setSharedData(this.shareableData);

    if(this.sharedService.currentCulture=='jp'){


      this.titleService.setTitle("英文校正サイトのエラー報告");
      this.meta.updateTag({ name: 'keywords', content: 'サイトエラー報告,格安校正,オンライン校正,英語校正,英語,校正会社,英語論文チェック' });
      this.meta.updateTag({ name: 'description', content: '英文校正サイト上に何かしらのエラーが発生した際には直ちに対応致しますのでこちらからエラー内容をお伝えください。' });


    }
    else{

      this.titleService.setTitle("password change,English editing,English,professional editorediting,English editor,editing company,academic writing editing,English academic writing check,editing check");
      this.meta.updateTag({ name: 'keywords', content: 'password change,English editing,English,professional editorediting,English editor,editing company,academic writing editing,English academic writing check,editing check' });
      this.meta.updateTag({ name: 'description', content: 'Change account password of editing-Pro. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });



    }

  }

  private initializeDefault()
  {
//initialize default values
      this.siteErrorReport = new ErrorReportModel();
      this.siteErrorReport.Name = this.sharedService.currentUser.Name || this.sharedService.currentUser.CompanyPICName;
      this.siteErrorReport.BrowserVersion = this.clientEnvService.getFullBrowserName();
      this.siteErrorReport.OsName = this.clientEnvService.getFullOSName();
      this.siteErrorReport.ApplicationID = AppSetting.ApplicationID;
      this.siteErrorReport.ErrorDescription="";
      if(localStorage.getItem("currentUser"))
      {
        this.siteErrorReport.Email=JSON.parse(localStorage.getItem("currentUser")).Email;
        this.siteErrorReport.CustomerNo = JSON.parse(localStorage.getItem("currentUser")).RegistrationID;
      }
  }

  fileSelected(event: any) {  //this time developed by regarding that only one file will be uploaded
    this.uploadedDocument = event.target.files[0];
  }

  sendErrorReport() {
    this.customerService.saveSiteErrorReport(this.siteErrorReport, this.uploadedDocument).subscribe((data) => {
      if (data == true) {
        this.initializeDefault();
        this._toastr.success("あなたのエラーは送付されました。");//"Your Error Report Submitted Successfully."
      }
    });
  }

  ngOnInit() {
  }

}
