import { Component, OnInit, Input } from '@angular/core';
import {TabularList} from '../models/tabular-list.model';
import { _ } from 'underscore';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-tabular-list',
  templateUrl: './tabular-list.component.html',
  styleUrls: ['./tabular-list.component.css']
})
export class TabularListComponent implements OnInit {
  @Input() tabularList:TabularList
   currentPageNumber:number
  constructor(private datePipe: DatePipe) {
    this.currentPageNumber=1;
    
   }

  ngOnInit() {
   console.log(this.tabularList);
  }

  paginate (pageNumber) {
    --pageNumber; // because pages logically start with 1, but technically with 0
    return this.tabularList.data.slice(pageNumber * this.tabularList.pageSize, (pageNumber + 1) * this.tabularList.pageSize);
  }
   
  getRouterLink(obj,index)
  {
    let columnName=this.tabularList.dataKeys[index];
    let columnValue=obj[columnName];
    let linkColumn=_.find(this.tabularList.linkColumnList,function(item,i){return item.columnName==columnName});
    return  {link:linkColumn.routerLink.replace("{columnValue}",obj[columnName]),text:obj[columnName]};
  }
  getValueByColumnName(obj,index) {
    let columnName=this.tabularList.dataKeys[index];
    let linkColumn=_.find(this.tabularList.linkColumnList,function(item,i){return item.columnName==columnName});
    let dateColumn=_.find(this.tabularList.dateColumnList,function(item,i){return item==columnName});
    let currenyColumn=_.find(this.tabularList.currencyColumnList,function(item,i){return item==columnName});
    if(currenyColumn)
       {
         return '¥'+obj[columnName];
       }
    else if(dateColumn)
       {
      let ddMMyyyy = this.datePipe.transform(obj[columnName],"dd/MM/yyyy");
      return  ddMMyyyy;
       } 
       else if(linkColumn)
       {
         return  null;
       } 
    else 
       {
        return obj[columnName];
       }
   
  }

  onPageNumberClick(pageNo)
  {
    console.log("on page button cliked!Current page:",this.currentPageNumber,"going to set:",pageNo);
    this.currentPageNumber=pageNo;
  }

  previousButtonClick()
  {
    console.log("Previous button cliked!Current page:",this.currentPageNumber);
    if(this.currentPageNumber>1)
    {
      this.currentPageNumber--;
    }
  }

  nextButtonClick()
  {
    console.log("Next button cliked!Current page:",this.currentPageNumber);
    if(this.currentPageNumber<this.tabularList.totalPage)
    {
      this.currentPageNumber++;
    }
  }

}
