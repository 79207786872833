import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {DataShareService} from '../services/data-share.service';
import {DataSharingModel} from '../models/data-sharing-model';
import { Title, Meta}     from '@angular/platform-browser';
import { EditorSearchModel } from '../models/editor-search.model';
import { EditorService } from '../services/editor.service';
import { AppSetting } from '../app-setting';
import { CustomerService } from '../services/customer-service.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';

@Component({
  selector: 'app-editor-interview-details',
  templateUrl: './editor-interview-details.component.html',
  styleUrls: ['./editor-interview-details.component.css']
})
export class EditorInterviewDetailsComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  editorService:EditorService;
  editorSearchModel:EditorSearchModel;
  imageBaseUrl:string=AppSetting.IMAGE_BASE_URL;
  customerService:CustomerService;
  IsAlreadyFavoriteEditor: boolean;
  selectedEditors:Array<number>;
  certificationFields: Array<string> = ["法律", "医療", "特許", "証明書"];

  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private _editorService:EditorService,
    private toastr:ToastrService,
    private _customerService:CustomerService,
    private route:ActivatedRoute,
    private titleService: Title,
    private meta: Meta) { 
      this.router=_router.url;
    this.sharedService=dataShareService;
    this.editorService=_editorService;
    this.customerService=_customerService;
    this.IsAlreadyFavoriteEditor= false;
    this.editorSearchModel = new EditorSearchModel();
    this.shareableData=new DataSharingModel();
    this.selectedEditors=new Array<number>();
    this.route.params.subscribe(params=>{
      if(params['editorNo'])
        {
          let editorNo:number=params['editorNo'];
          this.getEditorInterviewDetails(editorNo);
        }
  });
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName="エディターインタビュー詳細";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("【認定分野】【国籍】【専門】英文専門エディターのインタビュー詳細");
    this.meta.updateTag({ name: 'keywords', content: '英文,英文校正,英文エディター,インタビュー詳細,プロのインタビュー,専門エディター,学術校正' });
    this.meta.updateTag({ name: 'description', content: '【認定分野】【国籍】【専門】英文校正の専門エディターのインタビューの詳細が読みたいならすぐクリック！あなたの知りたい英文校正のプロの世界について確認頂けます。' });
    
   
  }

  private updateMetaData(nationality:string,professional:string,certifiedCategory:string){
   this.meta.updateTag({ name: 'description', content: (nationality? nationality :"")+(professional? professional : '')+(certifiedCategory? certifiedCategory : '')+'英文校正の専門エディターのインタビューの詳細が読みたいならすぐクリック！あなたの知りたい英文校正のプロの世界について確認頂けます。' });
   this.titleService.setTitle((nationality? nationality :"")+(professional? professional : '')+(certifiedCategory? certifiedCategory : '')+'英文専門エディターのインタビュー詳細');
  }

    getEditorInterviewDetails(editorNo:number)
    {
       this.editorService.getEditorInterviewDetails(editorNo).subscribe((data)=>{
         if(data)
         {this.editorSearchModel = data;
         let specilityList:Array<string>=new Array<string>();
         specilityList=_.pluck(data.Speciality,"Name");
         let cert:string=this.getCertificationCSV();
         this.updateMetaData(data.Nationality,specilityList.join('、'), cert);
         this.getEditorFavoriteStatus();
         }
       });
    }

    getCertificationCSV(): string {
      let csv: string = "";
      let certifications: Array<string> = new Array<string>();
      if (this.editorSearchModel.CertificationInfo) {
        if (this.editorSearchModel.CertificationInfo.IsLegalExpert)
             certifications.push(this.certificationFields[0]);
        if (this.editorSearchModel.CertificationInfo.IsMedicalExpert)
             certifications.push(this.certificationFields[1]);
        if (this.editorSearchModel.CertificationInfo.IsPatentExpert)
             certifications.push(this.certificationFields[2]);
        if (this.editorSearchModel.CertificationInfo.IsCertificateExpert)
             certifications.push(this.certificationFields[3]);
  
        csv = certifications.join("、");
      }
      return csv
    }

    getEditorFavoriteStatus() {
      if (localStorage.getItem("currentUser")) {
        let customerId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
        this.customerService.isAlreadyFavoriteEditor(customerId, this.editorSearchModel.ID).subscribe((data) => {
          this.IsAlreadyFavoriteEditor = data;
        });
      }
    }

    addFavouriteEditor(regId:string){
      if (localStorage.getItem("currentUser")) {
        let customerId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
        this.customerService.registerAsFavorite(customerId, regId).subscribe((data) => {
          if (data) {
            this.IsAlreadyFavoriteEditor = true;
            this.toastr.success('お気に入り登録ができました。'); //"Successfully Registered as Favorite!"
          }
          else {
            this.toastr.error('お気に入り登録ができませんでした。'); //"Could not be registered as Favorite!"
          }
        });
      }
    }

    goToEstimate(){
      if(this.editorSearchModel.RegistrationID)
      {
        this.selectedEditors.push(this.editorSearchModel.RegistrationID);
        localStorage.setItem("SelectedEditorsForEstimation",JSON.stringify(this.selectedEditors));
        this._router.navigateByUrl("/appointed");
      }
    }
 
  ngOnInit() {
  }

}
