import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Title,Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-editor-evaluation-list',
  templateUrl: './editor-evaluation-list.component.html',
  styleUrls: ['./editor-evaluation-list.component.css']
})
export class EditorEvaluationListComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  constructor(private _router: Router,private dataShareService:DataShareService,
    private titleService: Title,
    private meta: Meta) {
    this.router=_router.url;

     this.sharedService=dataShareService;

    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName=='jp'?"エディター評価一覧":"Editor rating list";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("英文校正のエディター検索|editing-Pro.");
    this.meta.updateTag({ name: 'keywords', content: 'エディター検索,英語校正検索,エディティング検索, エディター, 英語校正, エディターログイン, 登録エディター, プロのエディター' });
    this.meta.updateTag({ name: 'description', content: 'エディターの言語,学歴,専門分野別で検索出来ます。校正する文章の専門分野とマッチング出来るエディターに校正依頼して下さい。' });
  }

  ngOnInit() {
  }

}
