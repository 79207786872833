import { Component, OnInit } from '@angular/core';
import { DataShareService } from 'src/app/services/data-share.service';
import { DataSharingModel } from 'src/app/models/data-sharing-model';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { CustomerService } from 'src/app/services/customer-service.service';
import { MiscellaneousModel } from 'src/app/models/miscellaneous-model.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/public_api';
import { OrderWeb } from 'src/app/models/order-web.model';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { InvoiceTemplateComponent } from 'src/app/invoice-template/invoice-template.component';

@Component({
  selector: 'app-invoice-breakdown',
  templateUrl: './invoice-breakdown.component.html',
  styleUrls: ['./invoice-breakdown.component.css']
})
export class InvoiceBreakdownComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  customerService: CustomerService;
  customerMonthlyPaymentList: { currentPage: number, totalItems: number, dataSource: MiscellaneousModel[] };
  customerCurrentMonthPaymentList: { currentPage: number, totalItems: number, dataSource: OrderWeb[] };
  currentDate: Date;
  bsModalRef: BsModalRef;
  selectedMonth: number;
  selectedYear: number;

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private _customerService: CustomerService,
    private modalService: BsModalService,
    private titleService: Title,
    private meta: Meta) {

    this.router = _router.url;
    this.sharedService = dataShareService;
    this.customerService = _customerService;
    this.currentDate = new Date();

    this.selectedYear = this.currentDate.getFullYear();
    this.selectedMonth = this.currentDate.getMonth() + 1;

    this.customerMonthlyPaymentList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.customerCurrentMonthPaymentList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = this.sharedService.currentCulture=='jp'?"請求内訳":"Invoice detail";
    this.sharedService.setSharedData(this.shareableData);



    if(this.sharedService.currentCulture=='jp'){


      this.titleService.setTitle("英文校正サイトの請求内容を確認");
      this.meta.addTag({ name: 'keywords', content: '請求内訳確認,英文校正,英語エディティング,注文,内訳,リスト,高品質,安心,英語,チェック,品質保証,校正依頼,校正依頼方法学術校正,医学論文校正,論文校正専門,論文エディティング' });
      this.meta.addTag({ name: 'description', content: '英文校正の請求内訳がご確認頂けます。24時間365日対応。オンラインで利用できる校正サービス。納品物の受領までウェブ上で完結。簡単,早い,確実！30分からの短時間納品も可能です。お問い合わせ,お見積りは無料。' });
    }
    else{

      this.titleService.setTitle("editing-Pro. Billing summar confirmation");
      this.meta.addTag({ name: 'keywords', content: 'billing summary confirm,editing-Pro.,English editing,order,detail,list,high quality,safe,English,check,qualityguarantee,editing order,editing order hmethod academic editing, medical academic writing editing,academic writingeditingprofessional,academic writingediting' });
      this.meta.addTag({ name: 'description', content: 'Billing summary confirmation on editing-Pro. 24hours service for 365 days. Online editing service that allows order to submission through online. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });

    }

    this.getMonthlyPaidList();
    this.getCurrentMonthPaymentList(this.selectedMonth, this.selectedYear);
  }

  onPageIndexChange(event: PageChangedEvent) {
    this.getMonthlyPaidList(event.page);
  }

  onClickGroupItem(item: MiscellaneousModel) {
    if (item.OrderMonth && item.OrderYear) {
      this.selectedYear = item.OrderYear;
      this.selectedMonth = item.OrderMonth;
      this.getCurrentMonthPaymentList(item.OrderMonth, this.selectedYear, 1);
    }
  }

  getMonthlyPaidList(pageNo: number = 1) {
    this.customerService.getCustomerMonthWisePaymentList(this.sharedService.currentUser.ID, pageNo, 5).subscribe((data) => {
      this.customerMonthlyPaymentList.currentPage = pageNo;
      this.customerMonthlyPaymentList.dataSource = data;
      if (data.length > 0) {
        this.customerMonthlyPaymentList.totalItems = data[0].TotalCount
      }
    });
  }

  onPageIndexChangeCurrentMonthPayment(event: PageChangedEvent) {
    this.getCurrentMonthPaymentList(this.selectedMonth, this.selectedYear, event.page,);
  }

  getCurrentMonthPaymentList(month: number, year: number, pageNo: number = 1) {
    this.customerService.getCustomerCurrentMonthPaymentList(this.sharedService.currentUser.ID, year, month, pageNo, 5).subscribe((data) => {
      this.customerCurrentMonthPaymentList.currentPage = pageNo;
      this.customerCurrentMonthPaymentList.dataSource = data;
      if (data.length > 0) {
        this.customerCurrentMonthPaymentList.totalItems = data[0].TotalCount
      }
    });
  }

  openInvoicePreviewWindow(month: number, year: number) {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-lg"
    };
    this.sharedService.setModalData({ OrderMonth: month, OrderYear: year });
    this.bsModalRef = this.modalService.show(InvoiceTemplateComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;
  }

  invoicePreview(month: number, year: number) {
    this.openInvoicePreviewWindow(month, year);
  }

  ngOnInit() {
  }

}
