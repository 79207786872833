import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import * as _ from 'underscore';

@Component({
  selector: 'app-editor-dashboard',
  templateUrl: './editor-dashboard.component.html',
  styleUrls: ['./editor-dashboard.component.css']
})
export class EditorDashboardComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  authService:AuthenticationService;
  titleService: Title;
   meta: Meta
  
  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private _authService:AuthenticationService,
    private _titleService: Title,
    private _meta: Meta) {
      this.router=_router.url;
      this.authService=_authService;
      this.sharedService=dataShareService;
      this.titleService=_titleService;
      this.meta=_meta;
      //this.sharedService.currentUser= this.authService.getCurrentUser();

      this.shareableData=new DataSharingModel();
      this.shareableData.currentRoute=this.router;
      this.shareableData.currentStateName == 'jp'? "ご利用ガイド":"User Guide";
      this.sharedService.setSharedData(this.shareableData);
      if(this.sharedService.currentCulture=='jp'){

        this.titleService.setTitle("英文校正のエディター紹介|editing-Pro.");
        this.meta.updateTag({ name: 'keywords', content: '"エディター紹介,英語エディター,専門エディター,エディター専攻,専攻論文,英語論文,論文校正,論文校閲,editing, proofreading."' });
        this.meta.updateTag({ name: 'description', content: 'エディターの学歴,専門分野を含め,専門分野を紹介しております。エディターのプロフィールを確認し,英語校正する文章の専門分野とマッチング出来るエディターに英語校正依頼して下さい。' });
  
      }
      else{
  
        this.titleService.setTitle("editing-Pro.editor introduction|editing-Pro.");
        this.meta.updateTag({ name: 'keywords', content: '""editor introduction,English editor,professional editor,English major, major academic writing,English academic writing,academic writing editing,academic writing proof, post editing, proofreading.""' });
        this.meta.updateTag({ name: 'description', content: 'Introducing editor\'s academic background, professional categories ,professional categories. Order English editing by confirming editor\'s profile and matching professional categories of English document needs editing.' });
  
  
      }
     }

  changeSelectedMenuItem(event)
  {
    const listItems = document.querySelectorAll('.logout-menu li');
    _.each(listItems,function(item,index){
       item.className="";
    });
      event.target.parentNode.classList.add("active");
  }

  logout()
  {
    this.authService.loggedOut();
  }

  ngOnInit() {
  }

}
