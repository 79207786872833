export class EmailModel {
    UserID: number
    Name: string
    EmailFrom: string
    EmailTo: string
    EmailSubject: string
    EmailBody: string
    EmailCc: string
    EmailBcc: string
    AttachmentFileLink: string
    EstimationID: string
    IsHtmlBody: boolean
    EmailFromDisplayName: string
    ApplicationID: number
    BodyText: string
    PlanType: string
    JobNumber: string
    WordCount: number
    OrderDate: Date
    OrderNo:string
}
