import { Component, OnInit } from '@angular/core';
import { DocumentModel } from '../../../models/document.model';
import { DataShareService } from '../../../services/data-share.service';
import { ToastrService } from 'ngx-toastr';
import { EstimationService } from '../../../services/estimation.service';
import { UtilityService } from '../../../services/utility.service';
import  * as _  from 'underscore';
import { NotificationType } from 'src/app/Enums.enum';

@Component({
  selector: 'app-editing-delivery',
  templateUrl: './editing-delivery.component.html',
  styleUrls: ['./editing-delivery.component.css']
})
export class EditingDeliveryComponent implements OnInit {
  documentModel: DocumentModel;
  sharedService: DataShareService;
  estimationService: EstimationService;
  uploadingDocument: any;
  IsAlreadyFileSent:boolean;
  utility: UtilityService;
  closeModalCallBack:any;

  constructor(
    private dataShareService: DataShareService,
    private _toastr: ToastrService,
    private _utility: UtilityService,
    private _estimationService: EstimationService) {

    this.sharedService = dataShareService;
    this.estimationService = _estimationService;
    this.utility = _utility;
    this.closeModalCallBack = null;

    this.documentModel = new DocumentModel();
    this.IsAlreadyFileSent=false;
    let sharedModalData = this.sharedService.getModalData();
    if(sharedModalData)
    {
      this.documentModel.OrderNo = sharedModalData.JobNumber;
      this.documentModel.SenderNo = sharedModalData.SenderNo;
      this.documentModel.ReceiverNo = sharedModalData.ReceiverNo;
      this.documentModel.OrderID = sharedModalData.OrderID;
      this.documentModel.UploadedByEditorID = sharedModalData.UploadedByEditorID;
      this.documentModel.EditoryType = sharedModalData.EditoryType;
      this.closeModalCallBack = sharedModalData.CloseCallBack;
    }

  }

  fileSelected(event: any) {  //this time developed by regarding that only one file will be uploaded
    this.uploadingDocument = event.target.files[0];
  }

  formatFileSize(sizeInBytes: number) {
    return this.utility.formatBytes(sizeInBytes);
  }

  submitDelivery()
  {
    if(this.documentModel &&  this.uploadingDocument)
    {
      this.documentModel.FileDescription=this.documentModel.Body;
    
      this.estimationService.submitDeliveryDocument(this.documentModel,this.uploadingDocument).subscribe((data)=>{
       if(data)
       {
        //this._toastr.success('送付が完了しました。');//"Successfully Sent!"
        this.sharedService.showTranslationToastr('NOTIFICATION.SUCCESSFULLY_SENT',NotificationType.SUCCESS);
        this.IsAlreadyFileSent=true;
        this.sharedService.bsModalRef.hide();
        if(_.isFunction(this.closeModalCallBack))
        {
          this.closeModalCallBack();
        }
       }
       else{
         //this._toastr.error('送付できません。'); //"Sorry!Could not be sent!"
         this.sharedService.showTranslationToastr('NOTIFICATION.SORRY_COULD_NOT_BE_SENT_01',NotificationType.ERROR);
       }
      });
    }
    else{
      //this._toastr.warning('ドキュメントを選択してください。'); //'Please Select a document first!'
      this.sharedService.showTranslationToastr('NOTIFICATION.PLEASE_SELECT_A_DOCUMENT_FIRST',NotificationType.WARNING);
    }
    
  }

  ngOnInit() {
  }

}
