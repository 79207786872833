import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import{CredentialModel, UserType} from '../models/credential.model';
import {AppSetting} from '../app-setting';
import {EditingProUser} from '../models/editing-pro.model';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataShareService } from './data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { IUser } from '../models/iuser';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  editingPro_API_ROOT:string=AppSetting.EDITINGPRO_API_ENDPOINT;
  httpClient:HttpClient
  currentUser:EditingProUser
  currentCandidate:CredentialModel
  toastr:ToastrService 
  router: Router
  sharedService:DataShareService;
  parentShareableData:DataSharingModel;

  constructor(private http:HttpClient,_toastr:ToastrService,private _router: Router,_sharedService:DataShareService) {
    this.httpClient=http;
    this.toastr=_toastr;
    this.router=_router;
    this.sharedService=_sharedService;
   }

   loggedOut()
   {
     if(localStorage.getItem('currentUser'))
     {
      localStorage.removeItem('currentUser');
      this.router.navigate(['/']);
      this.parentShareableData=new DataSharingModel();
      this.parentShareableData.currentRoute='/';
      this.parentShareableData.currentStateName="Home";
      this.sharedService.setSharedData(this.parentShareableData);
      this.sharedService.loginStatus=false;
      this.sharedService.currentUser=null;
      localStorage.clear();
      this.sharedService.setCurrentCurrency();
     }
   }

   setCaurrentCandidate(candidate:CredentialModel)
   {
     candidate.CultureId=this.sharedService.currentCulture;
     this.currentCandidate=candidate;
   }

   getCurrentUser():any{
     if(this.sharedService.loginStatus=true)
     return JSON.parse(localStorage.getItem('currentUser'));
     else
     return null;
   }

   isAuthenticated():Promise<boolean>
   {
      if(localStorage.getItem('currentUser'))
        {
           let promise=new Promise<boolean>((resolve,reject)=>{
               resolve(true);
           });
           this.sharedService.loginStatus=true;
           this.sharedService.currentUser= JSON.parse(localStorage.getItem('currentUser'));
           this.sharedService.setCurrentCurrency();
           return promise;
        }

        else{
          return new Promise((resolve,reject)=>{
            this.authenticateUser(this.currentCandidate).subscribe((data)=>{
              this.currentUser=data;
              if(data)
              localStorage.setItem('currentUser',JSON.stringify(this.currentUser));
              if(this.currentUser)
              {
                this.sharedService.loginStatus=true;
                this.sharedService.setCurrentCurrency();
                this.sharedService.currentUser= JSON.parse(localStorage.getItem('currentUser'));
                if(this.sharedService.bsModalRef)
                {
                  this.sharedService.bsModalRef.hide();
                  if(this.currentUser.UserType==1)
                  this.router.navigateByUrl('/customer/my-page');
                  else if(this.currentUser.UserType==2)
                  this.router.navigateByUrl('/editor/my-page');
                  else
                  {
                    this.router.navigateByUrl('/partner/my-page');
                  }
                }
                return resolve(true);
              }

              else
              {
                if(this.currentCandidate)
                this.toastr.error("Invalid Username or Password!");
                this.sharedService.loginStatus=false;
                this.sharedService.currentUser=null;
                return resolve(false); 
              }
            });
          });
        }
     
      
   }

   authenticateUser(user:CredentialModel):Observable<any>
   { 
       if(!user)
       {
        const simpleObservable = new Observable((observer) => {
          observer.next(null);
          observer.complete()
         });

         return simpleObservable;
       }

       switch(user.UserType)
       {
            case UserType.Customer:
              return this.http.post<EditingProUser>(this.editingPro_API_ROOT+'customer/login',user);
            case UserType.Editor:
              return this.http.post<EditingProUser>(this.editingPro_API_ROOT+'editor/login',user);
            case UserType.Partner:
              return this.http.post<EditingProUser>(this.editingPro_API_ROOT+'partner/login',user);
       }
   }
}
