import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { OrderWeb } from '../models/order-web.model';
import { AppSetting } from '../app-setting';
import { CompanyIndustryClassificationModel } from '../models/company-industry-classification-model.model';
import { CountryModel } from '../models/country-model.model';
import { PrefectureModel } from '../models/prefecture-model.model';
import { NoticeModel } from '../models/notice.model';
import { SoftwareSkillModel } from '../models/software-skill.model';
import { LanguageModel } from '../models/language.model';
import { LanguageLevelModel } from '../models/language-level.model';
import { SpecializedFieldModel } from '../models/specialized-field.model';
import { EducationModel } from '../models/education.model';
import { StaffWorkingStatusModel } from '../models/staff-working-status.model';
import { MajorSubjectDetailsModel } from '../models/major-subject-details.model';
import { MajorSubjectModel } from '../models/major-subject.model';
import { BankModel } from '../models/bank.model';
import { BankAccountModel } from '../models/bank-account.model';
import { BankAccountTypeModel } from '../models/bank-account-type.model';
import { BankBranchModel } from '../models/bank-branch.model';
import { RequestOptions, ResponseContentType } from '@angular/http';
import { saveAs } from 'file-saver';
import { Response } from '@angular/http';
import { SelectOptionModel } from '../models/select-option-model';
import { StaffEnglishTypeModel } from '../models/staff-english-type.model';
import { MasterCurrencyModel } from '../models/master-currency.model';

@Injectable({
  providedIn: 'root'
})
export class CommonListService {

  ROOT_URL: string = AppSetting.API_ENDPOINT;
  EDITING_PRO_URL: string = AppSetting.EDITINGPRO_API_ENDPOINT;

  constructor(private http: HttpClient) { }

  getCountries(culture:string="jp") {
    const params = new HttpParams().set('culture', culture);
    return this.http.get<Array<CountryModel>>(this.ROOT_URL + 'editingpro/GetCountryList',{params});
  }

  getPrefecturesByCountryID(countryID: number,culture:string="jp") {
    const params = new HttpParams().set('CountryId', countryID.toString()).set('culture', culture);
    return this.http.get<Array<PrefectureModel>>(this.ROOT_URL + 'editingpro/GetPrefectureListByCountryID/', { params });
  }

  getIndustryClassifications(currentCulture: string) {
    return this.http.post<Array<CompanyIndustryClassificationModel>>(this.ROOT_URL + 'companyindustryclassification/list', { CurrentCulture: currentCulture,CultureID:currentCulture,CurrentUserID:20257 });
  }

  getWebOrders(applicationId: number, pageNumber: number = 1, pageSize: number = 50, cultureId = 'jp'): Observable<Array<OrderWeb>> { //for test purpose only
    return this.http.post<Array<OrderWeb>>(this.ROOT_URL + 'order/getWebOrders', { cultureId: cultureId, ApplicationId: applicationId, PageSize: pageSize, PageNumber: pageNumber });
  }

  getNoticeList() {
    return this.http.get<Array<NoticeModel>>(this.ROOT_URL + 'editingpro/GetAllPublishedNoticeList');//GetNoticeList
  }
  getTotalRegisteredStaff() {
    return this.http.get<number>(this.ROOT_URL + 'editingpro/customer/GetTotalRegisteredStaff');
  }
  getAllStaffSoftwareSkills(culture:string="jp") {
    const params = new HttpParams().set('culture', culture);
    return this.http.get<Array<SoftwareSkillModel>>(this.ROOT_URL + 'editingpro/GetAllStaffSoftwareSkills',{params});
  }

  getAllLanguages(culture:string="jp") {
    const params = new HttpParams().set('culture', culture);
    return this.http.get<Array<LanguageModel>>(this.ROOT_URL + 'editingpro/GetLanguageList',{params});
  }
  getLanguageLevelList(culture:string="jp") {
    const params = new HttpParams().set('culture', culture);
    return this.http.get<Array<LanguageLevelModel>>(this.ROOT_URL + 'editingpro/GetLanguageLevelList',{params});
  }

  getSpecializedFieldList(culture:string="jp") {
    const params = new HttpParams().set('culture', culture);
    return this.http.get<Array<SpecializedFieldModel>>(this.ROOT_URL + 'editingpro/GetSpecializedFieldList',{params});
  }

  getEducationalDegreeList(culture:string="jp") {
    const params = new HttpParams().set('culture', culture);
    return this.http.get<Array<EducationModel>>(this.ROOT_URL + 'editingpro/GetEducationalDegreeList',{params});
  }

  getWorkingStatusList(culture:string="jp") {
    const params = new HttpParams().set('culture', culture);
    return this.http.get<Array<StaffWorkingStatusModel>>(this.ROOT_URL + 'editingpro/GetStaffWorkingStatusList',{params});
  }

  getBankAccountTypeList(culture:string="jp") {
    const params = new HttpParams().set('culture', culture);
    return this.http.get<Array<BankAccountTypeModel>>(this.ROOT_URL + 'editingpro/GetBankAccountTypeList',{params});
  }

  getBankList(countryId:number=0,culture:string="jp") {
    const params = new HttpParams().set('countryId', countryId.toString()).set('culture', culture);
    return this.http.get<Array<BankModel>>(this.ROOT_URL + 'editingpro/GetBankList',{params});
  }

  getBankBranchListByBankId(bankId: string,culture:string="jp") {
    const params = new HttpParams().set('bankId', bankId).set('culture', culture);
    return this.http.get<Array<BankBranchModel>>(this.ROOT_URL + 'editingpro/GetBankBranchListByBankId', { params });
  }

  getPaypalCurrencyList(culture:string="jp") {
    const params = new HttpParams().set('culture', culture);
    return this.http.get<Array<MasterCurrencyModel>>(this.ROOT_URL + 'editingpro/GetPaypalCurrencyList', { params });
  }

  getMajorSubjectList(culture:string="jp") {
    const params = new HttpParams().set('culture', culture);
    return this.http.get<Array<MajorSubjectModel>>(this.ROOT_URL + 'editingpro/GetMajorSubjectList',{params});
  }

  getMajorSubjectDetailList(majorSubjectId: string,culture:string="jp") {
    const params = new HttpParams().set('majorSubjectId', majorSubjectId).set('culture', culture);
    return this.http.get<Array<MajorSubjectDetailsModel>>(this.ROOT_URL + 'editingpro/GetMajorSubjectDetailList', { params });
  }

  createAndSave(data: any, filename: string) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    saveAs(blob, filename);
  }

  downloadFile(filePath: string, fileName: string) {
    return this.http.get(this.ROOT_URL + 'editingpro/DownloadFile?filePath=' + filePath, { responseType: 'blob' }).subscribe((data) => this.createAndSave(data, fileName));
  }

  getRecepientRegistrationNo(receiverID: string, isEditorSender: boolean) {
    const params = new HttpParams().set('ReceiverID', receiverID).set('IsEditorSender', isEditorSender ? 'true' : 'false');
    return this.http.get<string>(this.EDITING_PRO_URL + 'message/GetRecepientRegistrationNo', { params });
  }

  getCountryByBankId(bankId:string,culture:string="jp") {
    const params = new HttpParams().set('bankId', bankId).set('culture', culture);
    return this.http.get<CountryModel>(this.EDITING_PRO_URL + 'editor/GetCountryNameByBankId',{params});
  }

  getOfferingOrderParameterList() {
    let result:Array<SelectOptionModel>=new Array<SelectOptionModel>(); 
    
    let list = [
      { ID: null, Name: "--Select--" },
      { ID: 1, Name: "過去に指名した翻訳者" },
      { ID: 2, Name: "過去に指名した翻訳者 (他の社員の履歴を含む)" },
      { ID: 3, Name: "お気に入り" },
      { ID: 4, Name: "お気に入り (他の社員の履歴を含む)" },
      { ID: 5, Name: "過去に依頼した翻訳者" },
      { ID: 6, Name: "過去に依頼した翻訳者 (他の社員の履歴を含む)" }
    ];

    list.forEach(item=>{
      let option:SelectOptionModel=new SelectOptionModel();
      option.ID=item.ID;
      option.Name=item.Name;
      result.push(option);
    });

    return result;
  }

  getOfferingOrderParameterDefaultList() {
    let result:Array<SelectOptionModel>=new Array<SelectOptionModel>(); 
    let list = [
      { ID: null, Name: "--Select--" },
      { ID: 1, Name: "評価が高い翻訳者から順次" }
    ];

    list.forEach(item=>{
      let option:SelectOptionModel=new SelectOptionModel();
      option.ID=item.ID;
      option.Name=item.Name;
      result.push(option);
    });

    return result;
  }

}

