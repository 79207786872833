import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Title, Meta } from '@angular/platform-browser';
import { EditorService } from '../services/editor.service';
import { CommonListService } from '../services/common-list.service';
import { SpecializedFieldModel } from '../models/specialized-field.model';
import { EditorSearchModel } from '../models/editor-search.model';
import * as _ from 'underscore';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import {AppSetting} from '../app-setting';
import { LanguageModel } from '../models/language.model';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-editor-search',
  templateUrl: './editor-search.component.html',
  styleUrls: ['./editor-search.component.css']
})
export class EditorSearchComponent implements OnInit {
  editorService: EditorService;
  commonListService: CommonListService;
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  specialitiesList:Array<SpecializedFieldModel>;
  searchModel:{editorNo:number,isOnlyCeritfied:boolean,specialityId:string,sourceLanguageId:string,targetLanguageId:string};
  editorList:Array<EditorSearchModel>;
  pageNumber:number=1;
  pageSize:number=12;
  totalItems:number;
  imageBaseUrl:string=AppSetting.IMAGE_BASE_URL;
  languages:Array<LanguageModel>;


  constructor(private _editorService: EditorService,
    private _commonListService: CommonListService,
    private _router: Router,
    private utility:UtilityService,
    private dataShareService: DataShareService,
    private titleService: Title,
    private meta: Meta) {
    this.editorService = _editorService;
    this.commonListService = _commonListService;

    this.router = _router.url;
    this.sharedService = dataShareService;

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = this.sharedService.currentCulture=="jp"? "エディターﾘｽﾄ/検索":"Editor list search";
    this.sharedService.setSharedData(this.shareableData);
    this.specialitiesList=new Array<SpecializedFieldModel>();
    this.searchModel={editorNo:null,isOnlyCeritfied:false,specialityId:null,sourceLanguageId:'1484e197-70f8-4f64-98cf-e0f06eff6f49',targetLanguageId:'1484e197-70f8-4f64-98cf-e0f06eff6f49'};
    this.editorList=new Array<EditorSearchModel>();
    this.languages=new Array<LanguageModel>();
    this.totalItems=0;



    if(this.sharedService.currentCulture=='jp'){


      this.titleService.setTitle("英文校正のエディター紹介|editing-Pro.");
      this.meta.updateTag({ name: 'keywords', content: 'エディター紹介,英語エディター,専門エディター,エディター専攻,専攻論文,英語論文,論文校正,論文校閲,editing, proofreading.' });
      this.meta.updateTag({ name: 'description', content: 'エディターの学歴,専門分野を含め,専門分野を紹介しております。エディターのプロフィールを確認し,英語校正する文章の専門分野とマッチング出来るエディターに英語校正依頼して下さい。' });
    }
    else{

      
      this.titleService.setTitle("editing-Pro.editor introduction|editing-Pro.");
      this.meta.updateTag({ name: 'keywords', content: '"editor introduction,English editor,professional editor,English major, major academic writing,English academic writing,academic writing editing,academic writing proof, post editing, proofreading."' });
      this.meta.updateTag({ name: 'description', content: 'Introducing editor\'s ,academic background, professional categories ,professional categories. Order English editing by confirming editor\'s profile and matching professional categories of English document needs editing.' });

    }

    this.getSpecilitites();
    this.searchEditorNow();
    this.getLanguageList();

  }

  getLanguageList() {
    this.commonListService.getAllLanguages().subscribe((data) => {
      if (data)
        this.languages = data;
    });
  }

  clearFilter()
  {
    this.searchModel.specialityId=null;
    this.searchModel.isOnlyCeritfied=false;
    //'1484e197-70f8-4f64-98cf-e0f06eff6f49'  English Language ID
    this.searchModel.sourceLanguageId='1484e197-70f8-4f64-98cf-e0f06eff6f49';
    this.searchModel.targetLanguageId='1484e197-70f8-4f64-98cf-e0f06eff6f49';
    this.searchEditorNow();
  }

  onPageIndexChange(event: PageChangedEvent) {
     this.pageNumber=event.page;
  } 

  searchEditorNow()
  {
    this.pageNumber=1;
    let clientId:string =null;
    if(this.sharedService.loginStatus && this.sharedService.currentUser.UserType==1)
    {
     clientId=this.sharedService.currentUser.ID;
    }

    this.editorService.searchEditor(this.searchModel.specialityId,this.searchModel.isOnlyCeritfied,clientId,this.searchModel.editorNo).subscribe((data)=>{
      if(data)
      {
        this.editorList=data;
        this.totalItems=data.length;
        _.each(this.editorList,function(item:EditorSearchModel){
         let spList=_.pluck(item.Speciality.slice(0,5), 'Name');
         item.SpecializedFiled=spList.join(',');
        });

        this.editorList.forEach(item=>{
          item.SpecializedFiled=this.utility.getClippedText(item.SpecializedFiled,this.sharedService.currentCulture);
        });
      }
      else{
        this.editorList=new Array<EditorSearchModel>();
        this.totalItems=0;
      }
    });
  }

  getSpecilitites()
  {
    this.commonListService.getSpecializedFieldList().subscribe((data)=>{
      this.specialitiesList=data;
    });
  }


  ngOnInit() {
  }

}
