import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSetting } from '../app-setting';
import { DocumentModel } from '../models/document.model';
import { EditingProDeliveryTracingModel } from '../models/editing-pro-delivery-tracing.model';
import { OrderWeb } from '../models/order-web.model';
import { EmailModel } from '../models/email.model';
import { MessageModel } from '../models/message-model.model';
import { saveAs } from 'file-saver';
import { Response } from '@angular/http';
import { EditingChargeSettingsModel } from '../models/editing-charge-settings.model';
import { DeliveryTimeSetSettingsModel } from '../models/delivery-time-set-settings.model';
import { OrderOperationType } from '../Enums.enum';
import { OrderReceiveDocumentSettingsModel } from '../models/order-receive-document-settings';
import { DocumentDeliveryPeriodModel } from '../models/document-delivery-period.model';
import { MiscellaneousModel } from '../models/miscellaneous-model.model';


@Injectable({
  providedIn: 'root'
})
export class EstimationService {
  ROOT_URL:string=AppSetting.API_ENDPOINT; 
  EDITINGPRO_ROOT_URL:string=AppSetting.EDITINGPRO_API_ENDPOINT;

  constructor(private http:HttpClient) { }

  getManuscriptByPlainTextEstimated(estimationPlainText:string)
  {
    let formData:FormData = new FormData();  
    formData.append('PlainText',estimationPlainText);  
    return this.http.post<DocumentModel>(this.EDITINGPRO_ROOT_URL+'estimation/GetManuscriptByPlainTextEstimated',formData);
  }

  getManuscriptByUploadedDocumentEstimated(uploadDocuments:any)
  {
    let formData:FormData = new FormData();
    uploadDocuments.forEach((file,i) => {
      formData.append('file'+i+1, file);
    });  
    return this.http.post<Array<DocumentModel>>(this.EDITINGPRO_ROOT_URL+'estimation/GetManuscriptByUploadedDocumentEstimated',formData);
  }
  getEditingProDeliveryTracings()
  {
    return this.http.get<Array<EditingProDeliveryTracingModel>>(this.EDITINGPRO_ROOT_URL+'estimation/getEditingProDeliveryTracings');
  }

  savePreferenceRefFile(uploadDocument:any)
  {
    let formData:FormData = new FormData();
    formData.append("file",uploadDocument);
    return this.http.post<DocumentModel>(this.EDITINGPRO_ROOT_URL+"estimation/SaveEditingPreferenceRefFile",formData);
  }

  saveWebOrder(order:OrderWeb)
  {
    return this.http.post<string>(this.EDITINGPRO_ROOT_URL+"estimation/SaveWebOrder",order);
  }

  sendEmailToStakeHolder(emailModel:EmailModel)
  {
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL+'estimation/SendEmailToStakeholder',emailModel);
  }

  getEstimationWebOrderList(pageNo:number,pageSize:number,isLandingPage:boolean=false,culture:string="jp")
  {
    const params = new  HttpParams().set('pageNo', pageNo.toString()).set('pageSize', pageSize.toString())
    .set('isLandingPage',isLandingPage.toString()).set('culture',culture);
    return this.http.get<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL+'order/GetOnGoingOrderList', {params});
  }

  getEditorWebOrderList(editorId:number ,pageNo:number, pageSize:number,culture:string="jp")
  {
    const params = new HttpParams().set('editorId',editorId.toString()).set('pageNo', pageNo.toString()).set('pageSize', pageSize.toString()).set('culture',culture);
    return this.http.get<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL+ 'order/GetOngoingOrderListByEditorId',{params});
  }

  getCustomerOrderBreakdownList(clientId:number ,pageNo:number, pageSize:number,culture:string="jp")
  {
    const params = new HttpParams().set('clientId',clientId.toString()).set('pageNo', pageNo.toString()).set('pageSize', pageSize.toString()).set('culture',culture);
    return this.http.get<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL+ 'order/GetCustomerOrderBreakdownList',{params});
  }
  getOrderDetailsByOrderNo(orderNo:string)
  {
    const params = new HttpParams().set('orderNo',orderNo.toString());
    return this.http.get<OrderWeb>(this.EDITINGPRO_ROOT_URL + 'order/GetOrderDetailsByOrderNo', {params});
  }
  GetOrderDetailsForEditorByOrderNo(orderNo:string,editorId:string,culture:string="jp")
  {
    const params = new HttpParams().set('orderNo',orderNo.toString()).set('editorId',editorId).set('culture',culture);
    return this.http.get<OrderWeb>(this.EDITINGPRO_ROOT_URL + 'order/GetOrderDetailsForEditorByOrderNo', {params});
  }
  GetOrderDetailsForCustomerByOrderNo(orderNo:string,clientId:string)
  {
    const params = new HttpParams().set('orderNo',orderNo.toString()).set('clientId',clientId);
    return this.http.get<OrderWeb>(this.EDITINGPRO_ROOT_URL + 'order/GetOrderDetailsForCustomerByOrderNo', {params});
  }
  GetOrderDetailsForOthersCustomerByOrderNo(orderNo:string)
  {
    const params = new HttpParams().set('orderNo',orderNo.toString());
    return this.http.get<OrderWeb>(this.EDITINGPRO_ROOT_URL + 'order/GetOrderDetailsForOthersCustomerByOrderNo', {params});
  }
  getEditingServiceBottomLine()
  {
    return this.http.get<MiscellaneousModel>(this.EDITINGPRO_ROOT_URL + 'GetEditingServiceBottomLine');
  }
  getAllMessagesByOrderId(orderNo:string)
  {
    const  params = new  HttpParams().set('orderNo', orderNo);
    return this.http.get<Array<MessageModel>>(this.EDITINGPRO_ROOT_URL+'message/GetMessageListByOrderNo',{params});
  }
  
  createAndSave(data: any,fileTitle:string) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const reader = new FileReader();
    saveAs(blob, fileTitle+".txt");
  }

  getMenuScriptByOrderId(orderId:string,fileTitle:string)
  {
    return this.http.get(this.ROOT_URL + 'editingpro/DownloadMenuScriptFileForDirectInput?orderId='+orderId,{responseType: 'blob'}).subscribe((data)=>this.createAndSave(data,fileTitle));    
  }

  getUnassignedOrderList(pageNo:number,pageSize:number,editorId:string=null,culture:string="jp")
  {
    const  params = new  HttpParams().set('pageNo', pageNo.toString()).set('pageSize', pageSize.toString()).set('editorId', editorId.toString()).set('culture',culture);
    return this.http.get<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL+'order/GetUnassignedOrderList', {params});
  }
  getCustomerOrderListByCustomerID(clientId:string, pageNo:number)
  {
    const params = new HttpParams().set('clientId', clientId.toString()).set('pageNo', pageNo.toString());
    return this.http.get<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL + 'customer/GetCustomerOrderList',{params});
  }

  getEditorOrderListByEditorId(editorId:string,pageNo:number,pageSize:number,culture:string="jp")
  {
    const  params = new  HttpParams().set('editorId',editorId).set('pageNo', pageNo.toString()).set('pageSize', pageSize.toString()).set('culture',culture);
    return this.http.get<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL+'order/GetOngoingOrderListByEditorId', {params});
  }
  getEditorOpportunityList(editorId:string, pageNo:number, pageSize:number, isOnlyNominated:boolean,culture:string="jp")
  {
    const params = new HttpParams().set('editorId', editorId).set('pageNo', pageNo.toString()).set('pageSize',pageSize.toString())
    .set('isOnlyNominated',isOnlyNominated ? 'true':'false').set('culture',culture);
    return this.http.get<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL+'editor/GetEditorOpportunityList',{params});
  }

  getEditorRunningList(editorId:string, pageNo:number, pageSize:number,culture:string="jp")
  {
    const params = new HttpParams().set('editorId', editorId).set('pageNo', pageNo.toString()).set('pageSize',pageSize.toString())
    .set('culture',culture);
    return this.http.get<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL+'editor/GetEditorRunningOrderList',{params});
  }

  getEditingChargeSettings()
  {
    return this.http.get<EditingChargeSettingsModel>(this.EDITINGPRO_ROOT_URL+'estimation/GetEditingChargeSettings');
  }

  getDeliveryTimeSetSettings(isDoubleCheck:boolean=false)
  {
    const params = new HttpParams().set('isDoubleCheck', isDoubleCheck?"true":"false");
    return this.http.get<Array<DeliveryTimeSetSettingsModel>>(this.EDITINGPRO_ROOT_URL+'estimation/GetDeliveryTimeSetSettings',{params});
  }

  getPriceMatrixRowHeads(wordCount:number,isDoubleCheck:boolean=false)
  {
    const params = new HttpParams().set('wordCount',wordCount.toString()).set('isDoubleCheck', isDoubleCheck?"true":"false");
    return this.http.get<Array<DeliveryTimeSetSettingsModel>>(this.EDITINGPRO_ROOT_URL+'estimation/GetPriceMatrixRowHeads',{params});
  }

  executeOrderOperation(opType:OrderOperationType,order:OrderWeb)
  {
    return this.http.post<OrderWeb>(this.EDITINGPRO_ROOT_URL+'order/ExecuteOrderOperation/'+opType.toString(),order);
  }

  saveOrderReceiveDocumentSettings(settings:OrderReceiveDocumentSettingsModel)
  {
    return this.http.post<string>(this.EDITINGPRO_ROOT_URL+"order/SaveOrderReceiveDocumentSettings",settings);
  }

  getDocumentDeliveryPeriodList(culture:string="jp")
  {
    const params = new HttpParams().set('culture', culture);
    return this.http.get<Array<DocumentDeliveryPeriodModel>>(this.EDITINGPRO_ROOT_URL+'order/GetDocumentDeliveryPeriodList',{params});
  }

  submitDeliveryDocument(docModel:DocumentModel,uploadedDocument:any)
  {
    let formData:FormData = new FormData();  
    formData.append('file',uploadedDocument);
    formData.append('model',JSON.stringify(docModel));  
    return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL+'order/SubmitDeliveryDocument',formData);
  }

  getSubmittedDeliveryDocuments(orderId:string)
  {
    const params = new HttpParams().set('orderId', orderId);
    return this.http.get<Array<DocumentModel>>(this.EDITINGPRO_ROOT_URL+'order/GetSubmittedDeliveryDocuments',{params});
  }

  getSubmittedDeliveryDocumentsForCustomer(orderId:string)
  {
    const params = new HttpParams().set('orderId', orderId);
    return this.http.get<Array<DocumentModel>>(this.EDITINGPRO_ROOT_URL+'order/GetSubmittedDeliveryDocumentsForCustomer',{params});
  }

  IsEditorCanAcceptEnglishEditingOrder(staffID:string)
  {
    const params = new HttpParams().set('staffID', staffID);
    return this.http.get<boolean>(this.EDITINGPRO_ROOT_URL+'order/CanAcceptEnglishEditingOrder',{params});
  }

  getAllDiscountInfoByClientId(clientId:string)
  {
    const params = new HttpParams().set('clientId', clientId);
    return this.http.get<Array<MiscellaneousModel>>(this.EDITINGPRO_ROOT_URL+'order/GetAllDiscountInfoByClientId',{params});
  }

  getPlanCourseDurationInHours(courseDurationInText:string):number
  {
    let inHours:number=0;
    let duration:string=""; 
    if(courseDurationInText.indexOf('min')>-1)  //in minutes
    {
      duration=courseDurationInText.replace("min","");
      inHours=parseFloat(duration)/60;
    }
    else if(courseDurationInText.indexOf('h')>-1)
    {
      duration=courseDurationInText.replace("h","");
      inHours=parseInt(duration);
    }
    else if(courseDurationInText.indexOf('day')>-1)
    {
      duration=courseDurationInText.replace("day","");
      inHours=parseInt(duration)*24;
    }
    else{  //invalid
       inHours=-100000000;
    }
    return inHours;
  }

  getAnticipatedDateTime(courseDurationInText:string):Date 
  {
    let orderTime:Date=new Date();
    orderTime.setHours(orderTime.getHours()+this.getPlanCourseDurationInHours(courseDurationInText));
    return orderTime;
  }

  getRecentPastOrderListByClientId(clientId:string)
  {
    const params = new HttpParams().set('clientId', clientId);
    return this.http.get<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL+'order/GetRecentPastOrderListByClientId',{params});
  }

  updateCompanyCouponInfo(orderNo:string,clientId:string,couponType:string)
  {
    const params={orderNo:orderNo,clientId:clientId,couponType:couponType};
    return this.http.get<boolean>(this.EDITINGPRO_ROOT_URL+"order/UpdateCompanyCouponInfo",{params});
  }

  getInvoiceOrdersByClientId(clientId:string,month:number,year:number)
  {
    const params = new HttpParams().set('clientId', clientId).set('month', month.toString()).set('year', year.toString());
    return this.http.get<Array<OrderWeb>>(this.EDITINGPRO_ROOT_URL+'order/GetInvoiceOrdersByClientId',{params});
  }

  getUsedDiscountCouponName(orderNo:string,clientId:string)
  {
    const params = new HttpParams().set('orderNo', orderNo).set('clientId', clientId.toString());
    return this.http.get<MiscellaneousModel>(this.EDITINGPRO_ROOT_URL+'order/GetUsedDiscountCouponName',{params});
  }

  canAcceptExpertEditingOrder(fieldId: string,editorId:string)
   {
    const params={editorId:editorId,specializedFieldId:fieldId};
    return this.http.get<boolean>(this.EDITINGPRO_ROOT_URL+"order/CanAcceptExpertEditingOrder",{params});
   }

   getMailingAmountByOrderID(orderId: string)
   {
    const params={orderId:orderId};
    return this.http.get<boolean>(this.EDITINGPRO_ROOT_URL+"order/GetMailingAmountByOrderID",{params});
   }

   notifyAdminAboutjapaneseCommentsToEditor(model:MiscellaneousModel)
   {
     return this.http.post<boolean>(this.EDITINGPRO_ROOT_URL+'order/NotifyAdminAboutjapaneseCommentsToEditor',model);
   }

   getOrderCountByEditorId(editorId:string)
  {
    const params = new HttpParams().set('editorId',editorId);
    return this.http.get<number>(this.EDITINGPRO_ROOT_URL+ 'order/GetOrderCountByEditorId',{params});
  }

}
