import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Title,Meta  }     from '@angular/platform-browser';

@Component({
  selector: 'app-quality',
  templateUrl: './quality.component.html',
  styleUrls: ['./quality.component.css']
})
export class QualityComponent implements OnInit {
  router:string;
  shareService:DataShareService;
  shareableData:DataSharingModel;

  constructor(private _router:Router, 
    private _shareService:DataShareService,
    private titleService: Title,
    private meta: Meta) { 
    this.router=_router.url;
    this.shareService=_shareService;

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName=this.shareService.currentCulture=="jp"? "選ばれる理由" : "The reasons for choosing  Editing-Pro.";
    this.shareService.setSharedData(this.shareableData);


    if(this.shareService.currentCulture=='jp'){


      this.titleService.setTitle("英文校正の信頼出来る品質の秘密|editing-Pro.");
      this.meta.updateTag({ name: 'keywords', content: '品質の秘密,品質保証,校正依頼,校正依頼方法,校正方法,校正のコツ,論文校正,エディティング,学術校正,医学論文校正,論文校正専門,論文エディティング,エディティングプロ' });
      this.meta.updateTag({ name: 'description', content: 'レベルによる校正プラン,校正の品質,良い校正の依頼の仕方コツの説明させて頂きます。エディティングプロの品質保証についてもご確認下さい。' });

    }else{

      this.titleService.setTitle("editing-Pro. High quality mechanism that you can trust|editing-Pro.");
      this.meta.updateTag({ name: 'keywords', content: '"quality secret, qualityguarantee,editing order,editing how to order,editing method,editing tips,academic writingediting,editing,academic editing,medical academic writing editing,academic writingeditingprofessional,academic writingediting,editing-Pro."' });
      this.meta.updateTag({ name: 'description', content: '"editing plan and order tips that offers high quolity editing. editing-Pro. High quality guaranteed, editing plans at a variety of levels, quality of editing, tips for ordering editing jobs, check quality guarantee for editing pro."' });

    }
  }

  ngOnInit() {
  }

}
