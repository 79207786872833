import { Component, OnInit } from '@angular/core';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { Router } from '@angular/router';
import { DataShareService } from 'src/app/services/data-share.service';
import { Title, Meta } from '@angular/platform-browser';
import { NoticeModel } from 'src/app/models/notice.model';
import { DataSharingModel } from 'src/app/models/data-sharing-model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css']
})
export class NoticeComponent implements OnInit {

  affiliateService: AffiliateService;
  noticeList: { currentPage: number, totalItems: number, dataSource: NoticeModel[] };
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  noticeDetails: NoticeModel;

  constructor(private _affiliateService: AffiliateService, private _router: Router,
    private dataShareService: DataShareService,
    private titleService: Title,
    private meta: Meta) {

    this.router = _router.url;
    this.sharedService = dataShareService;
    this.affiliateService = _affiliateService;
    this.noticeDetails = new NoticeModel();

    this.noticeList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = "お知らせ";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("お知らせ | 翻訳プロ(editing-Pro.)管理者ページ");
    this.meta.addTag({ name: 'keywords', content: '英文校正, editing-pro, 管理者, お知らせ' });
    this.meta.addTag({ name: 'description', content: 'エディティング者プラスコーディネーターによるエディティングチェックで、簡単、確実な英語エディティングサービスをご提供中。高品質なエディティングをお届けします。お見積りは無料。' });

    this.getNoticeList();
  }

  getNoticeList(pageNo: number = 1) {
    if (localStorage.getItem("currentUser")) {
      this.affiliateService.getAllNoticeList().subscribe(data => {
        if (data) {
          this.noticeList.currentPage = pageNo;
          this.noticeList.dataSource = data;
          if (data.length > 0)
            this.noticeList.totalItems = data[0].TotalCount ? data[0].TotalCount : data.length;
        }
      });
    }
  }

  onNoticeListPageIndexChange(event: PageChangedEvent) {
    if (localStorage.getItem("currentUser")) {
      this.getNoticeList(event.page);
    }
  }

  showNotice(notice: NoticeModel) {
    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    let DateJP = new Date(notice.RegisteredDate).toLocaleDateString('ja-JP', options);
    this.noticeDetails = notice;
    this.noticeDetails.RegisteredDateJP = DateJP;

  }

  ngOnInit() {
  }

}
