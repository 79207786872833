import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { CommonListService } from '../../services/common-list.service';
import { SoftwareSkillModel } from '../../models/software-skill.model';
import { LanguageModel } from '../../models/language.model';
import { LanguageLevelModel } from '../../models/language-level.model';
import { EducationModel } from '../../models/education.model';
import { CountryModel } from '../../models/country-model.model';
import { SpecializedFieldModel } from '../../models/specialized-field.model';
import { PrefectureModel } from '../../models/prefecture-model.model';
import { StaffWorkingStatusModel } from '../../models/staff-working-status.model';
import { BankModel } from '../../models/bank.model';
import { BankAccountTypeModel } from '../../models/bank-account-type.model';
import { BankBranchModel } from '../../models/bank-branch.model';
import { MajorSubjectModel } from '../../models/major-subject.model';
import { MajorSubjectDetailsModel } from '../../models/major-subject-details.model';
import { StaffModel } from '../../models/staff.model';
import { UtilityService } from '../../services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { EditorService } from '../../services/editor.service';
import { StaffEducationModel } from '../../models/staff-education.model';
import { BankAccountModel } from '../../models/bank-account.model';
import { StaffProfesionalSpecialityModel } from '../../models/staff-profesional-speciality.model';
import { StaffPresentStatusModel } from '../../models/staff-present-status.model';
import { StaffSoftwareSkillModel } from '../../models/staff-software-skill.model';
import { Title, Meta } from '@angular/platform-browser';
import { AppSetting } from '../../app-setting';
import { CredentialModel, UserType } from '../../models/credential.model';
import { NotificationType } from '../../Enums.enum';
import { StaffEnglishTypeModel } from '../../models/staff-english-type.model';
import { EnglishLanguageTypeModel } from '../../models/english-language-type.model';
import { MasterCurrencyModel } from '../../models/master-currency.model';

@Component({
  selector: 'app-editor-profile',
  templateUrl: './editor-profile.component.html',
  styleUrls: ['./editor-profile.component.css']
})
export class EditorProfileComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  commonListService: CommonListService;
  shareableData: DataSharingModel;
  yearOptions: number[];
  dayOptions: number[];
  birthDate: { Day: number, Month: number, Year: number };
  softwareSkills: Array<SoftwareSkillModel>;
  languages: Array<LanguageModel>;
  languageLevels: Array<LanguageLevelModel>;
  educationalDegrees: Array<EducationModel>;
  countries: Array<CountryModel>;
  specializedFields: Array<SpecializedFieldModel>;
  prefectures: Array<PrefectureModel>;
  workingStatusList: Array<StaffWorkingStatusModel>;
  bankList: Array<BankModel>;
  bankAccountTypeList: Array<BankAccountTypeModel>;
  bankBranchList: Array<BankBranchModel>;
  majorSubjectList: Array<MajorSubjectModel>;
  majorSubjectDetailsList: Array<MajorSubjectDetailsModel>;
  editorModel: StaffModel;
  utility: UtilityService;
  imagesFiles: any = null;
  toastrService: ToastrService;
  editorService: EditorService;
  routerService: Router;
  imageBaseUrl: string = AppSetting.IMAGE_BASE_URL;
  englishTypeList: Array<EnglishLanguageTypeModel>;
  payPalCurrencyList:Array<MasterCurrencyModel>;

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private _editorService: EditorService,
    private _utility: UtilityService,
    private _commonListService: CommonListService,
    private titleService: Title,
    private meta: Meta,
    private _toastrService: ToastrService) {
    this.router = _router.url;

    this.sharedService = dataShareService;
    this.commonListService = _commonListService;
    this.editorService = _editorService;
    this.toastrService = _toastrService;
    this.utility = _utility;

    this.shareableData = new DataSharingModel();
    this.softwareSkills = new Array<SoftwareSkillModel>();
    this.languages = new Array<LanguageModel>();
    this.languageLevels = new Array<LanguageLevelModel>();
    this.educationalDegrees = new Array<EducationModel>();
    this.countries = new Array<CountryModel>();
    this.specializedFields = new Array<SpecializedFieldModel>();
    this.prefectures = new Array<PrefectureModel>();
    this.workingStatusList = new Array<StaffWorkingStatusModel>();
    this.majorSubjectList = new Array<MajorSubjectModel>();
    this.majorSubjectDetailsList = new Array<MajorSubjectDetailsModel>();
    this.bankList = Array<BankModel>();
    this.bankAccountTypeList = Array<BankAccountTypeModel>();
    this.bankBranchList = Array<BankBranchModel>();
    this.editorModel = new StaffModel();
    this.editorModel.Education1 = new StaffEducationModel();
    this.editorModel.Education2 = new StaffEducationModel();
    this.editorModel.Education3 = new StaffEducationModel();
    this.editorModel.Staffbank = new BankAccountModel();
    this.editorModel.StaffEnglishTypeList = Array<StaffEnglishTypeModel>();
    this.englishTypeList = Array<EnglishLanguageTypeModel>();
    this.payPalCurrencyList=Array<MasterCurrencyModel>();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = this.dataShareService.currentCulture == 'jp' ? "登録情報修正" : "Profile Edit";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("登録情報を確認（英文エディター用）");
    this.meta.updateTag({ name: 'keywords', content: '登録情報修正,英文エディティング,支払い確認,校正,オンライン校正,英語校正,英語,英文エディター,校正会社,論文校正,英語論文チェック' });
    this.meta.updateTag({ name: 'description', content: '英文校正サイトの登録情報を確認し,サイトをより的確に活用してみましょう。納品物の受領までウェブ上で完結。簡単,早い,確実！お問い合わせ,お見積りは無料。' });

    this.fillEnglishTypeList();
    this.initDateOfBirthFields();
    this.getSoftwareSkills();
    this.getLanguageList();
    this.getLanguageLevelList();
    this.getEducationalDegreeList();
    this.getCountryList();
    this.getSpecializedFieldList();
    this.getPrefectureList();
    this.getWorkingStatusList();
    this.getMajorSubjectList();
    this.getBankList();
    this.getBankAccountTypeList();
    this.getPaypalCurrencyList();
  }

  private fillEnglishTypeList() {
    let british: EnglishLanguageTypeModel = {
      ID: 1, Name_en: 'British English', Name_jp: 'イギリス英語', IsSelected: false
    };
    let american: EnglishLanguageTypeModel = {
      ID: 2, Name_en: 'American english', Name_jp: 'アメリカ英語', IsSelected: false
    };
    let australian: EnglishLanguageTypeModel = { ID: 3, Name_en: 'Australian English', Name_jp: 'オーストラリア英語', IsSelected: false };
    let other: EnglishLanguageTypeModel = {
      ID: 4, Name_en: 'Other English', Name_jp: 'その他地域の英語', IsSelected: false
    };
    this.englishTypeList.push(british, american, australian, other);
    //console.log('englishTypeList',this.englishTypeList);
  }

  private getSoftwareSkills() {
    this.commonListService.getAllStaffSoftwareSkills(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.softwareSkills = data;
    });
  }

  private getLanguageList() {
    this.commonListService.getAllLanguages(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.languages = data;
    });
  }

  private getLanguageLevelList() {
    this.commonListService.getLanguageLevelList(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.languageLevels = data;
    });
  }

  private getEducationalDegreeList() {
    this.commonListService.getEducationalDegreeList(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.educationalDegrees = data;
    });
  }

  private getCountryList() {
    this.commonListService.getCountries(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.countries = data;
        if(this.editorModel.Staffbank && this.editorModel.Staffbank.BankID && this.editorModel.Staffbank.CountryID){
          let country = this.countries.find(c=>c.ID==this.editorModel.Staffbank.CountryID);
         if(country){
          this.editorModel.Staffbank.Country=country;
          this.editorModel.Staffbank.CountryName = country.Name;
         }
        }
      this.getPrefectureList();
    });
  }

  private getPrefectureList() {
    //country ID 1 for Japan
    this.commonListService.getPrefecturesByCountryID(1, this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.prefectures = data;
    });
  }

  private getSpecializedFieldList() {
    this.commonListService.getSpecializedFieldList(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.specializedFields = data;
    });
  }

  private initDateOfBirthFields() {
    this.yearOptions = _.range(1951, new Date().getFullYear() + 1);
    this.dayOptions = _.range(1, 31 + 1);
  }
  private getBirthDate(): Date {
    return new Date(this.birthDate.Year, this.birthDate.Month - 1, this.birthDate.Day);
  }

  private getWorkingStatusList() {
    this.commonListService.getWorkingStatusList(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.workingStatusList = data;
    });
  }

  private getMajorSubjectList() {
    this.commonListService.getMajorSubjectList(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.majorSubjectList = data;
    });
  }

  getMajorSubjectDetailsList(majorsubjectId: string) {
    this.commonListService.getMajorSubjectDetailList(majorsubjectId, this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.majorSubjectDetailsList = data;
    });
  }

  public getBankList(countryId:number=0) {
    if (countryId) {
      this.commonListService.getBankList(countryId, this.sharedService.currentCulture).subscribe((data) => {
        if (data)
          this.bankList = data;
          if(this.editorModel.Staffbank.BankID){
            let bank = this.bankList.find(b=> b.ID==this.editorModel.Staffbank.BankID);
            if(bank)
            this.editorModel.Staffbank.BankName = bank.Name;
          }
      });
    }
  }

  checkLimit(event,item){
    let specialFields= _.filter(this.specializedFields,function(item:SpecializedFieldModel){
      return item.IsSelected==true;
     });
     if(specialFields.length>4)
     {
       if(item.IsSelected!=true)
        {event.preventDefault();
        event.stopPropagation();
       this.toastrService.error(this.shareableData.currentStateName =='jp'? "一度に選択することはできるのは5人までです。":"Sorry! You cannot select more than 5 at once.");//Sorry! You cannot select more than 5 at once.
        }
       
     }
  }

  private getCountryByBankId(){
    if(this.editorModel.Staffbank.BankID)
    {
      this.commonListService.getCountryByBankId(this.editorModel.Staffbank.BankID, this.sharedService.currentCulture).subscribe((data)=>{
        if(data)
        this.editorModel.Staffbank.CountryID= data.ID;
        this.getBankList(this.editorModel.Staffbank.CountryID);
      });
    }
  }

  private getBankAccountTypeList() {
    this.commonListService.getBankAccountTypeList(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.bankAccountTypeList = data;
    });
  }

  getBankBranchListByBankId(bankId: string) {
    if(bankId)
    {
      this.commonListService.getBankBranchListByBankId(bankId, this.sharedService.currentCulture).subscribe((data) => {
        if (data)
          this.bankBranchList = data;

          if(this.editorModel.Staffbank.BankBranchID){
            let branch = this.bankBranchList.find(bb=>bb.ID==this.editorModel.Staffbank.BankBranchID);
            if(branch){
              this.editorModel.Staffbank.BankBranch = branch;
              this.editorModel.Staffbank.BankBranchName = branch.Name;
            }
          }
      });
    }

  }

  getPaypalCurrencyList(){
    this.commonListService.getPaypalCurrencyList(this.sharedService.currentCulture).subscribe((data)=>{
      if(data)
      this.payPalCurrencyList = data;
    });
  }

  setFiles(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      //this.toastrService.warning("イメージのみサポートされています。");//"Only images are supported."
      this.sharedService.showTranslationToastr('NOTIFICATION.ONLY_IMAGES_ARE_SUPPORTED', NotificationType.WARNING);
      return;
    }
    this.imagesFiles = files;
  };

  isEditorAlreadyExists() {
    if (!this.editorModel.StaffEmailID || this.editorModel.StaffEmailID == "")
      return;
    this.editorService.checkExistingEditor(this.editorModel.StaffEmailID, 'jp').subscribe((data) => {
      if (data === true) {
        //this.toastrService.error("ご登録いただいたＥメールＩＤはすでに登録されています。別のものをお使いください。");//'Email ID already exist,Please use another one!'
        this.sharedService.showTranslationToastr('NOTIFICATION.EMAIL_ID_ALREADY_EXIST_PLEASE_USE_ANOTHER_ONE', NotificationType.ERROR);
      }
      else {
        // this.toastrService.success("このＥメールＩＤは利用可能です。");//'Email ID Available!'
        this.sharedService.showTranslationToastr('NOTIFICATION.EMAIL_ID_AVAILABLE', NotificationType.SUCCESS);
      }

    });
  }

  previewImage() {
    if (this.imagesFiles)
      this.utility.getImageDataURL(this.imagesFiles);
  }

  private mapEditorModelData() {
    //education
    let educations: Array<StaffEducationModel> = new Array<StaffEducationModel>();
    if (this.editorModel.Education1.StaffEducationDegreeID) {
      let eItem: StaffEducationModel = new StaffEducationModel();
      eItem.StaffEducationDegreeID = this.editorModel.Education1.StaffEducationDegreeID;
      this.editorModel.EducationalDegree1 = this.editorModel.Education1.StaffEducationDegreeID;
      eItem.InstituteName_jp = this.editorModel.Education1.InstituteName_jp;
      eItem.Department_jp = this.editorModel.Education1.Department_jp;
      eItem.MajorSubjectID = this.editorModel.Education1.MajorSubjectID;
      eItem.MajorSubjectDetailsID = this.editorModel.Education1.MajorSubjectDetailsID;
      educations.push(eItem);
    }

    if (this.editorModel.Education2.StaffEducationDegreeID) {
      let eItem: StaffEducationModel = new StaffEducationModel();
      eItem.StaffEducationDegreeID = this.editorModel.Education2.StaffEducationDegreeID;
      this.editorModel.EducationalDegree2 = this.editorModel.Education2.StaffEducationDegreeID;
      eItem.InstituteName_jp = this.editorModel.Education2.InstituteName_jp;
      eItem.Department_jp = this.editorModel.Education2.Department_jp;
      eItem.MajorSubjectID = this.editorModel.Education2.MajorSubjectID;
      eItem.MajorSubjectDetailsID = this.editorModel.Education2.MajorSubjectDetailsID;
      educations.push(eItem);
    }

    if (this.editorModel.Education3.StaffEducationDegreeID) {
      let eItem: StaffEducationModel = new StaffEducationModel();
      eItem.StaffEducationDegreeID = this.editorModel.Education3.StaffEducationDegreeID;
      this.editorModel.EducationalDegree3 = this.editorModel.Education3.StaffEducationDegreeID;
      eItem.InstituteName_jp = this.editorModel.Education3.InstituteName_jp;
      eItem.Department_jp = this.editorModel.Education3.Department_jp;
      eItem.MajorSubjectID = this.editorModel.Education3.MajorSubjectID;
      eItem.MajorSubjectDetailsID = this.editorModel.Education3.MajorSubjectDetailsID;
      educations.push(eItem);
    }

    this.editorModel.Educations = educations;

    //working status
    let wsList = _.filter(this.workingStatusList, function (item: StaffWorkingStatusModel) {
      return item.IsSelected == true;
    });
    let presetnStatusList = new Array<StaffPresentStatusModel>();
    _.each(wsList, function (item: StaffWorkingStatusModel) {
      let ps = new StaffPresentStatusModel();
      ps.StaffEmploymentTypeID = item.ID;
      presetnStatusList.push(ps);
    });

    this.editorModel.StaffPresentStatus = presetnStatusList;
    //software Skill
    let sSkills = _.filter(this.softwareSkills, function (item: SoftwareSkillModel) {
      return item.IsSelected == true;
    });

    let staffSSkills = new Array<StaffSoftwareSkillModel>();
    _.each(sSkills, function (item: SoftwareSkillModel) {
      let m: StaffSoftwareSkillModel = new StaffSoftwareSkillModel();
      m.StaffSoftwareSkillID = item.ID;
      staffSSkills.push(m);
    });

    this.editorModel.staffsoft = staffSSkills;

    //Specialized Fields
    let specialFields = _.filter(this.specializedFields, function (item: SpecializedFieldModel) {
      return item.IsSelected == true;
    });

    let staffSpecializedFields = Array<StaffProfesionalSpecialityModel>();
    _.each(specialFields, function (item: SpecializedFieldModel) {
      let s = new StaffProfesionalSpecialityModel();
      s.ProfessionalID = item.ID;
      staffSpecializedFields.push(s);
    });

    this.editorModel.StaffProfessional = staffSpecializedFields;

    //Birthdate
    this.editorModel.BirthDate = this.getBirthDate();
    this.editorModel.ApplicationID = 3;

    //Staff English TypeList
    this.editorModel.StaffEnglishTypeList = [];
    this.englishTypeList.forEach(item => {
      if (item.IsSelected) {
        let x: StaffEnglishTypeModel = new StaffEnglishTypeModel();
        x.EnglishType = item.ID;
        x.StaffID = this.sharedService.currentUser.ID;
        this.editorModel.StaffEnglishTypeList.push(x);
      }
    });

  }

  private mapDomainDataToModel() {
    //birthdate
    let date = new Date(this.editorModel.BirthDate);
    this.birthDate.Day = date.getDate();
    this.birthDate.Month = date.getMonth() + 1;
    this.birthDate.Year = date.getFullYear();
    //educations
    let eduDeg1 = this.editorModel.EducationalDegree1;
    let eduDeg2 = this.editorModel.EducationalDegree2;
    let eduDeg3 = this.editorModel.EducationalDegree3;
    if (eduDeg1) {
      this.editorModel.Education1 = _.find(this.editorModel.Educations, function (item: StaffEducationModel) {
        return item.StaffEducationDegreeID == eduDeg1;
      });
      this.getMajorSubjectDetailsList(this.editorModel.Education1.MajorSubjectID);
    }
    else {
      this.editorModel.Education1 = new StaffEducationModel();
    }
    if (eduDeg2) {
      this.editorModel.Education2 = _.find(this.editorModel.Educations, function (item: StaffEducationModel) {
        return item.StaffEducationDegreeID == eduDeg2;
      });
      this.getMajorSubjectDetailsList(this.editorModel.Education2.MajorSubjectID);
    }
    else {
      this.editorModel.Education2 = new StaffEducationModel();
    }
    if (eduDeg3) {
      this.editorModel.Education3 = _.find(this.editorModel.Educations, function (item: StaffEducationModel) {
        return item.StaffEducationDegreeID == eduDeg3;
      });
      this.getMajorSubjectDetailsList(this.editorModel.Education3.MajorSubjectID);
    }
    else {
      this.editorModel.Education3 = new StaffEducationModel();
    }
    //bank account
    if (!this.editorModel.Staffbank) {
      this.editorModel.Staffbank = new BankAccountModel();
    }
    else {
      this.getBankBranchListByBankId(this.editorModel.Staffbank.BankID);
    }

    //current state /working status
    let currentStateList = this.editorModel.StaffCurrentState;
    _.each(this.workingStatusList, function (item) {
      let obj = _.find(currentStateList, function (c) { return c.CurrentStateID == item.ID; });
      if (obj) item.IsSelected = true;
      else item.IsSelected = false;
    });

    //specialized Fields
    let specialities = this.editorModel.StaffProfessional;
    _.each(this.specializedFields, function (item) {
      let obj = _.find(specialities, function (p) { return p.ProfessionalID == item.ID; });
      if (obj) item.IsSelected = true;
      else item.IsSelected = false;
    });
    // software skills
    let softskills = this.editorModel.staffsoft;
    _.each(this.softwareSkills, function (item) {
      let obj = _.find(softskills, function (s) { return s.StaffSoftwareSkillID == item.ID; });
      if (obj) item.IsSelected = true;
      else item.IsSelected = false;
    });

    //Staff EnglishType
    let englishList = this.editorModel.StaffEnglishTypeList;
    _.each(this.englishTypeList, function(item){
      let obj = _.find(englishList, function(s){
        return s.EnglishType ==item.ID;
      });
      if(obj) item.IsSelected=true;
      else item.IsSelected=false;
    });
}

  updateEditor() {
    debugger;
    this.mapEditorModelData();
    this.editorService.updateEditor(this.editorModel, this.imagesFiles).subscribe((data) => {
      if (data == true) {
        //this.toastrService.success("アップデートできました。");//"Successfully Updated!"
        this.sharedService.showTranslationToastr('NOTIFICATION.SUCCESSFULLY_UPDATED', NotificationType.SUCCESS);
      }
      else
        this.sharedService.showTranslationToastr('NOTIFICATION.COULD_NOT_BE_UPDATED', NotificationType.ERROR);
      //this.toastrService.error("アップデートに失敗しました。");//"Could not be Updated!"
    });
  }

  ngOnInit() {
    this.birthDate = { Day: 1, Month: 1, Year: 1990 };
    this.editorModel.Sex = true;
    this.editorModel.IsJapanese = true;
    this.editorModel.StaffTypeID = 6; //editor
    this.editorService.getEditorDetailsById(this.sharedService.currentUser.ID).subscribe((data) => {
      if (data) {
        this.editorModel = data;
        this.getCountryList();
        this.mapDomainDataToModel();
        this.getCountryByBankId();
        this.getBankBranchListByBankId(this.editorModel.Staffbank.BankID);
      }
    });

  }
}