import { Component, OnInit } from '@angular/core';
import { EditorService } from '../../services/editor.service';
import { CommonListService } from '../../services/common-list.service';
import { SpecializedFieldModel } from '../../models/specialized-field.model';
import { EditorSearchModel } from '../../models/editor-search.model';
import * as _ from 'underscore';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import {AppSetting} from '../../app-setting';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {DataShareService} from '../../services/data-share.service';
import { ToastrService } from 'ngx-toastr';
import { EditingPreferenceModel } from '../../models/editing-pro-preference-model.model';
import { UtilityService } from 'src/app/services/utility.service';


@Component({
  selector: 'app-editor-search-window',
  templateUrl: './editor-search-window.component.html',
  styleUrls: ['./editor-search-window.component.css']
})
export class EditorSearchWindowComponent implements OnInit {
  editorService: EditorService;
  commonListService: CommonListService;
  specialitiesList:Array<SpecializedFieldModel>;
  searchModel:{isOnlyNative:boolean,specialityId:string, editorNo:number, isFavoriteEditor:boolean, isPastNominationEditor:boolean, isPastRequestEditor:boolean};
  editorList:Array<EditorSearchModel>;
  pageNumber:number=1;
  pageSize:number=8;
  totalItems:number;
  imageBaseUrl:string=AppSetting.IMAGE_BASE_URL;
  bsModalRef: BsModalRef;
  sharedService:DataShareService;
  selectedEditors:Array<number>;
  servicePreference:EditingPreferenceModel;


  constructor(private _editorService: EditorService,
    private _commonListService: CommonListService,
    private modalService: BsModalService,
    private dataShareService:DataShareService,
    private utility:UtilityService,
    private _toastrService:ToastrService ) 
    { 
    this.editorService = _editorService;
    this.commonListService = _commonListService;
    this.sharedService=dataShareService;
    this.servicePreference=new EditingPreferenceModel();
    this.specialitiesList=new Array<SpecializedFieldModel>();
    this.searchModel={isOnlyNative:false,specialityId:null, editorNo:null, isFavoriteEditor:false, isPastNominationEditor:false, isPastRequestEditor:false};
    this.editorList=new Array<EditorSearchModel>();
    if(!this.sharedService.SelectedEditorsForEstimation)
    {
      this.sharedService.SelectedEditorsForEstimation = new Array<number>();
      this.selectedEditors=new Array<number>();
    }
    else
    {
      let copy:Array<number>=this.sharedService.SelectedEditorsForEstimation;
      this.selectedEditors=new Array<number>();
      this.selectedEditors.concat(copy);
    }

    if(this.sharedService.getModalData())
    {
      this.servicePreference=this.sharedService.getModalData();
      
    }

    this.totalItems=0;

    this.getSpecilitites();
    this.searchEditorNow();
    //this.searchEditorList();
  }

  selectEditor(editor)
  {
    if(this.selectedEditors.length<5)
    {
      let item=_.find(this.selectedEditors,function(r:number) {
        return r==editor.RegistrationID;
      });

      if(!item) //not already in the array
      {
        this.selectedEditors.push(editor.RegistrationID);
        editor.IsAlreadySelected=true;
    
      }
      else{
        this._toastrService.warning('エディターは既に選択されています。'); //"The editor is already selected."
      }
    }
    else
    {
      this._toastrService.warning('一度に選択することはできるのは5人までです。'); //"Sorry! You cannot select more than 5 at once."
    }
  }
  removeEditor(editor)
  {
    this.selectedEditors=_.without(this.selectedEditors, editor.RegistrationID);
    editor.IsAlreadySelected=false;
    
  }

  clearFilter()
  {
    this.searchModel.specialityId=null;
    this.searchModel.isFavoriteEditor=false;
    this.searchModel.isOnlyNative=false;
    this.searchModel.isPastNominationEditor=false;
    this.searchModel.isPastRequestEditor=false;
    this.searchModel.editorNo=null;
    this.searchEditorNow();
  }

  onPageIndexChange(event: PageChangedEvent) {
     this.pageNumber=event.page;
  }
  
  private mapEditorListData(data)
  { 
    let alreadySleectedList=this.selectedEditors;
    _.each(data,function(editor){
      let regid=_.find(alreadySleectedList,function(item){
        return item==editor.RegistrationID;
       });

       if(regid)
       {
         editor.IsAlreadySelected=true;
       }
       else
       {
        editor.IsAlreadySelected=false;
       }
    });
     return data;
  }
  // searchEditorList()
  // {
  //   this.editorService.searchEditor().subscribe((data)=>{
  //     if(data)

  //   })
  // }

  searchEditorNow()
  {
    this.pageNumber=1;
    let isOnlyFirstEditor:boolean=this.servicePreference.IsDoubleCheck?false:true;
    let customerId:string = (localStorage.getItem("currentUser"))?JSON.parse(localStorage.getItem("currentUser")).ID: null;
    this.editorService.searchEditorForEstimation(customerId, this.searchModel.specialityId, this.searchModel.isOnlyNative, this.searchModel.isFavoriteEditor, this.searchModel.isPastNominationEditor, this.searchModel.isPastRequestEditor, this.searchModel.editorNo,isOnlyFirstEditor).subscribe((data)=>{
      if(data)
      {
        console.log('data:',data);
        this.editorList=this.mapEditorListData(data);
        this.totalItems=data.length;
        _.each(this.editorList,function(item:EditorSearchModel){
         let spList=_.pluck(item.Speciality.slice(0,5), 'Name');
         item.SpecializedFiled=spList.join(',');
        });

        this.editorList.forEach(item=>{
          item.SpecializedFiled=this.utility.getClippedText(item.SpecializedFiled,this.sharedService.currentCulture);
        });

      }
      else{
        this.editorList=new Array<EditorSearchModel>();
        this.totalItems=0;
      }
    });
  }

  getSpecilitites()
  {
    this.commonListService.getSpecializedFieldList().subscribe((data)=>{
      this.specialitiesList=data;
    });
  }

  removeFromList(regNo:number)
  {
    this.selectedEditors=_.without(this.selectedEditors,regNo);
    let selectedEditor:EditorSearchModel=this.editorList.find(item=>item.RegistrationID==regNo);
    if(selectedEditor)
    {
      selectedEditor.IsAlreadySelected=false;
    }

  }

  confirmSelection()
  {
    this.sharedService.SelectedEditorsForEstimation=this.selectedEditors;
    localStorage.setItem("SelectedEditorsForEstimation",JSON.stringify(this.sharedService.SelectedEditorsForEstimation));
    this.sharedService.bsModalRef.hide();
  }


  ngOnInit() {
  }

}
