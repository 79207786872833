import { Component, OnInit } from '@angular/core';
import { ChangePasswordModel } from '../../models/change-password.model';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Router } from '@angular/router';
import { CustomerService } from '../../services/customer-service.service';
import { ToastrService } from 'ngx-toastr';
import {ConfirmDialogComponent, confirmDialogConfig} from '../../confirm-dialog/confirm-dialog.component';
import { BsModalService, BsModalRef, ModalOptions} from 'ngx-bootstrap/modal';
import { AuthenticationService } from '../../services/authentication.service';
import { Title,Meta  }     from '@angular/platform-browser';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  passwordChangeModel:ChangePasswordModel=new ChangePasswordModel();
  customerService:CustomerService;
  toastr:ToastrService;
  ngbModalOptions:ModalOptions;

  constructor(private _router: Router,
              private dataShareService:DataShareService,
              private _customerService:CustomerService,
              private _toastr:ToastrService,
              private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private _authService:AuthenticationService,
    private titleService: Title,
    private meta: Meta) {
    this.router=_router.url;
    this.sharedService=dataShareService;
    this.customerService=_customerService;
    this.toastr=_toastr;

    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName=this.sharedService.currentCulture=='jp'?"パスワード変更":"password change";
    this.sharedService.setSharedData(this.shareableData);



    if(this.sharedService.currentCulture=='jp'){


      this.titleService.setTitle("英文校正サイトのパスワード変更");
      this.meta.updateTag({ name: 'keywords', content: 'パスワード変更,英語校正,英語,専門エディター校正,英語エディター,校正会社,論文校正,英語論文チェック,校正チェック' });
      this.meta.updateTag({ name: 'description', content: '英文校正サイトのアカウントパスワードを変更したい場合はこちら。納品物の受領までウェブ上で完結。簡単,早い,確実！お問い合わせ,お見積りは無料。' });

    }
    else{

      this.titleService.setTitle("editing-Pro. Password change");
      this.meta.updateTag({ name: 'keywords', content: 'password change,English editing,English,professional editorediting,English editor,editing company,academic writing editing,English academic writing check,editing check' });
      this.meta.updateTag({ name: 'description', content: 'Change account password of editing-Pro. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });



    }

    if(localStorage.getItem("currentUser"))
    this.passwordChangeModel.EmailID=JSON.parse(localStorage.getItem("currentUser")).Email;
    this.passwordChangeModel.CurrentPassword = null;

    let config:confirmDialogConfig={
      MessageText:"パスワード変更に成功しました 再度ログインしてください",
      IsOkOnly:true,
      OKBtnCallback:this.afterConfirm.bind(this)
      };
     this.ngbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      class: "modal-sm",
      initialState:config
    };
    
   
   }

   afterConfirm()
   {
       this._authService.loggedOut();
   }

   changePassword()
   {
     this.customerService.changePassword(this.sharedService.currentUser.ID,this.passwordChangeModel.NewPassword).subscribe((data)=>{
       if(data==true)
       {
        //this.toastr.success('Password has been changed successfully!');
        this.bsModalRef= this.modalService.show(ConfirmDialogComponent,this.ngbModalOptions);
       }
       else
       {
        this.toastr.error('保存できませんでした。'); //'Could not change the password!'
       }
     });
   }



  ngOnInit() {
  }

}
