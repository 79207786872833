import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditorDashboardComponent } from './editor-dashboard/editor-dashboard.component';
import { EditorProfileComponent } from './editor-profile/editor-profile.component';
import { EditorMyPageComponent } from './editor-my-page/editor-my-page.component';
import { ErrorReportComponent } from './error-report/error-report.component';
import { GuideComponent } from './guide/guide.component';
import { MessageListComponent } from './message-list/message-list.component';
import { MessageDetailsComponent } from './message-details/message-details.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AuthGuard } from '../guards/auth-guard.service';
import { RoleGuardService as RoleGuard } from '../guards/role-guard.service';
import { PenaltyComponent } from './penalty/penalty.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import{ ProfilePreviewComponent} from './profile-preview/profile-preview.component';
import { MessageComponent } from './message/message.component';
import { EditorOrderListComponent } from './editor-order-list/editor-order-list.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import {PaymentInfoComponent} from './payment-info/payment-info.component';
import { OrderOfferComponent } from './order-offer/order-offer.component';

const prefix:string =localStorage.getItem("currentCulture") ? localStorage.getItem("currentCulture") : 'jp';

const routes: Routes = [
  {
    path: 'editor', component: EditorDashboardComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      UserType: '2'
    },
    children: [
      { path: 'my-page', component: EditorMyPageComponent },
      { path: 'profile', component: EditorProfileComponent },
      { path: 'error-report', component: ErrorReportComponent },
      { path: 'guide', component: GuideComponent },
      { path: 'message-list', component: MessageListComponent },
      { path: 'order-offer', component: OrderOfferComponent },
      { path: 'message', component: MessageComponent },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'message-details/:id', component: MessageDetailsComponent },
      { path: 'penalty', component: PenaltyComponent },
      { path: 'profile-preview', component: ProfilePreviewComponent },
      { path: 'payment', component: PaymentInfoComponent },
      { path: 'order-list', component: EditorOrderListComponent},
      { path: 'order-detail/:orderNo', component: OrderDetailsComponent},
      { path: 'leave', component: UnsubscribeComponent }
    ]
  },
  {
    path: 'en/editor', component: EditorDashboardComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      UserType: '2'
    },
    children: [
      { path: 'my-page', component: EditorMyPageComponent },
      { path: 'profile', component: EditorProfileComponent },
      { path: 'error-report', component: ErrorReportComponent },
      { path: 'guide', component: GuideComponent },
      { path: 'message-list', component: MessageListComponent },
      { path: 'order-offer', component: OrderOfferComponent },
      { path: 'message', component: MessageComponent },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'message-details/:id', component: MessageDetailsComponent },
      { path: 'penalty', component: PenaltyComponent },
      { path: 'profile-preview', component: ProfilePreviewComponent },
      { path: 'payment', component: PaymentInfoComponent },
      { path: 'order-list', component: EditorOrderListComponent},
      { path: 'order-detail/:orderNo', component: OrderDetailsComponent},
      { path: 'leave', component: UnsubscribeComponent }
    ]
  },
  {
    path: 'jp/editor', component: EditorDashboardComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      UserType: '2'
    },
    children: [
      { path: 'my-page', component: EditorMyPageComponent },
      { path: 'profile', component: EditorProfileComponent },
      { path: 'error-report', component: ErrorReportComponent },
      { path: 'guide', component: GuideComponent },
      { path: 'message-list', component: MessageListComponent },
      { path: 'order-offer', component: OrderOfferComponent },
      { path: 'message', component: MessageComponent },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'message-details/:id', component: MessageDetailsComponent },
      { path: 'penalty', component: PenaltyComponent },
      { path: 'profile-preview', component: ProfilePreviewComponent },
      { path: 'payment', component: PaymentInfoComponent },
      { path: 'order-list', component: EditorOrderListComponent},
      { path: 'order-detail/:orderNo', component: OrderDetailsComponent},
      { path: 'leave', component: UnsubscribeComponent }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EditorRoutingModule { }
