import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Title, Meta } from '@angular/platform-browser';
import { EstimationService } from '../../services/estimation.service';
import { OrderWeb } from '../../models/order-web.model';
import { EditingPreferenceModel } from '../../models/editing-pro-preference-model.model';
import { UtilityService } from '../../services/utility.service';
import { CommonListService } from '../../services/common-list.service';
import { EditorSearchModel } from '../../models/editor-search.model';
import { EditorService } from '../../services/editor.service';
import { AppSetting } from '../../app-setting';
import { CustomerService } from '../../services/customer-service.service';
import { ToastrService } from 'ngx-toastr';
import { OrderEvaluationModel } from '../../models/order-evaluation-model.model';
import { EvaluationService } from '../../services/evaluation.service';

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.css']
})
export class EvaluationComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  estimationService: EstimationService;
  orderDetails: OrderWeb;
  commonService: CommonListService;
  firstEditor: EditorSearchModel;
  secondEditor: EditorSearchModel;
  editorService: EditorService;
  imageBaseUrl: string = AppSetting.IMAGE_BASE_URL;
  customerService: CustomerService;
  IsShowSecondEditor:boolean;
  IsAlreadyFavoriteEditor: boolean;
  IsAlreadyBlackListedEditor: boolean;
  IsAlreadyFavoriteEditor2nd: boolean;
  IsAlreadyBlackListedEditor2nd: boolean;
  orderEvaluation: OrderEvaluationModel;
  evaluationService: EvaluationService;

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private titleService: Title,
    private route: ActivatedRoute,
    private _estimationService: EstimationService,
    private toastr: ToastrService,
    private _evaluationService: EvaluationService,
    private _commonService: CommonListService,
    private _customerService: CustomerService,
    private _editorService: EditorService,
    private utilityService: UtilityService,
    private meta: Meta) {

    this.router = _router.url;
    this.sharedService = dataShareService;
    this.estimationService = _estimationService;
    this.commonService = _commonService;
    this.editorService = _editorService;
    this.customerService = _customerService;
    this.evaluationService = _evaluationService;
    this.orderDetails = new OrderWeb();
    this.orderDetails.EditingPreference = new EditingPreferenceModel();
    this.IsShowSecondEditor=false; //new requirement
    this.IsAlreadyBlackListedEditor = false;
    this.IsAlreadyFavoriteEditor = false;
    this.IsAlreadyBlackListedEditor2nd = false;
    this.IsAlreadyFavoriteEditor2nd = false;
    this.orderEvaluation = new OrderEvaluationModel();
    this.initEvaluationModel();

    this.route.params.subscribe(params => {
      if (params['orderNo']) {
        let orderNo: string = params['orderNo'];
        if (localStorage.getItem("currentUser")) {
          let clientId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
          this.getOrderDetails(orderNo, clientId);
        }
      }
    });

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = "ご利用ガイド";
    this.sharedService.setSharedData(this.shareableData);

    if(this.sharedService.currentCulture=='jp'){

      
      this.titleService.setTitle("オンライン英文校正の満足度に関するアンケート");
      this.meta.addTag({ name: 'keywords', content: '英文校正,アンケート,品質,ノーハウ,格安校正,オンライン校正,英語校正,英語,校正,英語英文校正,校正会社,納品論文校正,英語論文チェック' });
      this.meta.addTag({ name: 'description', content: 'オンライン英文校正の満足度に関するアンケートがご確認頂けます。' });
    }
    else{
      this.titleService.setTitle("Questionaire for online editing-Pro. satisfaction");
      this.meta.addTag({ name: 'keywords', content: 'editing-Pro.,questionaire,quality,knowhow,resonable price editing,online editing,English editing, English,editing,English editing-Pro.,editing company, submission academic writing editing,English academic writing check' });
      this.meta.addTag({ name: 'description', content: 'Please check the questionaire for online editing-Pro. satisfaction.' });

    }
  }

  private calculateAvgScore() {
    let avg: number = 0;
    avg = (this.orderEvaluation.Q1Score + this.orderEvaluation.Q2Score + this.orderEvaluation.Q3Score + this.orderEvaluation.Q4Score) / 4;
    return avg;
  }

  private initEvaluationModel() {
    this.orderEvaluation.Q1Score = 5;
    this.orderEvaluation.Q2Score = 5;
    this.orderEvaluation.Q3Score = 5;
    this.orderEvaluation.Q4Score = 5;
    this.orderEvaluation.ServiceEvaluationScore = 5;
    this.orderEvaluation.AvgScore = this.calculateAvgScore();
    this.orderEvaluation.Comment = null;
    this.orderEvaluation.FeedbackCompany = null;
    this.orderEvaluation.EvaluationDate = new Date();
  }

  getOrderDetails(orderId: string, clientId: string) {
    this.estimationService.GetOrderDetailsForCustomerByOrderNo(orderId, clientId).subscribe((data) => {
      if (data) {
        this.orderDetails = data;
        this.orderEvaluation.OrderID=this.orderDetails.ID;
        this.orderEvaluation.OrderNo=this.orderDetails.OrderNo;
        if (localStorage.getItem("currentUser")) {
          let clientId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
          this.orderEvaluation.ClientID=clientId;
          
         }
        if (this.orderDetails.AssignedTranslatorID) {
          this.getEditor(this.orderDetails.AssignedTranslatorID);
          this.getEditorFavoriteStatus(1,this.orderDetails.AssignedTranslatorID);
          this.orderEvaluation.TranslatorID=this.orderDetails.AssignedTranslatorID;  
        }

        if (this.orderDetails.Assigned2ndEditorID) {
          this.getEditor(this.orderDetails.Assigned2ndEditorID, false);
          this.getEditorFavoriteStatus(2,this.orderDetails.Assigned2ndEditorID);
          this.orderEvaluation.TranslatorAs2ndID=this.orderDetails.Assigned2ndEditorID;
        }

        if (this.orderDetails.EditingPreference && this.orderDetails.EditingPreference.ReferenceFilePath) {
          this.orderDetails.EditingPreference.UploadedFileOrginalName = this.utilityService.getFileNameFromPath(this.orderDetails.EditingPreference.ReferenceFilePath);
        }

        if (this.orderDetails.WebDocumentList && this.orderDetails.WebDocumentList.length > 0) {
          this.orderDetails.WebDocumentList=this.orderDetails.WebDocumentList.filter(doc=>doc.UploadType!="REFERENCES");
          
          if(this.orderDetails.WebDocumentList[0].TranslationText)
          this.orderDetails.WebDocumentList[0].TranslationText = this.orderDetails.WebDocumentList[0].TranslationText.split(' ').slice(0, 20).join(' ');
        }

        if (this.orderDetails.MenuScript && this.orderDetails.MenuScript.length > 0) {
          this.orderDetails.MenuScript = this.orderDetails.MenuScript.split(" ").splice(0, 50).join(" ");
          if (this.orderDetails.MenuScript.length > 50)
            this.orderDetails.MenuScript = this.orderDetails.MenuScript + ' ...';
        }

        if (!this.orderDetails.EditingPreference) {
          this.orderDetails.EditingPreference = new EditingPreferenceModel();
        }
      }
    })
  }

  private getEditor(editorId: string, isWorkedAs1stEditor: boolean = true) {
    if (isWorkedAs1stEditor) {
      this.editorService.getPastRequestedEditorById(editorId).subscribe((data) => {
        if (data)
          this.firstEditor = data;
      });
    }
    else {
      this.editorService.getPastRequestedEditorById(editorId).subscribe((data) => {
        if (data)
          this.secondEditor = data;
      });
    }
  }

  downloadFile(filePath: string, isAbsolutePath = false) {
    if (isAbsolutePath)
      filePath = this.utilityService.getRelativePathFromFileURL(filePath);
    this.commonService.downloadFile(filePath, this.utilityService.getFileNameFromPath(filePath));
  }

  downloadMenuScript(orderId: string) {
    let fileName = this.orderDetails.MenuScript.split(" ").splice(0, 5).join(" ");
    fileName = fileName.replace(/[/\\?%*:|"<>]/g, '-'); //remove illegal charecters
    this.estimationService.getMenuScriptByOrderId(orderId, fileName);
  }

  manipulateFileSize(fileSizeInBytes: number) {
    return this.utilityService.formatBytes(fileSizeInBytes);
  }

  
  getEditorFavoriteStatus(editorWorkedAs:number,  editorId: string) {
    if (localStorage.getItem("currentUser")) {
      let customerId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
      this.customerService.isAlreadyFavoriteEditor(customerId, editorId).subscribe((data) => {
        if (editorWorkedAs == 1)
        this.IsAlreadyFavoriteEditor = data;
      else
        this.IsAlreadyFavoriteEditor2nd = data;
      });
    }
  }

  registerAsFavorite(editorWorkedAs: number, editorId: string) {
    if (localStorage.getItem("currentUser")) {
      let customerId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
      this.customerService.registerAsFavorite(customerId, editorId).subscribe((data) => {
        if (data) {
          if (editorWorkedAs == 1)
            this.IsAlreadyFavoriteEditor = true;
          else
            this.IsAlreadyFavoriteEditor2nd = true;

          this.toastr.success("お気に入り登録ができました");//"Successfully Registered as Favorite!"
        }
        else {
          this.toastr.error("お気に入り登録ができませんでした。");//"Could not be registered as Favorite!"
        }
      });
    }
  }

  registerAsBlacklisted(editorWorkedAs: number, editorId: string) {
    if (localStorage.getItem("currentUser")) {
      let customerId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
      this.customerService.registerAsBlackListed(customerId, editorId).subscribe((data) => {
        if (data) {
          if (editorWorkedAs == 1)
            this.IsAlreadyBlackListedEditor = true;
          else
            this.IsAlreadyBlackListedEditor2nd = true;

          this.toastr.success("ブラックリストに登録されました。");//"Successfully Registered as Black Listed!"
        }
        else {
          this.toastr.error("ブラックリストに登録できませんでした。");//"Could not be registered as Black Listed!"
        }
      });
    }
  }

  saveCustomerEvaluation() {
    this.orderEvaluation.AvgScore = this.calculateAvgScore();
    this.orderEvaluation.OrderID=this.orderDetails.ID;
    if(!this.orderEvaluation.Comment || (this.orderEvaluation.Comment && this.orderEvaluation.Comment.length<20))
    {
      this.toastr.warning('エディターへのコメントを20文字以上で入力してください。'); //"Comment to editor is required and it must be more than 20 characters"
      return;
    }
    else{
      this.evaluationService.saveCustomerEvaluation(this.orderEvaluation).subscribe((data) => {
        if (data) {
          this.toastr.success("評価が保存されました。");//"Evaluation Successfully Saved!"
          this.initEvaluationModel();
          this._router.navigateByUrl('customer/order-detail/'+this.orderDetails.OrderNo);
        }
        else {
          this.toastr.error("保存できませんでした。");//"Sorry!Could not be saved!"
        }
      }); 
    }
    
  }

  resetAll() {
    this.initEvaluationModel();
  }

  ngOnInit() {
  }

}
