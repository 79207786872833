import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CustomerDashboardComponent} from './customer-dashboard/customer-dashboard.component';
import {CustomerProfileComponent} from './customer-profile/customer-profile.component';
import {CustomerMyPageComponent} from './customer-my-page/customer-my-page.component';
import {MessageListComponent} from './message-list/message-list.component';
import { AuthGuard } from '../guards/auth-guard.service';
import {ChangePasswordComponent} from '../customer/change-password/change-password.component';
import {ErrorReportComponent} from './error-report/error-report.component';
import {MessageDetailsComponent} from './message-details/message-details.component';
import {MessageComponent} from './message/message.component';
import {UnsubscribeComponent} from './unsubscribe/unsubscribe.component';
import {RoleGuardService as RoleGuard} from '../guards/role-guard.service';
import { FavoriteEditorListComponent } from './favorite-editor-list/favorite-editor-list.component';
import { EditorBlackListComponent } from './editor-black-list/editor-black-list.component';
import {PastAppointedComponent} from './past-appointed/past-appointed.component';
import {PastRequestedComponent} from './past-requested/past-requested.component';
import {OrderListComponent} from './order-list/order-list.component';
import {OrderDetailsComponent} from './order-details/order-details.component';
import {EvaluationComponent} from './evaluation/evaluation.component';
import {PaymentMethodSelectionComponent} from './payment-method-selection/payment-method-selection.component';
import { InvoiceBreakdownComponent } from './invoice-breakdown/invoice-breakdown.component';
import {PaymentInformationComponent} from './payment-information/payment-information.component';
import {PaymentConfirmComponent} from './payment-confirm/payment-confirm.component';
import { EstimationConfirmationComponent } from '../estimation-confirmation/estimation-confirmation.component';
import { ServiceSelectionGuard } from '../guards/service-selection-guard.service';

const prefix:string =localStorage.getItem("currentCulture") ? localStorage.getItem("currentCulture") : 'jp';

const routes: Routes = [
  {path:"customer",component:CustomerDashboardComponent,
   canActivate:[AuthGuard,RoleGuard],
   data: { 
    UserType: '1' 
    },
   children:[
     {path:'my-page',component:CustomerMyPageComponent,pathMatch:"full"},
     {path:'profile',component:CustomerProfileComponent},
     {path:'message-list',component:MessageListComponent},
     {path:'editor-list/favorite',component:FavoriteEditorListComponent},
     {path:'change-password',component:ChangePasswordComponent},
     {path:'error-report',component:ErrorReportComponent},
     {path:'message-details/:id',component:MessageDetailsComponent},
     {path:'message',component:MessageComponent},
     {path:'editor-list/black-list',component:EditorBlackListComponent},
     {path:'editor-list/appointed',component:PastAppointedComponent},
     {path:'editor-list/requested',component:PastRequestedComponent},
     {path:'order-list',component:OrderListComponent},
     {path:'invoice-breakdown',component:InvoiceBreakdownComponent},
     {path:'estimation-payment/:orderNo',component:PaymentMethodSelectionComponent},
     {path:'payment-finalize',component:PaymentInformationComponent},
     {path:'estimation-order',component:PaymentConfirmComponent},
     {path:'order-detail/:orderNo',component:OrderDetailsComponent},
     {path:'evaluation/:orderNo',component:EvaluationComponent},
     {path: 'estimation-result', component: EstimationConfirmationComponent,canActivate:[ServiceSelectionGuard]},
     {path:'leave',component:UnsubscribeComponent},
   ]},
   {path:"en/customer",component:CustomerDashboardComponent,
   canActivate:[AuthGuard,RoleGuard],
   data: { 
    UserType: '1' 
    },
   children:[
     {path:'my-page',component:CustomerMyPageComponent,pathMatch:"full"},
     {path:'profile',component:CustomerProfileComponent},
     {path:'message-list',component:MessageListComponent},
     {path:'editor-list/favorite',component:FavoriteEditorListComponent},
     {path:'change-password',component:ChangePasswordComponent},
     {path:'error-report',component:ErrorReportComponent},
     {path:'message-details/:id',component:MessageDetailsComponent},
     {path:'message',component:MessageComponent},
     {path:'editor-list/black-list',component:EditorBlackListComponent},
     {path:'editor-list/appointed',component:PastAppointedComponent},
     {path:'editor-list/requested',component:PastRequestedComponent},
     {path:'order-list',component:OrderListComponent},
     {path:'invoice-breakdown',component:InvoiceBreakdownComponent},
     {path:'estimation-payment/:orderNo',component:PaymentMethodSelectionComponent},
     {path:'payment-finalize',component:PaymentInformationComponent},
     {path:'estimation-order',component:PaymentConfirmComponent},
     {path:'order-detail/:orderNo',component:OrderDetailsComponent},
     {path:'evaluation/:orderNo',component:EvaluationComponent},
     {path: 'estimation-result', component: EstimationConfirmationComponent,canActivate:[ServiceSelectionGuard]},
     {path:'leave',component:UnsubscribeComponent},
   ]},
   {path:"jp/customer",component:CustomerDashboardComponent,
   canActivate:[AuthGuard,RoleGuard],
   data: { 
    UserType: '1' 
    },
   children:[
     {path:'my-page',component:CustomerMyPageComponent,pathMatch:"full"},
     {path:'profile',component:CustomerProfileComponent},
     {path:'message-list',component:MessageListComponent},
     {path:'editor-list/favorite',component:FavoriteEditorListComponent},
     {path:'change-password',component:ChangePasswordComponent},
     {path:'error-report',component:ErrorReportComponent},
     {path:'message-details/:id',component:MessageDetailsComponent},
     {path:'message',component:MessageComponent},
     {path:'editor-list/black-list',component:EditorBlackListComponent},
     {path:'editor-list/appointed',component:PastAppointedComponent},
     {path:'editor-list/requested',component:PastRequestedComponent},
     {path:'order-list',component:OrderListComponent},
     {path:'invoice-breakdown',component:InvoiceBreakdownComponent},
     {path:'estimation-payment/:orderNo',component:PaymentMethodSelectionComponent},
     {path:'payment-finalize',component:PaymentInformationComponent},
     {path:'estimation-order',component:PaymentConfirmComponent},
     {path:'order-detail/:orderNo',component:OrderDetailsComponent},
     {path:'evaluation/:orderNo',component:EvaluationComponent},
     {path: 'estimation-result', component: EstimationConfirmationComponent,canActivate:[ServiceSelectionGuard]},
     {path:'leave',component:UnsubscribeComponent},
   ]}
];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerRoutingModule { }
