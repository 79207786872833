import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Title, Meta } from '@angular/platform-browser';
import { OrderWeb } from '../../models/order-web.model';
import { EstimationService } from '../../services/estimation.service';
import { EditingPreferenceModel } from 'src/app/models/editing-pro-preference-model.model';
import { CommonListService } from '../../services/common-list.service';
import { UtilityService } from '../../services/utility.service';
import { confirmDialogConfig, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { ModalOptions, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SendEstimationWindowComponent } from '../../estimation-confirmation/send-estimation-window/send-estimation-window.component';
import { OrderOperationType } from '../../Enums.enum';
import { ToastrService } from 'ngx-toastr';
import { DownloadDeliveryComponent } from './download-delivery/download-delivery.component';
import { DocumentModel } from 'src/app/models/document.model';
import { InvoiceComponent } from 'src/app/PDFTemplates/invoice/invoice.component';
import { EstimationComponent } from 'src/app/PDFTemplates/estimation/estimation.component';
import { DeliveryComponent } from 'src/app/PDFTemplates/delivery/delivery.component';
import { ReceiptComponent } from 'src/app/PDFTemplates/receipt/receipt.component';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  estimationService: EstimationService;
  orderDetails: OrderWeb;
  orderNo:string;
  commonService: CommonListService;
  isDisableDeliveryDownloadButton:boolean;
  orderInfo: { OrderID: string, ReceiverID: string, ReceiverName: string, WebOrderNo: string, ParentId: number, ReplyDate: Date };

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private route: ActivatedRoute,
    private titleService: Title,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private _estimationService: EstimationService,
    private _commonService: CommonListService,
    private utilityService: UtilityService,
    private _toastr:ToastrService,
    private meta: Meta) {

    this.router = _router.url;
    this.sharedService = dataShareService;
    this.estimationService = _estimationService;
    this.commonService = _commonService;
    this.orderInfo = { OrderID: null, ReceiverID: null, ReceiverName: null, WebOrderNo: null, ParentId: 0, ReplyDate: null };
    this.isDisableDeliveryDownloadButton=true;
    this.orderDetails = new OrderWeb();
    this.orderDetails.EditingPreference = new EditingPreferenceModel();
    this.route.params.subscribe(params => {
      if (params['orderNo']) {
        let orderNo: string = params['orderNo'];
        this.orderNo=orderNo;
        if (localStorage.getItem("currentUser")) {
          let clientId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
          this.getOrderDetails(orderNo, clientId);
        }
      }
    });

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = this.sharedService.currentCulture=='jp'? "案件詳細":"Order detail";
    this.sharedService.setSharedData(this.shareableData);

    if(this.sharedService.currentCulture=='jp'){

      this.titleService.setTitle(`${this.orderNo} 英文校正サイトの案件詳細を確認`);
      this.meta.addTag({ name: 'keywords', content: '詳細確認,英文校正,英語エディティング,注文,内訳,リスト,高品質,安心,英語,チェック,品質保証,校正依頼,校正依頼方法学術校正,医学論文校正,論文校正専門,論文エディティング' });
      this.meta.addTag({ name: 'description', content: '英文校正の案件詳細がご確認頂けます。24時間365日対応。オンラインで利用できる校正サービス。納品物の受領までウェブ上で完結。簡単,早い,確実！30分からの短時間納品も可能です。お問い合わせ,お見積りは無料。' });
    }
    else{

      this.titleService.setTitle(`${this.orderNo} editing-Pro. Order detail confirmation`);
      this.meta.addTag({ name: 'keywords', content: 'detail confirm,editing-Pro.,English editing,order,detail,list,high quality,safe,English,check,qualityguarantee,editing order,editing order placement, academic editing, medical academic writing editing,academic writing editing professional,academic writing editing' });
      this.meta.addTag({ name: 'description', content: 'Detail confirmation on editing-Pro. 24hours service for 365 days. Online editing service that allows order to submission through online. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });
    }

  }

  openModalNow() {
    
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-lg"
    };

    let sharedModalData = {
      OrderDate: this.orderDetails.OrderDate,
      WordCount: this.orderDetails.WordCount,
      JobNumber: this.orderDetails.OrderNo,
      PlanType: this.orderDetails.DeliveryLevelName,
      Order:   this.orderDetails
    };

    this.sharedService.setModalData(sharedModalData);

    this.bsModalRef = this.modalService.show(SendEstimationWindowComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;
  }

  openEstimationPreviewWindow()
  {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-lg"
    };
    this.sharedService.setModalData({OrderNo:this.orderDetails.OrderNo});
    this.bsModalRef = this.modalService.show(EstimationComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;
  }

  openInvoicePreviewWindow() {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-lg"
    };
    this.sharedService.setModalData({OrderNo:this.orderDetails.OrderNo});
    this.bsModalRef = this.modalService.show(InvoiceComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;
  }

  openDeliveryNoticePreviewWindow()
  {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-lg"
    };
    this.sharedService.setModalData({OrderNo:this.orderDetails.OrderNo});
    this.bsModalRef = this.modalService.show(DeliveryComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;
  }

  openReceiptPreviewWindow()
  {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-lg"
    };
    this.sharedService.setModalData({OrderNo:this.orderDetails.OrderNo});
    this.bsModalRef = this.modalService.show(ReceiptComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;
  }

  openSendEmailEstimationWindow() {

    this.beforeSendingEmailToStakeHolderAction().then((isOrderSaved: boolean) => {
      if (isOrderSaved) {
        this.openModalNow();
      }
      else {
        console.log('Order not saved error occured... so no need to send email');
      }

    });
  }

  beforeSendingEmailToStakeHolderAction(): Promise<boolean> {
    let result = new Promise((resolve, reject) => {
      if (!this.orderDetails.OrderNo) {
        resolve(false);
        return result;
      }
      else {  //already placed order
        resolve(true);
        return result;
      }
    });
    return result;
  }

  private getMessageReceiverName() {
    if(this.orderInfo && this.orderInfo.ReceiverID)
    {
      this.commonService.getRecepientRegistrationNo(this.orderInfo.ReceiverID, false).subscribe((data) => {
        if (data)
          this.orderInfo.ReceiverName = data;
      });
    }
  }

  manipulateFileSize(fileSizeInBytes: number) {
    return this.utilityService.formatBytes(fileSizeInBytes);
  }

  downloadFile(filePath: string, isAbsolutePath = false) {
    if (isAbsolutePath)
      filePath = this.utilityService.getRelativePathFromFileURL(filePath);
    this.commonService.downloadFile(filePath, this.utilityService.getFileNameFromPath(filePath));
  }

  downloadMenuScript(orderId: string) {
    let fileName = this.orderDetails.MenuScript.split(" ").splice(0, 5).join(" ");
    fileName = fileName.replace(/[/\\?%*:|"<>]/g, '-'); //remove illegal charecters
    this.estimationService.getMenuScriptByOrderId(orderId, fileName);
  }


  getOrderDetails(orderId: string, clientId: string) {
    this.estimationService.GetOrderDetailsForCustomerByOrderNo(orderId, clientId).subscribe((data) => {
      if (data) {
        this.orderDetails = data;
        this.orderDetails.CurrentCulture=this.sharedService.currentCulture;
        if(!data.OrderNo)
        {
            this._router.navigateByUrl("/project/"+orderId);        
        }
        //we are now sending only to First Editor -- Later logic will change
        this.orderInfo.ReceiverID = this.orderDetails.AssignedTranslatorID;

        if (this.orderDetails.EditingPreference && this.orderDetails.EditingPreference.ReferenceFilePath) {
          this.orderDetails.EditingPreference.UploadedFileOrginalName = this.utilityService.getFileNameFromPath(this.orderDetails.EditingPreference.ReferenceFilePath);
          this.orderDetails.EditingPreference.ReferenceFile=data.WebDocumentList.find(item=>item.UploadType=="REFERENCES");
          this.orderDetails.WebDocumentList=data.WebDocumentList.filter(item=>item.UploadType!="REFERENCES");
        }

        if (this.orderDetails.WebDocumentList && this.orderDetails.WebDocumentList.length > 0 && this.orderDetails.WebDocumentList[0].TranslationText) {

          this.orderDetails.WebDocumentList[0].TranslationText = this.orderDetails.WebDocumentList[0].TranslationText.split(' ').slice(0, 20).join(' ');
        }

        if (this.orderDetails.MenuScript && this.orderDetails.MenuScript.length > 0) {
          this.orderDetails.MenuScriptOriginal=this.orderDetails.MenuScript;

          this.orderDetails.MenuScript = this.orderDetails.MenuScript.split(" ").splice(0, 50).join(" ");
          if (this.orderDetails.MenuScript.length > 50)
            this.orderDetails.MenuScript = this.orderDetails.MenuScript + ' ...';
        }

        if (!this.orderDetails.EditingPreference) {
          this.orderDetails.EditingPreference = new EditingPreferenceModel();
        }

        this.getMessageReceiverName();
        if (!data.EditingPreference)
          this.orderDetails.EditingPreference = new EditingPreferenceModel();
      }
        
       if(!this.orderDetails.EditingPreference.IsDoubleCheck)//single check
       {
         if(this.orderDetails.OrderStatus>5 && this.orderDetails.OrderStatus<9)
         {
           this.isDisableDeliveryDownloadButton=false;
         }
         else{
           this.isDisableDeliveryDownloadButton=true;
         }
       }
       else
       {
        if(this.orderDetails.OrderStatus>15 || (this.orderDetails.OrderStatus>5 && this.orderDetails.OrderStatus<9))
        {
          this.isDisableDeliveryDownloadButton=false;
        }    
        else{
          this.isDisableDeliveryDownloadButton=true;
        }
       }
    })
  }


  onConfirmClicked() {
    this.getSubmittedDeliveryDocuments();
  }

  goToSendMessage() {
    //alert('Are you kidding me?!!');
    this.orderInfo.OrderID = this.orderDetails.ID;
    this.orderInfo.WebOrderNo = this.orderDetails.OrderNo;
    localStorage.setItem("MessageOrderInfo", JSON.stringify(this.orderInfo));
    setTimeout(() => {
      this._router.navigateByUrl("/customer/message");
    }, 1000);
  }


  executeCancelOrder()
  {
    this.estimationService.executeOrderOperation(OrderOperationType.CANCEL,this.orderDetails).subscribe((data)=>{
      if(data && data.ID)
      {
        let clientId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
        this.getOrderDetails(this.orderDetails.OrderNo, clientId);
          this._toastr.success('ご注文はキャンセルされました。'); //"Order Successfully Canceled!"
      }
      else{
        this._toastr.error('ご注文はキャンセルできませんでした。'); //"Order could not be Canceled!"
      }
    });
  }

  config: confirmDialogConfig = {
    MessageText: "本当にキャンセルしますか？",
    IsYesNo: true,
    YesBtnCallback: this.executeCancelOrder.bind(this)
  };

  confirmModalOptions: ModalOptions = {
    backdrop: 'static',
    keyboard: false,
    class: "modal-sm",
    initialState: this.config
  };

  cancelOrder()
  {
    this.bsModalRef = this.modalService.show(ConfirmDialogComponent, this.confirmModalOptions);
  }

  copyOrderInformationForReOrderSimilarAndGoToEstimatmation()
  {
    let orderCopy:OrderWeb=new OrderWeb();
    orderCopy.EditingPreference=new EditingPreferenceModel();
    orderCopy.EditingPreference=this.orderDetails.EditingPreference;
    orderCopy.EditingPreference.ID=0;
    orderCopy.EditingPreference.WordCount=this.orderDetails.WordCount;
    orderCopy.DeliveryPlan=this.orderDetails.DeliveryPlan;
    orderCopy.DeliveryPlanID=this.orderDetails.EditingPreference.PlanCourseID;
    orderCopy.DeliveryLevelName=this.orderDetails.DeliveryLevelName;
    orderCopy.AppointedToStaffList=this.orderDetails.AppointedToStaffList;
    orderCopy.MenuScript=this.orderDetails.MenuScriptOriginal;
    let docList:Array<DocumentModel>=new Array<DocumentModel>();
    this.orderDetails.WebDocumentList.forEach(doc=>{
     doc.ID=null;
     doc.OrderID=null;
      docList.push(doc);
    });
    orderCopy.WebDocumentList=docList;

    localStorage.removeItem('selectedPlanCourseButtonId');
          localStorage.removeItem("estimationResult");
          localStorage.removeItem("servicePreferenceModel");
    localStorage.setItem("orderCopyModelForReorderSimilar",JSON.stringify(orderCopy));
    this._router.navigateByUrl("/estimation"); 
  }

  openDownloadModalNow(sharedModalData) {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-md"
    };

    this.sharedService.setModalData(sharedModalData);

    this.bsModalRef = this.modalService.show(DownloadDeliveryComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;
  }

  private getSubmittedDeliveryDocuments()
  {
    this.estimationService.getSubmittedDeliveryDocumentsForCustomer(this.orderDetails.ID).subscribe(data=>{
      if(data)
      this.openDownloadModalNow(data);
    });
  }

  calculateHalfDateTime(start:Date, end:Date):Date{
    return this.utilityService.getHalfDateTimeOfOrder(start,end);
   }

  ngOnInit() {
  }

}
