import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { ConfirmDialogComponent, confirmDialogConfig } from '../confirm-dialog/confirm-dialog.component';
import { ModalOptions, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EstimationService } from '../services/estimation.service';
import { ToastrService } from 'ngx-toastr';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/public_api';
import { OrderWeb } from '../models/order-web.model';
import { UtilityService } from '../services/utility.service';
import * as _ from 'underscore';
import { EditingPreferenceModel } from '../models/editing-pro-preference-model.model';
import { EditorSearchWindowComponent } from '../estimate-service/editor-search-window/editor-search-window.component';
import { LanguageModel } from '../models/language.model';
import { SpecializedFieldModel } from '../models/specialized-field.model';
import { CommonListService } from '../services/common-list.service';

@Component({
  selector: 'app-appointed-estimation',
  templateUrl: './appointed-estimation.component.html',
  styleUrls: ['./appointed-estimation.component.css']
})
export class AppointedEstimationComponent implements OnInit {
  router: string;
  bsModalRef: BsModalRef;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  ngbModalOptions: ModalOptions;
  ngbModalOptionsNonEnglishAlert: ModalOptions;
  estimationInputText: string;
  estimationInputLimit: number = 20000;
  esimationService: EstimationService;
  toastr: ToastrService;
  uploadedDocuments: Array<any>;
  uploadedFiles: { File1: any, File2: any, File3: any };
  fileUploadProgressBarValue: number;
  estimationOrderList: { currentPage: number, totalItems: number, dataSource: OrderWeb[] };
  pricesPerWord: { Light: number, Business: number, Expert: number };
  selectedEditors: Array<number>;
  specializedFieldID: string;
  languageTo: string;
  languageFrom: string;
  languages: Array<LanguageModel>;
  SpecializedFieldList: Array<SpecializedFieldModel>;
  commonListService: CommonListService;
  isNonEnglishConditionAgreed:boolean;
  isNonEnglishConditionAgreedAlertShown:boolean;

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private _commonListService: CommonListService,
    private titleService: Title,
    private _toastr: ToastrService,
    private utility: UtilityService,
    private modalService: BsModalService,
    private meta: Meta,
    private _esimationService: EstimationService) {
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.commonListService = _commonListService;
    this.estimationOrderList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.estimationInputText = "";
    this.esimationService = _esimationService;
    this.uploadedDocuments = new Array<any>();
    this.uploadedFiles = { File1: null, File2: null, File3: null };
    this.fileUploadProgressBarValue = 0;
    this.pricesPerWord = { Light: null, Business: null, Expert: null };
    this.languageFrom = '1484e197-70f8-4f64-98cf-e0f06eff6f49';
    this.languageTo = '1484e197-70f8-4f64-98cf-e0f06eff6f49';
    this.SpecializedFieldList = new Array<SpecializedFieldModel>();
    this.specializedFieldID = null;

    if (localStorage.getItem("SelectedEditorsForEstimation")) {
      let seletedEditors = JSON.parse(localStorage.getItem("SelectedEditorsForEstimation"));
      this.sharedService.SelectedEditorsForEstimation = seletedEditors;
    }
    else {
      this.sharedService.SelectedEditorsForEstimation = new Array<number>();
    }

    if (!this.sharedService.SelectedEditorsForEstimation) {
      this.sharedService.SelectedEditorsForEstimation = new Array<number>();
      this.selectedEditors = new Array<number>();
    }
    else {
      this.selectedEditors = this.sharedService.SelectedEditorsForEstimation;
    }

    this.isNonEnglishConditionAgreed=false;
    this.isNonEnglishConditionAgreedAlertShown=false;
    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = "指名校正無料見積";
    this.sharedService.setSharedData(this.shareableData);


    if(this.sharedService.currentCulture=='jp'){


      this.titleService.setTitle("お気に入りのエディターが指名できるオンライン専門英語校正");
      this.meta.updateTag({ name: 'keywords', content: '指名,英文,英文校正,英文エディター,専門校正,すぐ対応,簡単,品質の秘密,品質保証,校正依頼,校正依頼方法,校正方法,校正のコツ,論文校正,エディティング,学術校正,医学論文校正,論文校正専門,論文エディティング,エディティングプロ' });
      this.meta.updateTag({ name: 'description', content: '直接エディターを指名できるオンラインの英語校正サービスはこちら。納品物の受領までウェブ上で完結。簡単,早い,確実！お問い合わせ,お見積りは無料。' });

    }else{
      this.titleService.setTitle("Appoint favorite editor online professionalediting-Pro.");
      this.meta.updateTag({ name: 'keywords', content: 'appinting,English writing,editing-Pro.,English writingeditor,professionalediting,quick responce,east,secret for quality,quality guaranteed,editing order,editing how to order,editing hot,editing tips,academic writing editing,editing,academic editing,medical academic writingediting,academic writingeditingprofessional,academic writingediting,editing-Pro.' });
      this.meta.updateTag({ name: 'description', content: 'Appoint editor directly from online English editing service. Order to submission through website. Easy,fast and safe. Inquiry and estimation for free.' });

    }

    this.esimationService.getEstimationWebOrderList(1, 20, true, this.sharedService.currentCulture).subscribe((data) => {
      this.estimationOrderList.dataSource = data;
      if (data.length > 0)
        this.estimationOrderList.totalItems = data[0].TotalCount;
    });


    this.getLanguageList();
    this.getSpecializedFieldList();
    this.initModalConfig();
    this.getChargeSettings();
    this.initNonEnglishModalConfig();
    
  }

  getSpecializedFieldList() {
    this.commonListService.getSpecializedFieldList().subscribe((data) => {
      if (data)
        this.SpecializedFieldList = data;
    });
  }

  getLanguageList() {
    this.commonListService.getAllLanguages().subscribe((data) => {
      if (data)
        this.languages = data;
    });
  }

  removeFromList(regNo: number) {
    this.selectedEditors = _.without(this.selectedEditors, regNo);
    localStorage.setItem("SelectedEditorsForEstimation", JSON.stringify(this.sharedService.SelectedEditorsForEstimation));
  }

  getChargeSettings() {
    this.esimationService.getEditingChargeSettings().subscribe((data) => {
      if (data) {
        this.pricesPerWord.Business = data.BaseCharge * data.Business;
        this.pricesPerWord.Expert = data.BaseCharge * data.Expert;
        this.pricesPerWord.Light = data.BaseCharge * 1;//*data.Light;
      }
    });
  }

  initModalConfig() {
    let config: confirmDialogConfig = {
      MessageText: "英語20,000wordまで入力可能です",
      IsOkOnly: true
    };
    this.ngbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-sm",
      initialState: config
    };
  }

  inputBoxWordLimitValidation() {
    if (this.estimationInputText.length > 0) {
      var words = this.estimationInputText.match(/\S+/g).length;
      if (words > this.estimationInputLimit) {
        this.modalService.show(ConfirmDialogComponent, this.ngbModalOptions);
        return false;
      }
      else
        return true;
    }
    else
      return false;
  }

  private nonEnglishConditionAlertYesCallback()
  {
    this.isNonEnglishConditionAgreed=true;
    this.isNonEnglishConditionAgreedAlertShown=true;
    this.estimateByText();
  }
  private nonEnglishConditionAlertNoCallback()
  {
    this.isNonEnglishConditionAgreed=false;
    this.isNonEnglishConditionAgreedAlertShown=false;
  }

  initNonEnglishModalConfig() {

    let msgText:string="";
    if(this.sharedService.currentCulture=="jp")
    {
      msgText="原稿に英語ではない文字が含まれています。英文校正の依頼では英語のみが校正対象になりますのでご了承ください" ;
    }
    else
    {
      msgText="There is a manuscript containing non-English characters. In English editing the character is excluded. Would you all right?";
    }
 

    let config: confirmDialogConfig = {
      MessageText: msgText,
      IsYesNo: true,
      YesBtnCallback:this.nonEnglishConditionAlertYesCallback.bind(this),
      NoBtnCallback:this.nonEnglishConditionAlertNoCallback.bind(this)
    };
    this.ngbModalOptionsNonEnglishAlert = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-sm",
      initialState: config
    };
  }


  estimateByText() {

    if(this.isNonEnglishConditionAgreedAlertShown==false && this.utility.isContainNonEnglishText(this.estimationInputText))
    {
      if(this.isNonEnglishConditionAgreedAlertShown==false)
      {
        this.modalService.show(ConfirmDialogComponent, this.ngbModalOptionsNonEnglishAlert);
      }
      if(!this.isNonEnglishConditionAgreed)
      return;
    }

    if (!this.checkSpecializedField())
      return;
    if (!this.inputBoxWordLimitValidation())
      return;

    this.esimationService.getManuscriptByPlainTextEstimated(this.estimationInputText).subscribe((data) => {
      if (data) {
        localStorage.setItem("estimationResult", JSON.stringify(data));
        localStorage.removeItem("selectedPlanCourseButtonId");
        localStorage.removeItem("newEstimationBasicPreference");
        localStorage.removeItem("orderCopyModelForReorderSimilar");
        localStorage.removeItem("servicePreferenceModel"); //remove if any previous
        this._router.navigateByUrl("/estimation");
      }
    });
  }

  fileSelected(event: any, from: number) {  //this time developed by regarding that only one file will be uploaded
    if (from == 1)
      this.uploadedFiles.File1 = event.target.files[0];
    else if (from == 2)
      this.uploadedFiles.File2 = event.target.files[0];
    else
      this.uploadedFiles.File3 = event.target.files[0];

    this.fileUploadProgressBarValue = 0;
  }

  uploadEstimationDocuments() {
    this.fileUploadProgressBarValue = 0; //dummy progress bar
    setTimeout(() => {
      if (this.uploadedFiles.File1) {
        if (!this.utility.isDuplicateFile(this.uploadedFiles.File1, this.uploadedDocuments)) {
          this.uploadedDocuments.push(this.uploadedFiles.File1);
          this.fileUploadProgressBarValue = 33;
        }
      }
      if (this.uploadedFiles.File2) {
        if (!this.utility.isDuplicateFile(this.uploadedFiles.File2, this.uploadedDocuments)) {
          this.uploadedDocuments.push(this.uploadedFiles.File2);
          this.fileUploadProgressBarValue += 33;
        }
      }
      if (this.uploadedFiles.File3) {
        if (!this.utility.isDuplicateFile(this.uploadedFiles.File3, this.uploadedDocuments)) {
          this.uploadedDocuments.push(this.uploadedFiles.File3);
          this.fileUploadProgressBarValue += 34;
        }
      }
      if (this.fileUploadProgressBarValue > 0) //if atleast a file selected
        this.fileUploadProgressBarValue = 100;

    }, 300);

  }

  private checkSpecializedField() {
    if (this.specializedFieldID) {
      return true;
    }
    else {
      let msg: string = this.sharedService.currentCulture == 'jp' ? '自動見積の際にはエディタ言語と専門分野を先にお選びください。' : 'Please choose the Specialized field for Estimation.';
      this.toastr.warning(msg);
      return false;
    }
  }

  confirmSelection() {
    this.sharedService.SelectedEditorsForEstimation = this.selectedEditors;
    localStorage.setItem("SelectedEditorsForEstimation", JSON.stringify(this.sharedService.SelectedEditorsForEstimation));
  }

  estimateByDocuments() {
    if (!this.checkSpecializedField())
      return;

    if (this.uploadedDocuments.length > 0) {
      this.esimationService.getManuscriptByUploadedDocumentEstimated(this.uploadedDocuments).subscribe((data) => {
        if (data) {
          localStorage.setItem("estimationResult", JSON.stringify(data));
          localStorage.removeItem("selectedPlanCourseButtonId");
          localStorage.removeItem("newEstimationBasicPreference");
          localStorage.removeItem("orderCopyModelForReorderSimilar");
          localStorage.removeItem("servicePreferenceModel"); //remove if any previous
          this._router.navigateByUrl("/estimation");
        }
      });
    }
    else {
      return;
    }

  }

  onWebOrderPageIndexChange(event: PageChangedEvent) {
    this.esimationService.getEstimationWebOrderList(event.page, 20, true, this.sharedService.currentCulture).subscribe((data) => {
      this.estimationOrderList.currentPage = event.page;
      this.estimationOrderList.dataSource = data;
      if (data.length > 0)
        this.estimationOrderList.totalItems = data[0].TotalPage;
    });
  }

  openEditorSearchWindow() {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-lg"
    };
    let preference: EditingPreferenceModel = new EditingPreferenceModel();
    preference.IsDoubleCheck = false;
    this.sharedService.setModalData(preference);
    this.bsModalRef = this.modalService.show(EditorSearchWindowComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;

  }

  ngOnInit() {
  }

}
