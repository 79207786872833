import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'underscore';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  IsYesNo: boolean = false;
  IsOkOnly: boolean = false;
  MessageText: string = "";
  YesBtnName: string = "Yes";
  NoBtnName: string = "No";
  OKBtnName: string = "Ok";
  YesBtnCallback: any;
  NoBtnCallback: any;
  OKBtnCallback: any;

  constructor(private bsModalRef: BsModalRef) {

  }

  YesBtnClicked() {
    if(_.isFunction(this.YesBtnCallback))
    {
      this.YesBtnCallback();
    }
    this.bsModalRef.hide();
  }
  NoBtnClicked() {
    if(_.isFunction(this.NoBtnCallback))
    {
      this.NoBtnCallback();
    }
    this.bsModalRef.hide();
  }

  OKBtnClicked() {
    if(_.isFunction(this.OKBtnCallback))
    {
      this.OKBtnCallback();
    }
    this.bsModalRef.hide();
  }

  ngOnInit() {
  }

}

export class  confirmDialogConfig {

  IsYesNo?: boolean = false;
  IsOkOnly?: boolean = true;
  MessageText: string;
  YesBtnName?: string = "Yes";
  NoBtnName?: string = "No";
  OKBtnName?: string = "Ok";
  YesBtnCallback?: any;
  NoBtnCallback?: any;
  OKBtnCallback?: any;

}