import { Component, OnInit } from '@angular/core';
import { AffiliateService } from '../../services/affiliate.service';
import { Router } from '@angular/router';
import { DataShareService } from '../../services/data-share.service';
import { Title, Meta } from '@angular/platform-browser';
import { OrderWeb } from '../../models/order-web.model';
import { DataSharingModel } from '../../models/data-sharing-model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { CompanySearchModel } from 'src/app/models/company-search.model';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-list-s',
  templateUrl: './list-s.component.html',
  styleUrls: ['./list-s.component.css']
})
export class ListSComponent implements OnInit {
  affiliateService: AffiliateService;
  webOrderList: { currentPage: number, totalItems: number, dataSource: OrderWeb[] };
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  pageSize: number;
  orderNo: string;
  motherLanguage: string;
  searchModel: CompanySearchModel;
  utilityService: UtilityService;

  constructor(private _affiliateService: AffiliateService, private _router: Router,
    private dataShareService: DataShareService,
    private _utilityService: UtilityService,
    private titleService: Title,
    private meta: Meta) {
      this.router = _router.url;
    this.sharedService = dataShareService;
    this.affiliateService = _affiliateService;
    this.utilityService=_utilityService;
    this.pageSize = 20;

    this.webOrderList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.searchModel = new CompanySearchModel();
    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = "翻訳者リスト";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("翻訳者リスト | 英文校正(editing-Pro.)管理者ページ");
    this.meta.addTag({ name: 'keywords', content: '英文校正、editing-Pro., 管理者, 注文内訳, 翻訳者リスト' });
    this.meta.addTag({ name: 'description', content: 'エディティング者プラスコーディネーターによるエディティングチェックで、簡単、確実な英語エディティングサービスをご提供中。高品質なエディティングをお届けします。お見積りは無料。' });
    this.clearAll();
     }

     clearAll() {
      this.searchModel = new CompanySearchModel();
      this.searchModel.cultureId=this.sharedService.currentCulture;
      this.searchModel.ApplicationId=3;
      this.searchModel.PageNumber=1;
      this.searchModel.PageSize=20;
      this.searchModel.EndDate= new Date();
      this.searchModel.StartDate= this.utilityService.addMonthsToDate(new Date(this.searchModel.EndDate),-1);
      this.searchModel.TranslatorNo=null;
      this.searchModel.MotherLanguage=null;
  
      this.getEditorList();
    }

  search() {
    this.getEditorList();
  }

  getEditorList(pageNo: number = 1) {
      let user = JSON.parse(localStorage.getItem("currentUser"));
      //clientId = '533ddce8-e199-415b-95a8-77fd0461c61d';
      this.searchModel.PartnerId=user.ID; //here we need to send code instead
      this.searchModel.AffiliateCode=user.AffiliateCode;
      this.affiliateService.getEditorList(this.searchModel).subscribe(data => {
        if (data) {
          //console.log('customer list', data);
          this.webOrderList.currentPage = pageNo;
          this.webOrderList.dataSource = data;
          if (data.length > 0)
            this.webOrderList.totalItems = data[0].TotalPage;
        }
      });
    }

  onWebOrderPageIndexChange(event: PageChangedEvent) {
    if (localStorage.getItem("currentUser") || !localStorage.getItem("currentUser")) {
      this.getEditorList(event.page);
    }
  }

  ngOnInit() {
  }

}
