export class CompanySearchModel {
        cultureId :string 
        affiliatename :string 
        ApplicationId :number
        clientNo :string
        clientId :string
        PartnerId :string
        ClientName :string
        StartDate :Date
        EndDate :Date
        CustomerNo :number
        PageSize :number
        PageNumber :number
        MotherLanguage:string
        TranslatorNo:number
        AffiliateCode:string 
}
