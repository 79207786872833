import { Component, OnInit } from '@angular/core';
import { AffiliateService } from '../../services/affiliate.service';
import { Router } from '@angular/router';
import { DataShareService } from '../../services/data-share.service';
import { Title, Meta } from '@angular/platform-browser';
import { OrderWeb } from '../../models/order-web.model';
import { DataSharingModel } from '../../models/data-sharing-model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { CompanySearchModel } from 'src/app/models/company-search.model';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-list-c',
  templateUrl: './list-c.component.html',
  styleUrls: ['./list-c.component.css']
})
export class ListCComponent implements OnInit {

  affiliateService: AffiliateService;
  webOrderList: { currentPage: number, totalItems: number, dataSource: OrderWeb[] };
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  pageSize: number;
  searchModel: CompanySearchModel;
  utilityService: UtilityService;

  constructor(private _affiliateService: AffiliateService, private _router: Router,
    private dataShareService: DataShareService,
    private _utilityService: UtilityService,
    private titleService: Title,
    private meta: Meta) {
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.affiliateService = _affiliateService;
    this.utilityService=_utilityService;
    this.pageSize = 20;

    this.webOrderList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.searchModel = new CompanySearchModel();
    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = "お客様リスト";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("お客様リスト | 翻訳プロ(editing-Pro.)管理者ページ");
    this.meta.addTag({ name: 'keywords', content: '英文校正, editing-pro, 管理者, お客様リスト' });
    this.meta.addTag({ name: 'description', content: 'エディティング者プラスコーディネーターによるエディティングチェックで、簡単、確実な英語エディティングサービスをご提供中。高品質なエディティングをお届けします。お見積りは無料。' });
    this.clearAll();
  }

  clearAll() {
    this.searchModel = new CompanySearchModel();
    this.searchModel.cultureId=this.sharedService.currentCulture;
    this.searchModel.ApplicationId=3;
    this.searchModel.PageNumber=1;
    this.searchModel.PageSize=20;
    this.searchModel.EndDate=new Date();
    this.searchModel.StartDate=this.utilityService.addMonthsToDate(new Date(this.searchModel.EndDate),-1);
    this.searchModel.CustomerNo=null;
    this.searchModel.ClientName=null;

    this.getClientList();
  }

  search() {
    this.getClientList();
  }

  getClientList(pageNo: number = 1) {
      let clientId = JSON.parse(localStorage.getItem("currentUser")).ID;
      this.searchModel.PartnerId=clientId;
      this.affiliateService.getClientList(this.searchModel).subscribe(data => {
        if (data) {
          this.webOrderList.currentPage = pageNo;
          this.webOrderList.dataSource = data;
          if (data.length > 0)
            this.webOrderList.totalItems = data[0].TotalPage;
        }
      });
    
  }

  onWebOrderPageIndexChange(event: PageChangedEvent) {
      this.getClientList(event.page);
  }


  ngOnInit() {
  }

}
