import { Component, OnInit } from '@angular/core';
import { AffiliateService } from '../../services/affiliate.service';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { OrderWeb } from '../../models/order-web.model';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { AffiliateInvoiceModel } from 'src/app/models/affiliate-invoice.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AffiliateInvoiceTemplateComponent } from '../affiliate-invoice-template/affiliate-invoice-template.component';

@Component({
  selector: 'app-list-i',
  templateUrl: './list-i.component.html',
  styleUrls: ['./list-i.component.css']
})
export class ListIComponent implements OnInit {

  affiliateService: AffiliateService;
  webOrderList: { currentPage: number, totalItems: number, dataSource: AffiliateInvoiceModel[] };
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  pageSize: number;
  Month: number;
  Year: number;
  YearOptions: Array<number>;
  bsModalRef: BsModalRef;
  IsShowSeal:boolean;

  constructor(private _affiliateService: AffiliateService, private _router: Router,
    private dataShareService: DataShareService,
    private modalService: BsModalService,
    private titleService: Title,
    private meta: Meta) {
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.affiliateService = _affiliateService;
    this.pageSize = 10;

    this.IsShowSeal=false;
    this.YearOptions = new Array<number>();
    let date = new Date();
    this.Month = date.getMonth();
    this.Year = date.getFullYear();

    let year = date.getFullYear();
    this.fillYearOptions(year);
    this.webOrderList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = "請求書リスト";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("請求書リスト | 翻訳プロ(editing-Pro.)管理者ページ");
    this.meta.addTag({ name: 'keywords', content: '英文校正, editing-pro, 管理者, 請求書リスト' });
    this.meta.addTag({ name: 'description', content: 'エディティング者プラスコーディネーターによるエディティングチェックで、簡単、確実な英語エディティングサービスをご提供中。高品質なエディティングをお届けします。お見積りは無料。' });

    this.getAffiliateInvoiceList();
  }

  private fillYearOptions(year: number) {
    for (let y = year; y >= year - 5; y--) {
      this.YearOptions.push(y);
    }
  }

  arrayMonth(n: number): number[] {
    return Array(n);
  }

  getAffiliateInvoiceList(pageNo: number = 1) {
    if (localStorage.getItem("currentUser")) {
      let clientId = JSON.parse(localStorage.getItem("currentUser")).ID;
      this.affiliateService.getInvoiceList(clientId, this.Month, this.Year, 1, 3, this.sharedService.currentCulture, this.pageSize, pageNo).subscribe(data => {
        if (data) {
          this.webOrderList.currentPage = pageNo;
          this.webOrderList.dataSource = data;
          if (data.length > 0)
            this.webOrderList.totalItems = data[0].TotalCount;
        }
      });
    }
  }

  onWebOrderPageIndexChange(event: PageChangedEvent) {
    this.getAffiliateInvoiceList(event.page);
  }

  downloadInvoiceWithSeal(CompanyNo:string) {
    this.invoicePreview(true,CompanyNo);
  }

  downloadInvoiceWithoutSeal(CompanyNo:string) {
    this.invoicePreview(false,CompanyNo);
  }

  onOptionChange()
  {
    this.getAffiliateInvoiceList();
  }

  openInvoicePreviewWindow(month: number, year: number,CompanyNo:string=null) {
    let ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-lg"
    };
    this.sharedService.setModalData({ OrderMonth: month, OrderYear: year,IsShowSeal:this.IsShowSeal,CustomerNo: CompanyNo});
    this.bsModalRef = this.modalService.show(AffiliateInvoiceTemplateComponent, ngbModalOptions);
    this.sharedService.bsModalRef = this.bsModalRef;
  }

  invoicePreview(isSeal:boolean=false,CompanyNo:string) {
    this.IsShowSeal=isSeal;
    this.openInvoicePreviewWindow(this.Month, this.Year,CompanyNo);
  }

  ngOnInit() {
  }

}
