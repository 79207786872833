import { Component, OnInit } from '@angular/core';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { DataShareService } from '../../services/data-share.service';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { DataSharingModel } from '../../models/data-sharing-model';
import { MonthlySalesModel } from '../../models/monthly-sales-model';
import { OrderWeb } from 'src/app/models/order-web.model';

@Component({
  selector: 'app-monthly',
  templateUrl: './monthly.component.html',
  styleUrls: ['./monthly.component.css']
})
export class MonthlyComponent implements OnInit {
  affiliateService: AffiliateService;
  webOrderList: { currentPage: number, totalItems: number, dataSource: MonthlySalesModel[] };
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  pageSize: number;
  customerMonthlySales: { currentPage: number, totalItems: number, dataSource: OrderWeb[] };
  editorMonthlySales: { currentPage: number, totalItems: number, dataSource: OrderWeb[] };
  yearCurrent: string;
  monthCurrent: string;

  constructor(private _affiliateService: AffiliateService, private _router: Router,
    private dataShareService: DataShareService,
    private titleService: Title,
    private meta: Meta) {
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.affiliateService = _affiliateService;
    this.pageSize = 20;

    this.webOrderList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.customerMonthlySales = { currentPage: 1, totalItems: 0, dataSource: null };
    this.editorMonthlySales = { currentPage: 1, totalItems: 0, dataSource: null };
    this.yearCurrent = new Date().getFullYear().toString();
    this.monthCurrent = new Date().getMonth().toString();
    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = "提携月間売上内訳";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("月間売上内訳｜翻訳プロ(editing-Pro.)管理者ページ");
    this.meta.addTag({ name: 'keywords', content: '英文校正, editing-pro, 管理者' });
    this.meta.addTag({ name: 'description', content: 'エディティング者プラスコーディネーターによるエディティングチェックで、簡単、確実な英語エディティングサービスをご提供中。高品質なエディティングをお届けします。お見積りは無料。' });
    this.getCustomerAndEditorSales(this.yearCurrent,this.monthCurrent);
    this.getAllMonthlySalesBreakdownList();
  }


  getAllMonthlySalesBreakdownList(pageNo: number = 1) {
    let clientId = JSON.parse(localStorage.getItem("currentUser")).ID;
    this.affiliateService.getAllMonthlySalesBreakdown(clientId, 3, 1, pageNo, 10).subscribe(data => {
      if (data) {
        //console.log('Monthly Sales Break-Down List',data);
        this.webOrderList.currentPage = pageNo;
        this.webOrderList.dataSource = data;
        if (data.length > 0)
          this.webOrderList.totalItems = data[0].TotalCount;
      }
    });

  }

  onWebOrderPageIndexChange(event: PageChangedEvent) {
    if (localStorage.getItem("currentUser")) {
      let clientId = JSON.parse(localStorage.getItem("currentUser")).ID;
      this.getAllMonthlySalesBreakdownList(event.page);
    }
  }

  getCustomerAndEditorSales(Year: string, Month: string) {
    if(Year && Month)
    {
      this.yearCurrent = Year;
      this.monthCurrent = Month;
      this.getCustomerMonthlySales(Year, Month);
      this.getEditorMonthlySales(Year, Month);
    }

  }

  private getCustomerMonthlySales(Year: string, Month: string, pageNo: number = 1) {
    if(Year && Month)
    {
      let clientId = JSON.parse(localStorage.getItem("currentUser")).ID;
      this.affiliateService.getCustomerSalesBreakdown(clientId, parseInt(Month), parseInt(Year), 1, '', null, null, '', 10, 1, 'jp', 3).subscribe((data) => {
        if (data) {
          console.log(data);
          this.customerMonthlySales.currentPage = pageNo;
          this.customerMonthlySales.dataSource = data;
          if (data.length > 0)
            this.customerMonthlySales.totalItems = data[0].TotalCount;
        }
      });
    }

  }

  onCustomerMonthlySalesIndexChange(event: PageChangedEvent) {
    if (localStorage.getItem("currentUser")) {
      let clientId = JSON.parse(localStorage.getItem("currentUser")).ID;
      this.getCustomerMonthlySales(this.yearCurrent, this.monthCurrent, event.page);
    }
  }

  private getEditorMonthlySales(Year: string, Month: string, pageNo: number = 1) {
    let clientId = JSON.parse(localStorage.getItem("currentUser")).ID;
    this.affiliateService.getEditorSalesBreakdown(clientId, parseInt(Month), parseInt(Year), 1, '', null, null, null, 10, 1, 'jp', 3).subscribe((data) => {
      if (data)
        this.editorMonthlySales.currentPage = pageNo;
      this.editorMonthlySales.dataSource = data;
      if (data.length > 0)
        this.editorMonthlySales.totalItems = data[0].TotalCount;
    })
  }

  onEditorMonthlySalesIndexChange(event: PageChangedEvent) {
    if (localStorage.getItem("currentUser")) {
      let clientId = JSON.parse(localStorage.getItem("currentUser")).ID;
      this.getEditorMonthlySales(this.yearCurrent, this.monthCurrent, event.page);
    }
  }

  ngOnInit() {
  }
}
