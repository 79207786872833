import { Component, OnInit } from '@angular/core';
import { CommonListService } from '../services/common-list.service';
import { OrderWeb } from '../models/order-web.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ToastrService } from 'ngx-toastr';
import { EditorService } from '../services/editor.service';
import { EditorSearchModel } from '../models/editor-search.model';
import * as _ from 'underscore';
import { AppSetting } from '../app-setting';
import { ConfirmDialogComponent, confirmDialogConfig } from '../confirm-dialog/confirm-dialog.component';
import { ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { EstimationService } from '../services/estimation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { EvaluationService } from '../services/evaluation.service';
import { OrderEvaluationModel } from '../models/order-evaluation-model.model';
import { DataShareService } from '../services/data-share.service';
import { UtilityService } from '../services/utility.service';
import { LanguageModel } from '../models/language.model';
import { SpecializedFieldModel } from '../models/specialized-field.model';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  webOrderList: { currentPage: number, totalItems: number, dataSource: OrderWeb[] };
  listService: CommonListService;
  toastr: ToastrService;
  editorService: EditorService;
  editorList: { currentPage: number, totalItems: number, dataSource: EditorSearchModel[], pageSize: number };
  imageBaseUrl: string = AppSetting.IMAGE_BASE_URL;
  ngbModalOptions: ModalOptions;
  ngbModalOptionsNonEnglishAlert: ModalOptions;
  estimationInputText: string;
  estimationInputLimit: number = 20000;
  esimationService: EstimationService;
  evaluationService: EvaluationService;
  orderEvaluationModel: Array<OrderEvaluationModel>;
  evulationList: { currentPage: number, totalItems: number, dataSource: OrderEvaluationModel[], pageSize: number };
  uploadedDocuments: Array<any>;
  uploadedFiles: { File1: any, File2: any, File3: any };
  fileUploadProgressBarValue: number;
  pricesPerWord: { Light: number, Business: number, Expert: number };
  certifiedEditorList: { currentPage: number, totalItems: number, dataSource: EditorSearchModel[], pageSize: number };
  sharedService: DataShareService;
  languageTo:string;
  languageFrom:string;
  languages: Array<LanguageModel>;
  commonListService: CommonListService;
  SpecializedFieldList: Array<SpecializedFieldModel>;
  specializedFieldID:string;
  isNonEnglishConditionAgreed:boolean;
  isNonEnglishConditionAgreedAlertShown:boolean;

  constructor(private _router: Router,
    private arouter: ActivatedRoute,
    private _listService: CommonListService,
    private _evaluationService: EvaluationService,
    private _sharedService: DataShareService,
    private _toastr: ToastrService,
    private modalService: BsModalService,
    private _editorService: EditorService,
    private _esimationService: EstimationService,
    private utility: UtilityService,
    private _commonListService: CommonListService,
    private titleService: Title,
    private meta: Meta) {

    this.listService = _listService;
    this.editorService = _editorService;
    this.esimationService = _esimationService;
    this.evaluationService = _evaluationService;
    this.sharedService = _sharedService;
    this.commonListService = _commonListService;

    this.toastr = _toastr;
    this.orderEvaluationModel = new Array<OrderEvaluationModel>();
    this.evulationList = { currentPage: 1, totalItems: 0, dataSource: null, pageSize: 10 };
    this.webOrderList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.editorList = { currentPage: 1, totalItems: 0, dataSource: null, pageSize: 16 };
    this.certifiedEditorList = { currentPage: 1, totalItems: 0, dataSource: null, pageSize: 4 };
    this.estimationInputText = "";
    this.uploadedDocuments = new Array<any>();
    this.uploadedFiles = { File1: null, File2: null, File3: null };
    this.fileUploadProgressBarValue = 0;
    this.pricesPerWord = { Light: null, Business: null, Expert: null };
    this.languageFrom='1484e197-70f8-4f64-98cf-e0f06eff6f49';
    this.languageTo='1484e197-70f8-4f64-98cf-e0f06eff6f49';
    this.SpecializedFieldList=new Array<SpecializedFieldModel>();
    this.specializedFieldID=null;
    this.isNonEnglishConditionAgreed=false;
    this.isNonEnglishConditionAgreedAlertShown=false;

    this.arouter.queryParams.subscribe(params => {
      if(params && params.lang && params.lang.length==2)
        this.sharedService.setCurrentCulture(params.lang,false);
    });
    
    if(this.sharedService.currentCulture=='jp'){
      this.titleService.setTitle("英文校正のエディティングプロ。専門エディターによる本格英文校正|editing-Pro.");
      this.meta.updateTag({ name: 'keywords', content: '英文校正, 英文,校正, 専門家校正,校正会社,英文論文校正,英文論文チェック,校正チェック,リスト,校正サービス, 論文,マニュアル, スピード正, 格安, 激安校正, オンライン校正, 校正価格' });
      this.meta.updateTag({ name: 'description', content: '英文校正エディティングプロは認定されたプロの校正者及び校正・エディティングの専門のスタッフによる学術論文、ビジネス文書や法律、医療、特許などさまざまな分野における英文の校正・エディティングサービスを提供しております。' });
    }
    else{
      this.titleService.setTitle("English editing service editing-Pro. English editing service by professional editors|editing-Pro.");
      this.meta.updateTag({ name: 'keywords', content: 'editing-Pro., English writing,editing, professional editing,editing company,English writing academic writing editing,English writing academic writingcheck,editingcheck,list,editing service, academic writing,mannual, speed, resonable price, editing, onlineediting, editing price' });
      this.meta.updateTag({ name: 'description', content: 'editing-Pro. provides professional English editing service by certified editors. Services include editing service for academic writings by professional staffs, business documents, legal, medical and patent and other professional categories for English writing editing' });
    }

    this.esimationService.getEstimationWebOrderList(1, 20, true, this.sharedService.currentCulture).subscribe((data) => {
      this.webOrderList.dataSource = data;
      //console.log('landing page translator ID search',data);
      if (data.length > 0)
        this.webOrderList.totalItems = data[0].TotalCount;
    });

    this.getLanguageList();
    this.getSpecializedFieldList();
    this.getEditorList();
    this.getCertifiedEditorList();
    this.initModalConfig();
    this.getChargeSettings();
    this.getEvaluationList();
    this.orderEvaluationSummary();
    this.initNonEnglishModalConfig();
  }

  getSpecializedFieldList() {
    this.commonListService.getSpecializedFieldList().subscribe((data) => {
      if (data)
        this.SpecializedFieldList = data;
    });
  }

  getLanguageList() {
    this.commonListService.getAllLanguages().subscribe((data) => {
      if (data)
        this.languages = data;
    });
  }

  orderEvaluationSummary() {
    this.evaluationService.getOrderEvaluationSummary().subscribe((data) => {
      if (data)
        this.orderEvaluationModel = data;
    })
  }

  onEditorPageIndexChange(event: PageChangedEvent) {
    this.editorList.currentPage = event.page;
    this.getEditorList();
  }

  onCertifiedEditorPageIndexChange(event: PageChangedEvent) {
    this.certifiedEditorList.currentPage = event.page;
    this.getCertifiedEditorList();
  }

  getChargeSettings() {
    this.esimationService.getEditingChargeSettings().subscribe((data) => {
      if (data) { //+ 5%discount
        this.pricesPerWord.Business = data.BaseCharge * data.Business - (data.BaseCharge * data.Business * 0.1);
        this.pricesPerWord.Expert = data.BaseCharge * data.Expert - (data.BaseCharge * data.Expert * 0.1);
        this.pricesPerWord.Light = data.BaseCharge * data.Light - (data.BaseCharge * data.Light * 0.1);
      }
    });
  }

  getEditorList() {
    this.editorService.getEditorListForLandingPage(this.editorList.currentPage, this.editorList.pageSize).subscribe((data) => {
      if (data) {
        this.editorList.dataSource = data;
        if (data.length > 0)
          this.editorList.totalItems = data[0].TotalRows;
        _.each(this.editorList.dataSource, function (item: EditorSearchModel) {
          let spList = _.pluck(item.Speciality.slice(0, 5), 'Name');
          item.SpecializedFiled = spList.join('、');
        });

        this.editorList.dataSource.forEach(item => {
          item.SpecializedFiled = this.utility.getClippedText(item.SpecializedFiled, this.sharedService.currentCulture);
        });

      }
      else {
        this.editorList.dataSource = new Array<EditorSearchModel>();
        this.editorList.totalItems = 0;
      }
    });
  }

  getCertifiedEditorList() {
    this.editorService.getCertifiedEditorList(this.certifiedEditorList.currentPage, this.certifiedEditorList.pageSize).subscribe((data) => {
      if (data) {
        this.certifiedEditorList.dataSource = data;
        if (data.length > 0)
          this.certifiedEditorList.totalItems = data[0].TotalRows;
        _.each(this.certifiedEditorList.dataSource, function (item: EditorSearchModel) {
          let spList = _.pluck(item.Speciality.slice(0, 5), 'Name');
          item.SpecializedFiled = spList.join('、');
        });

      }
      else {
        this.certifiedEditorList.dataSource = new Array<EditorSearchModel>();
        this.certifiedEditorList.totalItems = 0;
      }
    });
  }

  onWebOrderPageIndexChange(event: PageChangedEvent) {
    this.esimationService.getEstimationWebOrderList(event.page, 20, true, this.sharedService.currentCulture).subscribe((data) => {
      this.webOrderList.currentPage = event.page;
      this.webOrderList.dataSource = data;
      if (data.length > 0)
        this.webOrderList.totalItems = data[0].TotalCount;
    });
  }

  initModalConfig() {
    let config: confirmDialogConfig = {
      MessageText: "英語20,000wordまで入力可能です",
      IsOkOnly: true
    };
    this.ngbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-sm",
      initialState: config
    };
  }

  private nonEnglishConditionAlertYesCallback()
  {
    this.isNonEnglishConditionAgreed=true;
    this.isNonEnglishConditionAgreedAlertShown=true;
    this.estimateByText();
  }
  private nonEnglishConditionAlertNoCallback()
  {
    this.isNonEnglishConditionAgreed=false;
    this.isNonEnglishConditionAgreedAlertShown=false;
  }

  initNonEnglishModalConfig() {

    let msgText:string="";
    if(this.sharedService.currentCulture=="jp")
    {
      msgText="原稿に英語ではない文字が含まれています。英文校正の依頼では英語のみが校正対象になりますのでご了承ください" ;
    }
    else
    {
      msgText="There is a manuscript containing non-English characters. In English editing the character is excluded. Would you all right?";
    }
 

    let config: confirmDialogConfig = {
      MessageText: msgText,
      IsYesNo: true,
      YesBtnCallback:this.nonEnglishConditionAlertYesCallback.bind(this),
      NoBtnCallback:this.nonEnglishConditionAlertNoCallback.bind(this)
    };
    this.ngbModalOptionsNonEnglishAlert = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-sm",
      initialState: config
    };
  }

  private checkSpecializedField()
  {
    if(this.specializedFieldID)
    {
      return true;
    }
    else
    {
      let msg:string=this.sharedService.currentCulture=='jp'? '自動見積の際にはエディタ言語と専門分野を先にお選びください。' : 'Please choose the Specialized field for Estimation.';
      this.toastr.warning(msg);
      return false;
    }
  }

  inputBoxWordLimitValidation() {
    if (this.estimationInputText.length > 0) {
      var words = this.estimationInputText.match(/\S+/g).length;
      if (words > this.estimationInputLimit) {
        this.modalService.show(ConfirmDialogComponent, this.ngbModalOptions);
        return false;
      }
      else
        return true;
    }
    else
      return false;
  }

  estimateByText() {

    if(this.isNonEnglishConditionAgreedAlertShown==false && this.utility.isContainNonEnglishText(this.estimationInputText))
    {
      if(this.isNonEnglishConditionAgreedAlertShown==false)
      {
        this.modalService.show(ConfirmDialogComponent, this.ngbModalOptionsNonEnglishAlert);
      }
      if(!this.isNonEnglishConditionAgreed)
      return;
    }

    if(!this.checkSpecializedField())
     return;

    if (!this.inputBoxWordLimitValidation())
      return;

    this.esimationService.getManuscriptByPlainTextEstimated(this.estimationInputText).subscribe((data) => {
      if (data) {
        localStorage.setItem("estimationResult", JSON.stringify(data));
        localStorage.removeItem("selectedPlanCourseButtonId");
        localStorage.removeItem("newEstimationBasicPreference");
        localStorage.removeItem("orderCopyModelForReorderSimilar");
        localStorage.removeItem("servicePreferenceModel"); //remove if any previous
        localStorage.setItem("newEstimationBasicPreference", JSON.stringify({SpecializedFieldID:this.specializedFieldID,FromLangID:this.languageFrom,ToLangID:this.languageTo}));
        this._router.navigateByUrl("/estimation");
      }
    });
  }

  fileSelected(event: any, from: number) {  //this time developed by regarding that only one file will be uploaded
    if (from == 1)
      this.uploadedFiles.File1 = event.target.files[0];
    else if (from == 2)
      this.uploadedFiles.File2 = event.target.files[0];
    else
      this.uploadedFiles.File3 = event.target.files[0];

    this.fileUploadProgressBarValue = 0;
  }

  uploadEstimationDocuments() {
    this.fileUploadProgressBarValue = 0; //dummy progress bar
    setTimeout(() => {
      if (this.uploadedFiles.File1) {
        if (!this.utility.isDuplicateFile(this.uploadedFiles.File1, this.uploadedDocuments)) {
          this.uploadedDocuments.push(this.uploadedFiles.File1);
          this.fileUploadProgressBarValue = 33;
        }
      }
      if (this.uploadedFiles.File2) {
        if (!this.utility.isDuplicateFile(this.uploadedFiles.File2, this.uploadedDocuments)) {
          this.uploadedDocuments.push(this.uploadedFiles.File2);
          this.fileUploadProgressBarValue += 33;
        }
      }
      if (this.uploadedFiles.File3) {
        if (!this.utility.isDuplicateFile(this.uploadedFiles.File3, this.uploadedDocuments)) {
          this.uploadedDocuments.push(this.uploadedFiles.File3);
          this.fileUploadProgressBarValue += 34;
        }
      }
      if (this.fileUploadProgressBarValue > 0) //if atleast a file selected
        this.fileUploadProgressBarValue = 100;

    }, 300);

  }

  estimateByDocuments() {

    if(!this.checkSpecializedField())
    return;

    if (this.uploadedDocuments.length > 0) {
      this.esimationService.getManuscriptByUploadedDocumentEstimated(this.uploadedDocuments).subscribe((data) => {
        if (data) {
          localStorage.removeItem("estimationResult"); //remove if previously any
          localStorage.removeItem("selectedPlanCourseButtonId");
          localStorage.removeItem("newEstimationBasicPreference");
          localStorage.removeItem("orderCopyModelForReorderSimilar");
          localStorage.setItem("estimationResult", JSON.stringify(data));
          localStorage.setItem("newEstimationBasicPreference", JSON.stringify({SpecializedFieldID:this.specializedFieldID,FromLangID:this.languageFrom,ToLangID:this.languageTo}));
          localStorage.removeItem("servicePreferenceModel"); //remove if any previous
          this._router.navigateByUrl("/estimation");
        }
      });
    }
    else {
      return;
    }

  }

  getEvaluationList() {
    this.evaluationService.getOrderEvaluationList(1, null, this.evulationList.pageSize,this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.evulationList.dataSource = data;
      if (data.length > 0)
        this.evulationList.totalItems = data[0].TotalRecords;
    })
  }

  onEvaluationListPageIndexChange(event: PageChangedEvent) {
    this.evaluationService.getOrderEvaluationList(event.page, null, this.evulationList.pageSize,this.sharedService.currentCulture).subscribe((data) => {
      this.evulationList.currentPage = event.page;
      this.evulationList.dataSource = data;
      if (data.length > 0)
        this.evulationList.totalItems = data[0].TotalRecords;
    });
  }

  ngOnInit() {

    if (localStorage.getItem("OpenLoginPopup")) {
      localStorage.removeItem("OpenLoginPopup");
      if (!this.sharedService.loginStatus) {
        // this.sharedService.OpenLoginPopup();
      }

    }

  }

}
