import { Directive, ElementRef, Input } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { CurrencyConverterService } from '../services/currency-converter.service';
import {CurrencyPipe} from '@angular/common';

@Directive({
  selector: '[appChangeCurrency]'
})
export class ChangeCurrencyDirective {
  @Input('totalAmount') amountJPY:number;
  convertedAmount: number;
  currencySymbol:string;
  currencyService:CurrencyConverterService;
  sharedService:DataShareService;
  currentCurrecyCode:string;
  formatString:string;
  ele:ElementRef;

  constructor(Element: ElementRef,private cp:CurrencyPipe,private _sharedService:DataShareService,private _currencyService:CurrencyConverterService) { 
    this.sharedService=_sharedService;
    this.currentCurrecyCode=this.sharedService.currentCurrency?this.sharedService.currentCurrency : "JPY";
    this.currencyService=_currencyService;
    this.formatString='1.0-0';

    if(this.currentCurrecyCode=='USD')
    {
      this.currencySymbol='$';
      this.formatString='1.2-2';
    }
    else if(this.currentCurrecyCode=='PHP')
    {
      this.currencySymbol='₱';
    }
    else
    {
      this.currencySymbol='¥';
    }

    this.ele=Element;

  }

  private convertAmount()
   {
    let amount:number= this.currencyService.fx.convert(this.amountJPY, {from: "JPY", to: this.currentCurrecyCode});
    this.convertedAmount=amount;
    this.ele.nativeElement.innerText=
    this.cp.transform(this.convertedAmount,this.currentCurrecyCode,this.currencySymbol,this.formatString);
   }

   ngOnInit() {
    this.currencyService.executeCurrencyConversion(this.convertAmount.bind(this));
  }

}
