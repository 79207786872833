import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router } from '@angular/router';
import { Title,Meta  }     from '@angular/platform-browser';

@Component({
  selector: 'app-tips-for-order',
  templateUrl: './tips-for-order.component.html',
  styleUrls: ['./tips-for-order.component.css']
})
export class TipsForOrderComponent implements OnInit {

  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  
  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private titleService: Title,
    private meta: Meta) {

    this.router=_router.url;
    this.sharedService=dataShareService;

    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName=this.sharedService.currentCulture== 'jp'?"校正依頼のコツ":" Editing tips";
    this.sharedService.setSharedData(this.shareableData);



    if(this.sharedService.currentCulture=='jp'){


      this.titleService.setTitle("英文校正の依頼のコツ|editing-Pro.");
      this.meta.updateTag({ name: 'keywords', content: '依頼のコツ,校正依頼,校正依頼方法,校正方法,校正品質,論文校正,エディティング,学術校正,医学論文校正,論文校正専門,論文エディティング,エディティングプロ' });
      this.meta.updateTag({ name: 'description', content: '英語校正を依頼する前に,依頼のコツをご確認下さい。エディティングプロでお客様のご希望に合わせた校正文をご提供しております。' });
    }
    else{

      this.titleService.setTitle("editing-Pro. tips|editing-Pro.");
      this.meta.updateTag({ name: 'keywords', content: '"tips for order,editing order,editing order how to,how to edit,editingquality,academic writingediting,editing,academic editing,medical academic writingediting,academic writingeditingprofessional,academic writingediting,editing-Pro."' });
      this.meta.updateTag({ name: 'description', content: 'Before placing your English editing order, check tips for order confirmation. editing-Pro. Provides order plan that are accustom to your editing needs.' });
    }

   }

  ngOnInit() {
  }

}
