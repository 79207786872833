import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {DataShareService} from '../services/data-share.service';
import {DataSharingModel} from '../models/data-sharing-model';
import { Title,Meta  }     from '@angular/platform-browser';
import { EvaluationService } from '../services/evaluation.service';
import { OrderEvaluationModel } from '../models/order-evaluation-model.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/public_api';

@Component({
  selector: 'app-customer-evaluation',
  templateUrl: './customer-evaluation.component.html',
  styleUrls: ['./customer-evaluation.component.css']
})
export class CustomerEvaluationComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  evaluationService:EvaluationService;
  orderEvaluationModel:Array<OrderEvaluationModel>;
  evulationList: { currentPage: number, totalItems: number, dataSource: OrderEvaluationModel[] };

  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private _evaluationService:EvaluationService,
    private titleService: Title,
    private meta: Meta) {
      this.router=_router.url;
    this.sharedService=dataShareService;
    this.evaluationService=_evaluationService;
    this.evulationList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.orderEvaluationModel = new Array<OrderEvaluationModel>();
    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName=this.sharedService.currentCulture=='jp'?"エディター評価一覧":" Editor evaluation list";
    this.sharedService.setSharedData(this.shareableData);


    if(this.sharedService.currentCulture=='jp'){

      this.titleService.setTitle("英文校正のエディター検索|editing-Pro.");
      this.meta.updateTag({ name: 'keywords', content: 'エディター検索,英語校正検索,エディティング検索, エディター, 英語校正, エディターログイン, 登録エディター, プロのエディター' });
      this.meta.updateTag({ name: 'description', content: 'エディターの言語,学歴,専門分野別で検索出来ます。校正する文章の専門分野とマッチング出来るエディターに校正依頼して下さい。' });

    }
    else{

      this.titleService.setTitle("editing-Pro.editor search|editing-Pro.editing-Pro.");
      this.meta.updateTag({ name: 'keywords', content: '"editorsearch,Englisheditingsearch,editingsearch, editor, Englishediting, editor log in, register editor, professional editor"' });
      this.meta.updateTag({ name: 'description', content: 'Search based on editors language, academic, professional categorie\'s. Match professional editor\'s with their backgrounds and documents that needs to be edited. ' });


    }

    this.orderEvaluationSummary();
    this.orderEvaluationList();
     }

     orderEvaluationSummary(){
       this.evaluationService.getOrderEvaluationSummary().subscribe((data)=>{
         if(data)
         this.orderEvaluationModel = data;
       })
     }

     orderEvaluationList(pageNo:number=1){

       this.evaluationService.getOrderEvaluationList(pageNo,null,10,this.sharedService.currentCulture).subscribe((data)=>{
        if(data)
         {
           this.evulationList.dataSource = data;
           if (data.length > 0)
           this.evulationList.totalItems = data[0].TotalRecords;
         }
       })
    }

    onEvaluationListPageIndexChange(event: PageChangedEvent) {
      this.evaluationService.getOrderEvaluationList(event.page,null,10,this.sharedService.currentCulture).subscribe((data) => {
        this.evulationList.currentPage = event.page;
        this.evulationList.dataSource = data;
        if (data.length > 0)
          this.evulationList.totalItems = data[0].TotalRecords;
      });
    }

  ngOnInit() {
  }

}
