import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DataShareService } from '../../../services/data-share.service';
import { DocumentModel } from '../../../models/document.model';
import { CommonListService } from '../../../services/common-list.service';
import { UtilityService } from '../../../services/utility.service';

@Component({
  selector: 'app-download-delivery',
  templateUrl: './download-delivery.component.html',
  styleUrls: ['./download-delivery.component.css']
})
export class DownloadDeliveryComponent implements OnInit {
downloadFiles:Array<DocumentModel>;

  constructor(private bsModalRef: BsModalRef,
    private _commonService: CommonListService,
    private utilityService: UtilityService,
     private sharedService: DataShareService) {
    let sharedModalData = this.sharedService.getModalData();
    this.downloadFiles=sharedModalData;
  }

  OKBtnClicked() {
    this.bsModalRef.hide();
  }

  downloadFile(filePath: string, isAbsolutePath = false) {
    if (isAbsolutePath)
      filePath = this.utilityService.getRelativePathFromFileURL(filePath);
    this._commonService.downloadFile(filePath, this.utilityService.getFileNameFromPath(filePath));
  }

  ngOnInit() {
  }

}
