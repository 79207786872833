import { BankBranchModel } from "./bank-branch.model"
import { BankModel } from "./bank.model"
import { CountryModel } from "./country-model.model"

export class BankAccountModel {
    ID: string
    BankID: string
    BankBranchID: string
    AccountTypeID: number
    AccountNo: string
    AccountName: string
    AccountName_en: string
    AccountName_jp: string
    AccountName_kr: string
    AccountName_cn: string
    AccountName_fr: string
    AccountName_tl: string
    AccountHolderName_jp:string
    AccountHolderName_en:string
    AccountHolderName:string
    BankName:string
    BankBranchName:string
    BankAccountType:string
    CountryID:number
    PPAccountMail:string
    PPAccountHolderName:string
    PaymentTypeID:number
    CurrencyID:number
    AccountHolderAddress_jp:number
    AccountHolderAddress:string
    CountryName:string 
    PaypalAccountMail:string 
    PaypalAccountName:string 
    AccountCurrency:string
    CurrencyName:string
    BankBranch:BankBranchModel
    Bank:BankModel
    Country:CountryModel
}
