import { Component, OnInit } from '@angular/core';
import { DataShareService } from 'src/app/services/data-share.service';
import { DataSharingModel } from 'src/app/models/data-sharing-model';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { OrderWeb } from 'src/app/models/order-web.model';
import { EstimationService } from 'src/app/services/estimation.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/public_api';

@Component({
  selector: 'app-order-offer',
  templateUrl: './order-offer.component.html',
  styleUrls: ['./order-offer.component.css']
})
export class OrderOfferComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  estimationService: EstimationService;
  editorOpportunityList: { currentPage: number, totalItems: number, isOnlyNominated: boolean, dataSource: OrderWeb[] };

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private _estimationService: EstimationService,
    private titleService: Title,
    private meta: Meta) {
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.estimationService = _estimationService;
    this.editorOpportunityList = { currentPage: 1, totalItems: 20, isOnlyNominated: false, dataSource: null };
    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = this.sharedService.currentCulture =='jp'? "案件リスト（受注可能/指名）":"Order List (Order Acceptable / Nomination)";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("受注可能リストを確認（英文エディター用）");
    this.meta.updateTag({ name: 'keywords', content: '可能な案件リスト,案件リスト,英文校正,オンライン校正,英語校正,英語,英語英文エディター,校正会社,案件論文校正,英語論文チェック' });
    this.meta.updateTag({ name: 'description', content: '受注可能案件・指名案件をご確認頂けます。24時間365日,オンライン校正サービス。すべての過程がウェブ上で完結。簡単,早い,確実！' });
    this.getOpportunityList();
  }

  onWebOrderPageIndexChange(event: PageChangedEvent) {
    this.getOpportunityList(event.page);
  }

  getOpportunityList(pageNo: number = 1) {
    let editorId = JSON.parse(localStorage.getItem("currentUser")).ID;
    this.estimationService.getEditorOpportunityList(editorId, pageNo, 20, false,this.sharedService.currentCulture).subscribe((data) => {
      this.editorOpportunityList.currentPage = pageNo;
      this.editorOpportunityList.dataSource = data;
      console.log('oder offer list',data);
      if (data.length > 0) {
        this.editorOpportunityList.totalItems = data[0].TotalCount;
      }
    })
  }

  ngOnInit() {
  }

}