export class MiscellaneousModel {
    FirstEditorTotalEvaluation: number
    FirstEditorTotalAVGScore?: number
    FirstEditorAVGScore?: number
    SecondEditorTotalEvaluation: number
    SecondEditorTotalAVGScore?: number
    SecondEditorAVGScore?: number
    FavouriteCount: number
    TotalInvited: number
    TotalAssigned: number
    AppointedRate: number
    Duration: string
    NoOfCases: number
    TotalAmount: number
    CardPayment: number
    PostPay: number
    TotalCount?: number
    SpecializedFieldName: string
    FirstEditorCase?: number
    SecondEditorCase?: number
    Culture: string
    DiscountTitle: string
    DiscountType: string
    DiscountRate?: number
    DiscountStatus: boolean
    OrderMonth: number
    OrderYear: number
    CouponCount: number
    TotalCountries: number
    TotalTranslators: number
    TotalLanguages: number
    AsOfDate:string
    OrderNo:string 
	LegalExpertCount:number
	MedicalExpertCount:number
	GeneralExpertCount:number
	PatentExpertCount:number
    CertificateExpertCount:number
    CustomerNo:number
    IsShowSeal:boolean
    GeneralCase:number 
    AppointedCase:number
}
