import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { OrderWeb } from '../models/order-web.model';
import { EstimationService } from '../services/estimation.service';
import { EditingPreferenceModel } from '../models/editing-pro-preference-model.model';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-order-details-common',
  templateUrl: './order-details-common.component.html',
  styleUrls: ['./order-details-common.component.css']
})
export class OrderDetailsCommonComponent implements OnInit {

  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  orderDetails:OrderWeb;
  estimationService:EstimationService;
  customerRating:number=4.5;
  utilityService:UtilityService;
  IsShowComments: boolean;
  constructor(
    private _router: Router,
    private dataShareService:DataShareService,
    private titleService: Title,
    private _estimationService:EstimationService,
    private _utilityService: UtilityService,
    private _route: ActivatedRoute,
    private meta: Meta) { 
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.estimationService =_estimationService;
    this.utilityService=_utilityService;
    this.orderDetails = new OrderWeb();
    this.orderDetails.EditingPreference=new EditingPreferenceModel();
    this.shareableData =new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName=this.dataShareService.currentCulture =='jp'? "案件詳細":"Order details";
    this.sharedService.setSharedData(this.shareableData);
    //update dynamic field after getting order detail from API


    if(this.sharedService.currentCulture=='jp'){

      this.titleService.setTitle("英語【分野】【プラン】校正エディティング");
      this.meta.updateTag({ name: 'keywords', content: '英語, 【分野】,【プラン】, 校正エディティング' });
      this.meta.updateTag({ name: 'description', content: '英語【分野】【プラン】校正エディティング' });
    }
    else{

      this.titleService.setTitle("英語【分野】【プラン】校正エディティング");
      this.meta.updateTag({ name: 'keywords', content: '英語, 【分野】,【プラン】, 校正エディティング' });
      this.meta.updateTag({ name: 'description', content: '英語【分野】【プラン】校正エディティング' });

    }

    this._route.params.subscribe(params=>{
      if(params["orderNumber"])
      {
        let orderNo:string=params['orderNumber'];
        this.getOrderDetailsByOrderNo(orderNo);
      }
    });
  }

  calculateHalfDateTime(start:Date, end:Date):Date{
    return this.utilityService.getHalfDateTimeOfOrder(start,end);
   }

  private updateMetaData(category:string,plan:string){
    this.titleService.setTitle(this.orderDetails.OrderNo+' 英語'+(category? category :"")+(plan? plan :"")+'校正エディティング');
    this.meta.updateTag({ name: 'description', content:   '英語'+(category? category :"")+(plan? plan :"")+'校正エディティング'});
    this.meta.updateTag({name:'keyword',content: '英語, '+(category? category :"")+(plan? plan :"")+', 校正エディティング' });
   }

  getOrderDetailsByOrderNo(orderNo:string)
  {
    this.estimationService.getOrderDetailsByOrderNo(orderNo).subscribe((data)=>{
      if(data)
      {
        this.orderDetails = data;
        //console.log('Order Details',this.orderDetails);
        this.customerRating=data.AvgScore;
        this.updateMetaData(this.orderDetails.TranslationFieldName,this.orderDetails.DeliveryLevelName)
      }
 
    });
  }

  ngOnInit() {

    if(localStorage.length>0)
    {
    this.IsShowComments=true;
    //alert(this.IsShowComments);
    }
    else
    {
      this.IsShowComments=false;
      //alert(this.IsShowComments);
    }

  }

}
