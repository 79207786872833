import { Component, OnInit, HostListener } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CredentialModel, UserType } from '../models/credential.model';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { DataShareService } from '../services/data-share.service';
@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css']
})
export class LoginModalComponent implements OnInit {
  title: string;
  closeBtnName: string;
  loginModel: CredentialModel;
  authService: AuthenticationService;
  router: Router
  sharedService: DataShareService
  constructor(_authService: AuthenticationService,
    _router: Router, _dataShareService: DataShareService
  ) {
    this.router = _router;
    this.loginModel = new CredentialModel();
    this.authService = _authService;
    this.sharedService = _dataShareService;
  }

  onTabChange(type: number) {
    this.loginModel.UserType = type;
  }

  onLoginFormSubmit() {

    this.authService.setCaurrentCandidate(this.loginModel);
    switch (this.loginModel.UserType) {
      case UserType.Customer:
        this.router.navigateByUrl('/customer/my-page');
        break;
      case UserType.Editor:
        this.router.navigateByUrl('/editor/my-page');
        break;
      case UserType.Partner:
        this.router.navigateByUrl('/partner/my-page');
        break;

    }
  }


  private nvaigateToReissue(type:string)
  {
    localStorage.setItem('PasswordReissueFor', type);
    this.sharedService.bsModalRef.hide();
    if(this.router.url=='/password-reissue')
    {
      this.router.navigateByUrl('/password-reissue');
      location.reload();
    }
    else{
      this.router.navigateByUrl('/password-reissue');
    }
  }

  goToPasswordReissue(type: number) {
    this.loginModel.UserType = type;

    switch (this.loginModel.UserType) {
      case UserType.Customer:
        this.nvaigateToReissue('Customer');
        break;
      case UserType.Editor:
        this.nvaigateToReissue('Editor');
        break;
      case UserType.Partner:
        break;

    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if(event.keyCode && event.keyCode==13)
    {
      this.onLoginFormSubmit();   
    }
    
  }

  ngOnInit() {
    this.loginModel.UserType = UserType.Customer;
  }

}
