import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { OrderWeb } from '../../models/order-web.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { EstimationService } from '../../services/estimation.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  webOrderList: { currentPage: number, totalItems: number, dataSource: OrderWeb[] };
  esimationService: EstimationService;

  constructor( 
    private _router: Router,
    private dataShareService:DataShareService,
    private _esimationService: EstimationService,
    private titleService: Title,
    private meta: Meta) {

      this.router=_router.url;
      this.sharedService=dataShareService;
      this.esimationService = _esimationService;
      this.webOrderList = { currentPage: 1, totalItems: 0, dataSource: null };
  
      this.shareableData=new DataSharingModel();
      this.shareableData.currentRoute=this.router;
      this.shareableData.currentStateName=this.sharedService.currentCulture=='jp'?"注文内訳":"Order detail";
      this.sharedService.setSharedData(this.shareableData);


      if(this.sharedService.currentCulture=='jp'){

        this.titleService.setTitle("英文校正サイトの内訳確認");
        this.meta.addTag({ name: 'keywords', content: '英文校正,英語エディティング,注文,確認,内訳,リスト,高品質,安心,英語,チェック,簡単,品質保証,校正依頼,校正依頼方法学術校正,医学論文校正,論文校正専門,論文エディティング' });
        this.meta.addTag({ name: 'description', content: '英文校正の注文内訳をご確認頂けます。24時間365日対応。オンラインで利用できる校正サービス。納品物の受領までウェブ上で完結。簡単,早い,確実！30分からの短時間納品も可能です。お問い合わせ,お見積りは無料。' });

      }
      else{

        this.titleService.setTitle("editing-Pro. Order detail confirmation");
        this.meta.addTag({ name: 'keywords', content: 'detail confirm,editing-Pro.,English editing,order,detail,list,high quality,safe,English,check,qualityguarantee,editing order,editing order placement, academic editing, medical academic writing editing,academic writing editing professional,academic writing editing' });
        this.meta.addTag({ name: 'description', content: 'Detail confirmation on editing-Pro. 24hours service for 365 days. Online editing service that allows order to submission through online. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });
      }
      this.getorderBreakdownList();
    }

     getorderBreakdownList()
     {
      if(localStorage.getItem("currentUser"))
      {
        let clientId=JSON.parse(localStorage.getItem("currentUser")).ID;
        this.esimationService.getCustomerOrderBreakdownList(clientId,1,20,this.sharedService.currentCulture).subscribe((data) => {
        this.webOrderList.currentPage = 1;
        this.webOrderList.dataSource = data;
        if (data.length > 0)
          this.webOrderList.totalItems = data[0].TotalCount;
      });
     }
     }

     onWebOrderPageIndexChange(event: PageChangedEvent) {
      if(localStorage.getItem("currentUser"))
      {
        let clientId=JSON.parse(localStorage.getItem("currentUser")).ID;
      this.esimationService.getCustomerOrderBreakdownList(clientId,event.page,20,this.sharedService.currentCulture).subscribe((data) => {
        this.webOrderList.currentPage = event.page;
        this.webOrderList.dataSource = data;
        if (data.length > 0)
          this.webOrderList.totalItems = data[0].TotalCount;
      });
      }
    }
  

  ngOnInit() {
  }

}
