import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Router } from '@angular/router';
import { CredentialModel } from '../../models/credential.model';
import { CustomerService } from '../../services/customer-service.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { confirmDialogConfig, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { AuthenticationService } from '../../services/authentication.service';
import { Title,Meta  }     from '@angular/platform-browser';


@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  customerService: CustomerService;
  shareableData: DataSharingModel;
  unsubscribeModel: CredentialModel;
  IsSureToUnsubscribe: boolean = false;
  unpaidAmount:number;

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private _customerService: CustomerService,
    private toastr: ToastrService,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private _authService:AuthenticationService,
    private titleService: Title,
    private meta: Meta) {
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.customerService = _customerService;
    this.unpaidAmount=null;
    this.unsubscribeModel = new CredentialModel();
    this.unsubscribeModel.Description=null;
    this.unsubscribeModel.Password=null;
    if (localStorage.getItem("currentUser"))
      this.unsubscribeModel.EmailId = JSON.parse(localStorage.getItem("currentUser")).Email;

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = this.sharedService.currentCulture=='jp'?"退会":"Unsubscribe";
    this.sharedService.setSharedData(this.shareableData);


    if(this.sharedService.currentCulture=='jp'){


      this.titleService.setTitle("英文校正サイトの退会ページ");
      this.meta.updateTag({ name: 'keywords', content: '退会ページ,英文校正,エディター,登録,品質,格安校正,オンライン校正,英語校正,英語,登録,専門校正,英語英文校正,校正会社,新規論文校正,英語論文チェック' });
      this.meta.updateTag({ name: 'description', content: '英文校正エディティングプロはを利用頂きましてありがとうございました。またのお越しをお待ちしております。' });

    }
    else{

      this.titleService.setTitle("Unsubscribe editing-Pro.");
      this.meta.updateTag({ name: 'keywords', content: 'unsubscribe page,editing-Pro.,editor, reigstration,quality,resonable price editing,online editing,English editing,English, register,professional editing,English editing-Pro.,editingc ompany,new academic writingediting,Englishacademic writingcheck' });
      this.meta.updateTag({ name: 'description', content: 'editing-Pro. unsubscription page. 24hours service for 365 days. Online editing service that allows order to submission through online. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });



    }

    this.getUnpaidAmount(this.sharedService.currentUser.ID);
  }


  private getUnpaidAmount(customerId:string){
    this.customerService.getCustomerUnpaidAmount(customerId).subscribe((data)=>{
        this.unpaidAmount= data;
        this.configAmount.MessageText='Accounts payable remain : ¥'+this.unpaidAmount+' <br> You cannot withdraw until payment is completed';
    });
  }


  config: confirmDialogConfig = {
    MessageText: "本当に退会しますか？",
    IsYesNo: true,
    YesBtnCallback: this.unsubscribeNow.bind(this)
  };

  configAmount: confirmDialogConfig={
    MessageText: '',
    IsOkOnly:true
  };
  amountModelOptions: ModalOptions={
    backdrop: 'static',
    keyboard: false,
    class: "modal-sm",
    initialState: this.configAmount
  };

  withdrawalModalOptions: ModalOptions = {
    backdrop: 'static',
    keyboard: false,
    class: "modal-sm",
    initialState: this.config
  };

  onUnsuscribeClicked() {
    this.bsModalRef = this.modalService.show(ConfirmDialogComponent, this.withdrawalModalOptions);
  }
  private unsubscribeNow() {
    if(this.unpaidAmount>0)
    {
      this.bsModalRef = this.modalService.show(ConfirmDialogComponent, this.amountModelOptions);
      return;
    }
    else{
    this.customerService.unsubscribe(this.unsubscribeModel).subscribe((data) => {
      if (data == "Success") {
        this.toastr.success('退会手続きが完了しました。'); //"You are Successfully unsubscribed!"
        this._authService.loggedOut();
      }
      else if (data == "InvalidCredential") {
        this.toastr.error('Eメール、もしくはパスワードが一致しません。'); //"Email or password doesn't match!"
      }
      else {
        this.toastr.error('退会手続きが完了できておりません。'); //"Could not be unsubscribed!"
      }
      
    })};
  }

  ngOnInit() {
  }

}
