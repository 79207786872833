import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { EditorService } from '../services/editor.service';
import { EditorSearchModel } from '../models/editor-search.model';
import { AppSetting } from '../app-setting';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/public_api';

@Component({
  selector: 'app-editor-interview-list',
  templateUrl: './editor-interview-list.component.html',
  styleUrls: ['./editor-interview-list.component.css']
})
export class EditorInterviewListComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  editorService: EditorService;
  editorInterviewList: { data: Array<EditorSearchModel>, pageNo: number, pageSize: number, totalItems: number };
  imageBaseUrl: string = AppSetting.IMAGE_BASE_URL;
  certificationFields: Array<string> = ["法律", "医療", "特許", "証明書"];

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private _editorService: EditorService,
    private titleService: Title,
    private meta: Meta) {
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.editorService = _editorService;
    this.editorInterviewList = { data: null, pageNo: 1, pageSize: 5, totalItems: 0 };

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = "エディターインタビューリスト";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("専属プロのエディターのインタビューリスト及びプロフィール");
    this.meta.updateTag({ name: 'keywords', content: '英文,英文校正,英文エディター,インタビューリスト,プロのプロフィール,専門エディター,学術校正' });
    this.meta.updateTag({ name: 'description', content: '英文校正の専門エディターのインタビューが読みたいならすぐクリック！あなたの知りたい英文校正のプロの世界についてご確認頂けます。' });

    this.getEditorInterviewList();
  }

  getEditorInterviewList() {
    this.editorService.getEditorInterviewList(this.editorInterviewList.pageNo, this.editorInterviewList.pageSize).subscribe(data => {
      if (data) {
        this.editorInterviewList.data = data;
        if (data.length > 0)
          this.editorInterviewList.totalItems = data[0].TotalRows;
      }
    });
  }

  getCertificationCSV(editor: EditorSearchModel): string {
    let csv: string = "";
    let certifications: Array<string> = new Array<string>();
    if (editor && editor.CertificationInfo) {
      if (editor.CertificationInfo.IsLegalExpert)
           certifications.push(this.certificationFields[0]);
      if (editor.CertificationInfo.IsMedicalExpert)
           certifications.push(this.certificationFields[1]);
      if (editor.CertificationInfo.IsPatentExpert)
           certifications.push(this.certificationFields[2]);
      if (editor.CertificationInfo.IsCertificateExpert)
           certifications.push(this.certificationFields[3]);

      csv = certifications.join("、");
    }
    return csv
  }

  onPageIndexChange(event: PageChangedEvent) {
    this.editorInterviewList.pageNo = event.page;
    this.getEditorInterviewList();
  }

  ngOnInit() {
  }

}
