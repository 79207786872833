import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { CredentialModel } from '../models/credential.model';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-customer-registration-confirm',
  templateUrl: './customer-registration-confirm.component.html',
  styleUrls: ['./customer-registration-confirm.component.css']
})
export class CustomerRegistrationConfirmComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  authService: AuthenticationService;
  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private _authService: AuthenticationService,
    private titleService: Title,
    private meta: Meta) {
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.authService = _authService;

    if (!localStorage.getItem("currentCandidate")) {
      this._router.navigateByUrl('/404');
    }

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = "新規登録完了";
    this.sharedService.setSharedData(this.shareableData);

    if(this.sharedService.currentCulture=='jp'){
      this.titleService.setTitle("新規登録情報の内容確認");
      this.meta.updateTag({ name: 'keywords', content: '登録的情報,アカウント登録,格安校正,オンライン校正,英語校正,英語,校正,専門エディター校正,英語エディター,校正会社,英文論文校正,英語論文チェック,校正チェック' });
      this.meta.updateTag({ name: 'description', content: '登録の内容をご確認ください！簡単登録でいつでも英文エディティングの依頼ができる！納品物の受領までウェブ上で完結。簡単,早い,確実！お問い合わせ,お見積りは無料。' });


    }
    else {
      this.titleService.setTitle("confirm new registration content");
      this.meta.updateTag({ name: 'keywords', content: 'registered information,account registration,resonable priceediting,online editing,English editing, English,editing,professional editorediting,English editor,editing company,English writing academic writing editing,English academic writing check,editing check' });
      this.meta.updateTag({ name: 'description', content: 'Confirm registered information. Smooth and easy registration and order English editing service anywhere. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });
    }
  }

  goToMyPage() {
    if (localStorage.getItem("currentCandidate")) {
      let currentCandidate: CredentialModel = new CredentialModel();
      currentCandidate = JSON.parse(localStorage.getItem("currentCandidate"));
      this.authService.setCaurrentCandidate(currentCandidate);
      localStorage.removeItem("currentCandidate");
      this._router.navigateByUrl('/customer/my-page');

      setTimeout(() => {
        this._router.navigateByUrl('/customer/my-page');
      }, 300);

    }

  }

  ngOnInit() {
  }

}
