import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router } from '@angular/router';
import { Title,Meta  }     from '@angular/platform-browser';

@Component({
  selector: 'app-service-terms',
  templateUrl: './service-terms.component.html',
  styleUrls: ['./service-terms.component.css']
})
export class ServiceTermsComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  
  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private titleService: Title,
    private meta: Meta) {
    this.router=_router.url;

    this.sharedService=dataShareService;

   this.shareableData=new DataSharingModel();
   this.shareableData.currentRoute=this.router;
   this.shareableData.currentStateName=this.sharedService.currentCulture=='jp'?"利用規約":"terms of service";
   this.sharedService.setSharedData(this.shareableData);



   if(this.sharedService.currentCulture=='jp'){


     this.titleService.setTitle("英文校正サイトの利用規約|editing-Pro.");
     this.meta.updateTag({ name: 'keywords', content: '利用規約,英語規約,校正方法,校正品質,学術校正,医学論文校正,論文校正専門,論文エディティング,エディティングプロ' });
     this.meta.updateTag({ name: 'description', content: 'エディティングプルの利用時に注意することなど。ご利用の前には是非一読下さい。' });

   }
   else{

    this.titleService.setTitle("editing-Pro.terms of service|editing-Pro.");
    this.meta.updateTag({ name: 'keywords', content: '"user rules,English rules,editing how,editingquality,academic editing,medical academic writingediting,academic writingeditingprofessional,academic writingediting,editing-Pro."' });
    this.meta.updateTag({ name: 'description', content: 'Points to consider before using editing-Pro. Please take a look before placing an order. ' });


   }

   }

  ngOnInit() {
  }

}
