import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import * as _ from 'underscore';

@Component({
  selector: 'app-company-dashboard',
  templateUrl: './company-dashboard.component.html',
  styleUrls: ['./company-dashboard.component.css']
})
export class CompanyDashboardComponent implements OnInit {

  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  authService: AuthenticationService;
  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private _authService: AuthenticationService) {
    this.router = _router.url;
    this.authService = _authService;

    this.sharedService = dataShareService;

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = "ご利用ガイド";
    this.sharedService.setSharedData(this.shareableData);
  }

  changeSelectedMenuItem(event) {
    const listItems = document.querySelectorAll('.logout-menu li');
    _.each(listItems, function (item, index) {
      item.className = "";
    });
    event.target.parentNode.classList.add("active");
  }

  logout() {
    this.authService.loggedOut();
  }

  ngOnInit() {
  }

}
