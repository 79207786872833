import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router } from '@angular/router';
import { Title,Meta  }     from '@angular/platform-browser';
import { CredentialModel } from '../models/credential.model';
import { AuthenticationService } from '../services/authentication.service';


@Component({
  selector: 'app-editor-registration-confirm',
  templateUrl: './editor-registration-confirm.component.html',
  styleUrls: ['./editor-registration-confirm.component.css']
})
export class EditorRegistrationConfirmComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  authService:AuthenticationService;

  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private _authService:AuthenticationService,
    private titleService: Title,
    private meta: Meta) {
    this.router=_router.url;
    this.sharedService=dataShareService;
    this.authService=_authService;

    if(!localStorage.getItem("currentCandidate"))
    {
      this._router.navigateByUrl('/404');
    }
    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName=='jp'?"新規登録完了（エディター）":"Registration confirmation";
    this.sharedService.setSharedData(this.shareableData);

    if(this.sharedService.currentCulture=='jp'){

      this.titleService.setTitle("エディティングプロへの新規エディター登録内容確認");
      this.meta.updateTag({ name: 'keywords', content: '登録的情報,アカウント登録,格安校正,オンライン校正,英語校正,英語,校正,専門エディター校正,英語エディター,校正会社,英文論文校正,英語論文チェック,校正チェック' });
      this.meta.updateTag({ name: 'description', content: 'エディティングプロへの登録内容をご確認ください。' });

    }else{

      this.titleService.setTitle("editing-Pro. New editor registration content confirmation");
      this.meta.updateTag({ name: 'keywords', content: 'register information,account registration,resonable priceediting,onlineediting,English editing,English,editing,professional editor editing,English editor,editing company,English writing academic writing editing,English academic writing check,editing check' });
      this.meta.updateTag({ name: 'description', content: 'Confirm registered information of editing-Pro' });

    }
   }

   goToMyPage(routeIndex:number){
    if(localStorage.getItem("currentCandidate"))
    {
      let currentCandidate:CredentialModel=new CredentialModel();
      currentCandidate=JSON.parse(localStorage.getItem("currentCandidate"));
      this.authService.setCaurrentCandidate(currentCandidate);
      localStorage.removeItem("currentCandidate");
      if(routeIndex==1)
      {
        this._router.navigateByUrl('/editor/my-page');
        
        setTimeout(()=>{
          this._router.navigateByUrl('/editor/my-page');
        },300);
      }
      else if(routeIndex==2)
      {
        this._router.navigateByUrl('/editor/profile');
      }
      else if(routeIndex==3)
      {
        this._router.navigateByUrl('/editor/guide');
      }
    }
  }

  ngOnInit() {
  }

}
