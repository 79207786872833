
export class PriceRowHeadModel {
    RowHead1:{ID:number,Name:string,Duration_en:string}
    RowHead2:{ID:number,Name:string,Duration_en:string}
    RowHead3:{ID:number,Name:string,Duration_en:string}
    RowHead4:{ID:number,Name:string,Duration_en:string}
    constructor()
    {
        this.RowHead1={ID:0,Name:"",Duration_en:"0h"};
        this.RowHead2={ID:0,Name:"",Duration_en:"0h"};
        this.RowHead3={ID:0,Name:"",Duration_en:"0h"};
        this.RowHead4={ID:0,Name:"",Duration_en:"0h"};
    }
}
