import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppSetting } from '../app-setting';
@Injectable({
  providedIn: 'root'
})
export class CurrencyConverterService {
  ROOT_URL: string = AppSetting.API_ENDPOINT;
  EDITINGPRO_ROOT_URL: string = AppSetting.EDITINGPRO_API_ENDPOINT;
  EXCHAGE_RATE_API: string = AppSetting.ExchangeRateApiEndPoint;
  fx: any;
  fxSetup: any;
  constructor(private http: HttpClient) {
    this.fx = (<any>window).fx;
    this.fxSetup=null;
    //this.loadExchangeRates();
  }


  public executeCurrencyConversion(conversionTask:any,from:string="JPY",to:string="USD")
  {
       if(this.fx && this.fx.rates && Object.keys(this.fx.rates).length>0)
       {
        conversionTask();
       }
       else{
         this.loadExchangeRates(conversionTask);
       }
  }

  private loadExchangeRates(callBackTask:any=null) {
    //fx.convert(12.99, {from: "GBP", to: "HKD"});
    this.http.get<any>(this.EXCHAGE_RATE_API).subscribe(data => {
      if (data) {
        if (typeof this.fx !== "undefined" && this.fx.rates) {
          if(Object.keys(this.fx.rates).length==0)
          {
            this.fx.rates = data.rates;
            this.fx.base = data.base;
          }
          
           if(callBackTask)
           {
            callBackTask();
           }          

        } else {
          // If not, apply to fxSetup global:
          this.fxSetup = {
            rates: data.rates,
            base: data.base
          }
        }
      }
    });

    //https://api.exchangeratesapi.io/latest
    /*    $.getJSON(
         // NB: using Open Exchange Rates here, but you can use any source!
           'https://openexchangerates.org/api/latest.json?app_id=[YOUR APP ID]',
           function(data) {
               // Check money.js has finished loading:
               if ( typeof fx !== "undefined" && fx.rates ) {
                   fx.rates = data.rates;
                   fx.base = data.base;
               } else {
                   // If not, apply to fxSetup global:
                   var fxSetup = {
                       rates : data.rates,
                       base : data.base
                   }
               }
           }
       ); */
  }


}
