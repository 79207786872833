import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { EditorService } from '../../services/editor.service';
import { CredentialModel, UserType } from '../../models/credential.model';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { confirmDialogConfig, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Title,Meta  }     from '@angular/platform-browser';
import { NotificationType } from 'src/app/Enums.enum';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {
  router: string;
  datashareService: DataShareService;
  shareableData: DataSharingModel;
  editorService: EditorService;
  unsubscribeModel: CredentialModel;
  IsSureToUnsubscribe:boolean=false;
  confirmMessageText:string;

  constructor(private _router: Router,
    private _datashareService: DataShareService,
    private _editorService: EditorService,
    private titleService: Title,
    private meta: Meta,
    private toastr: ToastrService,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private _authService:AuthenticationService) {

    this.router = _router.url;
    this.datashareService = _datashareService;
    this.confirmMessageText=this.datashareService.currentCulture=='jp'? "本当に退会しますか？" : "Are you sure you want to unsubscribe?";
    this.editorService=_editorService;
    this.unsubscribeModel = new CredentialModel();
    this.unsubscribeModel.EditingProUserType=2;
    if(localStorage.getItem("currentUser"))
    this.unsubscribeModel.EmailId=JSON.parse(localStorage.getItem("currentUser")).Email;

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute= this.router;
    this.shareableData.currentStateName=='jp'? "退会":"Unsubscribe";
    this.datashareService.setSharedData(this.shareableData);
    this.titleService.setTitle("英文校正サイトの退会ページ");
    this.meta.updateTag({ name: 'keywords', content: '退会ページ,英文校正,エディター,登録,品質,格安校正,オンライン校正,英語校正,英語,登録,専門校正,英語英文校正,校正会社,新規論文校正,英語論文チェック' });
    this.meta.updateTag({ name: 'description', content: '英文校正エディティングプロはを利用頂きましてありがとうございました。またのお越しをお待ちしております。' });
  }
  config:confirmDialogConfig={
    MessageText: this.confirmMessageText,
    IsYesNo:true,
    YesBtnCallback:this.unsubscribeNow.bind(this)
  };
  withdrawalModalOptions:ModalOptions={
    backdrop:'static',
    keyboard:false,
    class:"modal-sm",
    initialState:this.config
  };

  onUnsubscribeClickd(){
    this.bsModalRef = this.modalService.show(ConfirmDialogComponent, this.withdrawalModalOptions);
  }

  private unsubscribeNow(){
    //console.log('Unsubscribe Model working..',this.unsubscribeModel)
    this.editorService.unsubscribe(this.unsubscribeModel).subscribe((data)=>{
      if(data=="Success")
      {
        //this.toastr.success('退会手続きが完了しました。');//"You are Successfully unsubscribed!"
        this.datashareService.showTranslationToastr('NOTIFICATION.YOU_ARE_SUCCESSFULLY_UNSUBSCRIBED',NotificationType.SUCCESS);
        this._authService.loggedOut();
      }
      else if(data=="InvalidCredential"){
        //this.toastr.error('Eメール、もしくはパスワードが一致しません。'); //"Email or password doesn't match!"
        this.datashareService.showTranslationToastr('NOTIFICATION.EMAIL_OR_PASSWORD_DOESNT_MATCH',NotificationType.ERROR);
      }
      else{
        //this.toastr.error('退会手続きが完了できておりません。'); //"Could not be unsubscribed!"
        this.datashareService.showTranslationToastr('NOTIFICATION.COULD_NOT_BE_UNSUBSCRIBED',NotificationType.ERROR);
      }
     });
  }

  ngOnInit() {
  }

}
