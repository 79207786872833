export class AppSetting {
    
    public static IsBuidForAffiliate=false;
    public static AffiliateCode='002';
    public static AffiliateURL='http://kousei-pro.com/';


/*     public static API_ENDPOINT='https://live.transpro-api.com/';
    public static EDITINGPRO_API_ENDPOINT='https://live.transpro-api.com/editingpro/';
    public static IMAGE_BASE_URL="https://bcause-api.com/";   */

 
    // public static API_ENDPOINT='http://localhost:58579/';
    // public static EDITINGPRO_API_ENDPOINT='http://localhost:58579/editingpro/';
    // public static IMAGE_BASE_URL="https://bcause-api.com/"; 
   
     public static API_ENDPOINT='https://bcause-api.com/';
      public static EDITINGPRO_API_ENDPOINT='https://bcause-api.com/editingpro/';
      public static IMAGE_BASE_URL="https://bcause-api.com/";

            // Stage server
    //   public static API_ENDPOINT='http://78.46.57.156:8094/';
    //   public static EDITINGPRO_API_ENDPOINT='http://78.46.57.156:8094/editingpro/';
    //   public static IMAGE_BASE_URL="https://bcause-api.com/";
      // Stage server end 

    
    public static CurrentCulture:string='jp';
    public static CurrencyCode:string = 'JPY';
    public static StripeCurrencyCode:string = 'JPY';//'USD';
    public static ApplicationID:number=3;
    
    
    //stripe payment
    public static StripeCheckoutScriptURL="https://checkout.stripe.com/checkout.js";
   /*  public static StripePublishableKey ="pk_test_PtUzHJGrrguoyRmlUQ9roBRs"; */
    public static StripePublishableKey ='pk_live_VBI2bLGJ1YFnAPeGKgIzYGGi';
    public static ExchangeRateApiEndPoint='https://api.exchangeratesapi.io/latest';

}