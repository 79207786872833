import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as wanakana from 'wanakana';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private toastrService: ToastrService;

  constructor(_toastr: ToastrService) {
    this.toastrService = _toastr;

  }

  getTimeString(min: number, culture: string = 'jp') {
    let d: number = 0;
    let h: number = 0;
    let m: number = 0;
    let hp: string = '';
    let mp: string = '';
    let remaining: string = '';

    if (min < 0) {
      remaining = culture == 'jp' ? '納期遅れ' : 'Delayed delivery';
      return remaining
    }

    let IsMoreThanOneDay: boolean = min > 1440 ? true : false;

    d = Math.floor(min / (60 * 24));
    m = min % (60 * 24);

    h = Math.floor(min / 60);
    m = min % 60;
    if (Math.abs(h) < 10) {
      hp = '0';
    }
    if (Math.abs(m) < 10) {
      mp = '0';
    }

    if (IsMoreThanOneDay) {
      remaining = d + (culture == 'jp' ? '日' : 'day');
    }
    else {
      remaining = hp + Math.abs(h).toFixed() + ':' + mp + Math.abs(m).toFixed();
    }

    remaining = culture == 'jp' ? 'まで(残り ' : 'remaining ' + remaining;

    return remaining;
  }

  isJapaneseLanguage(text: string) {
    let isJapanese: boolean = false;
    if (text && text.length > 0) {
      let nonenglishText = this.extractNonEnglishText(text);
      isJapanese = (nonenglishText && nonenglishText.length > 2);
    }

    return isJapanese;

  }

  isContainNonEnglishText(text:string):boolean
  {
    let isContainNonEnglishText: boolean = false;
    if (text && text.length > 0) {
      let nonenglishText = this.extractNonEnglishText(text);
      isContainNonEnglishText = (nonenglishText && nonenglishText.length > 0);
    }

    return isContainNonEnglishText;
  }

  private extractNonEnglishText(text: string) {
    let nonenglish = [];
    if (text && text.length > 0) {
      let j = 0;
      for (let i = 0; i <= text.length; i++) {
        console.log(text.charCodeAt(i));
        if (text.charCodeAt(i) >= 32 && text.charCodeAt(i) <= 126) {

        }
        else {
          nonenglish[j++] = text[i];
        }

      }
    }

    return nonenglish.join("");
  }

  isDuplicateFile(file: File, filesList: Array<File>): boolean {
    let isSame: boolean = false;
    filesList.forEach(element => {
      if (element.name == file.name && element.size == file.size) {
        isSame = true;
        return isSame;
      }
    });
    return isSame;
  }
  getClippedText(input: string, culture: string = "jp", forEng: number = 160, forJap: number = 90): string {
    let result: string = "";
    if (input == null) {
      input = "";
    }
    if (culture == "jp") {
      result = input.substr(0, forJap);
      if (input.length > forJap)
        result = result + "...";
    }
    else {
      result = input.substr(0, forEng);
      if (input.length > forEng)
        result = result + "...";
    }
    return result;
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes == 0) return '0 Bytes';
    var k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getImageDataURL(files): string {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.toastrService.warning("Only images are supported.");
      return;
    }

    var reader = new FileReader();
    /*   this.imagePath = files; */
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      let myel = (<HTMLImageElement>document.querySelector("#imgEditor"));
      myel.src = reader.result.toString();
    }
  }

  getFileNameFromPath(path: string, deUniqueization: boolean = true) {
    if (path && path.length > 3) {
      let fullName = path.substring(path.lastIndexOf('/') + 1);;
      if (deUniqueization) {
        let revfullName = fullName.split("").reverse().join("");
        revfullName = revfullName.replace(revfullName.substring(revfullName.indexOf('.') + 1, revfullName.indexOf('_') + 1), '');
        return revfullName.split("").reverse().join("");
      }
      else
        return fullName;

    }
    else {
      return null;
    }
  }

  getRelativePathFromFileURL(fileUrl: string) {
    let relPath = fileUrl.substring(fileUrl.indexOf('/Upload'));
    return '~' + relPath;
  }

  getThousandCommaSeparatedNumber(num: number) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }


  getSum(list: Array<any>, fieldName: string) {

    let sum = 0;
    list.forEach(item => {
      sum += item[fieldName] ? item[fieldName] : 0;
    });

    return sum;

  }

  getHalfDateTimeOfOrder(start: Date, end: Date): Date {
    let startDate = new Date(start);
    let endDate = new Date(end);
    let dif = endDate.getTime() - startDate.getTime();
    let halfDef = dif / 2;
    let halfDateTime = new Date(startDate.getTime() + halfDef);
    return halfDateTime;
  }

  addMonthsToDate(dt: Date, months: number):Date {
    let date:Date=dt;
    let d = date.getDate();
    date.setMonth(date.getMonth() + months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }


}
