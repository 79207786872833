import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Title, Meta } from '@angular/platform-browser';
import { OrderWeb } from '../../models/order-web.model';
import { CommonListService } from 'src/app/services/common-list.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/public_api';
import { EstimationService } from 'src/app/services/estimation.service';

@Component({
  selector: 'app-editor-order-list',
  templateUrl: './editor-order-list.component.html',
  styleUrls: ['./editor-order-list.component.css']
})
export class EditorOrderListComponent implements OnInit {
  editorOngoingOrderList: { currentPage: number, totalItems: number, dataSource: OrderWeb[] };
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  commonListService: CommonListService;
  orderList: Array<OrderWeb>;
  esimationService: EstimationService;
  editorNo: number;

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private _commonListService: CommonListService,
    private titleService: Title,
    private _estimationService: EstimationService,
    private meta: Meta) {
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.editorOngoingOrderList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.esimationService = _estimationService;
    this.commonListService = _commonListService;
    this.orderList = new Array<OrderWeb>();
    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = this.sharedService.currentCulture == 'jp' ? "受注内訳" : "Order List";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("オンライン英文校正の受注内訳を確認");
    this.meta.updateTag({ name: 'keywords', content: '受注内訳,英文校正,英語エディティング,注文,確認,内訳,リスト,高品質,安心,英語,チェック' });
    this.meta.updateTag({ name: 'description', content: 'オンライン英文校正の受注内訳をご確認頂けます。24時間365日,オンライン校正サービス。すべての過程がウェブ上で完結。簡単,早い,確実！' });
    let editorNo = JSON.parse(localStorage.getItem("currentUser")).RegistrationID;
    this.editorNo = editorNo;
    this.editorOrderList();
  }

  getWorkedAsEditorType(order:OrderWeb)
  {
    if(order.TranslatorNo==this.editorNo)
    return '1st';
    else
    return '2nd';
  }

  editorOrderList() {
    if (localStorage.getItem("currentUser")) {
      let editorId = JSON.parse(localStorage.getItem("currentUser")).ID;
      this.esimationService.getEditorWebOrderList(editorId, 1, 20, this.sharedService.currentCulture).subscribe((data) => {
        this.editorOngoingOrderList.currentPage = 1;
        this.editorOngoingOrderList.dataSource = data;
        if (data.length > 0)
          this.editorOngoingOrderList.totalItems = data[0].TotalPage;
      });
    }
  }

  onWebOrderPageIndexChange(event: PageChangedEvent) {
    let editorId = JSON.parse(localStorage.getItem("currentUser")).ID;
    this.esimationService.getEditorWebOrderList(editorId, event.page, 20, this.sharedService.currentCulture).subscribe((data) => {
      this.editorOngoingOrderList.currentPage = event.page;
      this.editorOngoingOrderList.dataSource = data;
      if (data.length > 0)
        this.editorOngoingOrderList.totalItems = data[0].TotalPage;
    });
  }


  ngOnInit() {
  }

}
