export class MessageModel {
    ID:number
    ParentId:number
    SenderID:string
    SenderName:string
    ReceiverID:string
    ReceiverName:string
    Title:string
    Body:string
    Status:number
    ReadDate:Date
    ReplyDate:Date
    CreatedDate:Date
    CreatedBy:number
    AttachedFile:string
    AttachedSize:number
    DownloadURL:string
    OriginalFileName:string
    OrderID:string
    WebOrderNo:string 
    TotalRows:number 
    CultureID:string
}
