import { Injectable } from '@angular/core';
import { AuthenticationService} from '../services/authentication.service';
import { CanActivate,ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  _authService :AuthenticationService
  constructor(private _auth:AuthenticationService,private router: Router) {
    this._authService=_auth;
   }

   canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve,reject)=>{
      this._authService.isAuthenticated().then(function(data){
        if(data)
        return resolve(true);
        else
        {
          return resolve(false);
        }
      });
    }); 
    }

}
