import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { MessageModel } from '../../models/message-model.model';
import { UtilityService } from '../../services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { EditorService } from '../../services/editor.service';
import { NotificationType } from 'src/app/Enums.enum';


@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  messageModel: MessageModel;
  editorService: EditorService;

  utility: UtilityService;
  uploadedDocument: any;

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private _editorService: EditorService,
    private _utility: UtilityService,
    private _toastr: ToastrService,
    private titleService: Title,
    private meta: Meta) {
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.editorService = _editorService;
    this.utility = _utility;
    this.messageModel = new MessageModel();
    if (localStorage.getItem('currentUser')) {
      let user = JSON.parse(localStorage.getItem('currentUser'));
      this.messageModel.SenderID = user.ID;
      this.messageModel.SenderName = user.RegistrationNo;
      this.messageModel.Status = 2; //unread 
      this.messageModel.CreatedBy = 1;

      if(localStorage.getItem('MessageOrderInfo')) 
      {
        let orderinfo:{Title:string,OrderID:string,ReceiverID:string,ReceiverName:string, WebOrderNo:string,ParentId:number,ReplyDate:Date} = JSON.parse(localStorage.getItem('MessageOrderInfo'));
      this.messageModel.OrderID = orderinfo.OrderID;
      this.messageModel.ReceiverID = orderinfo.ReceiverID;
      this.messageModel.ReceiverName = orderinfo.ReceiverName;
      this.messageModel.WebOrderNo = orderinfo.WebOrderNo;
      this.messageModel.Title=orderinfo.Title;
      if(orderinfo.ParentId)
      this.messageModel.ParentId=orderinfo.ParentId;
      if(orderinfo.ReplyDate)
      {
        this.messageModel.ReplyDate=orderinfo.ReplyDate;
        this.messageModel.Title='Re: '+this.messageModel.Title;
      }
      
      localStorage.removeItem('MessageOrderInfo');
      }
      else{
        this._router.navigateByUrl("/404"); 
      }
      
     
    }

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName=this.dataShareService.currentCulture =='jp'? "メッセージ（作成）": "Message (composition)";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("英文校正サイトのメッセージ作成");
    this.meta.updateTag({ name: 'keywords', content: 'メッセージ,品質,ノーハウ,作成,格安校正,オンライン校正,英語校正,英語,専門校正,英語校正会社,論文校正,英語論文チェック' });
    this.meta.updateTag({ name: 'description', content: '英文の専門校正サイト・エディティングプロのメッセージの作成ページです。いつでもどこでもサクサク英文校正依頼や確認を。' });
  }

  validate() {
    if (!this.messageModel.Title || this.messageModel.Title == "") {
      //this._toastr.warning("件名が必要です。"); //'Subject is required!'
      this.sharedService.showTranslationToastr('NOTIFICATION.SUBJECT_IS_REQUIRED',NotificationType.WARNING);
      return false;
    }
    if (!this.messageModel.Body || this.messageModel.Body == "") {
      //this._toastr.warning("メッセージが空っぽです。");//'Message cannot be empty!'
      this.sharedService.showTranslationToastr('NOTIFICATION.MESSAGE_CANNOT_BE_EMPTY',NotificationType.WARNING);
      return false;
    }

    return true;

  }

  sendMessage() {
    if (this.validate()) {
      this.messageModel.CultureID = this.sharedService.currentCulture;
    this.editorService.sendMessage(this.messageModel,this.uploadedDocument).subscribe((data)=>{
     if(data===true)
     {
      //this._toastr.success("送付が完了しました。");//'Successfully Sent!!'
      this.sharedService.showTranslationToastr('NOTIFICATION.SUCCESSFULLY_SENT',NotificationType.SUCCESS);
      this._router.navigateByUrl("/editor/message-list"); 
     }
     else{
      //this._toastr.error("送付できません。");//'Could not be Sent!'
      this.sharedService.showTranslationToastr('NOTIFICATION.SORRY_COULD_NOT_BE_SENT_01',NotificationType.ERROR);
     }
    });
  }
  }

  formatFileSize(sizeInBytes: number) {
    return this.utility.formatBytes(sizeInBytes);
  }

  fileSelected(event: any) {  //this time developed by regarding that only one file will be uploaded
      this.uploadedDocument = event.target.files[0];
  }

  ngOnInit() {
  }

}
