export class OrderAppointedToStaffModel {
    ID: string
    TranslatorID: string
    TranslatorNo: string
    TranslatorEmailID: string
    OrderID: string
    OrderNo: string
    ClientID: string
    ClientNo: string
    ClientEmailID: string
    AppointedDate: Date
    RegistrationID:number
	IsRejected:boolean 
}
