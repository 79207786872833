import { CompanyIndustryClassificationModel } from "./company-industry-classification-model.model"


export class CompanyModel {
    ID:string
    ClientID:string 
    RetypedClientID:string
    Password:string 
    RetypedPassword:string
    RegistrationType:string
    Name_Global:string 
    Name_Local:string 
    Name:string 
    Name_jp:string 
    DepartmentName:string
    Fax:string
    MobileNo:string
    Email:string 
    Address1_jp:string 
    Address1:string 
    Address2:string 
    CompanyLocationID:number
    PostalCode:string
    CompanyPicName:string //person In charge
    WebSiteURL:string 
    TelephoneNo:string 
    IsCampaignEmail:boolean
    IsPostTransactionAllowed:boolean
    PrefectureID:string 
    PrefectureName:string
    CompanyTypeID:number
    CurrentCulture:string 
    CompanyAssociatedIndustryClassifications:CompanyIndustryClassificationModel[]
    FirstParameter?:number
    SecondParameter?:number
    ThirdParameter?:number
    FourthParameter?:number
    CompanyTradingOfficeID:string 
    Address_jp:string
	Address:string
    IsPermissionPostPayTransactionResult:boolean
    ApplicationId:number
    AffiliateCode:string
    IsPartner:boolean 
}
