import { SpecializedFieldModel } from "./specialized-field.model"
import { SoftwareSkillModel } from "./software-skill.model"
import { EditorCertificationInfoModel } from "./editor-certification-info"
import { StaffProfesionalSpecialityModel } from "./staff-profesional-speciality.model"

export class EditorSearchModel {
          ID :string 
          RegistrationID :number 
          CountryName:string
          LivingCountryName:string 
          BirthDate:Date
          NativeLanguageName:string
          EditorFlag:string
          ForiegnLanguage1Name:string
          ForiegnLanguage2Name:string
          ForiegnLanguage3Name:string
          ForiegnLanguage4Name:string
          InstituteName:string
          MajorSubjectName:string
          ImageURL:string
          YearsOfExperience:number
          Speciality :Array<SpecializedFieldModel>
          SpecializedFiled:string
          SofwareSkills:Array<SoftwareSkillModel>
          SofwareSkillCSV:string 
          MainCareer:string
          SelfIntroductionPR:string
          DegreeName:string 
          TotalRows:number
          AvgScore?:number
          Achievement:number
          CertificationInfo:EditorCertificationInfoModel
          Nationality:string
          StaffProfessional: Array<StaffProfesionalSpecialityModel>
          IsAlreadyFavoriteEditor:boolean
          IsAlreadySelected: boolean
          EditorType:string 
		  SelfIntroductionPRClipped:string 
}
