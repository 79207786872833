import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-estimate',
  templateUrl: './estimate.component.html',
  styleUrls: ['./estimate.component.css']
})
export class EstimateComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  constructor(private _router: Router,private dataShareService:DataShareService) {
    this.router=_router.url;
    this.sharedService=dataShareService;

    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName="見積り・お問合せ（コーディネーター依頼）";
    this.sharedService.setSharedData(this.shareableData);
   }

   onSendButtonClicked()
   {
     console.log('estimate send button clicked!');
   }

  ngOnInit() {
  }

}
