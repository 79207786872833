import { CompanyIndustryClassificationItemModel } from "./company-industry-classification-item-model.model"

export class CompanyIndustryClassificationModel {
        Id:string
        CompanyID:string 
        IndustryClassificationID:string 
        IndustryClassificationItemID:string 
        Code:string
        Name:string 
        Description:string 
        IsOther:boolean
        IsSelected:boolean
        IsTradeSelected:boolean
        itemList:CompanyIndustryClassificationItemModel[]

}
