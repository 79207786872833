import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { ToastrService } from 'ngx-toastr';
import { EmailModel } from '../../models/email.model';
import { EstimationService } from '../../services/estimation.service';
import * as _ from 'underscore';
import { OrderWeb } from 'src/app/models/order-web.model';
import { EditingPreferenceModel } from 'src/app/models/editing-pro-preference-model.model';
import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { MiscellaneousModel } from 'src/app/models/miscellaneous-model.model';

@Component({
  selector: 'app-send-estimation-window',
  templateUrl: './send-estimation-window.component.html',
  styleUrls: ['./send-estimation-window.component.css']
})
export class SendEstimationWindowComponent implements OnInit {
  sharedService: DataShareService;
  emailModel: EmailModel;
  estimationService: EstimationService;
  isEmailAlreadySent: boolean;
  beforeSendingEmailToStakeHolderAction: any;
  orderModel: OrderWeb;
  editingServiceBottomLine:MiscellaneousModel;

  constructor(
    private dataShareService: DataShareService,
    private _toastr: ToastrService,
    private _estimationService: EstimationService) {

    this.sharedService = dataShareService;
    this.estimationService = _estimationService;

    this.emailModel = new EmailModel();
    this.orderModel=new OrderWeb();
    this.orderModel.EditingPreference=new EditingPreferenceModel();
    this.editingServiceBottomLine=new MiscellaneousModel();
    this.emailModel.ApplicationID = 3;
    this.emailModel.IsHtmlBody = true;
    this.isEmailAlreadySent = false;

    if (localStorage.getItem("currentUser"))
      this.emailModel.EmailFrom = JSON.parse(localStorage.getItem("currentUser")).Email;

    let sharedModalData = this.sharedService.getModalData();
    this.emailModel.OrderDate = sharedModalData.OrderDate? new Date(sharedModalData.OrderDate)  : new Date();
    this.emailModel.WordCount = sharedModalData.WordCount;
    this.emailModel.JobNumber=this.emailModel.OrderNo = sharedModalData.JobNumber;
    this.emailModel.PlanType = sharedModalData.PlanType;
    this.orderModel = sharedModalData.Order;
    this.beforeSendingEmailToStakeHolderAction = sharedModalData.beforeSendingEmailToStakeHolderAction;
    this.getEditingServiceBottomLine();
  }

  getEditingServiceBottomLine()
  {
    this.estimationService.getEditingServiceBottomLine().subscribe(data=>{
      if(data)  
      this.editingServiceBottomLine=data;
    });
  }

  sendEmail() {
    if (!this.emailModel.EmailTo || this.emailModel.EmailTo == "") {
      this._toastr.warning("宛先 is required!");
      return;
    }
    if (!this.emailModel.EmailSubject || this.emailModel.EmailSubject == "") {
      this._toastr.warning("タイトル is required!");
      return;
    }

    let bodyDiv = document.querySelector("#emailBodyDiv") as HTMLDivElement;
    this.emailModel.EmailBody = "<div><p>" + this.emailModel.BodyText + "</p></div>" + bodyDiv.innerHTML;

    this.estimationService.sendEmailToStakeHolder(this.emailModel).subscribe((data) => {
      if (data) {
        this._toastr.success("送付が完了しました。"); //Email Send Successfully!
        this.isEmailAlreadySent = true;
        this.sharedService.bsModalRef.hide();
      }
      else {
        this._toastr.error("送付できません。"); //Could not send the email!
      }
    });

  }

  ngOnInit() {
  }

}
