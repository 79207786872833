export class CompanyCouponModel {
    ID: string
    CompanyID: string
    CouponNo: number
    CouponName: string
    DiscountRate: number
    LastUsedDate: Date
    ExpireDate: Date
    AppliedEstimationNo: string
    IsActive: boolean
    IsDeleted: boolean
    AchievedOrderNo: string
    ApplicationID: number
    CompanyNo: string
}
