import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {DataShareService} from '../services/data-share.service';
import {DataSharingModel} from '../models/data-sharing-model';
import { Title,Meta  }     from '@angular/platform-browser';

@Component({
  selector: 'app-site-map',
  templateUrl: './site-map.component.html',
  styleUrls: ['./site-map.component.css']
})
export class SiteMapComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;

  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private titleService: Title,
    private meta: Meta) { 
      this.router=_router.url;
      this.sharedService=dataShareService;
  
      this.shareableData=new DataSharingModel();
      this.shareableData.currentRoute=this.router;
      this.shareableData.currentStateName="ご利用ガイド";
      this.sharedService.setSharedData(this.shareableData);


      if(this.sharedService.currentCulture=='jp'){


        this.titleService.setTitle("エディティングプロのサイトマップ");
        this.meta.updateTag({ name: 'keywords', content: 'サイトマップ,英文の校正,エディティングプロ,品質の秘密,品質保証,校正依頼,校正依頼方法,校正方法,校正のコツ,論文校正' });
        this.meta.updateTag({ name: 'description', content: 'エディティングプロのサイトマップとなります。校正,翻訳,通訳の他,外国語や外国人に任せたいお仕事がありましたらお問合わせ下さい。' });

      }
      else{

        this.titleService.setTitle("Site map of editing-Pro.");
        this.meta.updateTag({ name: 'keywords', content: 'site map,English writing editing,editing-Pro.,quality secret ,qualityguarantee,editing order,editing order how,editing how to,editing tips,academic writingediting' });
        this.meta.updateTag({ name: 'description', content: 'editing-Pro. site map. Editing, translation, interpretation, other job that requires foreign workers or foreign language, please contact us. ' });

      }

    }

  ngOnInit() {
  }

}
