import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { Router } from '@angular/router';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.css']
})
export class GuideComponent implements OnInit {

  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private titleService: Title,
    private meta: Meta) {

    this.router = _router.url;
    this.sharedService = dataShareService;

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName=  this.dataShareService.currentCulture == 'jp' ? "エディターの心構え" : "Editor Guide";
    this.sharedService.setSharedData(this.shareableData);



    if(this.sharedService.currentCulture=='jp'){


      this.titleService.setTitle("英文エディターとしての心構え");
      this.meta.updateTag({ name: 'keywords', content: 'エディター,品質,ノーハウ,心構え,格安校正,オンライン校正,英語校正,専門エディター校正,英語エディター,校正会社,論文校正,英語論文チェック,英文エディティング,高クオリティ,英文校正のやり方' });
      this.meta.updateTag({ name: 'description', content: '英文エディターとしての心構えを確認して,高クオリティの英文校正に従事してください。' });

    }
    else{

      
      this.titleService.setTitle("English writing editor's motto");
      this.meta.updateTag({ name: 'keywords', content: 'editor,quality,knowhow,motto,resonable priceediting,onlineediting,Englishediting,professionaleditorediting,Englisheditor,editingcompany,academic writingediting,Englishacademic writingcheck,English editing,high quolity,how to editing-Pro.' });
      this.meta.updateTag({ name: 'description', content: 'Please work on editing-Pro. as a high quolity English writing editor by confirming editor\'s motto and rules. ' });




    }

  }

  ngOnInit() {
  }

}
