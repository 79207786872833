import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router } from '@angular/router';
import { Title,Meta  }     from '@angular/platform-browser';

@Component({
  selector: 'app-question-answer',
  templateUrl: './question-answer.component.html',
  styleUrls: ['./question-answer.component.css']
})
export class QuestionAnswerComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  
  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private titleService: Title,
    private meta: Meta) {
    this.router=_router.url;
    this.sharedService=dataShareService;

    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName="よくある質問";
    this.sharedService.setSharedData(this.shareableData);


    if(this.sharedService.currentCulture=='jp'){



      this.titleService.setTitle("英文校正サービス,英文エディティングのよくある質問");
      this.meta.updateTag({ name: 'keywords', content: 'よくあるご質問,FAQ,英語校正,校正プロ,エディティングプロ,論文校正,学術校正,医学論文校正,論文校正専門,論文校正,論文エディティング' });
      this.meta.updateTag({ name: 'description', content: '英語校正に関するよくある質問をまとめております。英語校正のエディター,英語校正のコツ,依頼の方法,品質の保証方法等,英語校正を依頼する際に気になるところがありましたらご確認下さい。' });
    }
    else{



      this.titleService.setTitle("editing-Pro.service,English editing's friquently asked question");
      this.meta.updateTag({ name: 'keywords', content: '"frequent inquiries,FAQ,English editing,editing professional,editing-Pro.,academic writingediting, academic editing, medical academic writingediting,academic writing editing professional,academic writing editing,academic writing editing"' });
      this.meta.updateTag({ name: 'description', content: 'Inquiries about English editing services. English editing\'s editor,Englishediting tips, how to place an order,how to guarantee quality etc, please check the list if you have any concern over placing English editing order.' });


    }

   }

  ngOnInit() {
  }

}
