import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { FormsModule } from '@angular/forms';
import { TypeaheadModule  } from 'ngx-bootstrap/typeahead';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { RatingModule } from 'ng-starrating';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent,SafePipe  } from './app.component';
import { CalibrationSampleComponent } from './calibration-sample/calibration-sample.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { HomeBannerComponent } from './home-banner/home-banner.component';
import { CommonBannerComponent } from './common-banner/common-banner.component';
import { UserGuideComponent } from './user-guide/user-guide.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { TabularListComponent } from './tabular-list/tabular-list.component';
import { DatePipe, CurrencyPipe, DecimalPipe } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceTermsComponent } from './service-terms/service-terms.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CustomerModule} from './customer/customer.module';
import { EditorModule} from './editor/editor.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CustomerRegistrationComponent } from './customer-registration/customer-registration.component';
import { EditorRegistrationComponent } from './editor-registration/editor-registration.component';
import { EditorEvaluationListComponent } from './editor-evaluation-list/editor-evaluation-list.component';
import { CustomerRegistrationConfirmComponent } from './customer-registration-confirm/customer-registration-confirm.component';
import { InqueryComponent } from './inquery/inquery.component';
import { EstimateComponent } from './inquery/estimate/estimate.component';
import { ContactUsComponent } from './inquery/contact-us/contact-us.component';
import { EditorRegistrationConfirmComponent } from './editor-registration-confirm/editor-registration-confirm.component';
import { FreeEditingEstimationComponent } from './free-editing-estimation/free-editing-estimation.component';
import { PasswordReissueComponent } from './password-reissue/password-reissue.component';
import { ResetCustomerPasswordComponent } from './reset-customer-password/reset-customer-password.component';
import { NoticeComponent } from './notice/notice.component';
import { QuestionAnswerComponent } from './question-answer/question-answer.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { QualityComponent } from './quality/quality.component';
import { TipsForOrderComponent } from './tips-for-order/tips-for-order.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { OrderDetailsCommonComponent } from './order-details-common/order-details-common.component';
import { ResetEditorPasswordComponent } from './reset-editor-password/reset-editor-password.component';
import { DiscountCampaignComponent } from './discount-campaign/discount-campaign.component';
import { CoordinateIntroductionComponent } from './coordinate-introduction/coordinate-introduction.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { EditingServiceComponent } from './editing-service/editing-service.component';
import { EditorSearchComponent } from './editor-search/editor-search.component';
import { EditorProfileCommonComponent } from './editor-profile-common/editor-profile-common.component';
import { SiteMapComponent } from './site-map/site-map.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CommercialTransactionsLawComponent } from './commercial-transactions-law/commercial-transactions-law.component';
import { ErrorReportComponent } from './error-report/error-report.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { EstimateServiceComponent } from './estimate-service/estimate-service.component';
import { EditorSearchWindowComponent } from './estimate-service/editor-search-window/editor-search-window.component';
import { AppointedEstimationComponent } from './appointed-estimation/appointed-estimation.component';
import { EstimationConfirmationComponent } from './estimation-confirmation/estimation-confirmation.component';
import { SendEstimationWindowComponent } from './estimation-confirmation/send-estimation-window/send-estimation-window.component';
import { AboutComponent } from './about/about.component';
import { CustomerEvaluationComponent } from './customer-evaluation/customer-evaluation.component';
import { PriceComponent } from './price/price.component';
import { EditorInterviewListComponent } from './editor-interview-list/editor-interview-list.component';
import { EditorInterviewDetailsComponent } from './editor-interview-details/editor-interview-details.component';
import { EditingDeliveryComponent } from './editor/order-details/editing-delivery/editing-delivery.component';
import { DownloadDeliveryComponent } from './customer/order-details/download-delivery/download-delivery.component';
import { InvoiceTemplateComponent } from './invoice-template/invoice-template.component';
import { ImgFallbackDirectiveDirective } from './img-fallback-directive.directive';
import { OnlyNumberDirective } from './only-number.directive';
import { AffiliateModule } from './affiliate/affiliate.module';
import { InvoiceComponent } from './PDFTemplates/invoice/invoice.component';
import { EstimationComponent } from './PDFTemplates/estimation/estimation.component';
import { DeliveryComponent } from './PDFTemplates/delivery/delivery.component';
import { ReceiptComponent } from './PDFTemplates/receipt/receipt.component';
import { DeveloperTestingComponent } from './developer-testing/developer-testing.component';
import { CurrencyFieldComponent } from './currency-field/currency-field.component';
import { AdminModule } from './admin/admin.module';
import { MonthlyInvoiceComponent } from './admin/monthly-invoice/monthly-invoice.component';
import { AffiliateInvoiceTemplateComponent } from './affiliate/affiliate-invoice-template/affiliate-invoice-template.component';
import { EditorMindsetComponent } from './editor/editor-mindset/editor-mindset.component';
import { ProfilePhotoGuideComponent } from './profile-photo-guide/profile-photo-guide.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    SafePipe,
    CalibrationSampleComponent,
    LandingPageComponent,
    HomeBannerComponent,
    CommonBannerComponent,
    UserGuideComponent,
    LoginModalComponent,
    TabularListComponent,
    ServiceTermsComponent,
    PageNotFoundComponent,
    CustomerRegistrationComponent,
    EditorRegistrationComponent,
    EditorEvaluationListComponent,
    CustomerRegistrationConfirmComponent,
    InqueryComponent,
    EstimateComponent,
    ContactUsComponent,
    EditorRegistrationConfirmComponent,
    FreeEditingEstimationComponent,
    PasswordReissueComponent,
    ResetCustomerPasswordComponent,
    NoticeComponent,
    QuestionAnswerComponent,
    CompanyProfileComponent,
    QualityComponent,
    TipsForOrderComponent,
    ConfirmDialogComponent,
    OrderDetailsCommonComponent,
    ResetEditorPasswordComponent,
    DiscountCampaignComponent,
    CoordinateIntroductionComponent,
    PaymentMethodComponent,
    EditingServiceComponent,
    EditorSearchComponent,
    EditorProfileCommonComponent,
    SiteMapComponent,
    PrivacyPolicyComponent,
    CommercialTransactionsLawComponent,
    ErrorReportComponent,
    ScrollTopComponent,
    EstimateServiceComponent,
    EditorSearchWindowComponent,
    AppointedEstimationComponent,
    EstimationConfirmationComponent,
    SendEstimationWindowComponent,
    AboutComponent,
    CustomerEvaluationComponent,
    PriceComponent,
    EditorInterviewListComponent,
    EditorInterviewDetailsComponent,
    InvoiceTemplateComponent,
    ImgFallbackDirectiveDirective,
    OnlyNumberDirective,
    InvoiceComponent,
    EstimationComponent,
    DeliveryComponent,
    ReceiptComponent,
    DeveloperTestingComponent,
    CurrencyFieldComponent,
    ProfilePhotoGuideComponent
  ],
  exports:[],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(
      {timeOut: 2000, 
      preventDuplicates: true
      }),
      TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    CustomerModule,
    EditorModule,
    AffiliateModule,
    AdminModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    RatingModule,
    NgSelectModule,
    NoopAnimationsModule
  ],
  entryComponents: [ LoginModalComponent,ConfirmDialogComponent,EditorSearchWindowComponent,SendEstimationWindowComponent,EditingDeliveryComponent,DownloadDeliveryComponent
    ,InvoiceComponent,InvoiceTemplateComponent,EstimationComponent,DeliveryComponent,ReceiptComponent,MonthlyInvoiceComponent,AffiliateInvoiceTemplateComponent,EditorMindsetComponent ],
  providers: [DatePipe,BsModalRef,CurrencyPipe,DecimalPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
