import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {DataShareService} from '../services/data-share.service';
import {DataSharingModel} from '../models/data-sharing-model';
import { Title,Meta  }     from '@angular/platform-browser';

@Component({
  selector: 'app-coordinate-introduction',
  templateUrl: './coordinate-introduction.component.html',
  styleUrls: ['./coordinate-introduction.component.css']
})
export class CoordinateIntroductionComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;

  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private titleService: Title,
    private meta: Meta) {
      this.router=_router.url;
    this.sharedService=dataShareService;

    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName=this.sharedService.currentCulture=='jp'? "コーディネーターサービス" : "Coordinater service";
    this.sharedService.setSharedData(this.shareableData);



    if(this.sharedService.currentCulture=='jp'){

      this.titleService.setTitle("英文校正の問い合わせ|editing-Pro.");
      this.meta.updateTag({ name: 'keywords', content: 'コーディネーターサービス紹介,英語,校正,校正会社,英語校正,校正専門,エディティング' });
      this.meta.updateTag({ name: 'description', content: '見積は無料で,営業時間内には1時間以内に回答させて頂きます。お見積以外に校正や翻訳について確認されたい点がありましたら気軽に御連絡下さい。' });

    }else{


      this.titleService.setTitle("Inquiry about editing |editing-Pro.");
      this.meta.updateTag({ name: 'keywords', content: 'Corrdinator service introduction,English,editing,editingcompany,Englishediting,editingprofessional,editing' });
      this.meta.updateTag({ name: 'description', content: 'Estimation is for free and will be prepared within 1hour during office hours. If you have any inquries reagarding editing or translation work aside from estimation, please contact us.' });

    }

    }

  ngOnInit() {
  }

}
