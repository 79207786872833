import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { element } from '@angular/core/src/render3';

@Directive({
  selector: 'img[appImgFallback]'
})
export class ImgFallbackDirectiveDirective {
@Input() appImgFallback:string

  constructor(private eRef:ElementRef) {}

  @HostListener('error')
  loadFallbackOnError()
  {
    const element:HTMLImageElement=<HTMLImageElement> this.eRef.nativeElement;
    element.src=this.appImgFallback || 'http://via.placeholder.com/200';
  }

}
