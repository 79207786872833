import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {DataShareService} from '../../services/data-share.service';
import {DataSharingModel} from '../../models/data-sharing-model';
import { Title,Meta  }     from '@angular/platform-browser';
import { EditorService } from '../../services/editor.service';
import { BankAccountModel } from '../../models/bank-account.model';
import { OrderWeb } from 'src/app/models/order-web.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/public_api';
import { MiscellaneousModel } from 'src/app/models/miscellaneous-model.model';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.css']
})
export class PaymentInfoComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  editorService:EditorService;
  bankAccount:BankAccountModel;
  editorCurrentMonthJob:{currentPage: number, totalItems: number, dataSource:OrderWeb[]};
  editorMonthlyPayment:{currentPage: number, totalItems: number, dataSource:MiscellaneousModel[]};
  selectedMonth:number;
  selectedYear:number;
  planDetailsToolTipContent:string;

  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private titleService: Title,
    private meta: Meta,
    private _editorService:EditorService) { 

      this.router=_router.url;
      this.sharedService=dataShareService;
      this.editorService=_editorService;
      this.bankAccount=new BankAccountModel();
      this.selectedMonth=new Date().getMonth();
      this.selectedYear=new Date().getFullYear();
  
      this.shareableData=new DataSharingModel();
      this.editorCurrentMonthJob={currentPage: 1, totalItems:0, dataSource:null};
      this.editorMonthlyPayment={currentPage: 1, totalItems:0, dataSource:null};
      this.shareableData.currentRoute=this.router;
      this.shareableData.currentStateName=this.dataShareService.currentCulture=='jp'? "支払い確認":"Payment confirmation";
      this.sharedService.setSharedData(this.shareableData);
      this.titleService.setTitle("お支払い情報を確認（英文エディター用）");
      this.meta.updateTag({ name: 'keywords', content: '支払い確認,校正,オンライン校正,英語校正,英語,英文エディター,校正会社,論文校正,英語論文チェック' });
      this.meta.updateTag({ name: 'description', content: 'オンライン英文校正の支払い情報をご確認頂けます。24時間365日,オンライン校正サービス。すべての過程がウェブ上で完結。簡単,早い,確実！' });
      
      this.getEditorBankAccountDetails();
      this.getEditorCurrentMonthOngoingJobList(this.selectedMonth,this.selectedYear);
      this.getEditorMonthlyPaymentList();
      this.planDetailsToolTipContent=this.getPlanDetailsTooltipHTML();
    }

    getEditorBankAccountDetails()
    {
      this.editorService.getBankAccountDetailsByEditorId(this.sharedService.currentUser.ID, this.sharedService.currentCulture).subscribe((data)=>{
         if(data)
         {
           this.bankAccount=data;
         }
      });
    }

    onClickGroupItem(item:MiscellaneousModel)
    {
       if(item.OrderMonth && item.OrderYear)
       {
         this.selectedYear=item.OrderYear;
         this.selectedMonth=item.OrderMonth;
        this.getEditorCurrentMonthOngoingJobList(item.OrderMonth,this.selectedYear,1);
       }
    }

    onPageIndexChange(event:PageChangedEvent){
      this.getEditorCurrentMonthOngoingJobList(this.selectedMonth,this.selectedYear,event.page);
    }
    
    getEditorCurrentMonthOngoingJobList(month:number,year:number,pageNo: number = 1){
      this.editorService.getEditorCurrentMonthOngoingJobHistory(this.sharedService.currentUser.ID, pageNo, 5,month,year,this.sharedService.currentCulture).subscribe((data)=>{
        this.editorCurrentMonthJob.currentPage = pageNo;
        this.editorCurrentMonthJob.dataSource = data;
        if(data.length>0){
          this.editorCurrentMonthJob.totalItems = data[0].TotalCount
        }
      });
    }

  
  
  getMonthNameByNumber(monthNum:number) {
    let months = [
      'January', 'February', 'March', 'April', 'May',
      'June', 'July', 'August', 'September',
      'October', 'November', 'December'
      ];
      
      return months[monthNum - 1] || '';
  }

    onPageIndexChangeForMonthly(event:PageChangedEvent){
      this.getEditorMonthlyPaymentList(event.page);
    }
    
    getEditorMonthlyPaymentList(pageNo: number = 1){
      this.editorService.getEditorMonthlyJobPaymentHistory(this.sharedService.currentUser.ID, pageNo, 5,this.sharedService.currentCulture).subscribe((data)=>{
        this.editorMonthlyPayment.currentPage = pageNo;
        this.editorMonthlyPayment.dataSource = data;
        if(data.length>0){
          this.editorMonthlyPayment.totalItems = data[0].TotalCount
        }
      });
    }

    private getPlanDetailsTooltipHTML():string{
      let html:string='';
      if(this.dataShareService.currentCulture=='en')
      {
        html=`<div style="margin:5px;padding:5px;" class="tprice3-box1">
                <div >
            <strong>Is editing the price will vary depending on the following conditions.</strong>
            <hr size="2" align="left" width="65%">
            <div>
              <ul style="list-style:none!important;">
                <li> Get the nomination</li>
                <li> Increasing the experience number of projects</li>
                <li> Increase the average evaluation score</li>
              </ul>
            </div>
            
            <p>
              For example, If you satisfy the conditions, depending on the type of project,<br>
              it is added up to 20-50% payment fee.
            </p>
          </div>
              </div>`;
      }
      else
      {
            html=`<div style="margin:5px;padding:5px;" class="tprice3-box1">
            <div >
            <strong>以下の条件によってエディティング代金が変わります。</strong>
            <hr size="2" align="left" width="65%">
            <div>
              <ol style="list-style:none;">
                <li> 指名をもらう</li>
                <li> 案件の経験数を増やす</li>
                <li> 平均評価点数を上げる</li>
              </ol>
            </div>
            
            <p>
              例えば、どの条件もクリアしていないエディターと対して3つの条件を全て最高値まで満たしているエディターは、<br>
              案件の種類にもよりますが、最大20－50％支払い料金が追加せされます。
            </p>          
      </div>
          </div>`;
      }
      
      
  
    return html;
    }

  ngOnInit() {
  }

}