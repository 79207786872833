export class TabularList {
    columnHeaderList:Array<string>
    data:any
    pageSize:number
    totalPage:number
    pages:Array<number>
    dataKeys:Array<string>
    currencyColumnList:Array<string>
    linkColumnList:Array<{columnName:string,routerLink:string}>
    dateColumnList:Array<string>
    isServerSidePagination:boolean

}


