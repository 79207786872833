import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../../services/data-share.service';
import { DataSharingModel } from '../../models/data-sharing-model';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { LanguageModel } from '../../models/language.model';
import { CommonListService } from '../../services/common-list.service';
import { SpecializedFieldModel } from '../../models/specialized-field.model';
import { EditorService } from '../../services/editor.service';
import { EditorSearchModel } from '../../models/editor-search.model';
import * as _ from 'underscore';
import { AppSetting } from '../../app-setting';
import { CustomerService } from 'src/app/services/customer-service.service';
import { ToastrService } from 'ngx-toastr';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';


@Component({
  selector: 'app-editor-black-list',
  templateUrl: './editor-black-list.component.html',
  styleUrls: ['./editor-black-list.component.css']
})
export class EditorBlackListComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  languages:Array<LanguageModel>;
  commonListService:CommonListService;
  searchModel : {sourceLanguageId:string, targetLanguageId:string, specialityId:string, isOnlyCeritfied:boolean, editorNo:string};
  specialitiesList: Array<SpecializedFieldModel>;
  editorList:Array<EditorSearchModel>;
  editorService:EditorService;
  pageNumber:number=1;
  pageSize:number=12;
  totalItems:number;
  imageBaseUrl:string=AppSetting.IMAGE_BASE_URL;
  customerService:CustomerService;

  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private _commonListService:CommonListService,
    private _editorService:EditorService,
    private _customerService:CustomerService,
    private titleService: Title,
    private meta: Meta,
    private toster:ToastrService) {
      this.router=_router.url;
    this.sharedService=dataShareService;
    this.commonListService=_commonListService;
    this.customerService=_customerService;
    this.searchModel={sourceLanguageId:'1484e197-70f8-4f64-98cf-e0f06eff6f49', targetLanguageId:'1484e197-70f8-4f64-98cf-e0f06eff6f49', specialityId:null, isOnlyCeritfied:null, editorNo:null};
    this.specialitiesList=new Array<SpecializedFieldModel>();
    this.editorService=_editorService;
    this.editorList=new Array<EditorSearchModel>();
    this.totalItems=0;

    this.shareableData=new DataSharingModel();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName=this.sharedService.currentCulture=='jp'?"ブラックリスト":"Site error report";
    this.sharedService.setSharedData(this.shareableData);

    if(this.sharedService.currentCulture=='jp'){

      this.titleService.setTitle("英文校正サイトの専門エディターリストを確認");
      this.meta.updateTag({ name: 'keywords', content: 'エディター,リスト,指名可能,英文校正,オンライン校正,英語校正,英語,校正,専門エディター校正,英語エディター,校正会社,英文論文校正,英語論文チェック,校正チェック' });
      this.meta.updateTag({ name: 'description', content: 'プロのエディターのリストを見る' });

    }
    else{

      this.titleService.setTitle("Check the professional editor list of proofreading site");
      this.meta.updateTag({ name: 'keywords', content: 'Editor, list, dispatchable, proofreading, online proofreading, English proofreading, English, proofreading, professional editor proofreading, English editor, calibration company, English paper calibration, English paper check, calibration check' });
      this.meta.updateTag({ name: 'description', content: 'Check the professional editor list of proofreading' });

    }

    this.getLanguageList();
    this.getSpecilitites();
    this.searchEditorNow();
   }

   onPageIndexChange(event: PageChangedEvent) {
    this.pageNumber=event.page;
 } 

   removeFromBlackList(editorId:string){
    if (localStorage.getItem("currentUser"))
    {

     let customerId:string = JSON.parse(localStorage.getItem("currentUser")).ID;
     this.customerService.removeFromBlackList(customerId,editorId).subscribe((data)=>{
       if(data)
       {
         this.toster.success('アップデートできました。'); //"Editor Removed From BlackList Successfully!"
         this.searchEditorNow();
       }
       else
       this.toster.error('処理に失敗しました。');//"Editor Could Not be Removed From BlackList!"
     });
     }
   }
     
     getLanguageList(){
       this.commonListService.getAllLanguages().subscribe((data)=>{
         if(data)
           this.languages = data;
       })
     }
     getSpecilitites(){
       this.commonListService.getSpecializedFieldList().subscribe((data)=>{
         this.specialitiesList = data;
       })
     }

     searchEditorNow(){
       this.pageNumber=1;
       this.editorService.searchBlackListEditor(this.sharedService.currentUser.ID, this.searchModel.specialityId, this.searchModel.isOnlyCeritfied,this.searchModel.editorNo).subscribe((data)=>{
         if(data)
         {
           this.editorList=data;
           this.totalItems=data.length;
           _.each(this.editorList, function(item:EditorSearchModel){
             let spList = _.pluck(item.Speciality.slice(0,5), 'Name');
             item.SpecializedFiled=spList.join(',');
           })

         }
       })
     }
     clearFilter(){
       this.searchModel.specialityId=null;
       this.searchModel.isOnlyCeritfied=false;
       this.searchModel.editorNo=null;
       this.searchModel.sourceLanguageId='1484e197-70f8-4f64-98cf-e0f06eff6f49';
       this.searchModel.targetLanguageId='1484e197-70f8-4f64-98cf-e0f06eff6f49';
       this.searchEditorNow();
     }

  ngOnInit() {
  }

}
