export enum MessageStatus {
        Sent = 1,
        Unread = 2,
        Read = 3,
        Replied = 4
}

export enum PlanType {
        Light = 1,
        Business = 2,
        Expert = 3
}

export enum OrderOperationType {
        ACCEPT,
        DELIVERY,
        PAYMENT_COMPLETE,
        POST_PAY,
        POST_PAY_APPROVED,
        POST_PAY_DECLINED,
        CANCEL,
        EVALUATION,
        SECOND_EDITOR_DELIVERY,
        ORDER_CREATED,
        REJECT
}

export enum OrderDeliveryMode {
        VERY_FAST,
        FAST,
        NORMAL,
        SLOW
}

export enum NotificationType {
        SUCCESS,
        WARNING,
        ERROR
}

export enum DocumentVolume {
        SMALL,
        MEDIUM,
        LARGE
}

export enum EnglishLanguageType {
        BritishEnglish = 1,
        AmericanEnglish = 2,
        AustralianEnglish = 3,
        OtherEnglish = 4
}