import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminTestingComponent } from './admin-testing/admin-testing.component';
import { MonthlyInvoiceComponent } from './monthly-invoice/monthly-invoice.component';

@NgModule({
  declarations: [AdminTestingComponent, MonthlyInvoiceComponent],
  imports: [
    CommonModule,
    FormsModule,
    AdminRoutingModule
  ]
})
export class AdminModule { }
