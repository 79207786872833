import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import {DataShareService} from '../services/data-share.service';
import {DataSharingModel} from '../models/data-sharing-model';
import { Title,Meta  }     from '@angular/platform-browser';
import { LanguageModel } from '../models/language.model';
import { SpecializedFieldModel } from '../models/specialized-field.model';
import { EstimationService } from '../services/estimation.service';
import { CommonListService } from '../services/common-list.service';
import { PriceMatrixService } from '../services/price-matrix.service';
import { DeliveryTimeSetSettingsModel } from '../models/delivery-time-set-settings.model';
import { DocumentVolume, OrderDeliveryMode, PlanType } from '../Enums.enum';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.css']
})
export class PriceComponent implements OnInit {

  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  languages: Array<LanguageModel>;
  SpecializedFieldList: Array<SpecializedFieldModel>;
  estimationService: EstimationService;
  commonListService: CommonListService;
  isDoubleCheck:boolean;
  priceMatrixService:PriceMatrixService;
  editingFieldAreaCode:string;
  priceList:Array<DeliveryTimeSetSettingsModel>;
  languageTo:string;
  languageFrom:string;

  constructor(private _router: Router,
    private dataShareService:DataShareService,
    private _estimationService: EstimationService,
    private _commonListService: CommonListService,
    private _priceMatrixService:PriceMatrixService,
    private titleService: Title,
    private meta: Meta) { 
    this.router=_router.url;
    this.sharedService=dataShareService;
    this.estimationService = _estimationService;
    this.commonListService = _commonListService;
    this.priceMatrixService=_priceMatrixService;
    this.isDoubleCheck=false;
    this.languageFrom='1484e197-70f8-4f64-98cf-e0f06eff6f49';
    this.languageTo='1484e197-70f8-4f64-98cf-e0f06eff6f49';
    this.editingFieldAreaCode="06";
    this.shareableData=new DataSharingModel();
    this.SpecializedFieldList=new Array<SpecializedFieldModel>();
    this.priceList=new Array<DeliveryTimeSetSettingsModel>();
    this.shareableData.currentRoute=this.router;
    this.shareableData.currentStateName=this.sharedService.currentCulture=="jp"? "料金表" : "Price List";
    this.sharedService.setSharedData(this.shareableData);


    if(this.sharedService.currentCulture=='jp'){

      this.titleService.setTitle("英文校正,英文エディティングの料金表");
      this.meta.updateTag({ name: 'keywords', content: '料金表,お支払,校正価額,校正プロ,エディティングプロ,論文校正,エディティング,学術校正,医学論文校正,論文校正専門,論文エディティング' });
      this.meta.updateTag({ name: 'description', content: '英文校正,英文エディティングの料金表。プロのエディターによる英文校正料金表をご確認下さい。お客様のご希望に併せて納期や品質プランに併せて御対応させて頂きます。' });

    }else{
      this.titleService.setTitle("editing-Pro.,English editing price list");
      this.meta.updateTag({ name: 'keywords', content: '"price list,payment,editing price,editing-Pro,editing-Pro.,academic writingediting,editing,academic editing,medical academic writingediting,academic writingeditingprofessional,academic writingediting"' });
      this.meta.updateTag({ name: 'description', content: 'editing-Pro.,English editing price list. Please check the price list for professional editors editing-Pro. Your customized order according to your deadline and quality plan. ' });

    }

    this.getLanguageList();
    this.getSpecializedFieldList();
    this.getEditingChargeSettings();
    
  
  }

  getDeliverTimeSetting(isDoubleCheck=false){
    this.estimationService.getDeliveryTimeSetSettings(isDoubleCheck).subscribe((data)=>{
      if(data && data.length>0)
      {
        let groupId:number=data[0].GroupID;
        let seqCounter:number=0;
        data.forEach(item=>{
          let planType:PlanType=PlanType.Light;//for some cases no Light plan available
          if(this.editingFieldAreaCode=="01" || this.editingFieldAreaCode=="04" || 
          this.editingFieldAreaCode=="10" || this.editingFieldAreaCode=="13" )
          {
            planType=PlanType.Business;
          }
          else{
            planType=PlanType.Light;
          }

          let docVolume:DocumentVolume=DocumentVolume.MEDIUM;
          let deliveryMode:OrderDeliveryMode=seqCounter;


          if(item.WordCountMaxLimit<=1500)
          {
            docVolume=DocumentVolume.SMALL;
          }
          else if(item.WordCountMaxLimit>1500 && item.WordCountMaxLimit<=6000)
          {
            docVolume=DocumentVolume.MEDIUM;
          }
          else
          {
            docVolume=DocumentVolume.LARGE;
          }

          item.BaseFee=this.priceMatrixService.calculateBasePriceForPriceList(isDoubleCheck,this.editingFieldAreaCode,deliveryMode,planType,docVolume)//1 for light
       
          
          if(item.GroupID==groupId)
          {
            seqCounter++;
          }
          else{
            seqCounter=0;
            groupId=item.GroupID;
          }

        });

        this.priceList=data;
      }
    });
  }

  parameterChanged(value:any)
  {
    this.getDeliverTimeSetting(value);
  }

  getLanguageList() {
    this.commonListService.getAllLanguages(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.languages = data;
    });
  }
  getSpecializedFieldList() {
    this.commonListService.getSpecializedFieldList(this.sharedService.currentCulture).subscribe((data) => {
      if (data)
        this.SpecializedFieldList = data;
    });
  }

  private getEditingChargeSettings() {
    this.estimationService.getEditingChargeSettings().subscribe((data) => {
       if (data) {
          this.priceMatrixService.editingChargeSettings = data;
          this.getDeliverTimeSetting();
       }
    });
 }

  ngOnInit() {
  }

}
