import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../services/customer-service.service';
import { saveAs } from "file-saver";
import { OrderWeb } from '../models/order-web.model';
import { EstimationService } from '../services/estimation.service';
import { CompanyModel } from '../models/company-model.model';
import { DataShareService } from '../services/data-share.service';
import { MiscellaneousModel } from '../models/miscellaneous-model.model';

@Component({
  selector: 'app-invoice-template',
  templateUrl: './invoice-template.component.html',
  styleUrls: ['./invoice-template.component.css']
})
export class InvoiceTemplateComponent implements OnInit {

  customerService: CustomerService;
  model: { EstimationModel: any, Html: string, Css: string };
  itemList: Array<any>;
  orderDetail: OrderWeb;
  estimationService: EstimationService;
  orderNo: string;
  customerModel: CompanyModel = new CompanyModel();
  fixedDataModel: any;
  obj: any;
  baseURL: string;
  sharedService: DataShareService;
  orderYear: number;
  orderMonth: number;


  constructor(private _customerService: CustomerService,
    private _sharedService: DataShareService,
    private _estimationService: EstimationService) {
    this.customerService = _customerService;
    this.estimationService = _estimationService;
    this.sharedService = _sharedService;
    this.itemList = new Array<any>();
    this.orderDetail = new OrderWeb();
    this.model = { EstimationModel: null, Html: "", Css: "" };
    this.baseURL = window.location.origin;

    let modalData: MiscellaneousModel = this.sharedService.getModalData();
    if (modalData) {
      this.orderMonth = modalData.OrderMonth;
      this.orderYear = modalData.OrderYear;
    }

    if (localStorage.getItem("currentUser")) {
      let clientId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
    }
    this.fixedDataModel = {};
    this.getCustomerModel();
    this.getOrderDetails();
    this.obj = {};
    this.obj.CustomerInformation = {};
    this.fillFixedDataModel();
  }

  private getPaymentDate(deliverydate:Date)
  {
    let date = new Date(deliverydate), y = date.getFullYear(), m = date.getMonth();
    let lastDay = new Date(y, m + 2, 0); //last date of the month
    return lastDay;
  }
  
private getInvoiceNumber(orderList:OrderWeb[])
{
  let invoiceNo:string='';
  if(orderList && orderList.length>0)
  {
    if(orderList && orderList.length==1)
    {
     invoiceNo=orderList[0].OrderNo;
    }
    else //multi order
    {
      let today:Date=new Date();
      let yearPart:string=today.getFullYear().toString().substr(-2);
      let userIDPart:string=this.sharedService.currentUser.RegistrationID.toString().substr(-5);
      let month:number=today.getMonth()+1;
      let monthPart:string='';
      if(month<10)
      {
        monthPart='0'+month;
      }
      else
      {
        monthPart=month.toString();
      }
      invoiceNo=userIDPart+yearPart+monthPart;         
    }
  }
  
   return invoiceNo;
}

  fillFixedDataModel() {
    this.obj.IsSingleOrder=false;
    let options = { year: 'numeric', month: 'long', day: 'numeric' };

    this.fixedDataModel.ClientName = 'Kyushu University';
    this.fixedDataModel.PersonInCharge = 'Ms. Reiko Kamioka';
    this.fixedDataModel.Company = ' 株式会社 ビーコス ';
    this.fixedDataModel.BranchCompany = 'editing-Pro.事務局';
    this.fixedDataModel.UserPostCode = '〒 105-0013';
    this.fixedDataModel.UserAddress = '東京都港区浜松町2-1-3 第二森ビル4F';
    this.fixedDataModel.Contact = 'TEL +81-3-5408-9540 FAX +81-3-3433-3320';
    this.fixedDataModel.Email = 'info@editing-pro.jp';
    this.fixedDataModel.PaymentDate=this.getPaymentDate(new Date()).toLocaleDateString('ja-JP', options);
    this.fixedDataModel.ConsumptionTax = 0;
    this.fixedDataModel.Total = 0;
    this.fixedDataModel.Remarks1 = 'お支払いにつきましては、下記の口座へお願い致します。';
    this.fixedDataModel.Remarks2 = '振込手数料はお客様のご負担でお願い致します';
    this.fixedDataModel.Remarks3 = 'お振込名義が請求書宛名と違う場合はお手数ですが、ご一報頂けます様お願い致します。';
    this.fixedDataModel.Advise1 = 'この度は弊社のサービスをご利用頂き、誠にありがとうございます。';
    this.fixedDataModel.Advise2 = '下記の通りご請求差し上げます。';
    this.fixedDataModel.Advise3 = '次回も何卒御用命くださいますよう、お願い申し上げます。';
    this.fixedDataModel.Conclusion1 = 'We are supporting International Business and Multicultural Communication';
    this.fixedDataModel.Conclusion2 = "私たちは在住外国人のネットワークを活かし、海外ビジネスや国際交流をサポートします。";

    this.fixedDataModel.CompanyBankName = '三井住友銀行 ';
    this.fixedDataModel.CompanyBankBranchName = '浜松町支店';
    this.fixedDataModel.CompanyBankAccountType = '普通';
    this.fixedDataModel.CompanyBankAccountNo = '7471947';
    this.fixedDataModel.CompanyBankAccountHolderName = '株式会社 ビーコス';
    this.fixedDataModel.CompanyBankSwiftCode = 'SMBCJPJT';

  }


  downloadPdfTemplate() {
    this.model.Html = document.querySelector(".readonly-template-dom").innerHTML;
    this.model.Css = document.querySelector(".container-css").innerHTML;
    this.customerService.generatePdfTemplate(this.model).subscribe((data) => {
      let today: Date = new Date();
      let yearPart: string = today.getFullYear().toString().substr(-2);
      let month: number = today.getMonth() + 1;
      let monthPart: string = '';
      if (month < 10) {
        monthPart = '0' + month;
      }
      else {
        monthPart = month.toString();
      }
      let PDFName: string = this.sharedService.currentUser.RegistrationID+yearPart+monthPart + '_請求書' + '.pdf';
      saveAs(data, PDFName);
      this.sharedService.bsModalRef.hide();
    });
  }

  getOrderDetails() {
    let clientId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
    this.estimationService.getInvoiceOrdersByClientId(clientId, this.orderMonth, this.orderYear).subscribe((data) => {
      if (data) {

        this.fixedDataModel.InvoiceNo=this.getInvoiceNumber(data);
        //init some data
        let options = { year: 'numeric', month: 'long', day: 'numeric' };
        if(data.length==1)
        {
          this.fixedDataModel.DeliveryDate = new Date(data[0].EndDate).toLocaleDateString('ja-JP', options);
          this.obj.IsSingleOrder=true;
        }
        

        var paydate = new Date(data[0].PaymentDate);
        //this.obj.PaymentDate = new Date(paydate.getFullYear(), paydate.getMonth() + 2, 0).toLocaleDateString('ja-JP', options); //last date of Next month

        let CurrentDate: Date = new Date();
        this.obj.CurrentDate = CurrentDate == null ? null : new Date(CurrentDate).toLocaleDateString('ja-JP', options);
        this.obj.DeliveryDateJp = new Date(data[0].DeliveryDate).toLocaleDateString('ja-JP', options);

          data.forEach(order => {
          let detailObj = {
            OrderNo:order.OrderNo,
            PaymentAmount:order.PaymentAmount,
            // Remarks: this.orderDetail.SourceLanguage + '→' + this.orderDetail.TargetLanguage + ' ' + this.orderDetail.DeliveryLevelName + ' ' + this.orderDetail.TranslationFieldName +' ' + this.orderDetail.DeliveryPlan, AmountOfMoney: this.orderDetail.PaymentAmount, ConsumptionTax: this.orderDetail.ConsumptionTax
            Remarks: order.OrderNo+' '+order.OrderName, AmountOfMoney: order.PaymentAmount, ConsumptionTax: order.ConsumptionTax
          }
          this.itemList.push(detailObj);
        });

        this.obj.TotalEstimatedPrice = 0;
        this.obj.TotalConsumptionTax = 0;
        this.obj.PaymentAmountWithoutTax=0;
        for (let i = 0; i < this.itemList.length; i++) {
          this.obj.TotalEstimatedPrice = this.obj.TotalEstimatedPrice + this.itemList[i].AmountOfMoney;
          this.obj.TotalConsumptionTax = this.obj.TotalConsumptionTax + this.itemList[i].ConsumptionTax;
        }
        
        this.fixedDataModel.ConsumptionTax = this.obj.TotalConsumptionTax;
        this.fixedDataModel.Total = this.orderDetail.EstimatedPrice; 
        this.fixedDataModel.FeesHead=this.orderYear+"年"+this.padNumber(this.orderMonth)+"月ご依頼分("+this.itemList.length+"件分)";
        this.obj.PaymentDate=this.getPaymentDate(data[0].DeliveryDate);
        this.obj.PaymentAmountWithoutTax=this.obj.TotalEstimatedPrice - this.obj.TotalConsumptionTax;

      }

    });
  }

  private padNumber(n) {
    return (n < 10) ? ("0" + n) : n;
}
  getCustomerModel() {
    let clientId: string = JSON.parse(localStorage.getItem("currentUser")).ID;
    this.customerService.getCompanyById(clientId).subscribe((data) => {
      this.customerModel = data;
    })
  }

  ngOnInit() {
  }

}
