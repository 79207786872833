import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { ConfirmDialogComponent, confirmDialogConfig } from '../confirm-dialog/confirm-dialog.component';
import { ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { EstimationService } from '../services/estimation.service';
import { ToastrService } from 'ngx-toastr';
import { OrderWeb } from '../models/order-web.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { UtilityService } from '../services/utility.service';
import { CommonListService } from '../services/common-list.service';
import { LanguageModel } from '../models/language.model';
import { SpecializedFieldModel } from '../models/specialized-field.model';

@Component({
  selector: 'app-free-editing-estimation',
  templateUrl: './free-editing-estimation.component.html',
  styleUrls: ['./free-editing-estimation.component.css']
})
export class FreeEditingEstimationComponent implements OnInit {
  estimationOrderList:{ currentPage: number, totalItems: number, dataSource: OrderWeb[] };
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  ngbModalOptions: ModalOptions;
  ngbModalOptionsNonEnglishAlert: ModalOptions;
  estimationInputText: string;
  estimationInputLimit: number = 20000;
  esimationService:EstimationService;
  toastr: ToastrService;
  uploadedDocuments: Array<any>;
  uploadedFiles: { File1: any, File2: any, File3: any };
  fileUploadProgressBarValue: number;
  orderList:Array<OrderWeb>;
  pricesPerWord:{Light:number,Business:number,Expert:number};
  specializedFieldID:string;
  languageTo:string;
  languageFrom:string;
  languages: Array<LanguageModel>;
  SpecializedFieldList: Array<SpecializedFieldModel>;
  commonListService: CommonListService;
  isNonEnglishConditionAgreed:boolean;
  isNonEnglishConditionAgreedAlertShown:boolean;

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private _commonListService: CommonListService,
    private titleService: Title,
    private _toastr: ToastrService,
    private utility: UtilityService,
    private modalService: BsModalService,
    private meta: Meta,
    private _esimationService:EstimationService) {
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.commonListService=_commonListService;
    this.toastr=_toastr;
    this.estimationOrderList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.estimationInputText = "";
    this.esimationService=_esimationService;
    this.uploadedDocuments = new Array<any>();
    this.uploadedFiles = { File1: null, File2: null, File3: null };
    this.fileUploadProgressBarValue = 0;
    this.pricesPerWord={ Light:null,Business:null,Expert:null};
    this.languageFrom='1484e197-70f8-4f64-98cf-e0f06eff6f49';
    this.languageTo='1484e197-70f8-4f64-98cf-e0f06eff6f49';
    this.SpecializedFieldList=new Array<SpecializedFieldModel>();
    this.specializedFieldID=null;

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = this.sharedService.currentCulture=='jp'?"校正無料見積":"Free estimation for editing";
    this.sharedService.setSharedData(this.shareableData);


    if(this.sharedService.currentCulture=='jp'){

      this.titleService.setTitle("オンラインからの専門英語校正");
      this.meta.updateTag({ name: 'keywords', content: '英文,英文校正,英文エディター,専門校正,すぐ対応,簡単,品質の秘密,品質保証,校正依頼,校正依頼方法,校正方法,校正のコツ,論文校正,エディティング,学術校正,医学論文校正,論文校正専門,論文エディティング,エディティングプロ' });
      this.meta.updateTag({ name: 'description', content: 'オンラインからの英語校正ならいつでもお任せください。24時間365日,オンラインで利用できる校正サービス。納品物の受領までウェブ上で完結。簡単,早い,確実！お問い合わせ,お見積りは無料。' });
    }
    else{

      this.titleService.setTitle("professional editing-Pro. Online");
      this.meta.updateTag({ name: 'keywords', content: 'English writing,editing-Pro.,English writingeditor,professionalediting,quick responce, secret for quality,qualityguarantee,editing order,editing how,editing how to,editing tips,academic writingediting,editing, academic editing,medical academic writing editing,academic writingeditingprofessional,academic writing editing,editing-Pro.' });
      this.meta.updateTag({ name: 'description', content: 'Online English editing available 24hours for 365 days. Online editing service that allows order to submission through online. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });

    }

    this.esimationService.getEstimationWebOrderList(1,20,true,this.sharedService.currentCulture).subscribe((data) => { //same as top page
      this.estimationOrderList.dataSource = data;
      if (data.length > 0)
        this.estimationOrderList.totalItems = data[0].TotalCount;
    });

    this.getLanguageList();
    this.getSpecializedFieldList();
    this.initModalConfig();
    this.getChargeSettings();
    this.initNonEnglishModalConfig();
  }

  getSpecializedFieldList() {
    this.commonListService.getSpecializedFieldList().subscribe((data) => {
      if (data)
        this.SpecializedFieldList = data;
    });
  }

  getLanguageList() {
    this.commonListService.getAllLanguages().subscribe((data) => {
      if (data)
        this.languages = data;
    });
  }

  getChargeSettings()
  {
    this.esimationService.getEditingChargeSettings().subscribe((data)=>{
          if(data)
          {
            this.pricesPerWord.Business=data.BaseCharge * data.Business - (data.BaseCharge * data.Business * 0.1);
            this.pricesPerWord.Expert=data.BaseCharge * data.Expert - (data.BaseCharge * data.Expert * 0.1);
            this.pricesPerWord.Light=data.BaseCharge * data.Light - (data.BaseCharge * data.Light * 0.1);
          }
    });
  }

  initModalConfig() {
    let config: confirmDialogConfig = {
      MessageText: "英語20,000wordまで入力可能です",
      IsOkOnly: true
    };
    this.ngbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-sm",
      initialState: config
    };
  }

  private checkSpecializedField()
  {
    if(this.specializedFieldID)
    {
      return true;
    }
    else
    {
      let msg:string=this.sharedService.currentCulture=='jp'? '自動見積の際にはエディタ言語と専門分野を先にお選びください。' : 'Please choose the Specialized field for Estimation.';
      this.toastr.warning(msg);
      return false;
    }
  }

  private nonEnglishConditionAlertYesCallback()
  {
    this.isNonEnglishConditionAgreed=true;
    this.isNonEnglishConditionAgreedAlertShown=true;
    this.estimateByText();
  }
  private nonEnglishConditionAlertNoCallback()
  {
    this.isNonEnglishConditionAgreed=false;
    this.isNonEnglishConditionAgreedAlertShown=false;
  }

  initNonEnglishModalConfig() {

    let msgText:string="";
    if(this.sharedService.currentCulture=="jp")
    {
      msgText="原稿に英語ではない文字が含まれています。英文校正の依頼では英語のみが校正対象になりますのでご了承ください" ;
    }
    else
    {
      msgText="There is a manuscript containing non-English characters. In English editing the character is excluded. Would you all right?";
    }
 

    let config: confirmDialogConfig = {
      MessageText: msgText,
      IsYesNo: true,
      YesBtnCallback:this.nonEnglishConditionAlertYesCallback.bind(this),
      NoBtnCallback:this.nonEnglishConditionAlertNoCallback.bind(this)
    };
    this.ngbModalOptionsNonEnglishAlert = {
      backdrop: 'static',
      keyboard: false,
      class: "modal-sm",
      initialState: config
    };
  }


  inputBoxWordLimitValidation() {
    if (this.estimationInputText.length > 0) {
      var words = this.estimationInputText.match(/\S+/g).length;
      if (words > this.estimationInputLimit) {
        this.modalService.show(ConfirmDialogComponent, this.ngbModalOptions);
        return false;
      }
      else
       return true;
    }
    else
      return false;
  }

  estimateByText() {

    if(this.isNonEnglishConditionAgreedAlertShown==false && this.utility.isContainNonEnglishText(this.estimationInputText))
    {
      if(this.isNonEnglishConditionAgreedAlertShown==false)
      {
        this.modalService.show(ConfirmDialogComponent, this.ngbModalOptionsNonEnglishAlert);
      }
      if(!this.isNonEnglishConditionAgreed)
      return;
    }

    if(!this.checkSpecializedField())
     return;

    if(!this.inputBoxWordLimitValidation())
    return;

    this.esimationService.getManuscriptByPlainTextEstimated(this.estimationInputText).subscribe((data)=>{
      if(data)  
       {
        localStorage.removeItem("estimationResult"); //remove if previously any
        localStorage.removeItem("selectedPlanCourseButtonId");
        localStorage.removeItem("newEstimationBasicPreference");
        localStorage.removeItem("orderCopyModelForReorderSimilar");
        localStorage.setItem("estimationResult",JSON.stringify(data));
        localStorage.setItem("newEstimationBasicPreference", JSON.stringify({SpecializedFieldID:this.specializedFieldID,FromLangID:this.languageFrom,ToLangID:this.languageTo}));
        localStorage.removeItem("servicePreferenceModel"); //remove if any previous
        this._router.navigateByUrl("/estimation");  
       }
    });
  }

  fileSelected(event: any, from: number) {  //this time developed by regarding that only one file will be uploaded
    if (from == 1)
      this.uploadedFiles.File1 = event.target.files[0];
    else if (from == 2)
      this.uploadedFiles.File2 = event.target.files[0];
    else
      this.uploadedFiles.File3 = event.target.files[0];

      this.fileUploadProgressBarValue = 0;
  }

  uploadEstimationDocuments() {
    this.fileUploadProgressBarValue = 0; //dummy progress bar
    setTimeout(() => {
      if (this.uploadedFiles.File1) {
        if (!this.utility.isDuplicateFile(this.uploadedFiles.File1, this.uploadedDocuments)) {
          this.uploadedDocuments.push(this.uploadedFiles.File1);
          this.fileUploadProgressBarValue = 33;
        }
      }
      if (this.uploadedFiles.File2) {
        if (!this.utility.isDuplicateFile(this.uploadedFiles.File2, this.uploadedDocuments)) {
          this.uploadedDocuments.push(this.uploadedFiles.File2);
          this.fileUploadProgressBarValue += 33;
        }
      }
      if (this.uploadedFiles.File3) {
        if (!this.utility.isDuplicateFile(this.uploadedFiles.File3, this.uploadedDocuments)) {
          this.uploadedDocuments.push(this.uploadedFiles.File3);
          this.fileUploadProgressBarValue += 34;
        }
      }
      if (this.fileUploadProgressBarValue > 0) //if atleast a file selected
        this.fileUploadProgressBarValue = 100;

    }, 300);

  }

  estimateByDocuments() {

    if(!this.checkSpecializedField())
     return;

    if (this.uploadedDocuments.length > 0) {
      this.esimationService.getManuscriptByUploadedDocumentEstimated(this.uploadedDocuments).subscribe((data) => {
        if (data) {
          localStorage.setItem("estimationResult",JSON.stringify(data));
          localStorage.removeItem("selectedPlanCourseButtonId");
          localStorage.removeItem("newEstimationBasicPreference");
          localStorage.removeItem("orderCopyModelForReorderSimilar");
          localStorage.setItem("newEstimationBasicPreference", JSON.stringify({SpecializedFieldID:this.specializedFieldID,FromLangID:this.languageFrom,ToLangID:this.languageTo}));
          localStorage.removeItem("servicePreferenceModel"); //remove if any previous
          this._router.navigateByUrl("/estimation");  
        }
      });
    }
    else {
      return;
    }

  }

  onWebOrderPageIndexChange(event: PageChangedEvent) {
    this.esimationService.getEstimationWebOrderList(event.page,20,true,this.sharedService.currentCulture).subscribe((data) => {
      this.estimationOrderList.currentPage = event.page;
      this.estimationOrderList.dataSource = data;
      if (data.length > 0)
        this.estimationOrderList.totalItems = data[0].TotalCount;
    });
  }


  ngOnInit() {
  }

}
