import { Component, OnInit } from '@angular/core';
import {DataShareService} from '../../services/data-share.service';
import {DataSharingModel} from '../../models/data-sharing-model';
import { Router } from '@angular/router';
import { CustomerService } from '../../services/customer-service.service';
import { MessageModel } from '../../models/message-model.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Title,Meta  }     from '@angular/platform-browser';
import { EstimationService } from 'src/app/services/estimation.service';
import { OrderWeb } from 'src/app/models/order-web.model';


@Component({
  selector: 'app-customer-my-page',
  templateUrl: './customer-my-page.component.html',
  styleUrls: ['./customer-my-page.component.css']
})
export class CustomerMyPageComponent implements OnInit {
  router:string ;
  sharedService:DataShareService;
  shareableData:DataSharingModel;
  customerService:CustomerService;
  estimationService:EstimationService;
  unreadMessageList:{currentPage:number,totalItems:number,dataSource:MessageModel[]};
  customerOrderList:{currentPage:number, totalItems:number, dataSource:OrderWeb[]};

  constructor(private _router: Router,
              private _dataShareService:DataShareService,
              private _estimationService:EstimationService,
              private _customerService:CustomerService,
              private titleService: Title,
              private meta: Meta) {
      this.router=_router.url;
      this.sharedService=_dataShareService;
      this.customerService=_customerService;
      this.estimationService=_estimationService;

      this.unreadMessageList={ currentPage:1, totalItems:0,dataSource:null};
      this.customerOrderList={ currentPage:1, totalItems:0, dataSource:null};

      this.shareableData=new DataSharingModel();
      this.shareableData.currentRoute=this.router;
      this.shareableData.currentStateName="My Page";
      this.sharedService.setSharedData(this.shareableData);



      if(this.sharedService.currentCulture=='jp'){

        this.titleService.setTitle("英文校正サイトのMypage（お客様）");
        this.meta.updateTag({ name: 'keywords', content: '英文,英文校正,英文エディター,専門校正,すぐ対応,簡単,品質,校正依頼,校正依頼方法,校正方法,論文校正,エディティング,学術校正,医学論文校正,論文校正専門,論文エディティング' });
        this.meta.updateTag({ name: 'description', content: '英文校正サイトのMypageの情報がご確認頂けます。24時間365日対応。オンラインで利用できる校正サービス。納品物の受領までウェブ上で完結。簡単,早い,確実！30分からの短時間納品も可能です。お問い合わせ,お見積りは無料' });

      }
      else{

        this.titleService.setTitle("editing-Pro.Mypage（Customer））");
        this.meta.updateTag({ name: 'keywords', content: 'English writing,editing-Pro.,English writing editor,professional editing,quick,easy,quality,editing order,editinghow to order,editing method,academic writing editing,editing,academic editing,medical academic writingediting,academic writing editing professional,academic writing editing' });
        this.meta.updateTag({ name: 'description', content: 'Confirm mypage infromation of editing-Pro. 24hours service for 365 days. Online editing service that allows order to submission through online. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });

      }

      if(localStorage.getItem("stateBeforeLogin"))
      {
        let stateToGo:string =localStorage.getItem("stateBeforeLogin");
        this._router.navigate([stateToGo]).then(function(status){
          if(status)
          {
            console.log("Removing SavedState Info...");
            localStorage.removeItem("stateBeforeLogin");
          }
        });  
      }
      
      this.getOrderList(1);
     }

     getUnreadMessages()
     {
      this.customerService.getUnreadMessages(this.sharedService.currentUser.ID,1).subscribe((data)=>{
        if(data){
        this.unreadMessageList.currentPage=1;
        this.unreadMessageList.dataSource=data;
        if(data.length)
         this.unreadMessageList.totalItems=data[0].TotalRows;
        }
        
      });
     }

     onPageIndexChange(event:PageChangedEvent)
     {
      this.customerService.getUnreadMessages(this.sharedService.currentUser.ID,event.page).subscribe((data)=>{
        if(data)
        {
          this.unreadMessageList.currentPage=event.page;
          this.unreadMessageList.dataSource=data;
          if(data.length>0)
          this.unreadMessageList.totalItems=data[0].TotalRows;
        }
     });
    }
     
    onWebOrderPageIndexChange(event: PageChangedEvent) {
      this.getOrderList(event.page);
    }

    getOrderList(pageNo:number){
      this.estimationService.getCustomerOrderListByCustomerID(this.sharedService.currentUser.ID, pageNo).subscribe((data) => {
        this.customerOrderList.currentPage = pageNo;
        this.customerOrderList.dataSource = data;
        if (data.length > 0)
          this.customerOrderList.totalItems = data[0].TotalCount;
      });
    }

  ngOnInit() {
    this.getUnreadMessages();
    if(this.sharedService.bsModalRef)
    {
      this.sharedService.bsModalRef.hide();
    }
  }

}
