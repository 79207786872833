import { Component, OnInit } from '@angular/core';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { OrderWeb } from '../../models/order-web.model';
import { DataShareService } from '../../services/data-share.service';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { DataSharingModel } from '../../models/data-sharing-model';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-sales-s',
  templateUrl: './sales-s.component.html',
  styleUrls: ['./sales-s.component.css']
})
export class SalesSComponent implements OnInit {
  affiliateService:AffiliateService;
  webOrderList: { currentPage: number, totalItems: number, dataSource: OrderWeb[] };
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  pageSize:number;
  orderNo:string;
  staffNo:number;
  startDate:Date;
  endDate:Date;
  utilityService: UtilityService;

  constructor(private _affiliateService:AffiliateService, private _router: Router,
    private dataShareService: DataShareService,
    private _utilityService: UtilityService,
    private titleService: Title,
    private meta: Meta) { 
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.affiliateService = _affiliateService;
    this.utilityService=_utilityService;
    this.pageSize=20;

    this.endDate=new Date();
    this.startDate=this.utilityService.addMonthsToDate(new Date(this.endDate),-1);

    this.webOrderList = { currentPage: 1, totalItems: 0, dataSource: null };
    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName = "注文内訳";
    this.sharedService.setSharedData(this.shareableData);
    this.titleService.setTitle("売上内訳（登録翻訳者）｜英文校正(editing-Pro.)管理者ページ");
    this.meta.addTag({ name: 'keywords', content: '英文校正、editing-Pro., 管理者, 注文内訳' });
    this.meta.addTag({ name: 'description', content: 'エディティング者プラスコーディネーターによるエディティングチェックで、簡単、確実な英語エディティングサービスをご提供中。高品質なエディティングをお届けします。お見積りは無料。' });
    this.clearAll();
  }

  clearAll()
  {
    this.endDate=new Date();
    this.startDate=this.utilityService.addMonthsToDate(new Date(this.endDate),-1);
    this.orderNo="";
    this.staffNo=null;
    this.getEditorSalesBreakdownList();
  }

  search()
  {
    this.getEditorSalesBreakdownList();
  }

  getEditorSalesBreakdownList(pageNo:number=1)
  {
      let clientId = JSON.parse(localStorage.getItem("currentUser")).ID;
      //clientId = '533ddce8-e199-415b-95a8-77fd0461c61d';
    this.affiliateService.getEditorSalesBreakdown(clientId, 1, 2019,3,this.orderNo,this.startDate,this.endDate,this.staffNo ,this.pageSize,pageNo, 'jp', 3).subscribe(data=>{
      if(data)
      {
        this.webOrderList.currentPage = pageNo;
        this.webOrderList.dataSource = data;
        if (data.length > 0)
          this.webOrderList.totalItems = data[0].TotalCount;
      }
    });
  }

  onWebOrderPageIndexChange(event: PageChangedEvent) {
    if (localStorage.getItem("currentUser")) {
      let clientId = JSON.parse(localStorage.getItem("currentUser")).ID;
      this.getEditorSalesBreakdownList(event.page);
    }
  }

  ngOnInit() {
  }

}
