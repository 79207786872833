import { Component, OnInit } from '@angular/core';
import { DataShareService } from '../services/data-share.service';
import { DataSharingModel } from '../models/data-sharing-model';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services/user.service';
import { UserType } from '../models/credential.model';

@Component({
  selector: 'app-password-reissue',
  templateUrl: './password-reissue.component.html',
  styleUrls: ['./password-reissue.component.css']
})
export class PasswordReissueComponent implements OnInit {
  router: string;
  sharedService: DataShareService;
  shareableData: DataSharingModel;
  passwordReissue: { email: string };
  userService: UserService;
  userType: UserType;

  constructor(private _router: Router,
    private dataShareService: DataShareService,
    private _userService: UserService,
    private titleService: Title,
    private meta: Meta,
    private _toastr: ToastrService) {
    this.router = _router.url;
    this.sharedService = dataShareService;
    this.userService = _userService;

    this.passwordReissue = { email: null };

    if (localStorage.getItem('PasswordReissueFor')) {
      let issueFor: string = localStorage.getItem('PasswordReissueFor');
      if (issueFor == 'Customer') {
        this.userType = UserType.Customer;
      }
      else if (issueFor == 'Editor') {
        this.userType = UserType.Editor;
      }
      else {
        this.userType = UserType.Partner;
      }
    }
    else {
      this._router.navigateByUrl('/404');
    }

    this.shareableData = new DataSharingModel();
    this.shareableData.currentRoute = this.router;
    this.shareableData.currentStateName= this.dataShareService.currentCulture =='jp'? "パスワード再発行":"Password reissue";
    this.sharedService.setSharedData(this.shareableData);



    if(this.sharedService.currentCulture=='jp'){

      this.titleService.setTitle("英文校正サイトのパスワード再発行");
      this.meta.updateTag({ name: 'keywords', content: 'パスワード再発行,英語校正,英語,専門エディター校正,英語エディター,校正会社,論文校正,英語論文チェック,校正チェック' });
      this.meta.updateTag({ name: 'description', content: '英文校正サイトのアカウントパスワードを変更したい場合はこちら。納品物の受領までウェブ上で完結。簡単,早い,確実！お問い合わせ,お見積りは無料。' });

    }else{
      this.titleService.setTitle("editing-Pro. Password reissue");
      this.meta.updateTag({ name: 'keywords', content: 'password reissue,English editing,English,professional editorediting,English editor,editing company,academic writing editing,English academic writing check,editing check' });
      this.meta.updateTag({ name: 'description', content: 'Reissue account password of editing-Pro. One stop service that is easy, fast and safe. Inquiry and estimate are free.' });




    }

  }

  reissuePassword() {
    this.userService.reissuePassword(this.passwordReissue.email, this.userType, this.dataShareService.currentCulture).subscribe((data) => {
      if (data == "Success") {
        this._toastr.success(this.shareableData.currentStateName =='jp'? "新しいパスワードがご登録のEメールに送付されました。":"A new password has been sent to your registration email.");
        localStorage.removeItem('PasswordReissueFor');
      }
      else if (data == "No email matched") {
        this._toastr.warning(this.shareableData.currentStateName =='jp'? "入力したEメールは存在しません。ご登録ください。":"The email you entered does not exist. Please register.");
        localStorage.removeItem('PasswordReissueFor');
      }
      else {
        this._toastr.error(this.shareableData.currentStateName =='jp'? "処理に失敗しました。":"The processing has failed.");
        localStorage.removeItem('PasswordReissueFor');
      }

    });
  }

  ngOnInit() {
  }

}
